import { useContext } from "react";
import { DataContext } from "../../../../../../../../context/DataContext";
import { ActionComponent } from "../../../../../ActionComponent";

export const ApproveContractApproval = (props: any) => {
  const { setApproveContractApproval }: any = useContext(DataContext);
  
  const { data, action , table } = props;

  const when_be_able = data?.contract_issuance_status?.en != "waiting for signature"
  return (
    <ActionComponent
  data={data}
  action={action}
  table={table}
  setData={async () => {
    setApproveContractApproval({
      id: data.id,
      record: data,
      action: action,
      table: table,
    });
  }}
  enabled={when_be_able}
/>
  );
};
