
import ReactMarkdown from 'react-markdown'

import {
  Paper,
  createStyles,
  TextInput,
  PasswordInput,
  Select,
  Checkbox,
  Button,
  Title,
  Text,
  Anchor,
  rem,
  Modal,
} from "@mantine/core";
import {
  useForm,
  isNotEmpty,
} from "@mantine/form";

import { useNavigate } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { useDebouncedValue, useDisclosure, useViewportSize } from "@mantine/hooks";
import { TranslationContext } from "../../../context/TranslationContext";
import { tn, vtn } from "../../../translation";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../../constants";
import { UserContext } from "../../../context/UserContext";
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

const useStyles = createStyles((theme):any => ({
  wrapper: {
    height: "100vh",
    display: "flex",
  },

  form: {
    zIndex: 1,
    background: "#21033F",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowY: "scroll",
    scrollbarWidth: "none",
    // justifyContent: 'center',
    height: "100vh",

    //
    maxHeight: "100vh",
    maxWidth: rem(700),
    paddingTop: rem(80),
    width: "100%",

    [theme.fn.smallerThan("md")]: {
      maxWidth: "100%",
    },
  },
  inner: {
    maxWidth: rem(500),
    width: "100%",
  },

  title: {
    color: "#69DCBF",
    fontSize: "40px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  },
}));
 function RegisterPage() {
  const [opened, { open, close }] = useDisclosure(false);

  const pb: any = new PocketBase(CMS_ENDPOINT);
  const [isErrorE, setisErrorE]: any = useState(false);
  // const {userCurrentData , setUserCurrentData} = useContext(UserContext);
  const validate_text = [
    vtn("case_study"),
    vtn("first_name"),
    vtn("last_name"),
    vtn("taken_email"),
    vtn("email"),
    vtn("password"),
    vtn("password_comfirm"),
    vtn("arabic_name"),
  ];
  const validatorsPassword =[
    vtn("password"),
    vtn("password1"),
    vtn("password2"),
    vtn("password3"),
    vtn("password4"),
    vtn("password5"),
    vtn("password_comfirm"),
  ]
  const validatePassword = (value:any,values:any) => {
      

    // Check for minimum 8 characters
    if (value.length < 8) return validatorsPassword[2];
  
    // Check for at least one uppercase letter
    if (!/[A-Z]/.test(value)) return validatorsPassword[3];
  
    // Check for at least one lowercase letter
    if (!/[a-z]/.test(value)) return validatorsPassword[4];
  
    // Check for at least one of the special characters !@#%?
    if (!/[!”#$%&’()*+,-./:;<=>?@[\]^_`{|}~]/.test(value)) return validatorsPassword[5];
    if(/^\S+ \S+$/.test(value) ) return validatorsPassword?.[0];



    return null;
  };
  const form: any = useForm({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      caseStudy: "",
      password: "",
      confirm: "",
      terms: false,
    },

    validate: {
      caseStudy: isNotEmpty(validate_text?.[0]),
      firstName: (value) =>
        !/^\S+ \S+$/.test(value) && value.length > 2 && !/[0-9]/.test(value)
          ? /^[\u0621-\u064A\s]+$/.test(value)? null:validate_text?.[7]
          : validate_text?.[1],
      lastName: (value) =>
       /^[\u0621-\u064A\s]+$/.test(value)? null:validate_text?.[7],
      email: (value) =>
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
          ? isErrorE
            ? validate_text?.[3]
            : null
          : validate_text?.[4],

      password: validatePassword,
      confirm: (value) =>
        value === form.values.password ? null : validate_text?.[6],
    },
  });
  const [debounced] = useDebouncedValue(form.values.email, 400);

  useEffect(() => {
    const resultList = pb
      .collection("applicants")
      .getList(1, 2, {
        filter: `email = "${form.values.email}"`,
      })
      .then((res: any) => {

        if (
          res.items
            .length === 0
        ) {
          setisErrorE(false);
        } else {
          setisErrorE(true);
        }
      });
  }, [debounced]);

  const navigate = useNavigate();
  const [termsError, setTermsError]: any = useState(false);

  const [isError, setisError]: any = useState(false);

  const { classes } = useStyles();
  const { height, width } = useViewportSize();
  const { locale, translation }: any = useContext(TranslationContext);
  const { userCurrentData, setUserCurrentData }:any = useContext(UserContext);

  const divider = (
    <div
      style={{
        width: width < 500 ? "50%" : "1px",
        height: width < 500 ? "1px" : "15px",
        background: "white",
        margin: width < 500 ? "10px 0px" : "0px 5px",
      }}
    />
  );

  const errorMassage = tn("Invalid email or password");
  const termsTXT = tn("You must accept the terms and conditions");
  const terms = translation?.terms?.[0];

  const case_studies = translation?.case_study;

  return (
    <>
    <Helmet>
      <title>{tn("Create a new account") + " / " + tn("Coophub")}</title>
    </Helmet>
    <form
      onSubmit={form.onSubmit(async (e: any) => {
        if (!form.values.terms) {
          setTermsError(true);
        } else {
        

          const data = {
            // username: name.split(" ").join("_").toLowerCase()+Math.floor(Math.random() * (1000 - 0 + 1)) + 0,
            email:e.email?.toLowerCase(),
            emailVisibility: true,
            password: e.password,
            case_study: {en:case_studies.find((item:any)=>item.name_en == e.caseStudy).name_en,ar:case_studies.find((item:any)=>item.name_en == e.caseStudy).name_ar},
            passwordConfirm: e.confirm,
            first_name: e.firstName.trim() ,
            last_name: e.lastName.trim(),
            gender:{
              ar:"ذكر",
              en:"male"
            },
            GPA: 0,
            status:{
              "ar": "غير نشط",
              "en": "inactive"
            },
            country_mobile_number:"+966",
          };
          try {
            const record = await pb
              .collection("applicants")
              .create(data)
              .then(async (res: any) => {
                // const authData = await pb
                //   .collection("applicants")
                //   .authWithPassword(res.email, e.password);
                const senEmail = await pb
                  .collection("applicants")
                  .requestVerification(res.email?.toLowerCase(),);
                  setUserCurrentData({...res,password:form?.values?.password});
              });

            navigate("/applicant/register_success");
           
          } catch (err) {
            setisError(true);
          }
        }

        // (optional) send an email verification request
        // await pb
        //   .collection("applicants")
        //   .requestVerification(email);
      })}
    >
      <div className={classes.wrapper}>
        <Paper className={classes.form} radius={0} p={30}>
          <div className={classes.inner}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "60px",
                flexDirection: width > 500 ? "row" : "column",
              }}
            >
              <Text className={classes.title}>{tn("you are welcome in")}</Text>
              <img
                style={{ height: "93px",cursor: "pointer" }}
                src="/white_text_logo.png"
                onClick={() => {
                  navigate("/applicant/login");
                }}
              />
            </div>
            <div
              style={{
                display: "flex",

                justifyContent: "center",
                alignItems: "center",
                marginTop: "30px",
              }}
            >
              <div
                style={{
                  width: "208px",
                  height: "60px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#8c8c8c",
                  fontSize: "16px",
                  margin: width > 500 ? "0px 20px" : "0px 7px",
                  border: "1px solid #8c8c8c",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/applicant/login")}
              >
                {tn("Login")}
              </div>
              <div
                style={{
                  background: "rgba(105, 220, 191, 0.77)",
                  width: "208px",
                  height: "60px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                  fontSize: "16px",
                  margin: width > 500 ? "0px 20px" : "0px 7px",
                  cursor: "pointer",
                }}
              >
                {tn("Create a new account")}
              </div>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <TextInput
                  {...form.getInputProps("firstName")}
                  required
                  mt={90}
                  variant="filled"
                  styles={(theme) => ({
                    label: {
                      color: "#69DCBF",
                    },
                    input: {
                      border: "0px solid #69dcbf86",
                      borderRadius: "0px",
                      color: "white",
                      backgroundColor: "#21033F",
                      borderBottom: "1px solid #69dcbf86",
                      "&:focus-within": {
                        border: "0px solid #69DCBF",
                        borderBottom: "1px solid #69DCBF",
                      },
                    },
                    error:{
                      marginBottom:"-20px",
                      fontSize:"70%",
                    }
                  })}
                  label={tn("First Name")}
                  // placeholder="hello@coophub.co"
                  size="md"
                />
                <TextInput
                  {...form.getInputProps("lastName")}
                  required
                  mt={90}
                  variant="filled"
                  styles={(theme) => ({
                    label: {
                      color: "#69DCBF",
                    },
                    input: {
                      borderRadius: "0px",
                      color: "white",
                      backgroundColor: "#21033F",
                      border: "0px solid #69dcbf86",
                      borderBottom: "1px solid #69dcbf86",
                      "&:focus-within": {
                        border: "0px solid #69DCBF",
                        borderBottom: "1px solid #69DCBF",
                      },
                    },
                    error:{
                      marginBottom:"-20px",
                      fontSize:"70%",
                    }
                  })}
                  label={tn("Last Name")}
                  // placeholder="hello@coophub.co"
                  size="md"
                />
              </div>
              <TextInput
                {...form.getInputProps("email")}
                required
                mt={30}
                variant="filled"
                styles={(theme) => ({
                  label: {
                    color: "#69DCBF",
                  },
                  input: {
                    border: "0px solid #69dcbf86",
                    borderRadius: "0px",
                    color: "white",
                    backgroundColor: "#21033F",
                    borderBottom: "1px solid #69dcbf86",
                    "&:focus-within": {
                      border: "0px solid #69DCBF",
                      borderBottom: "1px solid #69DCBF",
                    },
                  },
                })}
                label={tn("Email Address")}
                // placeholder="hello@coophub.co"
                size="md"
              />
              <Select
                data={
                  case_studies?.[0]
                    ? case_studies?.map((option:any) => ({
                        value:option?.name_en,
                        label: option?.[`name_${locale}`],
                      }))
                    : []
                }
                {...form.getInputProps("caseStudy")}
              

                required
                mt={30}
                variant="filled"
                styles={(theme) => ({
                  label: {
                    color: "#69DCBF",
                  },
                  input: {
                    border: "0px solid #69dcbf86",
                    borderRadius: "0px",
                    color: "white",
                    backgroundColor: "#21033F",
                    borderBottom: "1px solid #69dcbf86",
                    "&:focus-within": {
                      border: "0px solid #69DCBF",
                      borderBottom: "1px solid #69DCBF",
                    },
                  },
                })}
                label={tn("Case Study")}
                // placeholder={tn("Pick a case study")}
                size="md"
              />
              <PasswordInput
                {...form.getInputProps("password")}
                required
                variant="filled"
                styles={(theme) => ({
                  label: {
                    color: "#69DCBF",
                  },
                  input: {
                    border: "0px solid #69dcbf86",
                    borderRadius: "0px",
                    color: "white",
                    backgroundColor: "#21033F",
                    borderBottom: "1px solid #69dcbf9a",
                    "&:focus-within": {
                      border: "0px solid #69DCBF",
                      borderBottom: "1px solid #69DCBF",
                    },
                  },
                  innerInput: {
                    color: "white",
                  },
                })}
                label={tn("Password")}
                // placeholder={tn("Your password")}
                mt={30}
                size="md"
              />
              <PasswordInput
                {...form.getInputProps("confirm")}
                required
                variant="filled"
                styles={(theme) => ({
                  label: {
                    color: "#69DCBF",
                  },
                  input: {
                    border: "0px solid #69dcbf86",
                    borderRadius: "0px",
                    color: "white",
                    backgroundColor: "#21033F",
                    borderBottom: "1px solid #69dcbf9a",
                    "&:focus-within": {
                      border: "0px solid #69DCBF",
                      borderBottom: "1px solid #69DCBF",
                    },
                  },
                  innerInput: {
                    color: "white",
                  },
                })}
                label={tn("Password Confirm")}
                // placeholder={tn("Retype Your password")}
                mt={30}
                size="md"
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  marginTop: "40px",
                }}
              >
                <Checkbox
                  checked={form.values.terms}
                  {...form.getInputProps("terms")}
                  // mt="xl"
                  size="md"
                  styles={() => ({
                    label: { cursor: "pointer", color: "white" },
                    input: {
                      cursor: "pointer",
                      "&:checked": {
                        backgroundColor: "#69DCBF",
                        border: "0px solid #69DCBF",
                      },
                    },
                  })}
                />
                <Anchor<"a">
                  mx="md"
                  color="#FFB004"
                  href="#"
                  weight={700}
                  onClick={open}
                >
                  {tn("I agree to the CoopHub Privacy Policy and Terms of Use")}
                </Anchor>
              </div>
              {termsError && (
                <Text size="13px" color="red">
                  {termsTXT}
                </Text>
              )}
              <Button
                type="submit"
                style={{
                  borderRadius: "5.112px",
                  height: "70px",
                  backgroundColor: "rgba(105, 220, 191, 0.77)",
                  fontSize: "16px",
                }}
                fullWidth
                mt="xl"
                size="md"
              >
                {tn("Create a new account")}
              </Button>
              {isError && (
                <Text mt="md" size="13px" color="red">
                  {errorMassage}
                </Text>
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",

                margin: "70px 5px 0px 5px",
                width: "100%",
                maxWidth: "500px",
                flexDirection: width > 500 ? "row" : "column",
              }}
            >
              <Text color="#ffffff" fz="16px" weight={700}>
                {tn("terms and conditions")}
              </Text>{" "}
              {divider}
              <Text color="#ffffff" fz="16px" weight={700}>
                {tn("Usage policy")}
              </Text>{" "}
              {divider}
              <Text color="#ffffff" fz="16px" weight={700}>
                {tn("All rights are save")}
              </Text>{" "}
            </div>
          </div>
        </Paper>

        <div
          style={{
            height: "100%",
            width: `calc(100vw - 700px)`,
            backgroundImage: "url(/images/image1.jpg)",
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "absolute",
            left: locale == "en" ? "1" : "0",
            right: locale == "en" ? "0" : "1",
            filter: "grayscale(40%)",
          }}
        >
          <div
            style={{
              height: "100%",
              width: `100%`,
              background:
                "linear-gradient(312deg, rgba(2,0,36,1) 0%, rgba(88,170,162,1) 0%, rgba(183,254,247,0.4724483543417367) 100%)",
            }}
          >
            <div
              style={{
                height: "100%",
                width: "100%",
                backgroundImage: "url(/shapes/login_shape.svg)",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "bottom center",
                display: "flex",
                justifyContent: "center",
                alignItems: "start",
                fontSize: "40px",
                color: "white",
                fontWeight: 700,
                textAlign: "left",
                padding: "270px 0px 0px 0px",
              }}
            >
              خطوتك الأولى
              <br /> لمستقبل مهني واعد
            </div>
          </div>
        </div>
      </div>
      <Modal
        size="xl"
        opened={opened}
        onClose={close}
        centered
        styles={() => ({
          content: {
            borderRadius: "10px",
            background:
              "linear-gradient(153deg, #c2feff6a 100%, #d9b3ff5c 100%)",
            // display: "flex",
            // justifyContent: "center",
            // alignItems: "center",
          },
          header: { display: "none" },
          body: { padding: "20px" },
        })}
      >
        <div
          style={{
            marginTop: "20px",
            width: "100%",
            height: "70vh",
            background: "white",
            borderRadius: "5px",
            padding: "20px",
            overflowY: "scroll",
          }}
        >
          {/* {terms?.map((item:any) => (
            <div>
              <Title
                mb="md"
                style={{
                  color: "#21033F",
                  textAlign: "right",
                  fontSize: "18px",
                  fontWeight: "700",
                  lineHeight: "24px",
                }}
              >
                {item?.[`title_${locale}`]}
              </Title>
              <Text
                mb="md"
                style={{
                  color: "rgba(0, 0, 0, 0.70)",
                  textAlign: "justify",
                  fontSize: "16px",
                  fontWeight: "500",
                  lineHeight: "26px",
                }}
              >
                {item?.[`subtitle_${locale}`]}
              </Text>
            </div>
          ))} */}
          <MarkDownContainer>

          
          <ReactMarkdown children={terms?.[locale]}/>
          </MarkDownContainer>
        </div>
        <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
          <Button
            onClick={close}
            mt="md"
            style={{ background: "#69DCBF", height: "30px" }}
          >
            {tn("close")}
          </Button>
        </div>
      </Modal>
    </form>
    </>
  );
}

const MarkDownContainer = styled.div`
// add styles for markdown here
/* Headings */
h1, h2, h3, h4, h5, h6 {
    margin-top: 24px;
    margin-bottom: 16px;
}

h1 { font-size: 2em;color: #21033F; }
h2 { font-size: 1.5em;color: #21033F; }
h3 { font-size: 1.17em; }
h4 { font-size: 1em; }
h5 { font-size: 0.83em; }
h6 { font-size: 0.67em; }

/* Paragraphs */
p {
    line-height: 1.6;
    margin-top: 1em;
    margin-bottom: 1em;
}

/* Links */
a {
    color: #0077cc;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

/* Images */
img {
    max-width: 100%;
    display: block;
    margin: 0 auto;
}

/* Lists */
ul, ol {
    margin: 1em 0;
    padding-left: 2em;
}

li {
    margin-bottom: 0.5em;
}

/* Blockquotes */
blockquote {
    border-left: 4px solid #ddd;
    padding-left: 1em;
    margin-left: 0;
    color: #666;
}

/* Code blocks and inline code */
pre, code {
    font-family: "Courier New", monospace;
}

pre {
    overflow-x: scroll;
    padding: 1em;
    border: 1px solid #ddd;
    border-radius: 4px;
}

code {
    background-color: #f5f5f5;
    padding: 0 0.5em;
    border-radius: 3px;
}

/* Horizontal Rules */
hr {
    border: none;
    border-top: 1px solid #eee;
    margin: 2em 0;
}

/* Tables */
table {
    border-collapse: collapse;
    width: 100%;
    margin: 1em 0;
}

th, td {
    border: 1px solid #ddd;
    padding: 8px 12px;
}

th {
    background-color: #f5f5f5;
}

tr:nth-child(even) {
    background-color: #f9f9f9;
}


`
export default RegisterPage;
