import { hasLength, isEmail, isNotEmpty, useForm } from "@mantine/form";
import { ltn, tn, vtn } from "../../../../../../translation";
import {
  Accordion,
  Button,
  Checkbox,
  Group,
  Modal,
  Radio,
  Text,
  TextInput,
} from "@mantine/core";
import { useContext, useState } from "react";
import { TranslationContext } from "../../../../../../context/TranslationContext";
import { CMS_ENDPOINT } from "../../../../../../constants";
import PocketBase from "pocketbase";
import { AppContext } from "../../../../../../context/AppContext";
import { BannersContext } from "../../../../../../context/BannersContext";
import { Icon } from "@iconify/react/dist/iconify.js";
import { SystemContext } from "../../../../../../context/SystemContext";
import alasql from "alasql";
const pb = new PocketBase(CMS_ENDPOINT);
export const EditPermission = ({ data, onClose }: any) => {
  const { translation, locale }: any = useContext(TranslationContext);
  const { systemData }: any = useContext(SystemContext);

  const { reloader, setReloader }: any = useContext(AppContext);
  const [warnning, setWarnning]: any = useState(false);

  const pageGroubs = systemData?.permissions?.filter(
    (item: any) => item?.id?.split(".")?.length == 1
  );
  const pages = alasql(`Select * from ? order by order_by`,[systemData?.permissions?.filter(
    (item: any) => item?.id?.split(".")?.length == 2
  )??[]]);
  const accessible_data = translation?.accessible_data;

  const permissions = alasql(`Select * from ? order by order_by`,[ systemData?.permissions?.filter(
    (item: any) => item?.id?.split(".")?.length == 3
  )??[]]);


  // const { setTopBanner }: any = useContext(BannersContext);
  // const emailsError = [vtn("email"), vtn("taken_email")];
  // const validate_text = [vtn("empty"), vtn("sub_departments_empty")];
  // const dialogMassage = ltn("user_added_successfully");
  const form: any = useForm({
    initialValues: {
      name: data?.record?.name || "",
      accessible_data: data?.record?.accessible_data?.en || "",
      permissions: data?.record?.permissions || [],
    },

    validate: {
      name: isNotEmpty(vtn("empty")),
      accessible_data:isNotEmpty(vtn("empty")),
    },
  });
  const [loadingBotton, setLoadingButton] = useState(false);
  const [currentPages, setCurrentPages] = useState<string | null>(null);

  return (
    <form
      style={{
        width: "100%",
      }}
      onSubmit={form?.onSubmit(async (value: any) => {
        setLoadingButton(true);
        // const newPermission = await pb.collection("permissions").create({
        //  name: value?.name,
        //   permissions: value?.permissions,
        //   company: pb.authStore?.model?.company,
        //   status: {
        //     en: "active",
        //     ar: "نشط",
        //   },
        // });
        const oldPermission = await pb
          .collection("permissions")
          .update(data?.record?.id, {
            name: value?.name,
            permissions: value?.permissions,
            accessible_data: {
              en: value?.accessible_data,
              ar: accessible_data?.find((item:any)=>item?.name_en == value?.accessible_data)?.name_ar,
            },
          });
        setReloader(!reloader);
        onClose({});
      })}
    >
      <Button
        loading={loadingBotton}
        type="submit"
        compact
        style={{
          position: "absolute",
          bottom: "25px",
          left: "135px",
          background: "#69DCBF",
          borderRadius: "5.112px",
          width: "100px",
        }}
      >
        {tn("Save")}
      </Button>
      <Button
        compact
        style={{
          position: "absolute",
          bottom: "25px",
          left: "25px",
          background: "#F9837C",
          borderRadius: "5.112px",
          width: "100px",
        }}
        onClick={() => {
          form?.isDirty() ? setWarnning(true) : onClose({});
        }}
      >
        {tn("close")}
      </Button>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text
          style={{
            width: "100%",
            padding: "20px",
            color: "rgba(0, 0, 0, 0.70)",
            textAlign: "right",
            fontSize: "18px",
            fontWeight: "700",
          }}
        >
          {tn("Edit a permission")}
        </Text>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "stretch",
            padding: "0px 20px",
            marginBottom: "50px",
          }}
        >
          <div
            style={{
              borderRadius: "10px",
              background: "rgba(79, 81, 82, 0.05)",
              width: "calc(40% - 5px)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",

              alignItems: "center",
            }}
          >
            <div />
            <Icon
              icon={"icon-park-outline:permissions"}
              style={{
                fontSize: "160px",
                color: "#4F5152",
              }}
            />
           <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
                alignItems: "center",
                width: "100%",
                padding: "0px 20px",
                marginTop: "30px",
                marginBottom: form?.errors?.name ? "10px" : "10px",
              }}
            >
              <TextInput
                {...form.getInputProps("name")}
                label={tn("Permission")}
                placeholder={tn("Permission name")}
                style={{ width: "calc(100%)" }}
                styles={() => ({
                  input: {
                    borderRadius: "3.453px",
                  },
                  error: {
                    marginBottom: "-10px",
                  },
                  label: {
                    position: "sticky",
                    top: "0px",
                    zIndex: 9999,
                    background: "white",
                    padding: "0px 5px",
                    transform: "translate(10px, 10px)",
                    borderRadius: "3px",
                  },
                })}
              />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  borderRadius: "3px",
                  marginTop: "30px",
                  position: "relative",
                  border: "1px solid #d1d1d1",
                  padding: "10px 5px",
                  paddingTop: "20px",
                }}
              >
                <Text
                  style={{
                    position: "absolute",
                    top: "0px",
                    transform: "translate(0px, -25px)",
                    margin: "0px 5px",
                    padding: "6px",
                    borderRadius: "6px",
                    background: "white",
                    color: "rgba(0, 0, 0, 0.70)",
                    textAlign: "right",
                    fontSize: "13px",
                    fontWeight: "700",
                  }}
                >
                  {tn("accessible data")}
                </Text>

                <Radio.Group 
                {...form.getInputProps("accessible_data")}
                >
                  <Group mt="xs">
                    {accessible_data?.map((item: any) => {
                      return (
                        <Radio
                        style={form?.values?.accessible_data == item?.name_en ? { 
                          background: "white",
                          borderRadius: "13px",
                          padding: "5px 5px",
                        }: {padding: "5px 5px",}}
                          value={item?.name_en}
                          label={item?.["name_" + locale]}
                        />
                      );
                    })}
                   
                  </Group>
                </Radio.Group>
              </div>
              <Text>{form?.errors?.accessible_data}</Text>
              </div>
          </div>
          <div
            style={{
              borderRadius: "10px",
              background: "rgba(79, 81, 82, 0.05)",
              width: "calc(60% - 5px)",
              height: "400px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "20px 20px",
              overflow: "auto",
            }}
          >
            <Accordion
              style={{
                width: "100%",
              }}
              value={currentPages}
              onChange={setCurrentPages}
              variant="separated"
            >
              {pages?.map((page) => {
                const pagePermissions = permissions?.filter(
                  (permission: any) =>
                    permission.id?.split(".")?.[0] +
                      "." +
                      permission.id?.split(".")?.[1] ==
                    page.id
                );
                const currentPermissions = form.values.permissions?.filter(
                  (item) =>
                    item?.split(".")?.[0] + "." + item?.split(".")?.[1] ==
                    page.id
                );
                return (
                  <Accordion.Item
                    style={{
                      border: "1px solid #050505",
                    }}
                    value={page.id}
                  >
                    <Accordion.Control
                      icon={
                        <Checkbox
                          disabled={pagePermissions?.length == 0}
                          onClick={(e) => e.stopPropagation()}
                          checked={
                            currentPermissions.length == 0
                              ? false
                              : currentPermissions.length ==
                                pagePermissions.length
                          }
                          onChange={(e) => {
                            const pageId = page.id;
                            const newPages = e.target.checked;

                            if (e.target.checked) {
                              form.setFieldValue(
                                "permissions",
                                form.values.permissions.concat(
                                  permissions
                                    ?.filter(
                                      (permission: any) =>
                                        permission.id?.split(".")?.[0] ==
                                          page.id?.split(".")?.[0] &&
                                        permission.id?.split(".")?.[1] ==
                                          page.id?.split(".")?.[1] &&
                                        !form.values.permissions.includes(
                                          permission.id
                                        )
                                    )
                                    ?.map((permission: any) => permission.id)
                                )
                              );
                            } else {
                              form.setFieldValue(
                                "permissions",
                                form.values.permissions.filter(
                                  (item: any) =>
                                    !pagePermissions
                                      .map((permission: any) => permission.id)
                                      ?.includes(item)
                                )
                              );
                            }
                          }}
                        />
                      }
                    >
                      {page?.["label_" + locale]}
                    </Accordion.Control>
                    <Accordion.Panel>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        {pagePermissions.map((permission: any, i: any) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                alignItems: "start",
                                width: "100%",
                                padding: "0px 20px",
                              }}
                            >
                              <div
                                style={{
                                  width: "100%",
                                  height: i != 0 ? "20px" : "",
                                  borderRight:
                                    i != 0 ? "1px solid #aeaeae" : "",
                                  margin: "5px 9px",
                                }}
                              />
                              <Checkbox
                                label={permission?.["label_" + locale]}
                                checked={form.values.permissions.includes(
                                  permission.id
                                )}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    form.setFieldValue(
                                      "permissions",
                                      form.values.permissions.concat(
                                        permission.id
                                      )
                                    );
                                  } else {
                                    form.setFieldValue(
                                      "permissions",
                                      form.values.permissions.filter(
                                        (item: any) => item != permission.id
                                      )
                                    );
                                  }
                                }}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </Accordion.Panel>
                  </Accordion.Item>
                );
              })}
            </Accordion>
          </div>
        </div>
        <Modal
          style={{ zIndex: 9999999 }}
          overlayProps={{
            zIndex: 9999999,
            opacity: 0.55,
            blur: 3,
          }}
          styles={() => ({
            content: {
              zIndex: 10001,
              borderRadius: "10px",
              background:
                "linear-gradient(153deg, #22c0c372 100%, #21033f7a 100%)",
            },
            header: { display: "none" },
            body: { padding: "9px" },
            inner: { zIndex: 9999999 },
          })}
          opened={warnning}
          onClose={() => {
            setWarnning(false);
          }}
          centered
        >
          <div
            style={{
              background: "white",
              width: "100%",
              marginTop: "9px",
              borderRadius: "5px",
              paddingTop: "30px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "90%",
                display: "flex",
                justifyContent: "start",
              }}
            >
              <Text fw="600" color="grey">
                {ltn("discard_massage")}
              </Text>
            </div>
            <div
              style={{
                width: "90%",
                display: "flex",
                justifyContent: "start",
              }}
            >
              <Button
                my="md"
                style={{ backgroundColor: "#ff5e5e" }}
                onClick={() => {
                  onClose({});
                  setWarnning(false);
                }}
              >
                {tn("Yes")}
              </Button>
              <Button
                my="md"
                mx="md"
                variant="outline"
                color="gray"
                onClick={() => {
                  setWarnning(false);
                }}
              >
                {tn("Cancel")}
              </Button>{" "}
            </div>
          </div>
        </Modal>
      </div>
    </form>
  );
};
