import { ltn, tn } from "../../../../../../../translation";

import { Avatar, Button, Checkbox, Loader, Text } from "@mantine/core";

import { useContext, useState } from "react";
import { TranslationContext } from "../../../../../../../context/TranslationContext";
import { CMS_ENDPOINT } from "../../../../../../../constants";
import PocketBase from "pocketbase";
import { AppContext } from "../../../../../../../context/AppContext";
import { useDebouncedValue, useViewportSize } from "@mantine/hooks";
import { BannersContext } from "../../../../../../../context/BannersContext";
import { IconSquareRoundedCheck } from "@tabler/icons-react";
import { ProgramDetails } from "../../../../../../../applicant/mobile/components/ProgramDetails";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../../../../../../../context/DataContext";
const pb = new PocketBase(CMS_ENDPOINT);
export const ApplyForProgram = ({ data, onClose }: any) => {
  const [debounced_data] = useDebouncedValue(data?.record, 200);
  const [buttonLoading, setButtonLoading]: any = useState(false);

  const { translation, locale }: any = useContext(TranslationContext);
  const { reloader, setReloader }: any = useContext(AppContext);

  const { setTestInstructions }: any = useContext(DataContext);

  const userData = pb?.authStore?.model;
  const [accept, setAccept] = useState(false);
  const { setTopBanner }: any = useContext(BannersContext);
  const navigate = useNavigate();

  const onClickHandler = async () => {
    setButtonLoading(true);

    try {
      if (
        debounced_data?.has_filtration_test &&
        debounced_data?.filtration_test
      ) {
        setTestInstructions(data);
        onClose({});
      } else {
        if (
          debounced_data?.application_link &&
          debounced_data?.partial_status?.en
        ) {
          openApplicationLink(debounced_data.application_link);
        } else {
          await createApplicationRecord();
        }
      }
    } catch (error) {
      console.error("Error handling the button click:", error);
      setButtonLoading(false);
      // Optionally show an error message to the user
    } finally {
      // setButtonLoading(false);
    }
  };

  const openApplicationLink = (link) => {
    window.open(link, "_blank", "noreferrer");
  };

  const createApplicationRecord = async () => {
    if (!debounced_data?.id || !userData?.id) {
      console.error("Missing required data for creating application record.");
      return;
    }

    const data = {
      program: debounced_data.id,
      applicant: userData.id,
      applicant_status: { en: "under review", ar: "تحت المراجعة" },
      recruiter_status: { en: "under review", ar: "تحت المراجعة" },
      main_status: { en: "new", ar: "جديد" },
      date_of_commencement: new Date(debounced_data.start_date),
      date_of_end: new Date(debounced_data.end_date),
    };

    const record = await pb.collection("applications").create(data);

    if (debounced_data?.status?.en === "new") {
      await updateProgramStatus(debounced_data.id);
    }

    displaySuccessBanner();
    setReloader(!reloader);
    onClose({});
  };

  const updateProgramStatus = async (programId) => {
    await pb.collection("programs").update(programId, {
      status: {
        en: "under the procedure",
        ar: "تحت الإجراء",
      },
    });
  };

  const displaySuccessBanner = () => {
    const message =
      locale === "ar"
        ? `تم التقديم بنجاح على برنامج ${debounced_data?.expand?.company?.name} على ${debounced_data?.name}، قم بمتابعة حالة الطلب من خلال صفحة طلباتي`
        : `You have successfully applied for the ${debounced_data?.name} program at ${debounced_data?.expand?.company?.name}. You can follow up on your application status through the My Applications page`;

    setTopBanner({
      duration: 10000,
      message: message,
      color: "#2DC071",
      top: "83px",
      backgroundColor: "#F2FFF7EB",
      icon: <IconSquareRoundedCheck />,
    });
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Text
        mx="xl"
        style={{
          width: "100%",
          padding: "10px 30px 10px ",
          textAlign: "center",
          fontSize: "25px",
          fontWeight: "700",
          background:
            "var(--sc, linear-gradient(90deg, #21033F 0%, #69DCBF 100%))",
          backgroundClip: "text",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
        }}
      >
        {tn("program details")}
      </Text>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          padding: "0px 10px",
          flexDirection: "column",
          alignItems: "stretch",
          justifyContent: "space-around",
        }}
      >
        <div
          style={{
            width: "calc(100% - 10px)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: "10px",
            // height: "350px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "start",
              width: "100%",
            }}
          >
            <Text
              mt="xs"
              style={{ color: "#4D4D4D", fontSize: "14px", fontWeight: "700" }}
            >
              {tn("Review personal information")}
            </Text>
          </div>

          <div
            style={{
              width: "100%",
              maxWidth: "140px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <Avatar
              mt="xl"
              size="100%"
              radius="100%"
              style={{
                // height: "145px",
                width: "100%",
              }}
              src={`${CMS_ENDPOINT}/api/files/${userData?.collectionName}/${userData?.id}/${userData?.avatar}?token=`}
              styles={() => ({
                placeholder: {
                  height: "138px",
                },
              })}
            >
              <img
                style={{
                  width: "125%",
                  height: "125%",
                }}
                src={"/avatar_placeholder.svg"}
              />
            </Avatar>
          </div>
          <Text
              mt="xs"
              style={{
                color: "#4D4D4D",
                fontSize: "20px",
                fontWeight: "500",
                marginBottom: "10px",
              }}
            >
              {userData?.first_name + " " + userData?.last_name}
            </Text>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              gap: "10px",
              padding: "10px",
              flexWrap: "wrap",
            }}
          >
            <TwoValuesBox
              value1={userData?.major?.[locale]}
              value2={userData?.GPA + "/" + userData?.GPA_out_of}
            />
            <TwoValuesBox
              value1={tn("graduation year")}
              value2={userData?.graduation_year}
            />
            <TwoValuesBox
              value1={tn("Semester")}
              value2={userData?.semester?.[locale]}
            />
            <TwoValuesBox
              value1={tn("University")}
              value2={userData?.university?.[locale]}
            />
            <TwoValuesBox
              value1={
                <a
                  href={`${CMS_ENDPOINT}/api/files/${userData?.collectionName}/${userData?.id}/${userData?.cv}?token=`}
                  download={userData?.cv}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Text
                    style={{
                      color: "#4D4D4D",
                      fontSize: "12px",
                      fontWeight: "700",
                      marginBottom: "4px",
                    }}
                  >
                    {userData?.cv}
                  </Text>
                </a>
              }
              value2={undefined}
            />
          </div>

          <div />
          <div />
          <div />
        </div>
        <div
          style={{
            width: "calc(100% - 10px)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "start",
            borderRadius: "10px",
            marginTop: "10px",
            // height: width > 850 ? "350px" : "",
          }}
        >
       
          <ProgramDetails data={debounced_data} />
        </div>
      </div>

      <Checkbox
        onChange={(e) => {
          setAccept(e.currentTarget.checked);
        }}
        style={{
          width: "100%",
          marginTop: "10px",
          marginBottom: "10px",
          color: "#4D4D4D",
          fontSize: "12px",
          fontWeight: "700",
          padding: "5px 20px",
        }}
        label={ltn("apply_checkbox")}
        styles={() => ({
          label: {
            color: "#4D4D4D",
            fontWeight: "700",
          },
        })}
      />
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          padding: "10px 10px",
          gap: "10px",
          flexWrap: "wrap",
        }}
      >
        {
          <Button
          size="lg"
          radius="14px"
            loading={buttonLoading}
            disabled={!accept}
            style={{
              // color: "white",
              backgroundColor: accept ? "#69DCBF" : "",
            }}
            // color="red"
            onClick={onClickHandler}
          >
            {debounced_data?.has_filtration_test &&
            debounced_data?.filtration_test
              ? tn("Apply and go to the filtration test page")
              : tn("Apply")}
          </Button>
        }
        <Button
        size="lg"
        radius="14px"
          style={{
            color: "white",
            backgroundColor: "#F9837C",
          }}
          color="red"
          onClick={() => {
            navigate("/applicant/programs_search");
            onClose({});
          }}
        >
          {tn("close")}
        </Button>
      </div>
    </div>
  );
};

const TwoValuesBox = ({ value1, value2 }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent:value2? "space-between":"center",
        alignItems: "center",
        paddingBottom: "5px",
        borderRadius: "20px",
        background: "#FAF9F9",
        padding: "10px",
        gap: "10px",
        minWidth: "calc(100% - 10px)",
      }}
    >
      <Text
        style={{
          color: "#rgba(33, 3, 63, 0.62)",
          fontWeight: 400,
          fontSize: "12px",
        }}
      >
        {value1}
      </Text>
      <Text
        style={{
          color: "#FFB004",
          fontWeight: 400,
          fontSize: "12px",
        }}
      >
        {value2}
      </Text>
    </div>
  );
};
