import { TextInput, Text, NumberInput, Button, Modal } from "@mantine/core";
import { ltn, tn } from "../../../translation";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";

export function EvaluationView({ data, EditButtonClick,EditButtonText ,SubmitButtonText,
  SubmitButtonClick, submitButtonDisabled,DeleteButtonText,DeleteButtonClick,submitButtonLoading
}: any) {
const [deleteModal, setDeleteModal] = useState(false);
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        flexDirection: "column",
        height: "calc(100% - 120px)",
        maxHeight: "calc(100% - 120px)",
        backgroundColor: "#fff",
        borderRadius: "10px",
        border: "2px solid #21033F",
        position: "relative",        
      }}
    >
      <div
        style={{
          width: "calc(100% - 40px)",
          height: "4px",
          backgroundColor: "#21033F",
          borderRadius: "100px",
          marginBottom: "10px",
          
        }}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          gap: "10px",
          width: "100%",
          padding: "0px 20px",
          marginTop: "10px",
        }}
      >
        <Text 
        style={{
          color: "#21033F",
          fontSize: "15px",
          fontWeight: "500",
          backgroundColor: "#f3f3f3",
          borderRadius: "10px",
          border: "2px solid #21033f5d",
          padding: "5px 15px",
        }}
        >
          {data?.name}
        </Text>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          padding: "10px 40px",
          marginTop: "10px",

        }}
      >
        <Text
          style={{
            color: "#4F5152",
            fontSize: "15px",
            fontWeight: "500",
            width: "40px",
            textAlign: "center",
          }}
        >
          #
        </Text>
        <Text
          style={{
            color: "#4F5152",
            fontSize: "15px",
            fontWeight: "500",
            width: "calc(40% - 20px)",
            textAlign: "center",
          }}
        >
          {tn("Criterion")}
        </Text>
        <div />
        <Text
          style={{
            color: "#4F5152",
            fontSize: "15px",
            fontWeight: "500",
            width: "calc(40% - 20px)",
            textAlign: "center",
          }}
        >
          {tn("Criterion description")}
        </Text>
        <Text
          style={{
            color: "#4F5152",
            fontSize: "15px",
            fontWeight: "500",
            width: "80px",
            textAlign: "center",
          }}
        >
          {tn("Weight")}
        </Text>
      </div>
     <div style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        flexDirection: "column",
        gap: "15px",
        maxHeight: "calc(100% - 170px)",
        overflowY: "scroll",
      
     }}>
      {data?.criterias?.[0] ?data?.criterias?.map((item: any, index: number) => {
        return (
          <InputBlock
            item={item}
            index={index}
            data={data}
          />
        );
      }):<Text style={{
        color: "#21033F",
        fontSize: "15px",
        fontWeight: "500",
        backgroundColor: "#f3f3f3",
        borderRadius: "10px",
        border: "2px solid #21033f5d",
        padding: "5px 15px",
      }}>
        {tn("nothing")}
      </Text>
      }
      </div>
       <div style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          gap: "10px",
          width: "100%",
          position: "absolute",
          bottom: "-10px",
          padding: "0px 20px",
       }}>
         {DeleteButtonText &&  <Button style={{
          color: "#fff",
          borderRadius: "10px",
          padding: "5px 15px",
          marginTop: "20px",
          marginBottom: "20px",
          backgroundColor: "#F1623F",
        }}
          onClick={() => {
            // DeleteButtonClick();
            setDeleteModal(true);
          }
          }
        >
          {DeleteButtonText}
          </Button>}
     {EditButtonText &&  <Button color="color3" style={{
          color: "#fff",
          borderRadius: "10px",
          padding: "5px 15px",
          marginTop: "20px",
          marginBottom: "20px",
        }}
          onClick={() => {
            EditButtonClick();
          }
          }
        >
          {EditButtonText}
          </Button>}
          <Button 
            loading={submitButtonLoading}
          style={{
          backgroundColor: submitButtonDisabled?"":"#69DCBF",
          color: submitButtonDisabled?"":"#fff",
          borderRadius: "10px",
          padding: "5px 15px",
          marginTop: "20px",
          marginBottom: "20px",
        }}
        disabled={submitButtonDisabled}
        onClick={() => {
          SubmitButtonClick();
        }}
        >
          {
            SubmitButtonText
          }
          </Button>
       </div>
       <Modal 
        size={"md"}
        style={{ zIndex: 9999999, position: "relative" }}
        overlayProps={{
          zIndex: 2000,
          opacity: 0.55,
          blur: 3,
        }}
        styles={() => ({
          content: {
            zIndex: 10001,
            borderRadius: "10px",
            background: "linear-gradient(153deg, #69DCBF 100%, #21033f7a 100%)",
            // display: "flex",
            // justifyContent: "center",
            // alignItems: "center",
          },
          body: { padding: "9px" },
          header: { display: "none"},
          inner: { zIndex: 10001 },
        })}
        opened={deleteModal}
        onClose={() => {
          setDeleteModal(false);
        }}
        centered
       >
          <div
        style={{
          background: "white",
          width: "100%",
          height: "100%",
          marginTop: "9px",
          borderRadius: "5px",
          // paddingTop: "30px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
       <div style={{
        display:"flex",
        flexDirection:"column",
        alignItems:"start",
        justifyContent:"start",
        padding:"20px",
        width:"100%",

     }}>
      <Text fw={600} color="#5c5c5c">
      {ltn("delete_evaluation_model")}
      </Text>
      <div style={{
        display:"flex",
        flexDirection:"row",
        alignItems:"center",
        justifyContent:"space-between",
        marginTop:"20px"

      }}>
        <Button color="red" mx={5} onClick={
          ()=>{
            DeleteButtonClick();
            setDeleteModal(false);
          }
        }>
          {tn("Delete")}
        </Button>
        <Button variant="outline" color="gray" mx={5} onClick={() => setDeleteModal(false)}>
          {tn("Cancel")}
        </Button>
      </div>
       
     </div>
      </div>
       </Modal>
    </div>
  );
}

const InputBlock = ({
  index,
  data,
  item,
}: {
  index: number;
  data: any;
  item: any;

}) => {
  const total_waight = data?.criterias?.reduce(
    (acc: number, item: any) => acc + item.weight,
    0
  );
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        padding: "0px 40px",
        gap: "20px",
      }}
    >
     
        <Text
          style={{
            color: "#3f4041",
            fontSize: "15px",
            fontWeight: "500",
            width: "40px",
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
          }}
        >
         
          {index + 1}
        </Text>
    
      <TextInput
        disabled={true}
          value={item.name}
        style={{
          width: "calc(40% - 20px)",
        }}
        styles={{
          input: {
            backgroundColor: "#ffffff",
            borderRadius: "10px",
          },
        }}
      />
      <TextInput
        disabled={true}
        value={item.description}
        style={{
          width: "calc(40% - 20px)",
        }}
        styles={{
          input: {
            backgroundColor: "#ffffff",
            borderRadius: "10px",
          },
        }}
      />
      <NumberInput
        disabled={true}
        value={item.weight}
        rightSection={
          <Text
            style={{
              color: "#000",
              fontSize: "13px",
            }}
          >
          
               { ((item.weight / (total_waight ? total_waight : 1)) * 100)?.toFixed(0) + "%"}
          </Text>
        }
        style={{
          width: "calc(80px)",
        }}
        styles={{
          input: {
            backgroundColor: "#ffffff",
            borderRadius: "10px",
          },
        }}
        rightSectionWidth={40}
      />
    </div>
  );
};
const ADD_TEMPLATE = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 15 15"
    fill="none"
  >
    <path
      d="M7.5 1.40625C6.29477 1.40625 5.11661 1.76364 4.1145 2.43323C3.11238 3.10282 2.33133 4.05454 1.87011 5.16802C1.40889 6.28151 1.28821 7.50676 1.52334 8.68883C1.75847 9.8709 2.33884 10.9567 3.19107 11.8089C4.0433 12.6612 5.1291 13.2415 6.31117 13.4767C7.49324 13.7118 8.71849 13.5911 9.83198 13.1299C10.9455 12.6687 11.8972 11.8876 12.5668 10.8855C13.2364 9.88339 13.5938 8.70523 13.5938 7.5C13.5917 5.88446 12.9491 4.33566 11.8067 3.19329C10.6643 2.05093 9.11555 1.40827 7.5 1.40625ZM9.84375 7.96875H7.96875V9.84375C7.96875 9.96807 7.91937 10.0873 7.83146 10.1752C7.74355 10.2631 7.62432 10.3125 7.5 10.3125C7.37568 10.3125 7.25645 10.2631 7.16855 10.1752C7.08064 10.0873 7.03125 9.96807 7.03125 9.84375V7.96875H5.15625C5.03193 7.96875 4.9127 7.91936 4.8248 7.83146C4.73689 7.74355 4.6875 7.62432 4.6875 7.5C4.6875 7.37568 4.73689 7.25645 4.8248 7.16854C4.9127 7.08064 5.03193 7.03125 5.15625 7.03125H7.03125V5.15625C7.03125 5.03193 7.08064 4.9127 7.16855 4.82479C7.25645 4.73689 7.37568 4.6875 7.5 4.6875C7.62432 4.6875 7.74355 4.73689 7.83146 4.82479C7.91937 4.9127 7.96875 5.03193 7.96875 5.15625V7.03125H9.84375C9.96807 7.03125 10.0873 7.08064 10.1752 7.16854C10.2631 7.25645 10.3125 7.37568 10.3125 7.5C10.3125 7.62432 10.2631 7.74355 10.1752 7.83146C10.0873 7.91936 9.96807 7.96875 9.84375 7.96875Z"
      fill={"#21033F"}
    />
  </svg>
);

const DELETE_SVG = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 15 15"
    fill="none"
  >
    <g opacity="0.85">
      <path
        d="M7.62207 1.40625C6.41684 1.40625 5.23868 1.76364 4.23657 2.43323C3.23445 3.10282 2.4534 4.05454 1.99218 5.16802C1.53096 6.28151 1.41028 7.50676 1.64541 8.68883C1.88054 9.8709 2.46091 10.9567 3.31314 11.8089C4.16537 12.6612 5.25117 13.2415 6.43324 13.4767C7.61531 13.7118 8.84056 13.5911 9.95405 13.1299C11.0675 12.6687 12.0193 11.8876 12.6888 10.8855C13.3584 9.88339 13.7158 8.70523 13.7158 7.5C13.7141 5.88436 13.0716 4.33538 11.9291 3.19295C10.7867 2.05052 9.23771 1.40796 7.62207 1.40625ZM9.82871 9.04336C9.87227 9.08691 9.90681 9.13861 9.93038 9.19552C9.95395 9.25242 9.96608 9.31341 9.96608 9.375C9.96608 9.43659 9.95395 9.49758 9.93038 9.55448C9.90681 9.61139 9.87227 9.66309 9.82871 9.70664C9.78516 9.75019 9.73346 9.78474 9.67656 9.80831C9.61965 9.83188 9.55866 9.84401 9.49707 9.84401C9.43548 9.84401 9.37449 9.83188 9.31759 9.80831C9.26069 9.78474 9.20898 9.75019 9.16543 9.70664L7.62207 8.1627L6.07871 9.70664C6.03516 9.75019 5.98346 9.78474 5.92656 9.80831C5.86965 9.83188 5.80866 9.84401 5.74707 9.84401C5.68548 9.84401 5.62449 9.83188 5.56759 9.80831C5.51069 9.78474 5.45898 9.75019 5.41543 9.70664C5.37188 9.66309 5.33733 9.61139 5.31376 9.55448C5.29019 9.49758 5.27806 9.43659 5.27806 9.375C5.27806 9.31341 5.29019 9.25242 5.31376 9.19552C5.33733 9.13861 5.37188 9.08691 5.41543 9.04336L6.95938 7.5L5.41543 5.95664C5.32748 5.86868 5.27806 5.74939 5.27806 5.625C5.27806 5.50061 5.32748 5.38132 5.41543 5.29336C5.50339 5.2054 5.62268 5.15599 5.74707 5.15599C5.87146 5.15599 5.99076 5.2054 6.07871 5.29336L7.62207 6.8373L9.16543 5.29336C9.20898 5.24981 9.26069 5.21526 9.31759 5.19169C9.37449 5.16812 9.43548 5.15599 9.49707 5.15599C9.55866 5.15599 9.61965 5.16812 9.67656 5.19169C9.73346 5.21526 9.78516 5.24981 9.82871 5.29336C9.87227 5.33691 9.90681 5.38861 9.93038 5.44552C9.95395 5.50242 9.96608 5.56341 9.96608 5.625C9.96608 5.68659 9.95395 5.74758 9.93038 5.80448C9.90681 5.86139 9.87227 5.91309 9.82871 5.95664L8.28477 7.5L9.82871 9.04336Z"
        fill="#F1623F"
      />
    </g>
  </svg>
);
