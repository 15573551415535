import {Button, Modal, Slider, Text} from "@mantine/core"
import PocketBase from "pocketbase";
import { useContext, useState } from "react";
import { useDebouncedValue } from "@mantine/hooks";
import { CMS_ENDPOINT } from "../../../constants";
import { AppContext } from "../../../context/AppContext";
import { ltn, tn } from "../../../translation";

export const  EvaluationProgram = ({open,setOpen,id}:any) => {
  const pb: any = new PocketBase(CMS_ENDPOINT);

  const {reloader, setReloader} = useContext(AppContext);

  const [evaluation, setEvaluation] = useState(50)

     return   <Modal
     size={"lg"}
     style={{ zIndex: 9999999, position: "relative" }}
     overlayProps={{
       zIndex: 2000,
       opacity: 0.55,
       blur: 3,
     }}
     styles={() => ({
       content: {
         zIndex: 10001,
         borderRadius: "10px",
         background: "linear-gradient(153deg, #69DCBF 100%, #21033f7a 100%)",
         // display: "flex",
         // justifyContent: "center",
         // alignItems: "center",
       },
       header: { display: "none" },
       body: { padding: "9px" },
       inner: { zIndex: 10001 },
     })}
     opened={open}
     onClose={() => {
      setOpen(false);
     }}
     centered
   >
     <div
       style={{
         background: "white",
         width: "100%",
         height: "100%",
         marginTop: "9px",
         borderRadius: "5px",
         // paddingTop: "30px",
         display: "flex",
         flexDirection: "column",
         alignItems: "center",
         justifyContent: "center",
       }}
     >
       <div style={{
        display:"flex",
        flexDirection:"column",
        alignItems:"start",
        justifyContent:"start",
        padding:"20px",
        width:"100%",

     }}>
      <Text fw={600} color="#5c5c5c">
      {ltn("rate_program")}
      </Text>
      <Slider
       marks={[
        { value: 20, label: '20%' },
        { value: 50, label: '50%' },
        { value: 80, label: '80%' },
      ]}
      label={(value:any) => value + "%"} 
      value={evaluation}
      onChange={(e:any)=>{
        setEvaluation(e);
      }} style={{
        width:"100%",
        marginTop:"20px",
        marginBottom:"10px"
      }}  min={0} max={100} step={1}
  />
      <div style={{
        display:"flex",
        flexDirection:"row",
        alignItems:"center",
        justifyContent:"space-between",
        marginTop:"20px"

      }}>
        <Button mx={5}onClick={async () => {
          await pb.collection("applications").update(id,{
            program_evaluation:evaluation + "%",
            program_evaluation_date:new Date()
          });
          setOpen(false);
          setReloader(!reloader);
          
        }}>
          {tn("Save")}
        </Button>
        <Button variant="outline" color="gray" mx={5} onClick={() =>  setOpen(false)}>
          {tn("Cancel")}
        </Button>
      </div>
       
     </div>
     </div>
   </Modal>
}