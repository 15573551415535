import { ltn, tn, vtn } from "../../../../../../translation";

import { Button, Center, FileButton, FileInput, Loader, Text, Textarea, rem } from "@mantine/core";

import { useContext, useState } from "react";
import { TranslationContext } from "../../../../../../context/TranslationContext";
import { CMS_ENDPOINT } from "../../../../../../constants";
import PocketBase from "pocketbase";
import { useDebouncedValue, useViewportSize } from "@mantine/hooks";
import { isNotEmpty, useForm } from "@mantine/form";
import { AppContext } from "../../../../../../context/AppContext";
import { MIME_TYPES } from "@mantine/dropzone";
import { IconUpload, IconPhoto } from "@tabler/icons-react";
const pb = new PocketBase(CMS_ENDPOINT);
export const UploadingProgramCompletionLetter = ({ data, onClose }: any) => {
  const { translation, locale }: any = useContext(TranslationContext);
  const [debounced_data] = useDebouncedValue(data, 200);
  const {reloader, setReloader}:any = useContext(AppContext)
const [buttonLoading, setButtonLoading] = useState(false)
  const applicant_exclude_verbs = translation?.applicant_correction_verbs;
  const { width } = useViewportSize();
  const form = useForm({
    initialValues:{
      letter:""
    },
    validate:{
      letter:isNotEmpty(vtn("required_field"))
    }
  })
  return width > 10 ? (
    <form
    onSubmit={form.onSubmit((value)=>{
      const formData = new FormData();
      formData.append("completion_letter", value?.letter);
      const record = pb
        .collection("applications")
        .update(data?.id, formData)
        .then((res: any) => {
          const record = pb
            .collection("applications")
            .update(data?.id, {
              termination_status:{
                en:"completed",
                ar:"مكتمل"
              }
              
            })
            setReloader(!reloader)
            onClose();
            setButtonLoading(false)
        })
        .catch((err: any) => {
          setButtonLoading(false)
          console.log("err", err);
        });
    })}
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Text
        style={{
          width: "100%",
          padding: "10px 20px 10px ",
          color: "rgba(0, 0, 0, 0.70)",
          textAlign: "right",
          fontSize: "22px",
          fontWeight: "700",
        }}
      >
        {tn("Upload completion letter")}
      </Text>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          padding: "0px 10px",
          flexDirection: width > 850 ? "row" : "column",
          alignItems: width > 850 ? "stretch" : "stretch",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            width: width > 850 ? "calc(35% - 10px)" : "calc(100% - 10px)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "start",
            background: "rgba(79, 81, 82, 0.05)",
            borderRadius: "10px",
          }}
        >
          <div
            style={{
              width: "calc(100% - 30px)",
              background: "#21033F",
              height: "5px",
              borderRadius: "522px",
            }}
          />
          <TextBox
            value={debounced_data?.record?.expand?.program?.name}
            name={tn("Program") + ":"}
          />
          <TextBox
            value={debounced_data?.record?.department_name}
            name={tn("Department") + ":"}
          />
          <TextBox
            value={debounced_data?.record?.applicant_name}
            name={tn("Applicant name") + ":"}
          />
          <TextBox
            value={debounced_data?.record?.university?.[locale]}
            name={tn("University") + ":"}
          />
          <TextBox
            value={debounced_data?.record?.major?.[locale]}
            name={tn("Major") + ":"}
          />
           
           <TextBox
            value={new Date(
              debounced_data?.record?.date_of_commencement
            )?.toLocaleDateString("en-GB")}
            name={tn("Program start date") + ":"}
          />
          <TextBox
            value={new Date(
              debounced_data?.record?.date_of_end
            )?.toLocaleDateString("en-GB")}
            name={tn("Program end date") + ":"}
          />
         
          <TextBox
            value={debounced_data?.record?.expand?.program?.period?.[locale]}
            name={tn("Period") + ":"}
          />
         
          
          <TextBox
            value={debounced_data?.record?.applicant_evaluation?.precentage}
            name={tn("Evaluation ") + ":"}
          />
          <TextBox
            value={debounced_data?.record?.applicant_evaluation_evaluator_name}
            name={tn("Evaluator") + ":"}
          />
        </div>
        <div
          style={{
            width: width > 850 ? "calc(65% - 10px)" : "calc(100% - 10px)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            background: "rgba(79, 81, 82, 0.05)",
            borderRadius: "10px",
          }}
        >
          <div
            style={{ width: "100%", display: "flex", flexDirection: "column", alignItems:"center" }}
          >
            <div
              style={{
                width: "calc(100% - 30px)",
                  background: "#21033F",
                height: "5px",
                borderRadius: "522px",
              }}
            />
            <Text
              style={{
                width: "100%",
                padding: "10px 20px 10px ",
                color: "rgba(0, 0, 0, 0.70)",
                textAlign: "right",
                fontSize: "22px",
                fontWeight: "700",
              }}
            >
              {tn("Program completion letter")}
            </Text>
          </div>
          <Text
              style={{
                width: "100%",
                padding: "10px 20px 10px ",
                color: "rgba(60, 60, 60, 0.7)",
                textAlign: "right",
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              {ltn("completion_letter")}
            </Text>
          


            <div
          style={{
            // border: "1px solid #21033Fdc",
            borderRadius: "4px",
            display: "flex",
            alignItems: "end",
            marginTop: "20px",
            width: "100%",
            padding: "0px 20px",
          }}
        >
          <FileInput
            accept={MIME_TYPES.pdf}
            {...form.getInputProps("letter")}
            // style={{ width: "calc(100% - 60px)",}}
            withAsterisk={false}
            //   {...form.getInputProps("firstName")}
            icon={<IconUpload size={rem(14)} />}
            required
            mt={10}
            variant="filled"
            style={{ width: "calc(100% - 50px)" }}
            styles={(theme) => ({
              error: {
                marginBottom: "-22px",
              },
              label: {
                color: "#FFB004",
              },
              input: {
                borderRadius: "0px",
                // color: "#FFB004",
                backgroundColor: "transparent",
                border: "0px solid #FFB004",
                borderBottom: "1px solid #FFB004",
                "&:focus-within": {
                  border: "0px solid #FFB004",
                  borderBottom: "1px solid #FFB004",
                },
              },
            })}
            label={tn("Upload completion letter")}
            // placeholder="hello@coophub.co"
            size="md"
            valueComponent={(value):any => {
              return (
                value?.value?.name && (
                  <Center
                    mx="md"
                    inline
                    sx={(theme) => ({
                      backgroundColor: " #a0a0a094",
                      fontSize: theme.fontSizes.xs,
                      padding: `${rem(3)} ${rem(7)}`,
                      borderRadius: theme.radius.sm,
                    })}
                  >
                    <IconPhoto
                      size={rem(14)}
                      style={{
                        margin: rem(5),
                        marginTop: "0",
                        marginBottom: "0",
                      }}
                    />
                    <span
                      style={{
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        maxWidth: rem(200),
                        display: "inline-block",
                      }}
                    >
                      {value?.value?.name}
                    </span>
                  </Center>
                )
              );
            }}
          />{" "}
          <div
            style={{
              borderBottom: "1px solid #FFB004",
              paddingBottom: "10px",
            }}
          >
            <FileButton
              {...form.getInputProps("letter")}
              // multiple
              accept={MIME_TYPES.pdf}
            >
              {(props) => (
                <Button
                  style={{
                    background: "#FFB004",
                    borderRadius: "5.112px",
                    height: "30px",
                  }}
                  {...props}
                >
                  {tn("Upload")}
                </Button>
              )}
            </FileButton>
          </div>
        </div>
 <div/>

        </div>
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "end",
          padding: "20px 0px",
        }}
      >
        <Button
          type="submit"
          onClick={()=>{
            setButtonLoading(true)
          }}
          loading={buttonLoading}
          compact
          style={{
            backgroundColor: "#69DCBF",
            color: "white",
            width: "100px",
            borderRadius: "5.112px",
          }}
        >
          {tn("Upload and approve")}
        </Button>

        <Button
          compact
          mx="sm"
          style={{
            color: "white",
            width: "100px",
            borderRadius: "5.112px",
            backgroundColor: "#F9837C",
          }}
          color="red"
          onClick={() => {
            onClose({});
          }}
        >
          {tn("Cancel")}
        </Button>
      </div>
    </form>
  ) : (
    <div
      style={{
        height: "1270px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Loader color="color1" />
    </div>
  );
};
const TextInputStyle = (error: any) => ({
  description: {
    marginBottom: "-17px",
    transform: "translate(5px, 35px)",
    fontSize: "8px",
    opacity: error ? "0" : "1",
  },
  label: {
    zIndex: "10",
    position: "relative",
    margin: "0px 10px",
    transform: "translate(0px, 7px)",
    background: "#f6f6f6",
    borderRadius: "5px",
    padding: "0px 5px",
    fontSize: "10px",
  },
  input: {
    borderRadius: "5px",
    border: "1px solid #000000",
  },
  error: {
    marginBottom: "-17px",
    transform: "translate(4px, 3px)",
    // fontSize: "8px",
  },
});

const TextBox = ({ value, name }: any) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        margin: "3px 0px",
        padding: "0px 10px",
      }}
    >
      <Text
        style={{
          padding: "10px 3px 10px ",
          color: "#4F5152",
          textAlign: "right",
          fontSize: "14px",
          fontWeight: "700",
        }}
      >
        {name}
      </Text>
      <Text
        style={{
          minHeight: "34.8px",
          width: "180px",
          color: "#4F5152",
          padding: "5px 10px",
          borderRadius: "5px",
          background: "#ffffff",
          fontSize: value?.length > 23 ? "13px" : "16px",
          textAlign: "center",
          fontWeight: "400",
        }}
      >
        {value}
      </Text>
    </div>
  );
};
