import { PDFViewer } from "@react-pdf/renderer"
import MyDocument from "./ContrctPdf";
import { AppContext } from "../../../context/AppContext";
import { useContext } from "react";



    export const ContractViewer = () => {


        const {contractViewerData,setContractViewerData}:any = useContext(AppContext)
        return (
            <div style={{
                width:"100vw",
                height:"100vh",
                display:"flex",
                justifyContent:"center",
                alignItems:"center"
              
            }}>
                <PDFViewer style={{
                    width:"100%",
                    height:"100%",
                    display:"flex",
                    justifyContent:"center",
                    alignItems:"center"
                  
                
                }}>
                    {
                        <MyDocument data = {contractViewerData}/>}
                </PDFViewer>
            </div>
        )
    }