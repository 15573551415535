import { Helmet } from "react-helmet";
import { tn, ttn } from "../../translation";
import { Tabs } from "@mantine/core";
import { useNavigate, useParams } from "react-router-dom";
import { EvaluationModels } from "./evaluation_models/EvaluationModels";
import { EvaluationView } from "./evaluation_models/EvaluationView";

function Models() {
  const { id = "evaluation_questionnaire_for_personal_interview" } =
    useParams();

    const navigate = useNavigate();
  return (
    <div
      style={{
        height: "calc(100vh - 24px)",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Helmet>
        <title>{tn("Models") + " / " + tn("Coophub")}</title>
      </Helmet>

      <div
        style={{
          width: "calc(100% - 54px)",
          height: "calc(100% - 54px)",
          maxHeight: "calc(100vh - 54px)",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "start",
          backgroundColor: "#4F51520D",
        }}
      >
        <div
          style={{
            width: "calc(100% - 40px)",
            height: "8px",
            backgroundColor: "#21033F",
            borderRadius: "100px",
            marginBottom: "10px",
          }}
        />
        <Tabs
          value={id}
          style={{
            width: "calc(100% - 40px)",
          }}
          onTabChange={(value) => {
            navigate(`/recruiter/models/${value}`);
          }
        }
        >
          <Tabs.List>
            <Tabs.Tab
              value="evaluation_questionnaire_for_personal_interview"
            >
              {tn("Evaluation questionnaire for personal interview")}
            </Tabs.Tab>
       
            <Tabs.Tab value="trainee_evaluation_questionnaire_after_the_end_of_the_program">
              {tn("Trainee evaluation questionnaire after the end of the program")}
            </Tabs.Tab>
            <Tabs.Tab value="training_program_evaluation_questionnaire">
              {tn("Training program evaluation questionnaire")}
            </Tabs.Tab>
          </Tabs.List>
        </Tabs>
        <div
          style={{
            width: "calc(100% - 40px)",
            height: "calc(100% - 40px)",
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <EvaluationModelsSelector model_type={id} />
         
        </div>
      </div>
    </div>
  );
}

function EvaluationModelsSelector({model_type}:any) {

  switch (model_type) {
    case "evaluation_questionnaire_for_personal_interview":
      return <EvaluationModels model_type={model_type} key={model_type} />;
    case "trainee_evaluation_questionnaire_after_the_end_of_the_program":
      return <EvaluationModels model_type={model_type} key={model_type} />;
    case "training_program_evaluation_questionnaire":
      return <EvaluationModels model_type={model_type} key={model_type} />;
    default:
      return null;
  }
}

export default Models;
