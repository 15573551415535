import { ltn, tn } from "../../../../../../translation";
import { Button, Text } from "@mantine/core";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../../../../../constants";
import { useContext, useState } from "react";
import { AppContext } from "../../../../../../context/AppContext";
import { useDebouncedValue } from "@mantine/hooks";

export const RejectNewApplicant = (props: any) => {
  const pb: any = new PocketBase(CMS_ENDPOINT);
  const { data, onClose } = props;
  const { reloader, setReloader } = useContext(AppContext);
  const [debounced_data] = useDebouncedValue(data, 200);
  const [buttonLoading, setButtonLoading] = useState(false)

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "start",
        padding: "20px",
        width: "100%",
      }}
    >
      <Text fw={600} color="#5c5c5c">
        {debounced_data?.record?.[1]
          ? ltn("reject_applicants")
          : ltn("reject_applicant")}
      </Text>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <Button loading={buttonLoading}
          color="red"
          mx={5}
          onClick={
            data?.record?.[0]
              ? async () => {
                setButtonLoading(true)
                  for (const record of data?.record || []) {
                    await pb
                      .collection(data?.table?.table)
                      .update(record, {
                        applicant_status: {
                          en: "rejected",
                          ar: "مرفوض",
                        },
                        recruiter_status: {
                          en: "rejected",
                          ar: "مرفوض",
                        },
                        main_status: {
                          en: "rejected",
                          ar: "مرفوض",
                        },
                      })
                      ?.then((application) => {
                        const record = pb
                          .collection("programs")
                          .getOne(application?.program, {})
                          ?.then((program: any) => {
                            const records = pb
                              .collection("applications")
                              .getFullList({
                                sort: "-created",
                                filter: `program = "${program?.id}"`,
                              })
                              ?.then((applications: any) => {
                                // if all applications are excluded, or rejected, and the program last_submission_date is passed, then the program status will be {
                                //   en:"canceled",
                                //   ar:"ملغي"
                                // }
                                const all_applications_excluded_or_rejected =
                                  applications?.every(
                                    (item: any) =>
                                      item?.main_status?.en === "excluded" ||
                                      item?.main_status?.en === "rejected" ||
                                      item?.main_status?.en === "withdrawn"
                                  );
                                const program_last_submission_date_passed =
                                  new Date(program?.last_submission_date) <
                                  new Date();
                                if (
                                  all_applications_excluded_or_rejected &&
                                  program_last_submission_date_passed
                                ) {
                                  pb?.collection("programs").update(
                                    program?.id,
                                    {
                                      status: {
                                        en: "canceled",
                                        ar: "ملغي",
                                      },
                                    }
                                  );
                                }
                              });
                          });
                      });
                  }

                  setReloader(!reloader);
                  onClose({});
                }
              : async () => {
                setButtonLoading(true)
                  await pb
                    .collection(data?.table?.table)
                    .update(data?.record?.id, {
                      applicant_status: {
                        en: "rejected",
                        ar: "مرفوض",
                      },
                      recruiter_status: {
                        en: "rejected",
                        ar: "مرفوض",
                      },
                      main_status: {
                        en: "rejected",
                        ar: "مرفوض",
                      },
                    })
                    ?.then((application) => {
                      const record = pb
                        .collection("programs")
                        .getOne(application?.program, {})
                        ?.then((program: any) => {
                          const records = pb
                            .collection("applications")
                            .getFullList({
                              sort: "-created",
                              filter: `program = "${program?.id}"`,
                            })
                            ?.then((applications: any) => {
                              // if all applications are excluded, or rejected, and the program last_submission_date is passed, then the program status will be {
                              //   en:"canceled",
                              //   ar:"ملغي"
                              // }
                              const all_applications_excluded_or_rejected =
                                applications?.every(
                                  (item: any) =>
                                    item?.main_status?.en === "excluded" ||
                                    item?.main_status?.en === "rejected" ||
                                    item?.main_status?.en === "withdrawn"
                                );
                              const program_last_submission_date_passed =
                                new Date(program?.last_submission_date) <
                                new Date();
                              if (
                                all_applications_excluded_or_rejected &&
                                program_last_submission_date_passed
                              ) {
                                pb?.collection("programs").update(program?.id, {
                                  status: {
                                    en: "canceled",
                                    ar: "ملغي",
                                  },
                                });
                              }
                            })
                            ?.then((res: any) => {
                              setReloader(!reloader);
                              onClose({});
                            });
                        });
                    });
                  onClose({});
                  setReloader(!reloader);
                }
          }
        >
          {tn("Reject")}
        </Button>
        <Button
          variant="outline"
          color="gray"
          mx={5}
          onClick={() => onClose({})}
        >
          {tn("Cancel")}
        </Button>
      </div>
    </div>
  );
};
