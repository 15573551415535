import { useContext } from "react";
import { DataContext } from "../../../../../../context/DataContext";
import { ActionComponent } from "../../../ActionComponent";

export const PublishProgram = (props: any) => {
  const { setPublishProgram }: any = useContext(DataContext);

  const { data, action, table } = props;
  const when_be_able =
    data?.status?.en == "hanging" ;
  return (
    <ActionComponent
      data={data}
      action={action}
      table={table}
      setData={() => setPublishProgram(data)}
      enabled={when_be_able}
      visible={when_be_able}
    />
  );
};
