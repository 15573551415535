import { useContext, useEffect, useState } from "react";
import { useTimeout } from "@mantine/hooks";
import { Loader, Tabs } from "@mantine/core";
import {
  IconCoin,
  IconFileText,
  IconFileUpload,
  IconListDetails,
  IconUsers,
  IconWand,
} from "@tabler/icons-react";
import { tn } from "../../translation";
import { MainInformation } from "../components/edit_company/MainInformation";
import { UsersData } from "../components/edit_company/UsersData";
import { CompanyDepartments } from "../components/edit_company/CompanyDepartments";
import { ManagingEvaluationForms } from "../components/edit_company/ManagingEvaluationForms";
import { AppearanceIdentity } from "../components/edit_company/AppearanceIdentity";
import { SubscriptionDetails } from "../components/edit_company/SubscriptionDetails";
import { useParams } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../constants";

const pb = new PocketBase(CMS_ENDPOINT);
export function EditCompanyPage() {
  const { id }: any = useParams();
  const { reloader, setReloader } = useContext(AppContext);
  const [data, setData] = useState();
  const [user, setUser] = useState();

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const record = pb
      .collection("companies")
      .getOne(id, {})
      .then((record: any) => {
        setData(record);
        setLoading(false);
        const record_ = pb
          .collection("recruiters")
          .getFirstListItem(`role.en="HR Manager" && company="${id}"`, {})
          .then((record_: any) => {
            setUser(record_);
          });
      });
  }, [id, reloader]);

  const [bannerOpen, setBannerOpen] = useState(false);
  const { start, clear } = useTimeout(() => setBannerOpen(false), 3000);
  useEffect(() => {
    if (bannerOpen) {
      start();
    }
  }, [bannerOpen]);

  return (
    <>
      {data && user ?<div
        style={{
          width: "100%",
          height: "calc(100vh - 84px)",
          display: "flex",
          alignItems: "start",
          justifyContent: "center",
          padding: "20px",
          paddingTop: "30px",
          background: "#fdfdfd",
        }}
      >
        <div
          style={{
            width: "calc(100% - 20px)",
            height: "calc(100% - 20px)",
            display: "flex",
            alignItems: "start",
            justifyContent: "center",
            padding: "20px",
            paddingTop: "10px",
            background: "#ffffff",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.116)",
            borderRadius: "10px",
          }}
        >
          <Tabs
            color="color1"
            styles={() => ({})}
            style={{
              width: "calc(100% - 20px)",
            }}
            radius="md"
            defaultValue="Main information"
          >
            <Tabs.List>
              <Tabs.Tab
                value="Main information"
                icon={<IconFileText size="0.8rem" />}
              >
                {tn("Main information")}
              </Tabs.Tab>
              <Tabs.Tab value="Users data" icon={<IconUsers size="0.8rem" />}>
                {tn("Users data")}
              </Tabs.Tab>
              <Tabs.Tab
                value="Company departments"
                icon={<IconListDetails size="0.8rem" />}
              >
                {tn("Company departments")}
              </Tabs.Tab>
              <Tabs.Tab
                value="Managing evaluation forms"
                icon={<IconFileUpload size="0.8rem" />}
              >
                {tn("Managing evaluation forms")}
              </Tabs.Tab>
              <Tabs.Tab
                value="Appearance and identity"
                icon={<IconWand size="0.8rem" />}
              >
                {tn("Appearance and identity")}
              </Tabs.Tab>
              <Tabs.Tab
                value="Subscription details"
                icon={<IconCoin size="0.8rem" />}
              >
                {tn("Subscription details")}
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="Main information" pt="xs">
              <MainInformation data={{ company: data, accountManager: user }} />
            </Tabs.Panel>
            <Tabs.Panel value="Users data" pt="xs">
              <UsersData />
            </Tabs.Panel>
            <Tabs.Panel value="Company departments" pt="xs">
              <CompanyDepartments />
            </Tabs.Panel>
            <Tabs.Panel value="Managing evaluation forms" pt="xs">
              <ManagingEvaluationForms />
            </Tabs.Panel>
            <Tabs.Panel value="Appearance and identity" pt="xs">
              <AppearanceIdentity />
            </Tabs.Panel>
            <Tabs.Panel value="Subscription details" pt="xs">
              <SubscriptionDetails />
            </Tabs.Panel>
          </Tabs>
        </div>
      </div>:<div style={{
      width: "100%",
      height: "calc(100vh - 84px)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      }}><Loader color="color1"/></div>}
    </>
  );
}
