import React, { useContext } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import { da } from "date-fns/locale";
import { TranslationContext } from "../../../context/TranslationContext";

const MyDocument = ({data}:any) => {
  // const {locale} = useContext(TranslationContext)
  return <Document
    style={{
      fontFamily: "IBMPlexSansArabic",
    }}
  >
    <Page style={styles.body}>
      <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
        <Text />
        <Image style={{ width: "130px" }} src={data?.company?.logo ?? "/dark_blue.png"} />
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "10px",
        }}
      >
        <Text />
        <Text>عقد التدريب</Text>
        <Text />
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "10px",
        }}
      >
        <Text>Parties Information:</Text>
        <Text>:بيانات الأطراف</Text>
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "10px",
          backgroundColor: "#44546a",
          padding: "5px",
          color: "white",
          fontSize: "16px",
        }}
      >
        <Text
          style={{
            width: "50%",
            padding: "5px",
          }}
        >
          First Party’s Information
        </Text>
        <Text
          style={{
            width: "50%",
            padding: "5px",
            textAlign: "right",
          }}
        >
          بيانات الطرف الأول
        </Text>
      </View>
      <View
        style={{
          justifyContent: "space-between",
          backgroundColor: "#ffffff",
          color: "white",
          fontSize: "16px",
        }}
      >
        <ThreeFields
          en={"National Address"}
          ar={"العنوان الوطني"}
          value={
            data?.company?.national_address
          }
          top={1}
        />
        <ThreeFields
          en={"HRSD ID"}
          ar={"رقم ملف الـمنشأة"}
          value={data?.company?.HRSD_ID}
          top={2}
        />
        <ThreeFields
          en={"Mobile Number"}
          ar={"رقم الجوال"}
          value={data?.company?.tel}
          top={3}
        />
        <ThreeFields
          en={"Email"}
          ar={"البريد الإلكتروني الرسمي"}
          value={data?.company?.recruiter_email}
          top={4}
        />
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "10px",
          backgroundColor: "#44546a",
          padding: "5px",
          color: "white",
          fontSize: "16px",
        }}
      >
        <Text
          style={{
            width: "50%",
            padding: "5px",
          }}
        >
          Second Party’s Information
        </Text>
        <Text
          style={{
            width: "50%",
            padding: "5px",
            textAlign: "right",
          }}
        >
          بيانات الطرف الثاني
        </Text>
      </View>
      <View
        style={{
          justifyContent: "space-between",
          backgroundColor: "#ffffff",
          color: "white",
          fontSize: "16px",
        }}
      >
        <ThreeFields en={"Name"} ar={"الاسم"} value={data?.applicant?.name ?? ""} top={1} />
        <ThreeFields
          en={"ID Number"}
          ar={"رقم الهوية"}
          value={data?.applicant?.ID_number}
          top={2}
        />
        <ThreeFields en={"Gender"} ar={"الجنس"} value={data?.applicant?.gender} top={3} />
        <ThreeFields
          en={"Marital Status"}
          ar={"الحالة الاجتماعية"}
          value={data?.applicant?.marital_status}
          top={4}
        />
        <ThreeFields en={"Religion"} ar={"الديانة"} value={data?.applicant?.religion} top={5} />
        <ThreeFields
          en={"Birth Date"}
          ar={"تاريخ الـميلاد"}
          value={data?.applicant?.date_of_birth}
          top={6}
        />
        <ThreeFields
          en={"National Address"}
          ar={"العنوان الوطني"}
          value={data?.applicant?.national_address}
          top={7}
        />
        <ThreeFields
          en={"Mobile Number"}
          ar={"رقم الجوال"}
          value={data?.applicant?.mobile_number}
          top={8}
        />
        <ThreeFields
          en={"Email"}
          ar={"البريد الإلكتروني"}
          value={data?.applicant?.email}
          top={9}
        />
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "10px",
        }}
      >
        <Text />
        <Image style={{ width: "150px" }} src={data?.company?.logo ?? "/dark_blue.png"} />
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "10px",
          marginBottom: "20px",
        }}
      >
        <Text>Contract Conditions:</Text>
        <Text>:بنود العقد</Text>
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          backgroundColor: "#44546a",
          padding: "5px",
          color: "white",
          fontSize: "16px",
        }}
      >
        <Text
          style={{
            width: "50%",
            padding: "5px",
          }}
        >
          1.Profession, skills & Training Location
        </Text>
        <Text
          style={{
            width: "50%",
            padding: "5px",
            textAlign: "right",
          }}
        >
          الـمهنة، الـمهارة ومكان التدريب
        </Text>
      </View>
      <View
        style={{
          justifyContent: "space-between",
          backgroundColor: "#ffffff",
          color: "white",
          fontSize: "16px",
        }}
      >
        <ThreeFields
          en={"Targeted Profession"}
          ar={"الـمهنة الـمستهدفة بالتدريب"}
          value={
            data?.program?.programs_majors
          }
          top={1}
        />
        <ThreeFields
          en={"Targeted Skills"}
          ar={"الـمهارات الـمستهدف اكتسابها"}
          value={
            data?.program?.program_objective
          }
          top={2}
        />
        <ThreeFields
          en={"Training Location"}
          ar={"مقر التدريب"}
          value={
            data?.program?.program_location
          }
          top={3}
        />
        <ThreeFields
          en={"Training Type"}
          ar={"نوع التدريب"}
          value={
            data?.program?.program_type_translated
          }
          top={4}
        />
      </View>

      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          backgroundColor: "#44546a",
          padding: "5px",
          color: "white",
          fontSize: "16px",
          marginTop: "20px",
        }}
      >
        <Text
          style={{
            width: "50%",
            padding: "5px",
          }}
        >
          2.Contract Period
        </Text>
        <Text
          style={{
            width: "50%",
            padding: "5px",
            textAlign: "right",
          }}
        >
          2.مدة العقد
        </Text>
      </View>
      <View
        style={{
          justifyContent: "space-between",
          backgroundColor: "#ffffff",
          color: "white",
          fontSize: "16px",
        }}
      >
        <TwoFields
          en={`The contract shall be effective for a definite period of time from the date on ${data?.program?.date_of_commencement}. For the training type:
          ${data?.program?.program_type?.en}`}
          ar={`يسري نفاذ هذا العقد التدريبي لـمدة محددة تبدأ من يوم ${data?.program?.date_of_commencement} وذلك للتدريب من النوع:
          ${data?.program?.program_type?.ar}`}
          top={1}
        />
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          backgroundColor: "#44546a",
          padding: "5px",
          color: "white",
          fontSize: "16px",
          marginTop: "20px",
        }}
      >
        <Text
          style={{
            width: "50%",
            padding: "5px",
          }}
        >
          3. Work Hours & Weekly Rest
        </Text>
        <Text
          style={{
            width: "50%",
            padding: "5px",
            textAlign: "right",
          }}
        >
          3.ساعات العمل والراحة الأسبوعية
        </Text>
      </View>
      <View
        style={{
          justifyContent: "space-between",
          backgroundColor: "#ffffff",
          color: "white",
          fontSize: "16px",
        }}
      >
        <TwoFields
          en={`Normal working days and hours (WEEKLY/DAILY) shall be the same working days and hours for employees in the same profession for which the second party is training, as well as the entitled rest day per week.`}
          ar={`تحدد أيام العمل للـمتدرب وساعات العمل اليومية والأسبوعية حسب أيام وساعات العمل التي يخضع لها العاملين في الـمنشأة على نفس الـمهنة التي يقوم بها الطرف الثاني بالتدريب عليها.
          يحق للطرف الثاني عدد أيام الراحة الأسبوعية الـمستحقة للعاملين على نفس الـمهنة التي يقوم بالتدريب عليها.`}
          top={1}
        />
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "30px",
        }}
      >
        <Text />
        <Image style={{ width: "130px" }} src={data?.company?.logo ?? "/dark_blue.png"} />
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          backgroundColor: "#44546a",
          padding: "5px",
          color: "white",
          fontSize: "16px",
          marginTop: "10px",
        }}
      >
        <Text
          style={{
            width: "50%",
            padding: "5px",
          }}
        >
          4. Training Reward (If applied)
        </Text>
        <Text
          style={{
            width: "50%",
            padding: "5px",
            textAlign: "right",
          }}
        >
          4.مكافأة التدريب (إن وجدت)
        </Text>
      </View>
      <View
        style={{
          justifyContent: "space-between",
          backgroundColor: "#ffffff",
          color: "white",
          fontSize: "16px",
        }}
      >
        <TwoFields
          en={`The second party shall be given the Following reward and benefits:`}
          ar={`يستحق الطرف الثاني مكافأة التدريب في حال تم الاتفاق عليها في هذا العقد وتكون ملزمة للطرف الأول:`}
          top={1}
        />
        <ThreeFields
          en={"Basic Reward"}
          ar={"الـمكافأة الأساسية"}
          value={data?.program?.incentive}
          top={2}
        />
        <ThreeFields
          en={"Others"}
          ar={"بدلات أخرى"}
          value={""}
          top={3}
        />
        <TwoFields
          en={`The first party pays the second party each month a total amount of (Total Reward/NUMBER) Saudi riyals.`}
          ar={`دفع الطرف الأول للطرف الثاني مكافأة قدرها (${data?.program?.incentive}) ريال سعودي يستحق نهاية كل شهر.`}
          top={4}
        />
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          backgroundColor: "#44546a",
          padding: "5px",
          color: "white",
          fontSize: "16px",
          marginTop: "20px",
        }}
      >
        <Text
          style={{
            width: "50%",
            padding: "5px",
          }}
        >
          5. Second Party's Bank Account Information (if applied)
        </Text>
        <View
          style={{
            width: "50%", // Adjusted width
            padding: "5px",
            flexDirection: "row",
            justifyContent: "flex-end", // Aligns content to the right
          }}
        >
          <Text
            style={{
              flex: 1, // Takes up remaining space
              textAlign: "right",
              paddingRight: "10px", // Optional: adds some space between text and number
            }}
          >
            معلومات الحساب البنكي للطرف الثاني (إن وجدت)
          </Text>
          <Text
            style={{
              width: "30px",
              textAlign: "right",
            }}
          >
            .5
          </Text>
        </View>
      </View>

      <View
        style={{
          justifyContent: "space-between",
          backgroundColor: "#ffffff",
          color: "white",
          fontSize: "16px",
        }}
      >
        <ThreeFields
          en={"Bank Name"}
          ar={"اسم البنك"}
          value={data?.applicant?.bank_name}
          top={1}
        />
        <ThreeFields en={"IBAN"} ar={"رقم الآيبان"} value={data?.applicant?.iban_number} top={2} />
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          backgroundColor: "#44546a",
          padding: "5px",
          color: "white",
          fontSize: "16px",
          marginTop: "20px",
        }}
      >
        <Text
          style={{
            width: "50%",
            padding: "5px",
          }}
        >
          6.First Party’s Obligations
        </Text>
        <Text
          style={{
            width: "50%",
            padding: "5px",
            textAlign: "right",
          }}
        >
          6.التزامات الطرف الأول
        </Text>
      </View>
      <View
        style={{
          justifyContent: "space-between",
          backgroundColor: "#ffffff",
          color: "white",
          fontSize: "16px",
        }}
      >
        <TwoFieldsList
          en={[
            `Evaluate the second party once every (week / two weeks) and notify him of the results of the evaluation.`,
            `Granting the second party official holidays and sick leave as required by the labor regulations approved by the Ministry of Human Resources and Social Development.`,
            `The first party cannot force the second party to work for him after the training ends and cannot charged him any training costs.`,
          ]}
          ar={[
            `تقييم الطرف الثاني بشكل دوري وإشعاره بنتائج التقييم.`,
            `منح الطرف الثاني العطل الرسمية والإجازات الـمرضية وفق ما تقتضيه لائحة تنظيم العمل الـمعتمدة من وزارة الـموارد البشرية والتنمية الاجتماعية بما لا يتعارض مع نظام العمل ولائحته التنفيذية.`,
            `عدم إلزام الطرف الثاني بالعمل لديه بعد انتهاء التدريب، وعدم تحميله أي رسوم ناتجه عن التدريب.`,
          ]}
          top={1}
        />
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          backgroundColor: "#44546a",
          padding: "5px",
          color: "white",
          fontSize: "16px",
          marginTop: "50px",
        }}
      >
        <Text
          style={{
            width: "50%",
            padding: "5px",
          }}
        >
          7. Second Party’s Obligations
        </Text>
        <Text
          style={{
            width: "50%",
            padding: "5px",
            textAlign: "right",
          }}
        >
          7.التزامات الطرف الثاني
        </Text>
      </View>
      <View
        style={{
          justifyContent: "space-between",
          backgroundColor: "#ffffff",
          color: "white",
          fontSize: "16px",
        }}
      >
        <TwoFieldsList
          en={[
            `Trainee to finish assigned work in accordance with the principles of the profession and in accordance with the instructions of the employer, if these instructions do not violate the contract, order, public morals, or has a potential danger.
            `,
            `To take adequate care of the tools and tasks assigned and the first party’s ores that at the second party’s disposal or in which in his/her custody and restores the first party’s materials that did not consumed.
            `,
            `Committing to good behavior at work and at all times committing to law, rules, and etiquette in kingdom of Saudi Arabia. As well as rules, regulations and directives enforced by the first party and bearing all penalties about breaching the regulations.
            `,
            `To provide all assistance and support without requiring additional wages in the event of disasters and threats to the safety of the place of work or the people working in it.
            `,
            `To undergo medical examination according to the first party’s request prior to or during the course of training in order to ascertain whether he or she is free of occupational or occupational diseases, conditional on the first party paying all costs of the required medical examination.`,
          ]}
          ar={[
            `إنجاز الـمتدرب للعمل الـموكل إليه؛ وفقا لأصول الـمهنة، ووفق تعليمات الطرف الأول، إذا لـم يكن في هذه التعليمات ما يخالف العقد، أو النظام، أو الآداب العامة.`,
            `أن يعتني عناية كافية بالأدوات، والـمهمات الـمستندة إليه والخامات الـمملوكة للطرف الأول الـموضوعة تحت تصرفه، أو التي تكون في عهدته، وأن يعيد إلى الطرف الأول الـمواد غير الـمستهلكة.`,
            `أن يلتزم حسن السلوك والأخلاق أثناء العمل، وفي جميع الأوقات يلتزم بالأنظمة، والأعراف، والعادات، والآداب الـمرعية في الـمملكة العربية السعودية وكذلك بالقواعد واللوائح والتعليمات الـمعمول بها لدى الطرف الأول، ويتحمل الطرف الثاني كامل العقوبات الناتجة عن مخالفته لتك الأنظمة.`,
            `أن يقدم كل عون ومساعدة دون أن يشترط لذلك أجرًا إضافيا في حالات الكوارث والأخطار التي تهدد سلامة مكان العمل أو الأشخاص العاملين فيه.`,
            `أن يخضع -وفقا لطلب صاحب العمل- للفحوص الطبية التي يرغب في إجرائها عليه قبل الالتحاق بالتدريب أو أثناءه، للتحقق من خلوه من الأمراض الـمهنية أو السارية، على أن يتحمل صاحب العمل تكاليف الفحوصات الـمطلوبة.`,
          ]}
          top={1}
        />
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          backgroundColor: "#44546a",
          padding: "5px",
          color: "white",
          fontSize: "16px",
          marginTop: "40px",
        }}
      >
        <Text
          style={{
            width: "50%",
            padding: "5px",
          }}
        >
          8. Third Party’s Obligations
        </Text>
        <Text
          style={{
            width: "50%",
            padding: "5px",
            textAlign: "right",
          }}
        >
          أحكام عامة.8
        </Text>
      </View>
      <View
        style={{
          justifyContent: "space-between",
          backgroundColor: "#ffffff",
          color: "white",
          fontSize: "16px",
        }}
      >
        <TwoFieldsList
          en={[
            `The Labor Law and its executive regulations and the ministerial regulations and resolutions and the organization’s work regulation approved by the Ministry of Human Resources and Social Development, shall be the reference in all matters not explicitly stated herein, and it shall be deemed as an integral part of this Contract. In addition, this Contract replaces all previous agreements and contracts including oral or written if any.
            `,
            `Both parties agreed that the addresses stated at the head of this Contract are the official addresses for exchanging notifications, announcements and warnings and the correspondence is considered a legal argument.
            `,
            `Both parties acknowledge that they have known and understood all the provisions and contents of this contract.`,
          ]}
          ar={[
            `يكون نظام العمل ولائحته التنفيذية واللوائح والقرارات الوزارية ولائحة تنظيم العمل بالـمنشأة الـمعتمدة من قبل وزارة الـموارد البشرية والتنمية الاجتماعية، الـمراجع في أي بند أو أمر لـم يرد نصه بهذا العقد وتعد جزءًا لا يتجزأ من العقد، ويحل هذا العقد محل كافة الاتفاقيات والعقود السابقة الشفهية منها أو الكتابية إن وجدت.
            `,
            `اتفق الطرفان على ان العناوين الـموضحة في صدر العقد هي العناوين النظامية لتبادل الإشعارات والإخطارات والإنذارات وتعتبر الـمخاطبات ذات حجة نظامية.
            `,
            `يقر الطرفان بأنهما قد علـما وفهما كل أحكام هذا العقد ومضمونه.`,
          ]}
          top={1}
        />
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "10px",
        }}
      >
                <Text>الطرف الثاني/Second Party</Text>
        <Text>الطرف الأول/First Party</Text>
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "10px",
        }}
      >
        <View
          style={{
            marginTop: "5px",
            width: "35%",
          }}
        >
          <Text>الاسم/Name</Text>
          <Text
            style={{
              backgroundColor: "#5d687741",
              marginTop: "10px",
              padding: "5px",
            }}
          >
                        {data?.applicant?.name}

          </Text>
        </View>
        <View
          style={{
            marginTop: "5px",
            width: "35%",
            textAlign: "right",
          }}
        >
          <Text>الاسم/Name</Text>
          <Text
            style={{
              backgroundColor: "#5d687741",
              marginTop: "5px",
              padding: "5px",
            }}
          >
            {data?.company?.recruiter_name} 

          </Text>
        </View>
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "10px",
        }}
      ><View
      style={{
        marginTop: "5px",
        width: "35%",
        textAlign: "right",
      }}
    ></View>
        <View
          style={{
            marginTop: "5px",
            width: "35%",
            textAlign: "right",
          }}
        >
          <Text>الـمسمى الوظيفي/Job Titel</Text>
          <Text
            style={{
              backgroundColor: "#5d687741",
              marginTop: "5px",
              padding: "5px",
            }}
          >
            {data?.company?.job_title}
          </Text>
        </View>
        
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "10px",
        }}
      >
        <View
          style={{
            marginTop: "5px",
            width: "35%",
          }}
        >
          <Text>التاريخ/Date</Text>
          <Text
            style={{
              backgroundColor: "#5d687741",
              marginTop: "5px",
              padding: "5px",
            }}
          >
            {data?.applicant?.approved_date}
          </Text>
        </View>
        <View
          style={{
            marginTop: "5px",
            width: "35%",
            textAlign: "right",
          }}
        >
          <Text>التاريخ/Date</Text>
          <Text
            style={{
              backgroundColor: "#5d687741",
              marginTop: "5px",
              padding: "5px",
            }}
          >
            {data?.company?.approved_date}
          </Text>
        </View>
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "10px",
        }}
      >
        <Text style={{
            
            width: "45%",
        }}>تم اعتماد الـمستند إلكترونيا في التاريخ الـمشار إليه أعلاه</Text>
        <Text  style={{
            
            width: "45%",
        }}>تم اعتماد الـمستند إلكترونيا في التاريخ الـمشار إليه أعلاه</Text>
      </View>
    </Page>
  </Document>
};
const ThreeFields = ({ en, ar, value, top }: any) => {
  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        color: "black",
        fontSize: "12px",
        transform: `translateY(-${top}px)`,
        minHeight: "35px",
      }}
    >
      <View
        style={{
          border: "1px solid #000",
          padding: "5px",
          width: "calc(28% + 1px)",
          textAlign: "left",
          transform: "translateX(1px)",
        }}
      >
        <Text>{en}</Text>
      </View>
      <View
        style={{
          border: "1px solid #000",
          padding: "5px",
          width: "44%",
          textAlign: "center",
        }}
      >
        <Text>{value}</Text>
      </View>
      <View
        style={{
          border: "1px solid #000",
          padding: "5px",
          width: "calc(28% + 1px)",
          textAlign: "right",
          transform: "translateX(-1px)",
        }}
      >
        <Text> {ar}</Text>
      </View>
    </View>
  );
};
const TwoFields = ({ en, ar, value, top }: any) => {
  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        color: "black",
        fontSize: "12px",
        transform: `translateY(-${top}px)`,
        minHeight: "40px",
      }}
    >
      <View
        style={{
          border: "1px solid #000",
          padding: "15px",
          width: "calc(50% + 1px)",
          textAlign: "left",
          transform: "translateX(1px)",
        }}
      >
        <Text>{en}</Text>
      </View>

      <View
        style={{
          border: "1px solid #000",
          padding: "15px",
          width: "calc(50% + 1px)",
          textAlign: "right",
          transform: "translateX(-1px)",
        }}
      >
        <Text> {ar}</Text>
      </View>
    </View>
  );
};
const TwoFieldsList = ({ en, ar, value, top }: any) => {
  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        color: "black",
        fontSize: "12px",
        transform: `translateY(-${top}px)`,
        minHeight: "40px",
      }}
    >
      <View
        style={{
          border: "1px solid #000",
          width: "50%", // Adjusted width
          textAlign: "left",
        }}
      >
        {en?.map((item: any, index: number) => (
          <View key={index} style={{ flexDirection: "row", width: "100%" }}>
            <Text style={{ width: "20", textAlign: "right" }}>{index + 1}</Text>
            <Text
              style={{
                width: "calc(100% - 0px)",
                textAlign: "left",
                margin: "0px 5px",
              }}
            >
              {item}
            </Text>
          </View>
        ))}
      </View>

      <View
        style={{
          border: "1px solid #000",
          width: "50%", // Adjusted width
          textAlign: "right",
          direction: "rtl", // For proper Arabic text alignment
        }}
      >
        {ar?.map((item: any, index: number) => (
          <View key={index} style={{ flexDirection: "row-reverse" }}>
            <Text
              style={{
                width: "20px",
                textAlign: "left",
              }}
            >
              {index + 1}
            </Text>
            <Text
              style={{
                width: "calc(100% - 0px)",
                textAlign: "right",
                margin: "0px 5px",
              }}
            >
              {item}
            </Text>
          </View>
        ))}
      </View>
    </View>
  );
};

Font.register({
  family: "IBMPlexSansArabic",
  src: "/Tajawal/IBMPlexSansArabic-Regular.ttf",
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontSize: 12,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Tajawal",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Tajawal",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Tajawal",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

// const Quixote = () => (
//     <Document>
//       <Page style={styles.body}>
//      <View style={{
//             width:"100%",
//               flexDirection: 'row',
//                 justifyContent:"space-between"
//           }}>
//        <Text/>
//        <Image style={{
//            width:"130px"
//          }} src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg"/>

//         </View>
//          <View style={{
//             width:"100%",
//               flexDirection: 'row',
//                 justifyContent:"space-between"
//           }}>
//        <Text/>
//        <Image style={{
//            width:"130px"
//          }} src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg"/>

//         </View>
//       </Page>
//     </Document>
//   );

//   Font.register({
//     family: 'Oswald',
//     src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
//   });

//   const styles = StyleSheet.create({
//     body: {
//       paddingTop: 35,
//       paddingBottom: 65,
//       paddingHorizontal: 35,
//     },
//     title: {
//       fontSize: 24,
//       textAlign: 'center',
//       fontFamily: 'Oswald'
//     },
//     author: {
//       fontSize: 12,
//       textAlign: 'center',
//       marginBottom: 40,
//     },
//     subtitle: {
//       fontSize: 18,
//       margin: 12,
//       fontFamily: 'Oswald'
//     },
//     text: {
//       margin: 12,
//       fontSize: 14,
//       textAlign: 'justify',
//       fontFamily: 'Times-Roman'
//     },
//     image: {
//       marginVertical: 15,
//       marginHorizontal: 100,
//     },
//     header: {
//       fontSize: 12,
//       marginBottom: 20,
//       textAlign: 'center',
//       color: 'grey',
//     },
//     pageNumber: {
//       position: 'absolute',
//       fontSize: 12,
//       bottom: 30,
//       left: 0,
//       right: 0,
//       textAlign: 'center',
//       color: 'grey',
//     },
//   });

//   ReactPDF.render(<Quixote />);

export default MyDocument;
