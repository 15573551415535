import {
  Navbar,
  Group,
  Code,
  ScrollArea,
  createStyles,
  rem,
  getStylesRef,
  UnstyledButton,
  Button,
  UnstyledButtonProps,
  Avatar,
  Text,
  Box,
  Collapse,
  ThemeIcon,
  Drawer,
  Overlay,
  Modal,
  Burger,
} from "@mantine/core";
import PocketBase from "pocketbase";
import _ from "lodash";

import {
  IconLogout,
  IconChevronLeft,
  IconPencil,
  IconMenu,
  IconMenuDeep,
  IconLanguage,
} from "@tabler/icons-react";
// import { UserButton } from '../UserButton/UserButton';
// import { LinksGroup } from '../NavbarLinksGroup/NavbarLinksGroup';
const pb: any = new PocketBase(CMS_ENDPOINT);

const useStyles = createStyles((theme) => ({
  navbar: {
    // height: "100vh",
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.white,
    paddingBottom: 0,
  },

  header: {
    padding: theme.spacing.md,
    paddingTop: 0,
    marginLeft: `calc(${theme.spacing.md} * -1)`,
    marginRight: `calc(${theme.spacing.md} * -1)`,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    borderBottom: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  links: {
    marginLeft: `calc(${theme.spacing.md} * -1)`,
    marginRight: `calc(${theme.spacing.md} * -1)`,
  },

  linksInner: {
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
  },

  footer: {
    marginLeft: `calc(${theme.spacing.md} * -1)`,
    marginRight: `calc(${theme.spacing.md} * -1)`,
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
    user: {
      display: "flex",
      alignItems: "center",
      justifyContent: "start",
      width: "100%",
      padding: theme.spacing.md,
      color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,

      "&:hover": {
        backgroundColor:
          theme.colorScheme === "dark"
            ? theme.colors.dark[8]
            : theme.colors.gray[0],
      },
    },
  },
}));
export function Navbars({ name,color }: any) {
  const { height, width }: any = useViewportSize();
  const [opened, { open, close }] = useDisclosure(false);
  const { systemData }: any = useContext(SystemContext);
  const navbar = systemData?.navbars?.find((item: any) => item.name == name);
  const { locale }: any = useContext(TranslationContext);
  return width >= 1180 ? (
    <NavbarBody name={name} close={close} />
  ) : (
    <>
      {" "}
      <Drawer
        size="xs"
        style={{
          zIndex: 300020,
          padding: 0,
        }}
        opened={opened}
        onClose={close}
        overlayProps={{ zIndex: 100000 }}
        styles={() => ({
          inner: {
            zIndex: 100000,
            padding: 0,
          },
          body: {
            padding: 0,
          
          }
        })}
      >
        <NavbarBody close={close} name={name} />
      </Drawer>
      <motion.div
        onClick={open}
        style={{
          position: "absolute",
          cursor: "pointer",
          top: "30px",
          left: locale=="ar"?"20px":"",
          right: locale=="ar"?"": "20px",
          zIndex: 30000,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "2px",
          padding: "3px",
          color: "#ffffff",
        }}
        whileHover={{
          backgroundColor: "#ffffff79",
          color: "#21033F",
        }}
      >
        {" "}
        <IconMenuDeep
          style={{
            color: color ?? navbar?.drawer_button_color ?? "#000",
          }}
        />
      </motion.div>
    </>
  );
}
export function NavbarBody({ name, close }: any) {
  const { locale,setLocale }: any = useContext(TranslationContext);
  const { systemData }: any = useContext(SystemContext);
  const { reloader }: any = useContext(AppContext);

  const navbar = systemData?.navbars?.find((item: any) => item.name == name);
  const [numbers, setNumbers]: any = useState({});
  const [numbersDepartment, setNumbersDepartment]: any = useState({});

  useEffect(() => {
    const record = pb
      .collection(navbar?.numbers_table)
      .getFirstListItem("", {})
      ?.then((e: any) => {
        setNumbers(e);
      });
      const record_ = pb
      .collection(navbar?.numbers_table_department)
      .getFirstListItem("", {})
      ?.then((e: any) => {
        setNumbersDepartment(e);
      });
  }, [reloader]);
  const [logoutModal, setLogoutModal] = useState(false);
  const [userData, setUserData] = useState(pb.authStore.model);
  useEffect(() => {
    //     pb.collection(pb.authStore.model?.collectionName).subscribe(pb.authStore.model.id, (e) => {
    //       pb.authStore.save(pb.authStore.token, e.record);
    //       setUserData(e.record);
    // });
    // return () => {
    //   pb.collection(pb.authStore.model?.collectionName).unsubscribe()
    // };
    setUserData(pb.authStore.model);
  }, [reloader]);

  const pages = _.sortBy(
    systemData?.pages?.filter((page: any) => page.navbar == name),
    ["tabs_order"]
  )?.filter((page: any) =>
  page?.permissions != null
    ? pb.authStore.model?.expand?.permission?.permissions
        ?.map(
          (item) => item?.split(".")?.[0] + "." + item?.split(".")?.[1]
        )
        ?.includes(page?.permissions)
    : true
);
  // const test1 = alasql(
  //   `SELECT * FROM ? where page_groub_en IS NOT NULL GROUP BY page_groub_en `,
  //   [pages]
  // );
  // const test2 = alasql(`SELECT * FROM ? where page_groub_en IS NULL `, [pages]);
  const pageGroabs = pages?.[0]
    ? alasql(
        `SELECT * FROM ?  GROUP BY page_groub_order order by page_groub_order `,
        [pages]
      )
    : [];

  const mockdata = pageGroabs?.map((item: any) => {
    return({
    page_groub: item?.page_groub_en,
    number: item?.page_groub_en
      ? numbers?.[item?.groub_numbers_column]
      : numbers?.[item?.numbers_column],
    numberDepartment: item?.page_groub_en
      ? numbersDepartment?.[item?.groub_numbers_column]
      : numbersDepartment?.[item?.numbers_column],
    enable: item?.enable ? item?.enable : item?.page_groub_enable,
    label: item?.[`page_groub_${locale}`]
      ? item?.[`page_groub_${locale}`]
      : item?.[`title_${locale}`],
    icon: item?.page_groub_icon ? item?.page_groub_icon : item?.icon,
    initiallyOpened: item?.initiallyOpened,
    links: pages?.[0]
      ? alasql(
          `SELECT * FROM ? Where page_groub_en = "${item.page_groub_en}" group by tabs_order`,
          [pages]
        )?.[0] &&
        alasql(
          `SELECT * FROM ? Where page_groub_en = "${item.page_groub_en}" group by tabs_order`,
          [pages]
        )?.map((item: any) => ({
          label: item?.[`title_${locale}`],
          link: item?.path,
          number: numbers?.[item?.numbers_column],
          numberDepartment: numbersDepartment?.[item?.numbers_column],
          enable: item?.enable,
        }))
      : null,
    //   links: [
    //   { label: "Overview", link: "/" },
    //   { label: "Forecasts", link: "/" },
    //   { label: "Outlook", link: "/" },
    //   { label: "Real time", link: "/" },
    // ],
    link: item?.page_groub_en ? null : item?.path,
  })});

  // const mockdata = [
  //
  //   { label: tn("Dashboard"), icon: IconGauge , link:"/admin/dashboard" ,  },
  //   {
  //     label: tn("Dashboard"),
  //     icon: IconNotes,
  //     initiallyOpened: true,
  //     links: [
  //       { label: "Overview", link: "/" },
  //       { label: "Forecasts", link: "/" },
  //       { label: "Outlook", link: "/" },
  //       { label: "Real time", link: "/" },
  //     ],
  //   },
  //   {
  //     label: "Releases",
  //     icon: IconCalendarStats,
  //     links: [
  //       { label: "Upcoming releases", link: "/" },
  //       { label: "Previous releases", link: "/" },
  //       { label: "Releases schedule", link: "/" },
  //     ],
  //   },
  //   { label: "Analytics", icon: IconPresentationAnalytics },
  //   { label: "Contracts", icon: IconFileAnalytics },
  //   { label: "Settings", icon: IconAdjustments },
  //   {
  //     label: "Security",
  //     icon: IconLock,
  //     links: [
  //       { label: "Enable 2FA", link: "/" },
  //       { label: "Change password", link: "/" },
  //       { label: "Recovery codes", link: "/" },
  //     ],
  //   },
  // ]; ,

  const [openedTab, setOpenedTab] = useState("");
  const { classes } = useStyles();
  const links = mockdata.map((item: any) => {

    return(
    <LinksGroup
      {...item}
      navbar={navbar}
      key={item.label}
      close={close}
      setOpenedTab={setOpenedTab}
      openedTab={openedTab}
    />
  )});
  const navigate = useNavigate();
  return (
    <Navbar width={{ sm: 300 }} p="md" className={classes.navbar}>
      <Navbar.Section grow className={classes.links} component={ScrollArea}>
        <div
          style={{
            // marginTop: width >= 1180 ? "80px" : "20px",
            marginTop: "20px",

            width: "100%",
            display: "flex",
            justifyContent: "start",
            padding: "0px 20px",
          }}
        >
          <UserButton
            navbar={navbar}
            onClick={() => {
              navigate(navbar?.profile_path);
              close();
            }}
            image={`${CMS_ENDPOINT}/api/files/${userData?.collectionName}/${userData?.id}/${userData?.avatar}?token=`}
            name={
              navbar?.name_seperate
                ? (navbar?.first_name ? userData?.name?.split(" ")?.[0] : "") +
                  " " +
                  (navbar?.last_name ? userData?.name?.split(" ")?.[1] : "")
                : (navbar?.first_name ? userData?.first_name : "") +
                  " " +
                  (navbar?.last_name ? userData?.last_name : "")
            }
            email={""}
          />
        </div>
        <div className={classes.linksInner}>{links}</div>
      </Navbar.Section>

      <Navbar.Section className={classes.footer}>
        <Button
          onClick={() => {
            setLocale(locale == "ar" ? "en" : "ar")
          }}
          variant="subtle"
          styles={() => ({
            root: {
              "&:hover": {
                backgroundColor: "#c2c2c23b",
              },
            },
          })}
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            width: "90%",
            padding: "0px",
            backgroundColor: "",
            margin: "10px",
          }}
        >
          <IconLanguage style={{ margin: "0px 20px" }} size={18} color="gray" />
          <Text size="md" fw={600} color="gray">
            {
              locale == "ar" ? "English" : "العربية"
            }
          </Text>
        </Button>
      </Navbar.Section>
      <Navbar.Section className={classes.footer}>
        <Button
          onClick={() => {
            setLogoutModal(true);
            // pb.authStore.clear();
            // navigate("/");
          }}
          variant="subtle"
          styles={() => ({
            root: {
              "&:hover": {
                backgroundColor: "#c2c2c23b",
              },
            },
          })}
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            width: "90%",
            padding: "0px",
            backgroundColor: "",
            margin: "10px",
          }}
        >
          <IconLogout style={{ margin: "0px 20px" }} size={18} color="red" />
          <Text size="md" fw={600} color="red">
            {tn("Logout")}
          </Text>
        </Button>
      </Navbar.Section>

      <LogoutModal onClose={setLogoutModal} opened={logoutModal} />
    </Navbar>
  );
}
import { IconChevronRight } from "@tabler/icons-react";

import { useContext, useEffect, useState } from "react";
import { TranslationContext } from "../../context/TranslationContext";
import { ltn, t, tn } from "../../translation";
import { Navigate, useNavigate } from "react-router-dom";
import alasql from "alasql";
import { CMS_ENDPOINT } from "../../constants";
import { SystemContext } from "../../context/SystemContext";
import { useDisclosure, useViewportSize } from "@mantine/hooks";
import { motion } from "framer-motion";
import { AppContext } from "../../context/AppContext";

const useStyles_ = createStyles((theme) => ({
  control: {
    fontWeight: 500,
    display: "block",
    width: "100%",
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,
    fontSize: theme.fontSizes.sm,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[7]
          : theme.colors.gray[0],
      color: theme.colorScheme === "dark" ? theme.white : theme.black,
    },
  },

  link: {
    fontWeight: 500,
    display: "block",
    textDecoration: "none",
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    paddingLeft: rem(31),
    marginLeft: rem(30),
    fontSize: theme.fontSizes.sm,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    borderLeft: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[7]
          : theme.colors.gray[0],
      color: theme.colorScheme === "dark" ? theme.white : theme.black,
    },
  },

  chevron: {
    transition: "transform 200ms ease",
  },
}));

interface LinksGroupProps {
  icon: string;
  label: string;
  initiallyOpened?: boolean;
  links?: { label: string; link: string; enable: boolean; number: any; numberDepartment: any }[];
  link?: any;
  enable?: boolean;
  number?: any;
  close?: any;
  navbar?: any;
  setOpenedTab?: any;
  openedTab?: any;
  name?: any;
  numberDepartment?: any;
  page_groub?: any;
}

export function LinksGroup({
  icon,
  label,
  initiallyOpened,
  links,
  link,
  number,
  enable,
  close,
  navbar,
  setOpenedTab,
  openedTab,
  page_groub,
  numberDepartment,
}: LinksGroupProps) {

  const { classes, theme } = useStyles_();
  const hasLinks = Array.isArray(links);

  const ChevronIcon = theme.dir === "ltr" ? IconChevronRight : IconChevronLeft;
  const navigate = useNavigate();
  const items = (hasLinks ? links : []).map((link) => (
    <Text
      className={classes.link}
      // href={link.link}
      key={link.label}
      onClick={
        link.enable
          ? () => {
              navigate(link.link);
              close();
            }
          : () => {
              close();
            }
      }
      style={{
        color: link.enable ? "#030229" : "#b7b7b7",
        cursor: link.enable ? "pointer" : "not-allowed",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {link.label}
      {link?.number != undefined && (
        <div
          style={{
            // width: "15px",
            height: "15px",
            borderRadius: "1000px",
            backgroundColor: navbar?.number_color ?? "#21033f",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "2px 5px",
            fontSize: "10px",
            color: "#fff",
          }}
        >
          {pb.authStore.model.expand?.permission?.accessible_data?.en == "All data"  ? link?.number : link?.numberDepartment ?? link?.number }
        </div>
      )}
    </Text>
  ));

  return (
    <>
      <UnstyledButton
        disabled={!enable}
        onClick={() => {
          setOpenedTab((prev: any) => (prev == page_groub ? "" : page_groub));
          navigate(link);
          if (!hasLinks) {
            close();
          }
        }}
        className={classes.control}
        style={{ cursor: enable ? "pointer" : "not-allowed" }}
      >
        <Group position="apart" spacing={0}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              color: enable ? "#030229" : "#b7b7b7",
            }}
          >
            {/* <ThemeIcon color="dark" variant="light" size={30}> */}
            {/* <Icon color={enable?"#030229":"#b7b7b7"} icon={icon} /> */}
            <div
              style={{ filter: enable ? "grayscale(0)" : "grayscale(100%)" }}
            >
              <div
                style={{
                  width: "30px",
                  height: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                dangerouslySetInnerHTML={{ __html: icon }}
              />
            </div>
            {/* </ThemeIcon> */}
            <Box ml="md">{label}</Box>
          </Box>
          <div
            style={{
              display: "flex",
            }}
          >
            {number != undefined && (
              <div
                style={{
                  // width: "15px",
                  height: "15px",
                  borderRadius: "1000px",
                  backgroundColor: navbar?.number_color ?? "#21033f",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "2px 5px",
                  fontSize: "10px",
                  color: "#fff",
                }}
              >
                {number}
              </div>
            )}
            {hasLinks && (
              <ChevronIcon
                color={enable ? "#030229" : "#b7b7b7"}
                className={classes.chevron}
                size="1rem"
                stroke={1.5}
                style={{
                  transform:
                    openedTab == page_groub
                      ? `rotate(${theme.dir === "rtl" ? -90 : 90}deg)`
                      : "none",
                }}
              />
            )}
          </div>
        </Group>
      </UnstyledButton>
      {hasLinks ? (
        <Collapse in={openedTab == page_groub}>{items}</Collapse>
      ) : null}
    </>
  );
}

interface UserButtonProps extends UnstyledButtonProps {
  image: string;
  navbar?: any;
  name: string;
  email: string;
  icon?: React.ReactNode;
  onClick?: any;
}

export function UserButton({
  navbar,
  image,
  name,
  email,
  icon,
  onClick,
  ...others
}: UserButtonProps) {
  const { classes }: any = useStyles();
  const { locale }: any = useContext(TranslationContext);
  const [onHover, setOnHover] = useState(false);
  return (
    <motion.div
      style={{
        width: "100%",
      }}
      onHoverStart={() => {
        setOnHover(true);
      }}
      onHoverEnd={() => {
        setOnHover(false);
      }}
    >
      <UnstyledButton
        style={{
          width: "100%",
        }}
        className={classes.user}
        {...others}
        onClick={onClick}
      >
        <Group>
          <div
            style={{
              position: "relative",
            }}
          >
            <Avatar src={image} radius="xl" size={50}>
              {" "}
              <img
                style={{
                  width: "125%",
                  height: "125%",
                }}
                src={"/avatar_placeholder.svg"}
              />
            </Avatar>
            <motion.div
              animate={{
                width: onHover ? "260px" : "23px",
                height: onHover ? "58px" : "23px",
              }}
              style={{
                width: "23px",
                height: "23px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#21033f79",
                border: "1px solid #ffffff9b",
                borderRadius: "1000px",
                position: "absolute",
                bottom: "0px",
                right: locale == "ar" ? "0px" : "",
                left: locale == "en" ? "0px" : "",
                padding: "2px",
                transform:
                  locale == "ar" ? "translate(4px,4px)" : "translate(-4px,4px)",
              }}
            >
              <IconPencil color="white" />
            </motion.div>
          </div>

          <div style={{ flex: 1 }}>
            <Text size="lg" weight={700}>
              {navbar?.with_hi && (locale == "ar" ? "اهلًا" : "hi ") + " "}{" "}
              <span style={{ color: navbar?.color }}>{name}</span>
            </Text>

            <Text color="dimmed" size="xs">
              {email}
            </Text>
          </div>

          {/* {icon ||locale == "en" ? <IconChevronRight size="0.9rem" stroke={1.5} /> : <IconChevronLeft size="0.9rem" stroke={1.5} />} */}
        </Group>
      </UnstyledButton>
    </motion.div>
  );
}

function LogoutModal({ onClose, opened }: any) {
  const navigate = useNavigate();
  return (
    <Modal
      size={"sm"}
      style={{ zIndex: 9999999, position: "relative" }}
      overlayProps={{
        zIndex: 2000,
        opacity: 0.55,
        blur: 3,
      }}
      styles={() => ({
        content: {
          zIndex: 10001,
          borderRadius: "10px",
          background: "linear-gradient(153deg, #69DCBF 100%, #21033f7a 100%)",
          // display: "flex",
          // justifyContent: "center",
          // alignItems: "center",
        },
        header: { display: "none" },
        body: { padding: "9px" },
        inner: { zIndex: 10001 },
      })}
      opened={opened}
      onClose={() => {
        onClose(false);
      }}
      centered
    >
      <div
        style={{
          background: "white",
          width: "100%",
          // height: "100%",
          marginTop: "9px",
          borderRadius: "5px",
          // paddingTop: "30px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "start",
            padding: "20px",
            width: "100%",
          }}
        >
          <Text fw={600} color="#5c5c5c">
            {ltn("logout_massage")}
          </Text>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "20px",
            }}
          >
            <Button
              color="red"
              mx={5}
              onClick={() => {
                if (pb.authStore.model.collectionName == "admin") {
                  navigate("/admin/login");
                } else if (pb.authStore.model.collectionName == "recruiters") {
                  navigate("/recruiter/login");
                } else {
                  navigate("/applicant/login");
                }
                pb.authStore.clear();
              }}
            >
              {tn("Logout")}
            </Button>
            <Button
              variant="outline"
              color="gray"
              mx={5}
              onClick={() => onClose(false)}
            >
              {tn("Cancel")}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
