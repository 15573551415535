import { tn } from "../../../../../../translation";

import {
  Avatar,
  Button,
  Checkbox,
  Loader,
  Text,
  TextInput,
} from "@mantine/core";

import { useContext, useEffect, useState } from "react";
import { TranslationContext } from "../../../../../../context/TranslationContext";
import { CMS_ENDPOINT } from "../../../../../../constants";
import PocketBase from "pocketbase";
import { useDebouncedValue, useViewportSize } from "@mantine/hooks";
import { AppContext } from "../../../../../../context/AppContext";
import { DataTable } from "mantine-datatable";
const pb = new PocketBase(CMS_ENDPOINT);
export const InviteApplicant = ({ data, onClose }: any) => {
  const { translation, locale }: any = useContext(TranslationContext);
  const [search, setSearch] = useState("");

  const [debounced_data] = useDebouncedValue(data?.record, 200);
  const [debounced_search] = useDebouncedValue(search, 400);

  const { reloader, setReloader }:any = useContext(AppContext);
  const [buttonLoading, setButtonLoading] = useState(false);
  const { width } = useViewportSize();
  const [programs, setPrograms]: any = useState("loading");
  const [currentProgram, setCurrentProgram] = useState("");
  useEffect(() => {
    const record = pb
      .collection("invitations")
      .getFullList({
        sort: "-created",
        expand: "applicant,program",
        filter: `applicant.id='${debounced_data?.id}'`,
      })
      ?.then((invitation: any) => {
        const record = pb
          .collection("applicant_view_programs")
          .getFullList({
            sort: "-created",
            expand: "department",
            filter: search
              ? `code ~ '%${search}%' || name ~ '%${search}%' || department.name ~ '%${search}%' || type.en ~ '%${search}%'`
              : "",
          })
          ?.then((res: any) => {

            setPrograms(
              res?.filter((program) => !invitation?.map((o)=>o.program)?.includes(program.id))
            );
          });
      });
  }, [debounced_data, debounced_search]);

  return width > 10 ? (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Text
        style={{
          width: "100%",
          padding: "10px 20px 10px ",
          color: "rgba(0, 0, 0, 0.70)",
          textAlign: "right",
          fontSize: "22px",
          fontWeight: "700",
        }}
      >
        {tn("Invitation to join a training program")}
      </Text>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          padding: "0px 10px",
          flexDirection: width > 850 ? "row" : "column",
          alignItems: width > 850 ? "stretch" : "stretch",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            width: width > 850 ? "calc(35% - 10px)" : "calc(100% - 10px)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "start",
            background: "rgba(79, 81, 82, 0.05)",
            borderRadius: "10px",
          }}
        >
          <div
            style={{
              width: "calc(100% - 30px)",
              background: "#69DCBF",
              height: "5px",
              borderRadius: "522px",
            }}
          />
          <Avatar
            my="md"
            styles={() => ({
              placeholderIcon: {
                width: "120%",
                height: "120%",
                color: "#4F5152",
                minWidth: "120%",
                minHeight: "120%",
              },
            })}
            src={`${CMS_ENDPOINT}/api/files/${debounced_data?.collectionName}/${debounced_data?.id}/${debounced_data?.avatar}?token=`}
            size={150}
            radius="100%"
            variant="outline"
          >
            <img
              style={{
                width: "125%",
                height: "125%",
              }}
              src={"/avatar_placeholder.svg"}
            />
          </Avatar>
          <TextBox
            value={debounced_data?.name}
            name={tn("Applicant name") + ":"}
          />
          <TextBox
            value={debounced_data?.university?.[locale]}
            name={tn("University") + ":"}
          />
          <TextBox
            value={debounced_data?.major?.[locale]}
            name={tn("Major") + ":"}
          />
          <TextBox
            value={
              Math.floor(
                (debounced_data?.GPA / debounced_data?.GPA_out_of) * 100
              ) + "%"
            }
            name={tn("GPA") + ":"}
          />
          <TextBox
            value={debounced_data?.graduation_year}
            name={tn("Graduation Year") + ":"}
          />
          <TextBox
            value={debounced_data?.semester?.[locale]}
            name={tn("Semester") + ":"}
          />
          <Text
            style={{
              width: "100%",
              textAlign: "start",
              color: "#4b4c4c",
              fontSize: "20px",
              padding: "0px 10px",
              margin: "10px 0px 10px",
              fontWeight: "800",
            }}
          >
            {tn("About")}
          </Text>
          <Text
            style={{
              width: "calc(100% - 20px)",
              textAlign: debounced_data?.about ? "start" : "center",
              color: "#545757",
              fontSize: "14px",
              padding: debounced_data?.about ? "5px 5px" : "10px 10px",
              borderRadius: "5px",
              margin: "0px 10px 10px",
              fontWeight: "400",
              background: "rgb(255, 255, 255)",
            }}
          >
            {debounced_data?.about ? debounced_data?.about : tn("nothing")}
          </Text>
        </div>
        <div
          style={{
            width: width > 850 ? "calc(65% - 10px)" : "calc(100% - 10px)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            background: "rgba(79, 81, 82, 0.05)",
            borderRadius: "10px",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "calc(100% - 30px)",
                  background: "#21033F",
                height: "5px",
                borderRadius: "522px",
              }}
            />
            <Text
              style={{
                width: "100%",
                padding: "10px 20px 10px ",
                color: "rgba(38, 37, 37, 0.7)",
                textAlign: "right",
                fontSize: "20px",
                fontWeight: "400",
              }}
            >
              {tn(
                "Choose the program you would like to invite the candidate to join"
              )}
            </Text>
          </div>
          <div
            style={{
              width: "calc(100% - 30px)",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "10px 10px",
            }}
          >
            <TextInput
              size="xs"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              placeholder={tn("Search")}
              style={{
                width: "calc(80%)",
              }}
              styles={() => ({
                input: {
                  border: "1px solid #000000",
                },
                placeholder: {
                  color: "#000000",
                },
              })}
            />
          </div>
          <div
            style={{
              width: "calc(100% - 30px)",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "10px 10px",
              background: "#ffffff",
              height: "calc(100% - 150px)",
            }}
          >
            {programs != "loading" ? (
              <DataTable
                highlightOnHover
                style={{
                  width: "100%",
                  height: "100%",
                }}
                withBorder
                striped
                borderRadius="md"
                fontSize="sm"
                columns={[
                  {
                    title: "",
                    accessor: "check",
                    textAlignment: "center",
                  },
                  {
                    title: tn("Program code"),
                    accessor: "code",
                    textAlignment: "center",
                  },

                  {
                    title: tn("Program name"),
                    accessor: "name",
                    textAlignment: "center",
                  },
                  {
                    title: tn("Department"),
                    accessor: "department",
                    textAlignment: "center",
                  },
                  {
                    title: tn("Program type"),
                    accessor: "type",
                    textAlignment: "center",
                  },
                ]}
                onRowClick={(row) => {
                  setCurrentProgram(row?.id);
                }}
                records={programs
                  .filter((program) => {
                    // Check GPA condition
                    const validGPA =
                      (debounced_data.GPA_out_of === "4" &&
                        parseFloat(program.GPA_4) <=
                          parseFloat(debounced_data.GPA)) ||
                      (debounced_data.GPA_out_of === "5" &&
                        parseFloat(program.GPA_5) <=
                          parseFloat(debounced_data.GPA));

                    // Check university condition
                    const validUniversity = program.universities?.some(
                      (u) =>
                        u?.en === debounced_data.university?.en ||
                        u?.en === "all universities"
                    );

                    // Check target educational segment condition
                    const validSegment =
                      program.target_educational_segment?.some(
                        (segment) =>
                          segment?.en === debounced_data.case_study?.en
                      );

                    // Check major condition
                    const validMajor = program.major?.some(
                      (m) => m?.en === debounced_data.major?.en
                    );

                    // Check degree condition
                    const validDegree = program.degree?.some(
                      (d) => d?.en === debounced_data.degree?.en
                    );
                    debounced_data;
                    // Check status condition
                    const validStatus =
                      program.status?.en === "under the procedure" ||
                      program.status?.en === "new" ||
                      (program.partial_status &&
                        program.partial_status?.en === "published");

                    // Check last submission date condition
                    const validSubmissionDate =
                      new Date(program.last_submission_date) >= new Date();

                    // Check applicants count condition
                    const validApplicantsCount =
                      program.applicants_count <
                      parseInt(program.candidate_in_need);

                    // Combine all conditions using AND operator
                    return (
                      validGPA &&
                      validUniversity &&
                      validSegment &&
                      validMajor &&
                      validDegree &&
                      validStatus &&
                      validSubmissionDate &&
                      validApplicantsCount
                    );
                  })
                  ?.map((program: any) => {
                    return {
                      check: (
                        <Checkbox
                          checked={currentProgram === program?.id}
                          size="xs"
                          color="color2"
                          onChange={(e) => {
                            setCurrentProgram(program?.id);
                          }}
                        />
                      ),
                      code: program?.code,
                      name: program?.name,
                      department: program?.expand?.department?.name,
                      type: program?.type?.[locale],
                      id: program?.id,
                    };
                  })}
              />
            ) : (
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Loader color="color1" />
              </div>
            )}
          </div>
          <div />
        </div>
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "end",
          padding: "20px 0px",
        }}
      >
        <Button
          onClick={() => {
            setButtonLoading(true);
            pb.collection("invitations")
              .create({
                applicant: debounced_data?.id,
                program: currentProgram,
                status: {
                  en: "waiting for the candidate",
                  ar: "في انتظار المرشح",
                },
                invitation_date: new Date(),
              })
              .then((res) => {
                setButtonLoading(false);
                onClose({});
              });
          }}
          disabled={!currentProgram}
          type="submit"
          loading={buttonLoading}
          compact
          style={{
            backgroundColor: currentProgram ? "#69DCBF" : "",
            // color: "white",
            width: "100px",
            borderRadius: "5.112px",
          }}
        >
          {tn("Invite")}
        </Button>

        <Button
          compact
          mx="sm"
          style={{
            color: "white",
            width: "100px",
            borderRadius: "5.112px",
            backgroundColor: "#F9837C",
          }}
          color="red"
          onClick={() => {
            onClose({});
          }}
        >
          {tn("Cancel")}
        </Button>
      </div>
    </div>
  ) : (
    <div
      style={{
        height: "1270px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Loader color="color1" />
    </div>
  );
};
const TextInputStyle = (error: any) => ({
  description: {
    marginBottom: "-17px",
    transform: "translate(5px, 35px)",
    fontSize: "8px",
    opacity: error ? "0" : "1",
  },
  label: {
    zIndex: "10",
    position: "relative",
    margin: "0px 10px",
    transform: "translate(0px, 7px)",
    background: "#f6f6f6",
    borderRadius: "5px",
    padding: "0px 5px",
    fontSize: "10px",
  },
  input: {
    borderRadius: "5px",
    border: "1px solid #000000",
  },
  error: {
    marginBottom: "-17px",
    transform: "translate(4px, 3px)",
    // fontSize: "8px",
  },
});

const TextBox = ({ value, name }: any) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        margin: "0px 0px",
        padding: "0px 10px",
      }}
    >
      <Text
        style={{
          padding: "8px 3px 8px ",
          color: "#4F5152",
          textAlign: "right",
          fontSize: "14px",
          fontWeight: "700",
        }}
      >
        {name}
      </Text>
      <Text
        style={{
          minHeight: "34.8px",
          width: "180px",
          color: "#4F5152",
          padding: "5px 10px",
          borderRadius: "5px",
          background: "#ffffff",
          fontSize: value?.length > 23 ? "13px" : "16px",
          textAlign: "center",
          fontWeight: "400",
        }}
      >
        {value}
      </Text>
    </div>
  );
};
