import { Button, Select, Text, TextInput } from "@mantine/core";
import { tn } from "../../translation";
import { useForm } from "@mantine/form";
import { useContext, useState } from "react";
import { TranslationContext } from "../../context/TranslationContext";
import { pb } from "../../config";
import { AppContext } from "../../context/AppContext";

export function LocationDataForm({ data, onClose }) {
  const { locale, translation }: any = useContext(TranslationContext);
  const [buttonLoading, setButtonLoading] = useState(false);
  const { setReloader, reloader }: any = useContext(AppContext);

  const city = translation?.city;
  const data_path = data?.record?.location_data;
  const form = useForm({
    initialValues: {
      city: data_path?.city?.en ?? "",
      location: data_path?.location ?? "",
      building_number: data_path?.building_number ?? "",
      floor: data_path?.floor ?? "",
      office_no: data_path?.office_no ?? "",
    },
  });
  console.log('data2data',data);
  return (
    <form
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        padding: "10px",
      }}
      onSubmit={form?.onSubmit(async (values) => {
        setButtonLoading(true);

        const record = await pb.collection("companies").update(data?.id, {
          location_data: {
            ...values,
            city: {
              en:city?.find((c:any) => c.name_en == values?.city)?.name_en,
              ar:city?.find((c:any) => c.name_en == values?.city)?.name_ar,
            },
          },
        });
        setReloader(!reloader);
        onClose({});
        // setButtonLoading(false);
      })}
    >
      <Text
        style={{
          width: "100%",
          color: "#434343",
          fontSize: "15px",
          fontWeight: "bold",
          margin: "10px 10px 0px 10px",
        }}
      >
        {tn("Primary address")}
      </Text>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "10px",
          padding: "0px 10px",
        }}
      >
        <SelectField
          data={mapOptions(city, `name_${locale}`, "name_en")}
          label="City"
          description="Enter the city where this training opportunity is located"
          error={form.errors?.city}
          inputProps={form.getInputProps("city")}
          withAsterisk
        />

        <TextField
          description="Type the short national address to access the geographical location"
          label="location"
          error={form.errors?.location}
          inputProps={form.getInputProps("location")}
          withAsterisk
        />
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "10px",
          padding: "0px 10px",
        }}
      >
        <TextField
          description="Enter the building number"
          label="Building number"
          error={form.errors?.building_number}
          inputProps={form.getInputProps("building_number")}
          withAsterisk
        />
        <TextField
          description="Write the floor number in which the trainee will begin"
          label="The floor"
          error={form.errors?.floor}
          inputProps={form.getInputProps("floor")}
          withAsterisk
        />
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "10px",
          padding: "0px 10px",
        }}
      >
        <TextField
          description="Enter the office number"
          label="Office No"
          error={form.errors?.office_no}
          inputProps={form.getInputProps("office_no")}
          withAsterisk
        />
        <div style={{ width: "100%" }} />
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          padding: "10px 10px",
          gap: "10px",
        }}
      >
        <Button
          loading={buttonLoading}
          type="submit"
          color="color2"
          compact
          px="xl"
          disabled={!form.isDirty()}
        >
          {tn("Save")}
        </Button>
        <Button
          onClick={() => {
            onClose(false);
          }}
          style={{
            background: "#F9837C",
          }}
          compact
          px="xl"
        >
          {tn("Cancel")}
        </Button>
      </div>
    </form>
  );
}

const TextField = ({
  description,
  label,
  error,
  inputProps,
  withAsterisk = false,
  padding,
  disabled,
  marginTop,
}: any) => (
  <div
    style={{
      width: "100%",
      padding: padding ? padding : "0px 0px",
      marginTop: marginTop ? marginTop : "0px",
    }}
  >
    <TextInput
    required
      disabled={disabled}
      label={tn(label)}
      withAsterisk={withAsterisk}
      styles={() => TextInputStyle(error)}
      {...inputProps}
      size="xs"
      style={{ width: "100%" }}
    />
    <Text
      mt="2px"
      mb={error ? "12px" : "0px"}
      px="5px"
      fz="8px"
      color="#868e96"
    >
      {tn(description)}
    </Text>
  </div>
);
const TextInputStyle = (error: any) => ({
  label: {
    zIndex: "10",
    position: "relative",
    margin: "0px 10px",
    transform: "translate(0px, 7px)",
    background: "#f6f6f6",
    borderRadius: "5px",
    padding: "0px 5px",
    fontSize: "10px",
  },
  input: {
    borderRadius: "5px",
    border: "1px solid #000000",
  },
  error: {
    marginBottom: "-14px",
    transform: "translate(4px, 13px)",
    // fontSize: "8px",
  },
});
const SelectField = ({
  data,
  label,
  description,
  error,
  inputProps,
  withAsterisk = false,
  disabled,
}: any) => (
  <div
    style={{
      width: "100%",
    }}
  >
    <Select
      disabled={disabled}
      data={data}
      label={tn(label)}
      withAsterisk={withAsterisk}
      styles={() => TextInputStyle(error)}
      {...inputProps}
      size="xs"
      style={{ width: "100%" }}
    />
    <Text mt="2px" px="5px" fz="8px" color="#868e96">
      {tn(description)}
    </Text>
  </div>
);

const mapOptions = (items: any, labelField: any, valueField: any) =>
  items.map((item: any) => ({
    value: item[valueField],
    label: item[labelField],
  }));
