
import { Tabs, rem, Text } from "@mantine/core";
import { DataGrid } from "../../main_components/data-grid/DataGrid";
import {
  IconCalendarTime,
  IconClock,
  IconClockCancel,
  IconMessageCircle,
  IconPhoto,
  IconSettings,
} from "@tabler/icons-react";
import { tn, ttn } from "../../translation";
import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../constants";
import { AppContext } from "../../context/AppContext";
import { Helmet } from "react-helmet";

export const WithdrawalRequests  = () => {
  const iconStyle = { width: rem(16), height: rem(16) };
  const { page = "new_withdrawal_requests" } = useParams();
  const navigate = useNavigate();
  
  const { reloader }: any = useContext(AppContext);
  const pb: any = new PocketBase(CMS_ENDPOINT);
  const [numbers, setNumbers]: any = useState({});
  const [numbersDepartment, setNumbersDepartment]: any = useState({});

  useEffect(() => {
    const record = pb
      .collection("recruiters_numbers")
      .getFirstListItem("", {})
      ?.then((e: any) => {
        setNumbers(e);
      });
      const record_ = pb
      .collection("recruiters_department_number")
      .getFirstListItem("", {})
      ?.then((e: any) => {
        setNumbersDepartment(e);
      });
  }, [reloader]);
  const numbers_main = pb.authStore.model.expand?.permission?.accessible_data?.en == "All data"  ?numbers :numbersDepartment
  return ( 
    <div
      style={{
        width: "100%",
        height: "calc(100vh - 84px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        flexDirection: "column",
        padding: "20px",
        paddingTop: "20px",
        background: "#fcfcfc",
      }}
    >

    <Text style={{
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "start",
        "color": "rgba(0, 0, 0, 0.70)",
        "fontSize": "24px",
        "fontWeight": "500",
        padding: "0px 10px 5px",
    }} 
   
    >
        {tn("Withdrawal requests")}
    </Text>
      {" "}
      <Tabs
        style={{
          width: "100%",
        }}
        defaultValue="new_withdrawal_requests"
        value={page}
        onTabChange={(e) => {
          navigate(`/recruiter/withdrawal_requests/${e}`);
        }}
      >
        <Tabs.List>
          <Tabs.Tab
            style={{}}
            value="new_withdrawal_requests"
            icon={<IconClock style={iconStyle} />}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                gap: "10px",
              }}
            >
              {tn("New requests")}
             {numbers_main?.new_withdrawal_requests != undefined && <div style={{
                  padding: "3px 5px",
                borderRadius: "10000px",
                background: "#520f96",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#fff",
                fontSize: "10px",
                fontWeight: "bold",
              }}>{numbers_main?.new_withdrawal_requests}</div>}
            </div>
          </Tabs.Tab>
          <Tabs.Tab
            value="withdrawal_requests_history"
            icon={<IconCalendarTime style={iconStyle} />}
          >
           
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                gap: "10px",
              }}
            >
               {tn("Withdrawal requests history")}
             {numbers_main?.withdrawal_requests_history != undefined && <div style={{
                  padding: "3px 5px",
                borderRadius: "10000px",
                background: "#520f96",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#fff",
                fontSize: "10px",
                fontWeight: "bold",
              }}>{numbers_main?.withdrawal_requests_history}</div>}
            </div>
          </Tabs.Tab>
        
        </Tabs.List>
      </Tabs>
      {page == "new_withdrawal_requests" ? (
        <div
          style={{
            height: "calc(100% - 24px)",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
            <Helmet>
      <title>{ttn("new_withdrawal_requests") + " / " + tn("Coophub")}</title>
    </Helmet>
          <DataGrid name="new_withdrawal_requests" />
        </div>
      ) : (
        <></>
      )}
      {page == "withdrawal_requests_history" ? (
        <div
          style={{
            height: "calc(100% - 24px)",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
             <Helmet>
      <title>{ttn("withdrawal_requests_history") + " / " + tn("Coophub")}</title>
    </Helmet>
          <DataGrid name="withdrawal_requests_history" />
          {/* {tn("Scheduled interviews")} */}
        </div>
      ) : (
        <></>
      )}
     
    </div>
  );
};
