import {
  Paper,
  createStyles,
  TextInput,
  PasswordInput,
  Checkbox,
  Button,
  Title,
  Menu,
  Text,
  Anchor,
  Dialog,
  Modal,
  rem,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useForm } from "@mantine/form";
import { useContext, useEffect, useState } from "react";
import { useViewportSize } from "@mantine/hooks";
import { AppContext } from "../../../context/AppContext";
import { TranslationContext } from "../../../context/TranslationContext";
import { lt, ltn, tn } from "../../../translation";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../../constants";
import { UserContext } from "../../../context/UserContext";
import { IconEdit, IconRepeat, IconCheck } from "@tabler/icons-react";

import { useTimeout } from "@mantine/hooks";
import { TopShapeAuth } from "../components/TopShapeAuth";

function RegisterSuccess() {
  const pb: any = new PocketBase(CMS_ENDPOINT);

  const { userCurrentData, setUserCurrentData }: any = useContext(UserContext);
  const navigate = useNavigate();
  const [editModal, setEditModal] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { start, clear } = useTimeout(() => setDialogOpen(false), 3000);
  useEffect(start, [dialogOpen]);

  const [email, setEmail] = useState(userCurrentData?.email);
  useEffect(() => {
    if (!userCurrentData?.email) {
      navigate("/applicant/register");
    }
  }, []);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        height: "100vh",

      }}
    >
      <TopShapeAuth title={lt("applicant_registraion_success_title")} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "center",
          width: "100%",
          padding: "0px 20px",

        }}
      >
        <Text mt="xl" style={{ color: "#828282" }}>
          {userCurrentData?.email}
        </Text>

        <Text mt={45}>{lt("applicant_registraion_success_subtitle")}</Text>
        <div
          style={{
            display: "flex",
            marginTop: "30px",
            alignItems: "center",
          }}
        >
          <Text>{lt("applicant_registraion_success_massage")}</Text>
          <Menu shadow="md" width={200}>
            <Menu.Target>
              <Anchor
                onClick={() => {
                  // const senEmail = await  pb.collection('applicants').requestVerification(res.email);
                }}
                style={{
                  color: "#FFB004",
                }}
                mx="sm"
              >
                {tn("click here")}
              </Anchor>
            </Menu.Target>

            <Menu.Dropdown>
              {/* <Menu.Label>Application</Menu.Label> */}

              <Menu.Item
                icon={<IconEdit size={14} />}
                onClick={() => {
                  setEditModal(true);
                  setEmail(userCurrentData?.email);
                }}
              >
                {tn("Edit Email")}
              </Menu.Item>
              <Menu.Item
                icon={<IconRepeat size={14} />}
                onClick={async () => {
                  const senEmail = await pb
                    .collection("applicants")
                    .requestVerification(userCurrentData.email)
                    .then((res) => {
                      setDialogOpen(true);
                    });
                }}
              >
                {tn("Resend the activation message")}
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
          <Modal
            styles={() => ({
              content: {
                borderRadius: "10px",
                background:
                  "linear-gradient(153deg, rgba(34,193,195,1) 100%, rgba(33,3,63,1) 100%)",
                // display: "flex",
                // justifyContent: "center",
                // alignItems: "center",
              },
              header: { display: "none" },
              body: { padding: "9px" },
            })}
            opened={editModal}
            onClose={() => {
              setEditModal(false);
            }}
            centered
          >
            <div
              style={{
                background: "white",
                width: "100%",
                height: "100%",
                marginTop: "9px",
                borderRadius: "5px",
                paddingTop: "30px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TextInput
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                style={{ width: "90%" }}
                required
                // mt={90}
                variant="filled"
                styles={(theme) => ({
                  label: {
                    color: "#21033F",
                  },
                  input: {
                    borderRadius: "0px",
                    color: "#340662",
                    backgroundColor: "transparent",
                    borderBottom: "1px solid #21033F",
                    "&:focus-within": {
                      border: "0px solid #21033F",
                      borderBottom: "1px solid #21033F",
                    },
                  },
                })}
                label={tn("Email")}
                size="md"
                mx="md"
                data-autofocus
              />
              <div
                style={{
                  width: "90%",
                  display: "flex",
                  justifyContent: "start",
                }}
              >
                <Button
                  my="md"
                  style={{ backgroundColor: "#21033F" }}
                  onClick={async () => {
                    const senEmail = await pb
                      .collection("applicants")
                      .requestVerification(email)
                      .then((response) => {
                        setEditModal(false);
                        setDialogOpen(true);
                      });
                    setUserCurrentData({ ...userCurrentData, email: email });
                  }}
                >
                  {tn("Save")}
                </Button>
              </div>
            </div>
          </Modal>
          <Dialog
            opened={dialogOpen}
            withCloseButton
            onClose={() => setDialogOpen(false)}
            size="lg"
            radius="md"
            style={{ background: "#b4ffbc" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <IconCheck />
              <Text size="sm" mb="xs" weight={500} mx="md">
                {tn("Activation message sent")}
              </Text>
            </div>
          </Dialog>
        </div>
      </div>
      <div />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
          padding: "0px 20px",

          width: "100%",
          maxWidth: "500px",
          flexDirection: "row",
        }}
      >
        <Text
          style={{
            textAlign: "center",
          }}
          fz="12px"
          color="#767676"
          weight={700}
        >
          {tn("terms and conditions")}
        </Text>{" "}
        {divider}
        <Text
          style={{
            textAlign: "center",
          }}
          fz="12px"
          color="#767676"
          weight={700}
        >
          {tn("Usage policy")}
        </Text>{" "}
        {divider}
        <Text
          style={{
            textAlign: "center",
          }}
          fz="12px"
          color="#767676"
          weight={700}
        >
          {tn("All rights are save")}
        </Text>{" "}
      </div>
    </div>
  );
}
const divider = (
  <div
    style={{
      width: "1px",
      height: "15px",
      background: "#767676",
      margin: "0px 5px",
    }}
  />
);
export default RegisterSuccess;
