import { Avatar, Button, Text } from "@mantine/core";
import { tn } from "../../translation";
import { useViewportSize } from "@mantine/hooks";
import { useContext, useEffect, useState } from "react";
import { TranslationContext } from "../../context/TranslationContext";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../constants";
import { AppContext } from "../../context/AppContext";
import { MainDataProfile } from "./MainDataProfile";

const pb: any = new PocketBase(CMS_ENDPOINT);

export function ProfileView({ setEditing, setChangePassword }: any) {
  const { locale, translation }: any = useContext(TranslationContext);
  const { reloader, setReloader }: any = useContext(AppContext);

  const { height, width }: any = useViewportSize();
  const [userData, setUserData] = useState(pb.authStore.model);
  // useEffect(() => {
    // pb.collection(pb.authStore.model?.collectionName).subscribe(
    //   pb.authStore.model.id,
    //   (e) => {
    //     pb.authStore.save(pb.authStore.token, e.record);
    //     setUserData(e.record);
    //   }
    // );
  //   return () => {
  //     pb.collection(pb.authStore.model?.collectionName).unsubscribe()
  //   };
  // }, []);

  return width > 10 ? (
    <div
      style={{
        width: "100%",
        //   height: "100%",
        marginBottom: "20px",
        display: "flex",
        justifyContent: width < 1500 ? "start" : "center",
        alignItems: "center",
        flexDirection: width > 1500 ? "row" : "column",
      }}
    >
      <div
        style={{
          borderRadius: "10px",
          background: "#FFF",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.116)",
          padding: "10px",
          margin: "0px 20px",
          zIndex: 999,
          position: "absolute",
          top: width >= 500 ? "130px" : "170px",
          transform: width > 1500 ? "translateX(-460px)" : "translateX(0px)",
          left: width > 1500 ? "" : "0px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          mx="sm"
          // radius was 0 here
          style={{ background: "#69DCBF", height: "25px" }}
          onClick={() => {
            setEditing(true);
          }}
        >
          {tn("Edit data")}
        </Button>

        <Button
          mx="sm"
          // radius was 0 here
          style={{ background: "#F9837C", height: "25px" }}
          onClick={() => {
            setChangePassword(true);
          }}
        >
          {tn("Change password")}
        </Button>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: width > 600 ? "row" : "column",
          width: width > 1500 ? "280px" : "100%",
          height: width > 600 ? "320px" : "250px",
          maxWidth: "700px",
          margin: width > 1500 ? "0px 20px" : "0px 0px",
          marginBottom: width > 1500 ? "0px" : "10px",
          justifyContent: "space-between",
          // height: "100%",
        }}
      >
        <MainDataProfile />
      </div>
      <div
        style={{
          borderRadius: "10px",
          background: "#FFF",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.116)",
          padding: "30px 10px",
          // height: width > 600 ? "370px" : "270px",
          width: "100%",
          maxWidth: "700px",
          // margin: width > 1000 ? "0px 20px" : "20px 0px",
          display: "flex",
          justifyContent: "center",
          alignItems: "start",
          flexDirection: "column",
        }}
      >
        <Text fz="xl" fw="700" color="dimmed" pb={30}>
          {tn("Personal information")}
        </Text>
        <div
          style={{
            width: "100%",
            maxWidth: "700px",
            // margin: width > 1000 ? "0px 20px" : "20px 0px",
            display: "flex",
            justifyContent: "center",
            alignItems: "start",
            flexDirection: width > 650 ? "row" : "column",
          }}
        >
          <div
            style={{
              width: "100%",
              margin: "0px 10px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Text color="#676767" size="xs" fw="700">
                {tn("First Name")}
              </Text>
              <BoxStyleF text={userData?.first_name} />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                marginTop: "25px",
              }}
            >
              <Text color="#676767" size="xs" fw="700">
                {tn("Mobile")}
              </Text>
              <BoxStyleF text={userData?.mobile} />
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                marginTop: "25px",
              }}
            >
              <Text color="#676767" size="xs" fw="700">
                {tn("Gender")}
              </Text>
              <BoxStyleF text={userData?.gender?.[locale]} />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                marginTop: "25px",
              }}
            >
              <Text color="#676767" size="xs" fw="700">
                {tn("Premmissions")}
              </Text>
              <BoxStyleF
                text={locale == "en" ? "Administrator" : "مدير النظام"}
              />
            </div>
          </div>
          <div
            style={{
              width: "100%",
              margin: "0px 10px",
              marginTop: width > 650 ? "0px" : "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Text color="#676767" size="xs" fw="700">
                {tn("Last Name")}
              </Text>
              <BoxStyleF text={userData?.last_name} />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                marginTop: "25px",
              }}
            >
              <Text color="#676767" size="xs" fw="700">
                {tn("Email")}
              </Text>
              {/* <div style={boxStyle}>{userData?.email}</div> */}
              <BoxStyleF text={userData?.email} />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                marginTop: "25px",
              }}
            >
              <Text color="#676767" size="xs" fw="700">
                {tn("Extension")}
              </Text>
              <BoxStyleF text={userData?.extension} />
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}

const BoxStyleF: any = ({ text }: any) => {
  const boxStyle: any = {
    height: "1.875rem",
    boxSizing: "border-box",
    fontSize: text?.length > 15 ? "50%" : "14px",
    minWidth: "50%",
    maxWidth: "50%",
    color: "#727272",
    border: "0.0625rem solid #ced4da",
    backgroundColor: "#fff",
    paddingRight: "calc(2.25rem / 3)",
    paddingLeft: "calc(2.25rem / 3)",
    borderRadius: "0.25rem",
    display: "flex",
    alignItems: "center",
    // justifyContent: "end",
    overflow: "hidden",
    textOverflow: "ellipsis",
    // wordWrap: "break-word",
    // maxHeight: "3.6em",
    // lineHeight: "1.8em",
  };
  return <div style={boxStyle}>{text}</div>;
};
