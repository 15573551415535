import { useContext, useEffect, useState } from "react";
import { SystemContext } from "../../context/SystemContext";
import { TranslationContext } from "../../context/TranslationContext";
import {
  ActionIcon,
  Button,
  Checkbox,
  HoverCard,
  Loader,
  Menu,
  MultiSelect,
  Portal,
  Select,
  Text,
  TextInput,
} from "@mantine/core";
import * as XLSX from "xlsx";
import { DataTable } from "mantine-datatable";
import { tn } from "../../translation";
import { IconDots, IconSearch } from "@tabler/icons-react";
import alasql from "alasql";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../constants";
import { useDebouncedValue } from "@mantine/hooks";
import { useForm } from "@mantine/form";
import { motion } from "framer-motion";
import { Action } from "./components/ActionsMap";
import { Modals } from "./components/modals/Modals";
import { AppContext } from "../../context/AppContext";
import { DataContext } from "../../context/DataContext";
import { SelectableActions } from "./components/SelectableActionsMap";
import { ClickableActionsMap } from "./components/ClickableActionsMap";
import dayjs from "dayjs";

const pb: any = new PocketBase(CMS_ENDPOINT);

export function DataGrid({ name }: any) {
  const { systemData }: any = useContext(SystemContext);
  const { locale, translation }: any = useContext(TranslationContext);
  const { reloader }: any = useContext(AppContext);

  const { setAccountAdd }: any = useContext(DataContext);
  const [data, setData]: any = useState("loading");
  const [totalItems, setTotalItems]: any = useState(0);
  const [pagenation, setPagenation]: any = useState(1);
  const [loader]: any = useState(false);
  // const [selectedRecords, setSelectedRecords]: any = useState([]);
  const [mainChecked, setMainChecked]: any = useState(false);
  const [largeSetData, setLargeSetData]: any = useState([]);

  const table = systemData?.tables?.find((t: any) => t.name === name);
  if(!table) return 
  <></>;
  const actions = alasql(`select * from ? ORDER BY order_by`,[systemData?.actions])?.filter((a: any) =>
    table?.actions?.map((a: any) => a?.name)?.includes(a?.name)
  );
  const selectableActions = systemData?.actions?.filter((a: any) =>
    table?.selectable_actions?.map((a: any) => a?.name)?.includes(a?.name)
  );
  const columns = systemData?.columns?.filter(
    (c: any) => c.table_name === name
  );
  const filtered_columns = columns?.[0]
    ? alasql(`select * from ? where enable = true ORDER BY order_list`, [
        columns,
      ])
    : [];

  const expanded_columns = filtered_columns?.[0]
    ? alasql(
        `select * from ? where relation_table IS NOT NULL GROUP BY relation_table`,
        [filtered_columns ?? []]
      )
    : [];
  const [sortStatus, setSortStatus]: any = useState({
    columnAccessor: "",
    direction: "",
  });
  const [selectedRecords, setSelectedRecords]: any = useState([]);
  function generatePocketBaseFilter(json: any) {
    const filterParts: any = [];

    Object.entries(json).forEach(
      ([key, { type, value, table, relation_table }]: any) => {
        // Replace '—' with '.' to separate table and column
        const tableColumn = key.replace("--", ".");

        switch (type) {
          case "search":
            if (value) {
              filterParts.push(`${tableColumn} ~ '${value.trim()}'`); // PocketBase wraps the string with "%" automatically
            }
            break;
          case "multi_select":
          case "select":
            if (Array.isArray(value)) {
              if (value.length > 0) {
                // Create a series of OR conditions for English and Arabic values
                const orConditionsEn = value
                  .map((v) => `${tableColumn}.en = '${v.en}'`)
                  .join(" || ");
                const orConditionsAr = value
                  .map((v) => `${tableColumn}.ar = '${v.ar}'`)
                  .join(" || ");
                filterParts.push(`(${orConditionsEn}) || (${orConditionsAr})`);
              }
            } else {
              if (value) {
                filterParts.push(
                  `${tableColumn}.en = '${value.en}' || ${tableColumn}.ar = '${value.ar}'`
                );
              }
            }
            break;
          case "multi_select_data":
            if (value?.length > 0) {
              const orConditions = value
                .map(
                  (column_value: any) => `${tableColumn} = '${column_value}'`
                )
                .join(" || ");
              filterParts.push(`(${orConditions})`);

              // Assuming value is an array of IDs for 'multi_select_data'
            }

            break;
          // Add more cases as needed for other types
          default:
            // Handle any types that are not explicitly covered
            break;
        }
      }
    );

    return filterParts.join(" && "); // Use '&&' as per PocketBase documentation for AND
  }

  // Example usage:
  const jsonFilterObject = {
    // ... your JSON structure here ...
  };

  const filterQuery = generatePocketBaseFilter(jsonFilterObject);
  const form = useForm();

  function FetchData(isPagenation: any, onFetch: any) {
    const resultList = pb
      .collection(table?.view_table)
      .getList(isPagenation ? pagenation : 1, isPagenation ? 10 : 1000000, {
        filter:
          (table.filtered_columns && generatePocketBaseFilter(form?.values)
            ? "("
            : "") +
          generatePocketBaseFilter(form?.values) +
          (table.filtered_columns && generatePocketBaseFilter(form?.values)
            ? ") &&"
            : "") +
          (table.filtered_columns ? " " + table.filtered_columns : "") +
          (table.table == "applications" || table.table == "programs"
            ? pb.authStore.model?.collectionName == "recruiters"
              ? pb.authStore.model?.expand?.permission?.accessible_data?.en !=
                "All data"
                ? ` ${
                    table.filtered_columns ||
                    generatePocketBaseFilter(form?.values)
                      ? "&&"
                      : ""
                  } department = "${pb.authStore.model?.department}"`
                : ""
              : ""
            : ""),
        sort: sortStatus?.columnAccessor
          ? `${
              sortStatus?.direction == "desc" ? "-" : ""
            }${sortStatus?.columnAccessor?.replace("--", ".")}`
          : table?.sorted_columns
          ? table?.sorted_columns
          : `-created`,
        expand: expanded_columns?.map((item: any) => item?.relation_table),
      })
      .then(onFetch);

    return () => {};
  }
 
  const [debounced_form] = useDebouncedValue(form?.values, 200);

  useEffect(() => {
    FetchData(true, (records: any) => {
      setData(records?.items);
      setTotalItems(records?.totalItems);
      setLargeSetData(records?.items);
    });
  }, [debounced_form, pagenation, sortStatus, loader, reloader]);

  useEffect(() => {
    setSelectedRecords([]);
  }, [reloader]);
  useEffect(() => {
    setMainChecked(
      largeSetData?.length > 0
        ? largeSetData?.length == selectedRecords?.length
        : false
    );
  }, [selectedRecords, setLargeSetData]);

  const handleDownload = async () => {
    const columns = systemData?.excel_templates?.filter(
      (c: any) => c.table_name === name
    );

    try {
      const records = await pb.collection(table?.view_table).getFullList(
        table?.filtered_columns
          ? {
              sort: "created",
              filter: table?.filtered_columns,
            }
          : {
              sort: "created",
            }
      );

      // Map over the records to create the excelData array
      const excelData = records.map((item: any) => {
        // Create an object for each record where the key is the column's label_en
        // and the value is the item's value for the column name
        return columns.reduce((obj: any, column: any) => {
          if (column.translatable) {
            obj[column?.[`label_${locale}`]] = item[column.name]?.[locale];
            return obj;
          } else {
            obj[column?.[`label_${locale}`]] = item[column.name];
            return obj;
          }
        }, {});
      });

      // Proceed with creating the Excel file using the processed excelData
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(excelData);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "data.xlsx");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const currentAction = systemData.permissions?.filter((permission: any) =>
    pb.authStore.model?.expand?.permission?.permissions?.includes(
      permission?.id
    )
  );
  const current_table_actions = currentAction?.filter((item: any) =>
    item.table?.split("||")?.includes(table?.name)
  );
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        maxWidth: "1300px",
        height: "100%",
        display: "flex",
        alignItems: "start",
        justifyContent: "start",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <Text
        mx={50}
        mt={30}
        style={
          table?.title_styled
            ? {
                backgroundColor: "#f3ec78",
                backgroundImage:
                  "linear-gradient(113deg, rgba(105,220,191,1) 0%, rgba(222,140,134,1) 19%, rgba(33,3,63,1) 56%)",
                WebkitBackgroundClip: "text",
                MozBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                textAlign: "right",
                fontSize: "42px",
                fontStyle: "normal",
                fontWeight: "700",
                // lineHeight: "95%",
                height: "60px",
              }
            : {
                color: "#737373",
                textAlign: "center",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "normal",
              }
        }
      >
        {table?.[`title_${locale}`]}
      </Text>
      {table?.excel && (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            style={{
              backgroundColor: "#69DCBF",
              borderRadius: "5.112px",
              marginTop: "10px",
              marginLeft: "50px",
            }}
            onClick={handleDownload}
          >
            {tn("Export to Excel")}
          </Button>
        </div>
      )}

      <div
        // ref={ref}
        style={{
          width: "calc(100% )",
          // margin: `${!table?.action_bar ? "30px" : "0px"} 50px`,
          height: "calc(100%)",
          maxHeight: "calc(100% - 75px)",
        }}
      >
        {table?.action_bar && (
          <ControlBar
            data={data}
            form={form}
            table={table}
            setAccountAdd={setAccountAdd}
            selectableActions={selectableActions}
            selectedRecords={selectedRecords}
            current_table_actions={current_table_actions}
          />
        )}
        <Text
          mx={50}
          my={30}
          style={{
            color: "#2e2e2e",
            textAlign: "right",
            fontSize: "16px",
            fontWeight: "700",
          }}
        >
          {table?.["description_" + locale]}
        </Text>
        {data != "loading" ? (
          <div
            style={{
              position: "relative",
            }}
          >
            {" "}
            <DataTable
              totalRecords={totalItems}
              recordsPerPage={10}
              page={pagenation}
              onPageChange={(p) => {
                setPagenation(p);
              }}
              sortStatus={sortStatus}
              onSortStatusChange={setSortStatus}
              pinLastColumn
              minHeight={150}
              height={"calc(100vh - 350px)"}
              noRecordsText={table?.["no_data_massage_" + locale]}
              verticalSpacing="md"
              borderRadius="md"
              fontSize="sm"
              withBorder
              striped
              scrollAreaProps={{ type: "never" }}
              // highlightOnHover
              // provide data
              columns={[
                {
                  accessor: "checkbox",
                  textAlignment: "center",
                  title: table?.selectable ? (
                    <Checkbox
                      onChange={(e) => {
                        setSelectedRecords(
                          largeSetData?.length == selectedRecords?.length
                            ? []
                            : largeSetData?.map((item: any) => item?.id)
                        );
                        setMainChecked(
                          largeSetData?.length == selectedRecords?.length
                            ? false
                            : true
                        );
                      }}
                      checked={mainChecked}
                      styles={() => ({
                        label: { cursor: "pointer", color: "white" },
                        input: {
                          cursor: "pointer",
                          "&:checked": {
                            backgroundColor: table?.primary_color ?? "#58aaa2",
                            border: `0px solid ${
                              table?.primary_color ?? "#58aaa2"
                            }`,
                          },
                        },
                      })}
                    />
                  ) : (
                    ""
                  ),
                  filter: null,
                  sortable: false,
                },
                ...filtered_columns?.map((column: any, i: any) => ({
                  accessor: column?.relation_table
                    ? column?.relation_table + "--" + column.name
                    : column.name,
                  textAlignment: "center",
                  title: column?.[`title_${locale}`],
                  filter: column?.filter_type?.name ? (
                    <RenderFilter
                      column={column}
                      i={i}
                      locale={locale}
                      translation={translation}
                      form={form}
                    />
                  ) : null,
                  sortable: column?.sortable,
                })),
                {
                  accessor: "action",
                  textAlignment: "center",
                  title: "",
                  filter: null,
                  sortable: false,
                },
              ]}
              records={data?.map((item: any, i: any) => {
                let record: any = {};
                [
                  ...filtered_columns,
                  {
                    action: true,
                  },
                  {
                    idAccessor: true,
                  },
                  {
                    checkbox: true,
                  },
                ]?.forEach((column: any) => {
                  let value;
                  if (column.idAccessor) {
                    record["id"] = item?.id;
                  } else if (column.checkbox) {
                    record["checkbox"] = table?.selectable && (
                      <Checkbox
                        checked={selectedRecords?.includes(item?.id)}
                        onChange={(e) => {
                          setSelectedRecords(
                            selectedRecords?.includes(item?.id)
                              ? selectedRecords?.filter((id:any) => id != item?.id)
                              : [...selectedRecords, item?.id]
                          );
                        }}
                        styles={() => ({
                          label: { cursor: "pointer", color: "white" },
                          input: {
                            cursor: "pointer",
                            "&:checked": {
                              backgroundColor:
                                table?.primary_color ?? "#58aaa2",
                              border: `0px solid ${
                                table?.primary_color ?? "#58aaa2"
                              }`,
                            },
                          },
                        })}
                      />
                    );
                  } else if (column.action) {
                    record["action"] = (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        {table?.acions_type?.name == "menu" ? (
                          <Menu shadow="md" width={180} withArrow offset={1}>
                            <Menu.Target>
                              <motion.button
                                whileHover={{ scale: 1.1, opacity: "1" }}
                                style={{
                                  border: "none",
                                  cursor: "pointer",
                                  borderRadius: "100%",
                                  display: "flex",
                                  backgroundColor: "transparent",
                                  opacity: "0.5",
                                  width: "30px",
                                  minWidth: "30px",
                                  height: "30px",
                                  justifyContent: "end",
                                  alignItems: "center",
                                }}
                              >
                                <IconDots
                                  color="#6c6c6c"
                                  stroke={3}
                                  size={50}
                                  style={{
                                    minWidth: "20px",
                                  }}
                                />
                              </motion.button>
                            </Menu.Target>
                            <Portal>
                              <Menu.Dropdown style={{}}>
                                <Menu.Label>{tn("Actions")}</Menu.Label>
                                {actions?.map((action: any) => {
                                  const current_action =
                                    current_table_actions?.filter((item: any) =>
                                      item.action
                                        ?.split("||")
                                        ?.includes(action?.name)
                                    );
                                  if (
                                    !current_action?.[0] &&
                                    pb.authStore.model?.collectionName ==
                                      "recruiters" &&
                                    !pb.authStore.model?.expand?.company
                                      ?.is_partial &&
                                    systemData.permissions?.includes(
                                      action?.name
                                    )
                                  ) {
                                    return <></>;
                                  }
                                  return (
                                    <Action
                                      data={item}
                                      action={action}
                                      table={table}
                                    />
                                  );
                                })}
                              </Menu.Dropdown>
                            </Portal>
                          </Menu>
                        ) : table?.acions_type?.name == "items" ?(
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              minWidth: "fit-content",
                              height: "100%",
                              margin: "0px 3px",
                            }}
                          >
                            {actions?.map((action: any) => {
                              const current_action =
                                current_table_actions?.filter((item: any) =>
                                  item.action
                                    ?.split("||")
                                    ?.includes(action?.name)
                                );
                              if (
                                !current_action?.[0] &&
                                pb.authStore.model?.collectionName ==
                                  "recruiters" &&
                                !pb.authStore.model?.expand?.company?.is_partial
                              ) {
                                return <></>;
                              }
                              return (
                                <ActionIcon
                                  color={action?.second_color}
                                  variant="subtle"
                                  size={"sm"}
                                  mx="5px"
                                >
                                  <Action
                                    data={item}
                                    action={action}
                                    table={table}
                                  />
                                </ActionIcon>
                              );
                            })}
                          </div>
                        ):<div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          minWidth: "fit-content",
                          height: "100%",
                          margin: "0px 3px",
                        }}
                      >
                        {actions?.map((action: any) => {
                          const current_action =
                            current_table_actions?.filter((item: any) =>
                              item.action
                                ?.split("||")
                                ?.includes(action?.name)
                            );
                          if (
                            !current_action?.[0] &&
                            pb.authStore.model?.collectionName ==
                              "recruiters" &&
                            !pb.authStore.model?.expand?.company?.is_partial
                          ) {
                            return <></>;
                          }
                          return (
                           
                              <Action
                                data={item}
                                action={action}
                                table={table}
                              />
                          );
                        })}
                      </div>}
                      </div>
                    );
                  } else {
                    // Check if column has a relation table
                    if (column.relation_table) {
                      // Access the related value
                      const relatedValue =
                        item.expand?.[column.relation_table]?.[column.name];

                      // Check if the column is also translatable
                      if (column.translatable && relatedValue) {
                        // If translatable, use the locale to get the correct translation
                        value = relatedValue[locale] || relatedValue;
                      } else {
                        // If not translatable or relatedValue is undefined, use relatedValue as is
                        value = relatedValue;
                      }
                    } else if (column.translatable) {
                      // If translatable and not related to a relation table
                      value = item[column.name]?.[locale] || item[column.name];
                    } else {
                      // If not translatable and no relation table, use the value as is
                      value = item[column.name];
                    }

                    // Assign the value to the record
                    record[
                      column?.relation_table
                        ? column?.relation_table + "--" + column.name
                        : column.name
                    ] = (
                      <RenderCell
                      table={table}
                        item={value}
                        itemFull={
                          column?.relation_table
                            ? item.expand?.[column.relation_table]?.[
                                column.name
                              ]
                            : item[column.name]
                        }
                        data={item}
                        column={column}
                        i={i}
                        locale={locale}
                        // ClickableActionsMap={ClickableActionsMap}
                        translation={translation}
                        current_table_actions={current_table_actions}
                      />
                    );
                  }
                });
                return record;
              })}
            />
          </div>
        ) : (
          <div
            style={{
              width: "100%",
              height: "100px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader color={table?.primary_color ?? "#58aaa2"} />
          </div>
        )}
      </div>
     
    </div>
  );
}

function ControlBar({
  table,
  setAccountAdd,
  selectableActions,
  selectedRecords,
  current_table_actions,
}: any) {
  const { systemData }: any = useContext(SystemContext);
  const { locale, translation }: any = useContext(TranslationContext);

  const is_addable = current_table_actions?.filter(
    (item_table: any) => item_table.create == table?.name
  )?.[0]?.id;
  // function filterdData(itemName ,) {
  const Actions = ({ name }: any) => (
    <>
      {table?.selcable_action_section?.name == name &&
        selectableActions?.map((item:any) => {
          const current_action = current_table_actions?.filter((item__2: any) =>
            item__2.action?.split("||")?.includes(item?.name)
          );
          if (
            !current_action?.[0] &&
            pb.authStore.model?.collectionName == "recruiters" &&
            !pb.authStore.model?.expand?.company?.is_partial
          ) {
            return <></>;
          }
          return (
            <SelectableActions
              action={item}
              table={table}
              selectedRecords={selectedRecords}
            />
          );
        })}
      {((table?.selcable_action_section?.name == name &&
        table?.addable &&
        is_addable) ||
        pb.authStore.model?.expand?.company?.is_partial) && (
        <div
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minWidth: "140px",
            height: "100%",
            margin: "0px 3px",
          }}
          onClick={() => {
            setAccountAdd({
              id: "1",
              name: table?.name,
              table: table,
            });
          }}
        >
          <div
            style={{
              width: "30px",
              height: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            dangerouslySetInnerHTML={{ __html: table?.add_icon }}
          />
          <Text fz={13}>{table?.[`add_label_${locale}`]}</Text>
        </div>
      )}
    </>
  );

  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "30px 0px 0px 0px",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "40px",
            display: "flex",
            alignItems: "center",

            justifyContent: "start",
            borderRadius: "5px",
            border: "1px solid #E6E6E6",
            background: "#F9F9F9",
          }}
        ></div>
        <Actions name="center" />
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          // margin: "10px 0px 30px 0px",
          position: "absolute",
          top: "80px",
          right: "50px",
        }}
      >
        <Actions name="bottom" />
      </div>
    </div>
  );
}

function RenderCell({
  item,
  itemFull,
  data,
  column,
  i,
  locale,
  translation,
  current_table_actions,
  table
}: any) {
  const { systemData }: any = useContext(SystemContext);
  const clickActionHandler = ClickableActionsMap(column, data, table);
  const isClickable =
    !systemData.permissions?.filter((item_table: any) =>
      item_table.clickable_action
        ?.split("||")
        ?.includes(column?.click_action?.name)
    )?.[0]?.id ||
    current_table_actions?.filter((item_table: any) =>
      item_table.clickable_action
        ?.split("||")
        ?.includes(column?.click_action?.name)
    )?.[0]?.id ||
    pb.authStore.model?.collectionName != "recruiters" ||
    pb.authStore.model?.expand?.company?.is_partial;
  function onClick() {
    if (isClickable) {
      if ((column.onClickable, clickActionHandler)) {
        // Execute the handler directly
        clickActionHandler();
      }
    }
  }
  let bg_color =
    translation?.[column?.options]?.find(
      (option: any) => option?.name_en == itemFull?.en
    )?.bg_color ?? "";
  const commonStyle: any = {
    cursor: column.onClickable && isClickable && "pointer",
    display: "flex",
    justifyContent: "center",
    padding: "0px 0px",
    color: column?.color && isClickable ? column?.color : "#6c6c6c",
    textAlign: "center",
    fontSize: "12px",
    fontWeight: "700",
  };
  switch (column?.type?.name) {
    case "text":
      return (
        <div onClick={onClick} style={commonStyle}>
          {item ? item : column?.[`null_text_${locale}`]}
        </div>
      );

    case "defined_text":
      return (
        <div onClick={onClick} style={commonStyle}>
          {item
            ? column?.[`defined_text_${locale}`]
            : column?.[`null_text_${locale}`]}
        </div>
      );

    case "bg_color":
      return (
        <div
          onClick={onClick}
          style={{
            ...commonStyle,
            border: "2px solid " + bg_color,
            padding: "5px",
            borderRadius: "5px",
            color: bg_color ? bg_color : "",
            minWidth: "50px",
            fontWeight: "700",
          }}
        >
          {item ? item : column?.[`null_text_${locale}`]}
        </div>
      );
    case "bg_color_hover_upload":
      return (
        <HoverCard width={280} shadow="md">
          <HoverCard.Target>
            <div
              onClick={onClick}
              style={{
                ...commonStyle,
                border: "2px solid " + bg_color,
                padding: "5px",
                borderRadius: "5px",
                color: bg_color ? bg_color : "",
                minWidth: "50px",
                fontWeight: "700",
              }}
            >
              {item ? item : column?.[`null_text_${locale}`]}
            </div>
          </HoverCard.Target>
          {item?.document_status?.en == "anti-correction" && (
            <HoverCard.Dropdown>
              <Text size="sm">{item?.correction_note}</Text>
            </HoverCard.Dropdown>
          )}
        </HoverCard>
      );

    case "date":
      let currentDate = item && dayjs(item).format("DD/MM/YYYY");
      return (
        <div onClick={onClick} style={commonStyle}>
          {currentDate ? currentDate : column?.[`null_text_${locale}`]}
        </div>
      );

    case "remaining":
      const remaining = Math.floor(item);
      const finalRemaining =
        remaining > 0
          ? item
            ? remaining +
              " " +
              (locale == "en"
                ? remaining > 1
                  ? "days"
                  : "day"
                : remaining == 1
                ? "يوم"
                : remaining == 2
                ? "يومان"
                : remaining < 11
                ? "ايام"
                : "يوم")
            : column?.[`null_text_${locale}`]
          : locale == "en"
          ? "Completed"
          : "مكتمل";
      return (
        <div onClick={onClick} style={commonStyle}>
          {finalRemaining ? finalRemaining : column?.[`null_text_${locale}`]}
        </div>
      );
    default:
      return (
        <div onClick={onClick} style={commonStyle}>
          {item ? item : column?.[`null_text_${locale}`]}
        </div>
      );
  }
}

function RenderFilter({ column, i, locale, translation, form }: any) {
  const column_name = column?.relation_table
    ? column?.relation_table + "--" + column.name
    : column.name;
  const [optionsData, setOptionsData] = useState([]);
  useEffect(() => {
    if (column?.options_table) {
      const records = pb
        .collection(column?.options_table)
        .getFullList({
          sort: "-created",
        })
        ?.then((records: any) => {
          setOptionsData(records);
        });
    }
  }, []);
  switch (column?.filter_type?.name) {
    case "search":
      return (
        <TextInput
          label={column?.[`title_${locale}`]}
          icon={<IconSearch size={16} />}
          value={form?.values?.[column_name]?.value}
          size="sm"
          onChange={(e) => {
            form.setFieldValue(column_name, {
              type: "search",
              value: e.currentTarget.value,
            });
          }}
          style={{ width: "100%" }}
          placeholder={tn("Search")}
          //@ts-ignore
          styles={inputs_styles()}
        />
      );
    case "select":
      return (
        <Select
          label={column?.[`title_${locale}`]}
          value={form?.values?.[column_name]?.value?.en ?? ""}
          searchable
          clearable
          onChange={(e) => {
            if (e) {
              form.setFieldValue(column_name, {
                type: "select",
                value: {
                  en: translation?.[column?.options]?.find(
                    (option: any) => option?.name_en == e
                  )?.name_en,
                  ar: translation?.[column?.options]?.find(
                    (option: any) => option?.name_en == e
                  )?.name_ar,
                },
              });
            } else [form.setFieldValue(column_name, {})];
          }}
          style={{ minWidth: "140px" }}
          //@ts-ignore
          styles={inputs_styles()}
          data={
            translation?.[column?.options]?.[0]
              ? translation?.[column?.options]?.map((option: any) => ({
                  value: option?.name_en,
                  label: option?.[`name_${locale}`],
                }))
              : []
          }
        />
      );

    case "multi_select":
      return (
        //make it MultiSelect
        <MultiSelect
          label={column?.[`title_${locale}`]}
          value={form?.values?.[column_name]?.value?.map(
            (item: any) => item?.en
          )}
          searchable
          clearable
          onChange={(e) => {
            form.setFieldValue(column_name, {
              type: "multi_select",
              value: e?.map((item: any) => ({
                en: translation?.[column?.options]?.find(
                  (option: any) => option?.name_en == item
                )?.name_en,
                ar: translation?.[column?.options]?.find(
                  (option: any) => option?.name_en == item
                )?.name_ar,
              })),
            });
          }}
          style={{ minWidth: "140px" }}
          //@ts-ignore
          styles={inputs_styles()}
          data={
            translation?.[column?.options]?.[0]
              ? translation?.[column?.options]?.map((option: any) => ({
                  value: option?.name_en,
                  label: option?.[`name_${locale}`],
                }))
              : []
          }
        />
      );
    case "select_data":
      return (
        <Select
          label={column?.[`title_${locale}`]}
          value={form?.values?.[column_name]?.value}
          searchable
          clearable
          onChange={(e) => {
            form.setFieldValue(column_name, {
              type: "select_data",
              value: e,
              table: column?.options_table,
            });
          }}
          style={{ minWidth: "140px" }}
          //@ts-ignore
          styles={inputs_styles()}
          data={
            optionsData?.[0]
              ? optionsData?.map((option: any) => ({
                  value: option?.[column?.options_column],
                  label: option?.[column?.options_column],
                }))
              : []
          }
        />
      );
    case "multi_select_data":
      return (
        <MultiSelect
          label={column?.[`title_${locale}`]}
          value={form?.values?.[column_name]?.value}
          searchable
          clearable
          onChange={(e) => {
            form.setFieldValue(column_name, {
              type: "multi_select_data",
              value: e,
              table: column?.options_table,
              relation_table: column?.relation_table,
            });
          }}
          style={{ minWidth: "140px" }}
          //@ts-ignore
          styles={inputs_styles()}
          data={
            optionsData?.[0]
              ? optionsData?.map((option: any) => ({
                  value: option?.[column?.options_column],
                  label: option?.[column?.options_column],
                }))
              : []
          }
        />
      );
    default:
      <div></div>;
  }
}
const inputs_styles = () => ({
  label: {
    backgroundColor: "#fff",
    borderRadius: "5px",
    color: "#6e6e6e",
    padding: "0px 5px",
    fontSize: "12px",
    fontWeight: "700",
    zIndex: 100,
    transform: "translateY(10px)",
    margin: "0px 8px",
    position: "sticky",
  },
  input: {
    width: "100%",
    maxWidth: "300px",
  },
});
