import { useContext } from "react";
import { DataContext } from "../../../../../../context/DataContext";
import { ActionComponent } from "../../../ActionComponent";
import { pb } from "../../../../../../config";

export const ResendForApproval = (props: any) => {
  const { setResendForApproval }: any = useContext(DataContext);
  
  const { data, action , table } = props;
  return (
     <ActionComponent 
    data={data}
    action={action}
    table={table}
    setData={()=>setResendForApproval(data)}
    enabled={data?.status?.en == "rejected"}
    visible={pb.authStore.model.expand?.permission?.accessible_data?.en != "All data" && data?.status?.en == "rejected"}
    />
  );
};
