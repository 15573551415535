import { Helmet } from "react-helmet";
import { tn, ttn } from "../../translation";
import { Tabs } from "@mantine/core";
import { useNavigate, useParams } from "react-router-dom";
import { ProgramsTimeLine } from "../components/ProgramsTimeLine";
import { useEffect, useState } from "react";
import { pb } from "../../config";

function Overview() {
    const [departments, setDepartments] = useState([]);
    const [programs, setPrograms] = useState([]);
          useEffect(() => {
        const getDepartments = async () => {
            const departments = await pb.collection('departments_view').getFullList({
                sort: '-created',
            });
            const programs = await pb.collection('applicant_view_programs').getFullList({
                sort: '-created',
            });
            setDepartments(departments);
            setPrograms(programs);
        };
        getDepartments();
        
    }, []);
    const navigate = useNavigate();
  return (
    <div
      style={{
        height: "calc(100vh - 24px)",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Helmet>
        <title>{tn("Overview") + " / " + tn("Coophub")}</title>
      </Helmet>
      <ProgramsTimeLine departments={departments} programs={programs}/>
    </div>
  );
}

export default Overview;
