
import { useContext, useState } from "react";
import {
  createStyles,
  Header,
  Container,
  Anchor,
  Group,
  Burger,
  rem,
} from "@mantine/core";
import { useDisclosure, useViewportSize } from "@mantine/hooks";
import { MantineLogo } from "@mantine/ds";
import { CMS_ENDPOINT } from "../../constants";
import PocketBase, { BaseAuthStore } from "pocketbase";
import { UserContext } from "../../context/UserContext";
import { NotiPopup } from "../../main_components/notifications/NotiPopup";

const HEADER_HEIGHT = rem(84);

const useStyles = createStyles((theme) => ({
  inner: {
    height: HEADER_HEIGHT,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    maxWidth: 140000,
  },

  burger: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  links: {
    paddingTop: theme.spacing.lg,
    height: HEADER_HEIGHT,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",

    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  mainLinks: {
    marginRight: `calc(${theme.spacing.sm} * -1)`,
  },

  mainLink: {
    textTransform: "uppercase",
    fontSize: rem(13),
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[1]
        : theme.colors.gray[6],
    padding: `${rem(7)} ${theme.spacing.sm}`,
    fontWeight: 700,
    borderBottom: `${rem(2)} solid transparent`,
    transition: "border-color 100ms ease, color 100ms ease",

    "&:hover": {
      color: theme.colorScheme === "dark" ? theme.white : theme.black,
      textDecoration: "none",
    },
  },

  secondaryLink: {
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[2]
        : theme.colors.gray[6],
    fontSize: theme.fontSizes.xs,
    textTransform: "uppercase",
    transition: "color 100ms ease",

    "&:hover": {
      color: theme.colorScheme === "dark" ? theme.white : theme.black,
      textDecoration: "none",
    },
  },

  mainLinkActive: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    borderBottomColor:
      theme.colors[theme.primaryColor][theme.colorScheme === "dark" ? 5 : 6],
  },
}));

interface LinkProps {
  label: string;
  link: string;
}

export default function MainHeaderRecruirter() {
  const [opened, { toggle }] = useDisclosure(false);
  const { classes, cx } = useStyles();
  const [active, setActive] = useState(0);
  const { height, width } = useViewportSize();

  const pb: any = new PocketBase(CMS_ENDPOINT);
 const company = pb.authStore.model?.expand?.company;
 const logo = company?.logo ?`${CMS_ENDPOINT}/api/files/${company?.collectionName}/${company?.id}/${company?.logo}?token=` :"/dark_text_logo.svg";
  return (
    <Header
      style={{
        width: "100vw",
        zIndex: 1001,
        background: "#F7F6F5",
        boxShadow: "0px 2px 0px 0px #DCDCDC",
      }}
      height={HEADER_HEIGHT}
    >
      <Container className={classes.inner}>
       <div style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: width>=1180?"270px":"200px",
        height: "100%",
       }}> <img src={logo} style={{ height: "80%" , margin:`0px ${width>=1180?"0px":"20px"}` }} /></div>
        <div className={classes.links}></div>
        <div style={{
          margin: "0px 17px",
        }}>
        <NotiPopup width={400}/>
        </div>
      </Container>
    </Header>
  );
}