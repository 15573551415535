import { ltn, tn } from "../../../../../../translation";
import { Button, List, Text } from "@mantine/core";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../../../../../constants";
import { useDebouncedValue } from "@mantine/hooks";

export const CorrectionMessageDocuments = (props: any) => {
  const pb: any = new PocketBase(CMS_ENDPOINT);
  const { data, onClose } = props;
  const [debounced_data] = useDebouncedValue(data, 200);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "start",
        padding: "20px",
        width: "100%",
      }}
    >
      <Text style={{
        fontWeight:600,
        fontSize:"16px",
        color: "#5c5c5c",
      }} my="lg">{debounced_data?.record?.correction_note}</Text>

      <Button
        color="gray"
        variant="outline"
        // radius was 0 here
        compact
        style={{
          minWidth: "80px",
        }}
        mx={5}
        onClick={() => onClose({})}
      >
        {tn("Cancel")}
      </Button>
    </div>
  );
};
