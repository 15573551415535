import { useContext } from "react";
import { DataContext } from "../../../../../../context/DataContext";
import { ActionComponent } from "../../../ActionComponent";

export const EditAccount = (props: any) => {
  const { setRoleEdit }: any = useContext(DataContext);
  
  const { data, action , table } = props;
  return (
    <ActionComponent 
    data={data}
    action={action}
    table={table}
    setData={()=>setRoleEdit(
      data
    )}
    enabled={true}
    />
  );
};
