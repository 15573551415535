import { Button, Dialog, Menu, Tooltip } from "@mantine/core";
import { TranslationContext } from "../../../../../../../context/TranslationContext";
import { useContext } from "react";
import { DataContext } from "../../../../../../../context/DataContext";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../../../../../../constants";
import { BannersContext } from "../../../../../../../context/BannersContext";
import { ltn } from "../../../../../../../translation";
import { IconSquareRoundedX } from "@tabler/icons-react";
import { ActionComponent } from "../../../../ActionComponent";
export const AcceptWaitingInterview = (props: any) => {
  const { setAcceptWaitingInterview }: any = useContext(DataContext);
  const { setTopBanner }: any = useContext(BannersContext);

  const pb: any = new PocketBase(CMS_ENDPOINT);
  const dialogMassage = ltn("applicant_view_programs_full");
  const { data, action, table } = props;
  return (
    <ActionComponent
      data={data}
      action={action}
      table={table}
      setData={async () => {
        const record = await pb
          .collection("applicant_view_programs")
          .getOne(data?.program, {})
          ?.then((res: any) => {
            if (parseInt(res?.candidate_in_need) > res?.applicants_count) {
              setAcceptWaitingInterview({
                id: data.id,
                record: data,
                action: action,
                table: table,
              });
            } else {
              setTopBanner({
                message: dialogMassage,
                color: "#c02d2d",
                top: "83px",
                backgroundColor: "#fff2f2eb",
                icon: <IconSquareRoundedX />,
              });
            }
          });
      }}
      enabled={true}
    />
  );
};
