
import { ltn, tn } from "../../../../../../translation";
import {Button, Text} from "@mantine/core"
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../../../../../constants";
import { useContext } from "react";
import { AppContext } from "../../../../../../context/AppContext";
import { useDebouncedValue } from "@mantine/hooks";

export const  RejectProgramReason = (props:any) => {
  const pb: any = new PocketBase(CMS_ENDPOINT);
  const { data , onClose} = props;
  const {reloader, setReloader} = useContext(AppContext)
  const [debounced_data] = useDebouncedValue(data, 200);
     return <div style={{
        display:"flex",
        flexDirection:"column",
        alignItems:"start",
        justifyContent:"start",
        padding:"20px",
        width:"100%",

     }}>
      <Text fw={600} color="#5c5c5c">
      {debounced_data?.reject_reason}
      </Text>
      <div style={{
        display:"flex",
        flexDirection:"row",
        alignItems:"center",
        justifyContent:"space-between",
        marginTop:"20px"

      }}>
       
        <Button variant="outline" color="gray" mx={5} onClick={() => onClose({})}>
          {tn("close")}
        </Button>
      </div>
       
     </div>
}