import { Button, Dialog, Menu, Tooltip } from "@mantine/core";
import { TranslationContext } from "../../../../../../context/TranslationContext";
import { useContext } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { DataContext } from "../../../../../../context/DataContext";
import { motion } from "framer-motion";
import { ActionComponent } from "../../../ActionComponent";

export const CorrectionMessageDocuments = (props: any) => {
  const { locale }: any = useContext(TranslationContext);
  const { setCorrectionMessageDocuments }: any = useContext(DataContext);
  
  const { data, action , table } = props;
  return (
    <ActionComponent
    is_mobile={true}
    data={data}
    action={action}
    table={table}
    setData={async () => {
      setCorrectionMessageDocuments({
        id: data.id,
        record: data,
        action: action,
        table: table,
      });
      
    }}
    enabled={data?.correction_note}
  />
   
    
  );
};
