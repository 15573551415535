import { EditApplicantAction } from "./actions/other/EditApplicantAction";
import { DeleteAccount } from "./actions/other/DeleteAccount";
import { EditAccount } from "./actions/recruiters/recruiters_manage/EditRole";
import { Activator } from "./actions/other/Activator";
import { ActivateAccounts } from "./actions/other/ActivateAccounts";
import { DeleteCompany } from "./actions/other/DeleteCompany";
import { ActivateCompany } from "./actions/other/ActivateCompany";
import { EditCompany } from "./actions/other/EditCompany";
import { DeleteDepartment } from "./actions/recruiters/departments_manage/DeleteDepartment";
import { SoftDeleteAccount } from "./actions/recruiters/recruiters_manage/SoftDeleteAccount";
import { EditDepartment } from "./actions/recruiters/departments_manage/EditDepartment";
import { CancelProgram } from "./actions/recruiters/published_programs/CancelProgram";
import { EditProgram } from "./actions/recruiters/published_programs/EditProgram";
import { ApplyForProgram } from "./actions/applicants/programs_search/ApplyForProgram";
import { PublishProgram } from "./actions/recruiters/published_programs/PublishProgram";
import { AcceptNewApplicant } from "./actions/recruiters/new/AcceptNewApplicant";
import { RejectNewApplicant } from "./actions/recruiters/new/RejectNewApplicant";
import { AcceptWaitingInterview } from "./actions/recruiters/interview/waiting_for_scheduling/AcceptWaitingInterview";
import { ExcludeInterview } from "./actions/recruiters/interview/ExcludeInterview";
import { SchedulingInterview } from "./actions/recruiters/interview/waiting_for_scheduling/SchedulingInterview";
import { AcceptScheduledInterview } from "./actions/recruiters/interview/scheduled_interview/AcceptScheduledInterview";
import { CancelInterview } from "./actions/recruiters/interview/scheduled_interview/CancelInterview";
import { Reschedule } from "./actions/recruiters/interview/scheduled_interview/Reschedule";
import { DidNotAttend } from "./actions/recruiters/interview/scheduled_interview/DidNotAttend";
import { AcceptAppointment } from "./actions/applicants/personal_interviews/AcceptAppointment";
import { AcceptLetter } from "./actions/recruiters/documents/AcceptLetter";
import { CorrectionRequest } from "./actions/recruiters/documents/CorrectionRequest";
import { ExcludeDocument } from "./actions/recruiters/documents/ExcludeDocument";
import { UploadDocuments } from "./actions/applicants/upload_documents/UploadDocuments";
import { TerminateApplicant } from "./actions/recruiters/active_programs/TerminateApplicant";
import { SchedulePlan } from "./actions/recruiters/active_programs/SchedulePlan";
import { CancelProgramApplicant } from "./actions/recruiters/active_programs/CancelProgramApplicant";
import { UploadingProgramCompletionLetter } from "./actions/recruiters/completed_programs/UploadingProgramCompletionLetter";
import { TerminationProgramWithoutDocument } from "./actions/recruiters/completed_programs/TerminationProgramWithoutDocument";
import { ProgramEvaluation } from "./actions/applicants/my_programs_history/ProgramEvaluation";
import { CompletionLetterDownload } from "./actions/applicants/my_programs_history/CompletionLetterDownload";
import { WithdrawalRequest } from "./actions/applicants/withdrawal_request/WithdrawalRequest";
import { EditPermission } from "./actions/recruiters/permissions/EditPermission";
import { DeletePermission } from "./actions/recruiters/permissions/DeletePermission";
import { AcceptWithdraw } from "./actions/recruiters/withdrawal_requests/AcceptWithdraw";
import { RejectWithdraw } from "./actions/recruiters/withdrawal_requests/RejectWithdraw";
import { AdoptProgram } from "./actions/recruiters/published_programs/AdoptProgram";
import { RejectProgram } from "./actions/recruiters/published_programs/RejectProgram";
import { CancelPartialProgram } from "./actions/partial/CancelPartialProgram";
import { HangPartialProgram } from "./actions/partial/HangPartialProgram";
import { PublishPartialProgram } from "./actions/partial/PublishPartialProgram";
import { ResendForApproval } from "./actions/recruiters/published_programs/ResendForApproval";
import { ShareLink } from "./actions/recruiters/published_programs/ShareLink";
import { ApproveContract } from "./actions/recruiters/documents/contract/issuing/ApproveContract";
import { CorrectionMessageIssuing } from "./actions/recruiters/documents/contract/issuing/CorrectionMessageIssuing";
import { ExcludeContract } from "./actions/recruiters/documents/contract/ExcludeContract";
import { DownloadTheDraftContract } from "./actions/applicants/contract/DownloadTheDraftContract";
import { FillContractData } from "./actions/applicants/contract/FillContractData";
import { CorrectionMessageApplicant } from "./actions/applicants/contract/CorrectionMessageApplicant";
import { ApproveContractApproval } from "./actions/recruiters/documents/contract/approval/ApproveContractApproval";
import { CorrectionMessageDocuments } from "./actions/applicants/upload_documents/CorrectionMessageDocuments";
import { InviteApplicant } from "./actions/recruiters/search_applicants/InviteApplicant";
import { CancelInvitation } from "./actions/recruiters/search_applicants/CancelInvitation";
import { RejectInvitation } from "./actions/applicants/invitation/RejectInvitation";
import { AcceptInvitation } from "./actions/applicants/invitation/AcceptInvitation";
import { RepublishProgram } from "./actions/recruiters/published_programs/RepublishProgram";

export const Action = (props: any) => {
  const { data, action } = props;
  switch (action?.name) {
    case "edit_data_applicant":
      return <EditApplicantAction {...props} />;
    case "delete_account":
      return <DeleteAccount {...props} />;
    case "soft_delete_account":
      return <SoftDeleteAccount {...props} />;
    case "edit_role":
      return <EditAccount {...props} />;
    case "activator":
      return <Activator {...props} />;
    case "activate_account":
      return <ActivateAccounts {...props} />;
    case "activate_company":
      return <ActivateCompany {...props} />;
    case "delete_company":
      return <DeleteCompany {...props} />;
    case "edit_company":
      return <EditCompany {...props} />;
    case "delete_department":
      return <DeleteDepartment {...props} />;
    case "edit_department":
      return <EditDepartment {...props} />;
    case "cancel_program":
      return <CancelProgram {...props} />;
    case "edit_program":
      return <EditProgram {...props} />;
    case "apply_for_program":
      return <ApplyForProgram {...props} />;
    case "publish_program":
      return <PublishProgram {...props} />;
    case "accept_new_applicant":
      return <AcceptNewApplicant {...props} />;
    case "reject_new_applicant":
      return <RejectNewApplicant {...props} />;
    case "accept_waiting_interview":
      return <AcceptWaitingInterview {...props} />;
    case "exclude_interview":
      return <ExcludeInterview {...props} />;
    case "scheduling_interview":
      return <SchedulingInterview {...props} />;
    case "accept_scheduled_interview":
      return <AcceptScheduledInterview {...props} />;
    case "cancel_interview":
      return <CancelInterview {...props} />;
    case "reschedule":
      return <Reschedule {...props} />;
    case "did_not_attend":
      return <DidNotAttend {...props} />;
    case "accept_appointment":
      return <AcceptAppointment {...props} />;
    case "exclude_document":
      return <ExcludeDocument {...props} />;
    case "correction_request":
      return <CorrectionRequest {...props} />;
    case "accept_letter":
      return <AcceptLetter {...props} />;
    case "upload_documents":
      return <UploadDocuments {...props} />;
    case "terminate_applicant":
      return <TerminateApplicant {...props} />;

    case "cancel_program_applicant":
      return <CancelProgramApplicant {...props} />;
    case "schedule_plan":
      return <SchedulePlan {...props} />;
    case "termination_program_without_document":
      return <TerminationProgramWithoutDocument {...props} />;
    case "uploading_program_completion_letter":
      return <UploadingProgramCompletionLetter {...props} />;
    case "program_evaluation":
      return <ProgramEvaluation {...props} />;
    case "completion_letter_download":
      return <CompletionLetterDownload {...props} />;
    case "withdrawal_request":
      return <WithdrawalRequest {...props} />;
    case "edit_permission":
      return <EditPermission {...props} />;
    case "delete_permission":
      return <DeletePermission {...props} />;
    case "accept_withdraw":
      return <AcceptWithdraw {...props} />;
    case "reject_withdraw":
      return <RejectWithdraw {...props} />;
    case "adopt_program":
      return <AdoptProgram {...props} />;
    case "reject_program":
      return <RejectProgram {...props} />;
    case "cancel_partial_program":
      return <CancelPartialProgram {...props} />;
    case "hang_partial_program":
      return <HangPartialProgram {...props} />;
    case "publish_partial_program":
      return <PublishPartialProgram {...props} />;
    case "resend_for_approval":
      return <ResendForApproval {...props} />;
    case "share_link":
      return <ShareLink {...props} />;
    case "approve_contract":
      return <ApproveContract {...props} />;
    case "exclude_contract":
      return <ExcludeContract {...props} />;
    case "correction_message_issuing":
      return <CorrectionMessageIssuing {...props} />;
    case "download_the_draft_contract":
      return <DownloadTheDraftContract {...props} />;
    case "fill_data":
      return <FillContractData {...props} />;
    case "correction_message_applicant":
      return <CorrectionMessageApplicant {...props} />;
    case "approve_contract_approval":
      return <ApproveContractApproval {...props} />;
    case "correction_message_documents":
      return <CorrectionMessageDocuments {...props} />;
    case "invite_applicant":
      return <InviteApplicant {...props} />;
    case "cancel_invitation":
      return <CancelInvitation {...props} />;
    case "reject_invitation":
      return <RejectInvitation {...props} />;
    case "accept_invitation":
      return <AcceptInvitation {...props} />;
    case "republish_program":
      return <RepublishProgram {...props} />;

    default:
      return <div {...props} />;
  }
};
