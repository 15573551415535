import {
  Avatar,
  Button,
  Center,
  NumberInput,
  SegmentedControl,
  Select,
  Text,
  TextInput,
  Textarea,
  rem,
} from "@mantine/core";
import { tn } from "../../translation";
import { useViewportSize } from "@mantine/hooks";
import { MIME_TYPES } from "@mantine/dropzone";
import { IconCheck, IconPhoto, IconSquareRoundedCheck, IconUpload } from "@tabler/icons-react";
import { useContext, useState, useEffect } from "react";
import { TranslationContext } from "../../context/TranslationContext";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../constants";
import { useForm } from "@mantine/form";
import { BannersContext } from "../../context/BannersContext";
import { AppContext } from "../../context/AppContext";
import { MainDataProfile } from "./MainDataProfile";

const pb: any = new PocketBase(CMS_ENDPOINT);

export function ProfileEdit({ setEditing, setWarnning }: any) {
  const [userData, setUserData] = useState(pb.authStore.model);
//   useEffect(() => {
//     pb.collection(pb.authStore.model?.collectionName).subscribe(pb.authStore.model.id, (e) => {
//       pb.authStore.save(pb.authStore.token, e.record);
//       setUserData(e.record);
// });
// return () => {
//   pb.collection(pb.authStore.model?.collectionName).unsubscribe()
// };
//   }, [])

  const form = useForm({
    initialValues: {
      first_name: userData?.first_name,
      last_name: userData?.last_name,
      gender: userData?.gender?.en,
      mobile: userData?.mobile,
      extension: userData?.extension,
    },
    validate: {
      first_name: (value) =>
        value?.length < 2
          ? "First name must be at least 2 characters long"
          : null,
      last_name: (value) =>
        value?.length < 2
          ? "Last name must be at least 2 characters long"
          : null,
      mobile: (value) =>
        value?.length < 10
          ? value?.length != 0?  "Mobile must be at least 10 characters long":null
          : null,
    },
  });

  const { locale, translation }: any = useContext(TranslationContext);
 const {setTopBanner}:any = useContext(BannersContext)
 const {reloader,setReloader} = useContext(AppContext)

  const { height, width }: any = useViewportSize();
  const dialogMassage = tn("Data has been updated");

  return width > 10 ? (
    <form
      onSubmit={form.onSubmit((value) => {
        const data_ = {
          last_name: value?.last_name,
          first_name: value?.first_name,
          gender:
            value?.gender == "male"
              ? { en: "male", ar: "ذكر" }
              : { en: "female", ar: "انثى" },
          mobile: value?.mobile,
          extension: value?.extension,
        };
       
        const record = pb
          .collection("admin")
          .update(userData?.id, data_)
          .then((records: any) => {
            // setBannerOpen(true);
            setTopBanner({
              message: dialogMassage,
              color: "#2DC071",
              top: "83px",
              backgroundColor: "#F2FFF7EB",
              icon: <IconSquareRoundedCheck/>,
            })
            setEditing(false);
            setReloader(!reloader)
            setUserData(pb?.authStore?.model);
          });
      })}
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: width < 1500 ? "start" : "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div
      style={{
        width: "100%",
        //   height: "100%",
        marginBottom: "20px",
        display: "flex",
        justifyContent: width < 1500 ? "start" : "center",
        alignItems: "center",
        flexDirection: width > 1500 ? "row" : "column",
      }}
    >
      <div
        style={{
          borderRadius: "10px",
          background: "#FFF",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.116)",
          padding: "10px",
          margin: "0px 20px",
          zIndex: 999,
          position: "absolute",
          top: width >= 500 ? "130px" : "170px",
          transform: width > 1500 ? "translateX(-460px)" : "translateX(0px)",
          left: width > 1500 ? "" : "0px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
         <Button
            disabled={!form.isDirty()}
            type="submit"
            mx="sm"
            // radius was 0 here
            style={
              form.isDirty()
                ? { background: "#69DCBF", height: "25px" }
                : { height: "25px" }
            }
          >
            {tn("Save")}
          </Button>

          <Button
            mx="sm"
            // radius was 0 here
            style={{ background: "#F9837C", height: "25px" }}
            onClick={() => {
              if (form.isDirty()) {
                setWarnning(true);
              } else {
                setEditing(false);
              }
            }}
          >
            {tn("Cancel")}
          </Button>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: width > 600 ? "row" : "column",
          width: width > 1500 ? "280px" : "100%",
          height: width > 600 ? "320px" : "250px",
          maxWidth: "700px",
          margin: width > 1500 ? "0px 20px" : "0px 0px",
          marginBottom: width > 1500 ? "0px" : "10px",
          justifyContent: "space-between",
          // height: "100%",
        }}
      >
        <MainDataProfile />
      </div>
      <div
        style={{
          borderRadius: "10px",
          background: "#FFF",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.116)",
          padding: "30px 10px",
          // height: width > 600 ? "370px" : "270px",
          width: "100%",
          maxWidth: "700px",
          // margin: width > 1000 ? "0px 20px" : "20px 0px",
          display: "flex",
          justifyContent: "center",
          alignItems: "start",
          flexDirection: "column",
        }}
      >
        <Text fz="xl" fw="700" color="dimmed" pb={30}>
          {tn("Personal information")}
        </Text>
        <div
          style={{
            width: "100%",
            maxWidth: "700px",
            // margin: width > 1000 ? "0px 20px" : "20px 0px",
            display: "flex",
            justifyContent: "center",
            alignItems: "start",
            flexDirection: width > 650 ? "row" : "column",
          }}
        >
          <div
            style={{
              width: "100%",
              margin: "0px 10px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Text color="#676767" size="xs" fw="700">
                {tn("First Name")}
              </Text>
              <TextInput
                  size="xs"
                  
                  {...form.getInputProps("first_name")}
                  style={{
                    width: "60%",
                  }}
                />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                marginTop: "25px",
              }}
            >
              <Text color="#676767" size="xs" fw="700">
                {tn("Mobile")}
              </Text>
              <TextInput
                  size="xs"
                  
                  {...form.getInputProps("mobile")}
                  style={{
                    width: "60%",
                  }}
                />
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                marginTop: "25px",
              }}
            >
              <Text color="#676767" size="xs" fw="700">
                {tn("Gender")}
              </Text>
                <SegmentedControl
                  size="xs"
                  {...form.getInputProps("gender")}
                  //   style={{height:"30px"}}
                  styles={() => ({
                    root: {
                      width: "60%",
                      color: "#737373",
                      backgroundColor: "transparent",
                      border: "1px solid #69dcbfdc",
                    },
                    label: {
                      color: "#737373",
                      height: "25px",
                      borderRadius: "2px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "0px 10px",
                      "&:hover": {
                        color: "#737373",
                      },
                      '&[data-active="true"]': {
                        color: "#737373",
                      },
                      "&[data-active]:hover": {
                        color: "#737373",
                      },
                    },
                    indicator: {
                      color: "#737373",
                      backgroundColor: "#69DCBF !important",
                      borderRadius: "2px",
                    },
                    control: {
                      width: "100%",
                      color: "#737373",
                      //    backgroundColor: "#efefef5f !important",
                      borderRadius: "2px",
                    },
                    controlActive: { color: "#737373" },
                  })}
                  data={[
                    { label: tn("Male"), value: "male" },
                    { label: tn("Female"), value: "female" },
                  ]}
                />
              </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                marginTop: "25px",
              }}
            >
              <Text color="#676767" size="xs" fw="700">
                {tn("Premmissions")}
              </Text>
              <BoxStyleF
                text={locale == "en" ? "Administrator" : "مدير النظام"}
              />
            </div>
          </div>
          <div
            style={{
              width: "100%",
              margin: "0px 10px",
              marginTop: width > 650 ? "0px" : "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Text color="#676767" size="xs" fw="700">
                {tn("Last Name")}
              </Text>
              <TextInput
                  size="xs"
                  
                  {...form.getInputProps("last_name")}
                  style={{
                    width: "60%",
                  }}
                />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                marginTop: "25px",
              }}
            >
              <Text color="#676767" size="xs" fw="700">
                {tn("Email")}
              </Text>
              {/* <div style={boxStyle}>{userData?.email}</div> */}
              <BoxStyleF text={userData?.email} />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                marginTop: "25px",
              }}
            >
              <Text color="#676767" size="xs" fw="700">
                {tn("Extension")}
              </Text>
              <TextInput
                  size="xs"
                  
                  {...form.getInputProps("extension")}
                  style={{
                    width: "60%",
                  }}
                />
            </div>
          </div>
        </div>
      </div>
    </div>
    </form>
  ) : (
    <></>
  );
}

const BoxStyleF: any = ({ text }: any) => {
  const boxStyle: any = {
    height: "1.875rem",
    boxSizing: "border-box",
    fontSize: text?.length > 15 ? "60%" : "14px",
    minWidth: "60%",
    maxWidth: "60%",
    color: "#727272",
    border: "0.0625rem solid #ced4da",
    backgroundColor: "#fff",
    paddingRight: "calc(2.25rem / 3)",
    paddingLeft: "calc(2.25rem / 3)",
    borderRadius: "0.25rem",
    display: "flex",
    alignItems: "center",
    // justifyContent: "end",
    overflow: "hidden",
    textOverflow: "ellipsis",
    // wordWrap: "break-word",
    // maxHeight: "3.6em",
    // lineHeight: "1.8em",
  };
  return <div style={boxStyle}>{text}</div>;
};
// {/* <div
//         style={{
//           width: "100%",
//           //   height: "100%",
//           marginBottom: "20px",
//           display: "flex",
//           justifyContent: width < 1500 ? "start" : "center",
//           alignItems: "center",
//           flexDirection: width > 1500 ? "row" : "column",
//         }}
//       >
//         <div
//           style={{
//             borderRadius: "10px",
//             background: "#FFF",
//             boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.116)",
//             padding: "10px",
//             margin:  "0px 10px",
//             zIndex: 999,
//             position: "absolute",
//             top: width >= 500 ?"130px":"170px",
//            transform: width > 1500 ?"translateX(-510px)":"translateX(0px)",
//            left: width > 1500 ?"":"0px",
//            display: "flex",
//            justifyContent: "center",
//            alignItems: "center",

//           }}
//         >
//           <Button
//             disabled={!form.isDirty()}
//             type="submit"
//             mx="sm"
//             // radius was 0 here
//             style={
//               form.isDirty()
//                 ? { background: "#69DCBF", height: "25px" }
//                 : { height: "25px" }
//             }
//           >
//             {tn("Save")}
//           </Button>

//           <Button
//             mx="sm"
//             // radius was 0 here
//             style={{ background: "#F9837C", height: "25px" }}
//             onClick={() => {
//               if (form.isDirty()) {
//                 setWarnning(true);
//               } else {
//                 setEditing(false);
//               }
//             }}
//           >
//             {tn("Cancel")}
//           </Button>
//         </div>
//         <div
//           style={{
//             display: "flex",
//             flexDirection: width > 600 ? "row" : "column",
//             width: width > 1500 ? "540px" : "100%",
//             maxWidth: "700px",
//             marginBottom: width > 1500 ? "0px" : "10px",
//             justifyContent: "space-between",
//             // height: "100%",
//           }}
//         >
//          <MainDataProfile/>
         
//         </div>
//         <div
//           style={{
//             borderRadius: "10px",
//             background: "#FFF",
//             boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.116)",
//             padding: "30px 10px",
//             width: "100%",
//             maxWidth: "700px",
//             // margin: width > 1000 ? "0px 20px" : "20px 0px",
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             flexDirection: "column",
//           }}
//         >
//           <Text fz="xl" fw="700" color="dimmed" pb={30}>
//             {tn("Personal information")}
//           </Text>
//           <div
//             style={{
//               width: "100%",
//               maxWidth: "700px",
//               // margin: width > 1000 ? "0px 20px" : "20px 0px",
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               flexDirection: width > 650 ? "row" : "column",
//             }}
//           >
//             <div
//               style={{
//                 width: "100%",
//                 margin: "0px 10px",
//               }}
//             >
//               <div
//                 style={{
//                   display: "flex",
//                   justifyContent: "space-between",
//                   alignItems: "center",
//                   width: "100%",
//                 }}
//               >
//                 <Text color="#676767" size="xs" fw="700">
//                   {tn("First Name")}
//                 </Text>
//                 <TextInput
//                   size="xs"
                  
//                   {...form.getInputProps("first_name")}
//                   style={{
//                     width: "60%",
//                   }}
//                 />
//               </div>
//               <div
//                 style={{
//                   display: "flex",
//                   justifyContent: "space-between",
//                   alignItems: "center",
//                   width: "100%",
//                   marginTop: "25px",
//                 }}
//               >
//                 <Text color="#676767" size="xs" fw="700">
//                   {tn("Mobile")}
//                 </Text>
//                 <TextInput
//                   size="xs"
                  
//                   {...form.getInputProps("mobile")}
//                   style={{
//                     width: "60%",
//                   }}
//                 />
//               </div>

//               <div
//                 style={{
//                   display: "flex",
//                   justifyContent: "space-between",
//                   alignItems: "center",
//                   width: "100%",
//                   marginTop: "25px",
//                 }}
//               >
//                 <Text color="#676767" size="xs" fw="700">
//                   {tn("Gender")}
//                 </Text>
//                 <SegmentedControl
//                   size="xs"
//                   {...form.getInputProps("gender")}
//                   //   style={{height:"30px"}}
//                   styles={() => ({
//                     root: {
//                       width: "60%",
//                       color: "#737373",
//                       backgroundColor: "transparent",
//                       border: "1px solid #69dcbfdc",
//                     },
//                     label: {
//                       color: "#737373",
//                       height: "25px",
//                       borderRadius: "2px",
//                       display: "flex",
//                       alignItems: "center",
//                       justifyContent: "center",
//                       padding: "0px 10px",
//                       "&:hover": {
//                         color: "#737373",
//                       },
//                       '&[data-active="true"]': {
//                         color: "#737373",
//                       },
//                       "&[data-active]:hover": {
//                         color: "#737373",
//                       },
//                     },
//                     indicator: {
//                       color: "#737373",
//                       backgroundColor: "#69DCBF !important",
//                       borderRadius: "2px",
//                     },
//                     control: {
//                       width: "100%",
//                       color: "#737373",
//                       //    backgroundColor: "#efefef5f !important",
//                       borderRadius: "2px",
//                     },
//                     controlActive: { color: "#737373" },
//                   })}
//                   data={[
//                     { label: tn("Male"), value: "male" },
//                     { label: tn("Female"), value: "female" },
//                   ]}
//                 />
//               </div>
            
//               <div
//                 style={{
//                   display: "flex",
//                   justifyContent: "space-between",
//                   alignItems: "center",
//                   width: "100%",
//                   marginTop: "25px",
//                 }}
//               >
//                 <Text color="#676767" size="xs" fw="700">
//                   {tn("GPA")}
//                 </Text>

               
//                 </div>
//               </div>
//             </div>
//             <div
//               style={{
//                 width: "100%",
//                 margin: "0px 10px",
//                 marginTop: width > 650 ? "0px" : "20px",
//               }}
//             >
//               <div
//                 style={{
//                   display: "flex",
//                   justifyContent: "space-between",
//                   alignItems: "center",
//                   width: "100%",
//                 }}
//               >
//                 <Text color="#676767" size="xs" fw="700">
//                   {tn("Last Name")}
//                 </Text>
//                 <TextInput
//                   size="xs"
                  
//                   {...form.getInputProps("last_name")}
//                   style={{
//                     width: "60%",
//                   }}
//                 />
//               </div>
//               <div
//                 style={{
//                   display: "flex",
//                   justifyContent: "space-between",
//                   alignItems: "center",
//                   width: "100%",
//                   marginTop: "25px",
//                 }}
//               >
//                 <Text color="#676767" size="xs" fw="700">
//                   {tn("Email")}
//                 </Text>
//                 {/* <div style={boxStyle}>{data?.email}</div> */}
        //         <BoxStyleF text={userData?.email} />
        //       </div>
             
              
        //       <div
        //         style={{
        //           display: "flex",
        //           justifyContent: "space-between",
        //           alignItems: "center",
        //           width: "100%",
        //           marginTop: "25px",
        //         }}
        //       >
        //         <Text color="#676767" size="xs" fw="700">
        //           {tn("CV")}
        //         </Text>
               
        //         {userData?.cv ? (
        //         <a
        //           href={`${CMS_ENDPOINT}/api/files/${userData?.collectionName}/${userData?.id}/${userData?.cv}?token=`}
        //           download
        //           target="_blank"
        //           rel="noopener noreferrer"
        //         >
        //           <Button
        //             onClick={async() => {
        //               // downloadPDF(`${CMS_ENDPOINT}/api/files/${data?.collectionName}/${data?.id}/${data?.cv}?token=`,data?.cv)

        //             }}
        //             style={{
        //               background: "#69DCBF",
        //               borderRadius: "0px",
        //               height: "30px",
        //             }}
        //           >
        //             {tn("Download")}
        //           </Button>
        //          </a>
        //       ) : (
        //         <Text color="#676767" size="xs" fw="700">
        //           {tn("No Data")}
        //         </Text>
        //       )}
        //       </div>{" "}
            
        //     </div>
        //   </div>
        // </div> 