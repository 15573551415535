import { useState } from "react";
import { CMS_ENDPOINT } from "../../../constants";
import { ltn, tn } from "../../../translation";
import { Button, Modal, Text } from "@mantine/core";
import PocketBase from "pocketbase";
import { useViewportSize } from "@mantine/hooks";
import { ProfileView } from "../components/ProfileView";
import { ProfileEdit } from "../components/ProfileEdit";
import { ChangePassword } from "../../../main_components/ChangePassword";
import { Helmet } from "react-helmet";
 function Profile() {
  const pb: any = new PocketBase(CMS_ENDPOINT);
  const { height, width }: any = useViewportSize();
  const [editing, setEditing] = useState(false);
  const [warnning, setWarnning]: any = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  return width > 10 ? (
    <>
    <Helmet>
<title>{tn("Profile") + " / " + tn("Coophub")}</title>
</Helmet>
      {" "}
      <div
        style={{
          position: "relative",
          width: width >= 1180 ? "calc(100vw - 300px)" : "100vw",
          height: "calc(100vh - 84px)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "start",
          padding: "20px",
          paddingTop: "30px",
          background: "#F6F5F7",
          overflowY: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            padding: "10px 30px",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            maxWidth: "1180px",
          }}
        >
          <Text
            style={{
              backgroundColor: "#f3ec78",
              backgroundImage:
                "linear-gradient(113deg, rgba(105,220,191,1) 0%, rgba(222,140,134,1) 19%, rgba(33,3,63,1) 56%)",
              WebkitBackgroundClip: "text",
              MozBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              fontSize:  width >= 580 ?"72px":"48px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "95%",
              height: "175px",
              width:  width >= 580 ?"300px":"200px",
            }}
          >
            {tn("Profile")}
          </Text>
        </div>
        <div
          style={{
            height: "100%",
            display: "flex",

            justifyContent: "center",
            width: "100%",
          }}
        >
          {editing ? (
            <ProfileEdit
              setWarnning={setWarnning}
              setEditing={setEditing}
            />
          ) : (
            <ProfileView
              setEditing={setEditing}
              setChangePassword={setChangePassword}
            />
          )}
        </div>
        <Modal
          style={{ zIndex: 9999999 }}
          overlayProps={{
            zIndex: 9999999,
            opacity: 0.55,
            blur: 3,
          }}
          styles={() => ({
            content: {
              zIndex: 10001,
              borderRadius: "10px",
              background:
                "linear-gradient(153deg, #22c0c372 100%, #21033f7a 100%)",
              // display: "flex",
              // justifyContent: "center",
              // alignItems: "center",
            },
            header: { display: "none" },
            body: { padding: "9px" },
            inner: { zIndex: 9999999 },
          })}
          opened={warnning}
          onClose={() => {
            setWarnning(false);
          }}
          centered
        >
          <div
            style={{
              background: "white",
              width: "100%",
              height: "100%",
              marginTop: "9px",
              borderRadius: "5px",
              paddingTop: "30px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "90%",
                display: "flex",
                justifyContent: "start",
              }}
            >
              <Text fw="600" color="grey">
                {ltn("discard_massage")}
              </Text>
            </div>
            <div
              style={{
                width: "90%",
                display: "flex",
                justifyContent: "start",
              }}
            >
              <Button
                my="md"
                style={{ backgroundColor: "#ff5e5e" }}
                onClick={() => {

                  setWarnning(false);
                  setEditing(false);
                }}
              >
                {tn("Yes")}
              </Button>
              <Button
                my="md"
                mx="md"
                variant="outline"
                color="gray"
                onClick={() => {
                  setWarnning(false);
                }}
              >
                {tn("Cancel")}
              </Button>{" "}
            </div>
          </div>
        </Modal>
        {/* <motion.div
          animate={{
            opacity: bannerOpen ? 1 : [1, 1, 0, 0],
            display: bannerOpen ? "flex" : "none",
          }}
          style={{
            border: "1px solid #76D8A3",
            background: "#F2FFF7",
            width: "100vw",
            height: "50px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            position: "absolute",
            zIndex: 99999,
            top: "84px",
            boxShadow: "0px 13px 19px 0px rgba(0, 0, 0, 0.07)",
            marginRight: locale == "en" ? "" : "-300px",
            marginLeft: locale == "ar" ? "" : "-300px",
          }}
        >
          <div style={{ display: "flex" }}>
            <IconCircleCheck color="#2DC071" style={{ margin: "0px 20px" }} />
            <Text fw="500" color="green">
              تم تحديث البيانات بنجاح
            </Text>
          </div>
          <motion.div
            style={{
              cursor: "pointer",
              width: "30px",
              height: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0px 10px",
            }}
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.9 }}
            onClick={() => setBannerOpen(false)}
          >
            {" "}
            <IconSquareRoundedX color="#2DC071" style={{}} />
          </motion.div>
        </motion.div> */}
      </div>
      <Modal
        size={500}
        style={{ zIndex: 9999999 }}
        overlayProps={{
         zIndex: 9999999,
          opacity: 0.55,
          blur: 3,
        }}
        styles={() => ({
          content: {
            
            borderRadius: "20px",
            background:
              "linear-gradient(153deg, #22c0c372 100%, #21033f7a 100%)",
            // display: "flex",
            // justifyContent: "center",
            // alignItems: "center",
          },
          header: { display: "none" },
          body: { padding: "9px" },
          inner: { zIndex: 9999999 },
        })}
        opened={changePassword}
        onClose={() => {
          setChangePassword(false);
        }}
        centered
      >
        <div
          style={{
            background: "white",
            width: "100%",
            height: "100%",
            marginTop: "9px",
            borderRadius: "15px",
            paddingTop: "30px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <ChangePassword
          table="applicants"
            onClose={setChangePassword}
            // setBanner={setBannerOpen}
          />
        </div>
      </Modal>
    </>
  ) : (
    <></>
  );
}

export default Profile;