import { useContext } from "react";
import { DataContext } from "../../../../../../context/DataContext";
import { ActionComponent } from "../../../ActionComponent";

export const RepublishProgram = (props: any) => {
  const { setRepublishProgram }: any = useContext(DataContext);

  const { data, action, table } = props;
  const when_be_able =
    data?.status?.en == "canceled" ||
    data?.status?.en == "rejected" ||
    data?.status?.en == "completed";
  return (
    <ActionComponent 
    data={data}
    action={action}
    table={table}
    setData={()=>setRepublishProgram(
      data
    )}
    enabled={when_be_able}
    visible={when_be_able}
    />
  );
};
