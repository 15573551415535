
// import { DataGrid } from "../../main_components/data-grid/DataGrid"

// export const DocumentReview = () => {
//     return (
//         <div
//         style={{
//           width: "100%",
//           height: "calc(100vh - 84px)",
//           display: "flex",
//           alignItems: "start",
//           justifyContent: "center",
//           padding: "20px",
//           paddingTop: "30px",
//           background: "#fcfcfc",
//         }}
//       >
//             <DataGrid name="document_review"/>
//         </div>
//     )
// }
import { Tabs, rem } from "@mantine/core";
import { DataGrid } from "../../main_components/data-grid/DataGrid";
import {
  IconCalendarTime,
  IconClock,
  IconClockCancel,
  IconFileCheck,
  IconMessageCircle,
  IconPhoto,
  IconScript,
  IconScriptPlus,
  IconSettings,
} from "@tabler/icons-react";
import { tn, ttn } from "../../translation";
import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../constants";
import { AppContext } from "../../context/AppContext";
import { Helmet } from "react-helmet";

export const DocumentReview = () => {
  const iconStyle = { width: rem(16), height: rem(16) };
  const { page = "document_review" } = useParams();
  const navigate = useNavigate();
  
  const { reloader }: any = useContext(AppContext);
  const pb: any = new PocketBase(CMS_ENDPOINT);
  // const [numbers, setNumbers]: any = useState({});
  // const [numbersDepartment, setNumbersDepartment]: any = useState({});

  // useEffect(() => {
  //   const record = pb
  //     .collection("recruiters_numbers")
  //     .getFirstListItem("", {})
  //     ?.then((e: any) => {
  //       setNumbers(e);
  //     });
  //     const record_ = pb
  //     .collection("recruiters_department_number")
  //     .getFirstListItem("", {})
  //     ?.then((e: any) => {
  //       setNumbersDepartment(e);
  //     });
  // }, [reloader]);
  // const numbers_main = pb.authStore.model.expand?.permission?.accessible_data?.en == "All data"  ?numbers :numbersDepartment
  return ( 
    <div
      style={{
        width: "100%",
        height: "calc(100vh - 84px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        flexDirection: "column",
        padding: "20px",
        paddingTop: "30px",
        background: "#fcfcfc",
      }}
    >
      {" "}
      <Tabs
        style={{
          width: "100%",
        }}
        defaultValue="document_review"
        value={page}
        onTabChange={(e) => {
          navigate(`/recruiter/document_review/${e}`);
        }}
      >
        <Tabs.List>
          <Tabs.Tab
            style={{}}
            value="document_review"
            icon={<IconFileCheck style={iconStyle} />}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                gap: "10px",
              }}
            >
              {tn("Document review")}
            
            </div>
          </Tabs.Tab>
          <Tabs.Tab
            value="issuing_the_contract"
            icon={<IconScriptPlus style={iconStyle} />}
          >
           
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                gap: "10px",
              }}
            >
                {tn("Issuing the contract")}
            
            </div>
          </Tabs.Tab>
         
          <Tabs.Tab
            value="approval_of_the_contract"
            icon={<IconScript style={iconStyle} />}
          >
           
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                gap: "10px",
              }}
            >
               {tn("Approval of the contract")}
            
            </div>
          </Tabs.Tab>
        
        </Tabs.List>
      </Tabs>
      {page == "document_review" ? (
        <div
          style={{
            height: "calc(100% - 24px)",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
        <Helmet>
      <title>{ttn("document_review") + " / " + tn("Coophub")}</title>
    </Helmet>
          <DataGrid name="document_review" />
        </div>
      ) : (
        <></>
      )}
      {page == "approval_of_the_contract" ? (
        <div
          style={{
            height: "calc(100% - 24px)",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
        <Helmet>
      <title>{ttn("approval_contract") + " / " + tn("Coophub")}</title>
    </Helmet>
          <DataGrid name="approval_contract" />
          {/* {tn("Scheduled interviews")} */}
        </div>
      ) : (
        <></>
      )}
      {page == "issuing_the_contract" ? (
        <div
          style={{
            height: "calc(100% - 24px)",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
         <Helmet>
      <title>{ttn("contract_issuing") + " / " + tn("Coophub")}</title>
    </Helmet>
          <DataGrid name="contract_issuing" />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
