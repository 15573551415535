import { isNotEmpty, useForm } from "@mantine/form";
import { tn, vtn } from "../../../../../../translation";
import dayjs from "dayjs";
import _ from "lodash";
import { Button } from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import { TranslationContext } from "../../../../../../context/TranslationContext";
import { CMS_ENDPOINT } from "../../../../../../constants";
import PocketBase from "pocketbase";
import { AppContext } from "../../../../../../context/AppContext";
import { ProgramForm } from "./program_form/ProgramForm";
const pb = new PocketBase(CMS_ENDPOINT);
export const AddProgram = ({ data, onClose }: any) => {
  const { translation }: any = useContext(TranslationContext);
  const { reloader, setReloader }: any = useContext(AppContext);
  const [company, setCompany]: any = useState({});
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(false);
  const program_type = translation?.program_type;
  const city = translation?.city;
  const major = translation?.major;
  const degree = translation?.degree;
  const case_study = translation?.case_study;
  const period = translation?.period;
  const university = translation?.university;
  const fill_all_fields = vtn("fill_all_fields");
  const program_nature = translation?.program_nature;
  const address_type = translation?.address_type;
  const interview_type = translation?.interview_type;
  const required_field = vtn("required_field");
  const skills = translation?.skills;

  const address_validation = (value: any, values: any) => {
    if (values?.address_type === "New address") {
      return value ? null : required_field;
    } else {
      return null;
    }
  };

  const accessible_data =
    pb.authStore?.model?.expand?.permission?.accessible_data?.en;
  useEffect(() => {
    const resultList = pb
      .collection("departments_view")
      .getList(1, 150, {})
      .then((res: any) => {
        setDepartments(res?.items);
      });
  }, []);

  useEffect(() => {
    const resultList = pb
      .collection("companies_view")
      .getFirstListItem("")
      .then((res: any) => {
        setCompany(res);
      });
  }, []);
  const form: any = useForm({
    initialValues: {
      department:
        accessible_data == "All data" ? "" : pb.authStore?.model?.department,
      type: "",
      nature: "Field",
      name: "",
      brief: "",
      objective: "",
      period: "",
      degree: [],
      targeted_majors: [],
      target_educational_segment: "",
      candidate_in_need: "",
      is_incentive: true,
      incentive: 0,
      last_submission_date: "",
      start_date: "",
      address_type: "New address",
      city: "",
      location: "",
      building_number: "",
      floor: "",
      office_no: "",
      has_interview: false,
      interview_type: "In-person interview",
      personal_interview_link: "",
      has_filtration_test: false,
      other_requirements: [],
      required_skills: [],
      additional_instructions: "",
      attachment_1: null,
      attachment_2: null,
      universities: [],
      academic_average_4: 3,
      academic_average_5: 3,
      status: {},
      allUniversities: true,
      filtration_test: "",
    },

    validate: {
      personal_interview_link: (value, values) => {
        if (values?.has_interview) {
          if (values?.interview_type == "remote") {
            return value ? null : required_field;
          } else {
            return null;
          }
        } else {
          return null;
        }
      },

      department: isNotEmpty(required_field),
      type: isNotEmpty(required_field),
      name: isNotEmpty(required_field),
      brief: isNotEmpty(required_field),
      objective: isNotEmpty(required_field),
      targeted_majors: isNotEmpty(required_field),
      target_educational_segment: isNotEmpty(required_field),
      period: isNotEmpty(required_field),
      candidate_in_need: (value) => {
        const current_department: any = departments?.find(
          (item: any) => item?.id == form?.values?.department
        );
        if (value) {
          if (current_department?.[`current_acceptance_ceiling`] < value) {
            return `لا يمكن ان يكون عدد المتدربين اكثر من ${
              current_department?.[`current_acceptance_ceiling`]
            }`;
          }
        } else {
          return required_field;
        }
      },
      city: address_validation,
      location: address_validation,
      building_number: address_validation,
      floor: address_validation,
      office_no: address_validation,
      last_submission_date: isNotEmpty(required_field),
      start_date: isNotEmpty(required_field),
      degree: isNotEmpty(required_field),
      universities: (value, values) => {
        if (!values?.allUniversities) {
          return !value?.[0] ? required_field : null;
        }
      },
      other_requirements: (value: any) => {
        if (value?.[0]) {
          if (value?.filter((item: any) => item?.name == "")?.[0]) {
            return fill_all_fields;
          } else {
            return null;
          }
        } else {
          return null;
        }
      },
    },
  });
  console.log("formformformform", form);

  const findLocalizedNames = (
    list: any,
    value: any,
    field: any = "name_en"
  ) => {
    const listItem = list.find((item: any) => item[field] === value);
    return listItem?.value
      ? {
          en: listItem?.name_en,
          ar: listItem?.name_ar,
          value: listItem?.value ?? null,
        }
      : {
          en: listItem?.name_en,
          ar: listItem?.name_ar,
        };
  };
  return (
    <form
      style={{
        width: "100%",
      }}
      onSubmit={form.onSubmit(async (values: any) => {
        setLoading(true);

        const is_new_address = values?.address_type == "New address";
        const is_for_approval = values?.status?.en == "waiting for approval";

        const data = {
          department: values?.department,
          name: values?.name,
          brief: values?.brief,
          objective: values?.objective,
          candidate_in_need: values?.candidate_in_need,
          incentive: values?.is_incentive
            ? values?.incentive?.toString()
            : null,
          type: findLocalizedNames(program_type, values.type),
          period: findLocalizedNames(period, values.period),
          universities: values.allUniversities
            ? [
                {
                  en: "all universities",
                  ar: "جميع الجامعات",
                },
              ]
            : values.universities.map((item: any) =>
                findLocalizedNames(university, item)
              ),
          target_educational_segment: values.target_educational_segment.map(
            (item: any) => findLocalizedNames(case_study, item)
          ),
          major: values.targeted_majors.map((item: any) =>
            findLocalizedNames(major, item)
          ),
          degree: values.degree.map((item: any) =>
            findLocalizedNames(degree, item)
          ),
          last_submission_date: dayjs(values.last_submission_date)
            .hour(23)
            .minute(59)
            .toDate(),
          start_date: values?.start_date,
          end_date: dayjs(values.start_date)
            .add(
              parseInt(findLocalizedNames(period, values.period).value),
              "month"
            )
            .hour(23)
            .minute(59)
            .toDate(),
          other_requirements: values?.other_requirements,
          GPA_4: values?.academic_average_4,
          GPA_5: values?.academic_average_5,
          status: values?.status,
          publish_date: values?.status?.en == "new" ? new Date() : null,
          company: pb.authStore?.model?.company,
          code: "C-1",
          nature: findLocalizedNames(program_nature, values.nature),
          address_type: findLocalizedNames(address_type, values.address_type),

          building_number: is_new_address
            ? values.building_number
            : company?.location_data?.building_number,
          floor: is_new_address ? values.floor : company?.location_data?.floor,
          office_no: is_new_address
            ? values.office_no
            : company?.location_data?.office_no,
          city: is_new_address
            ? findLocalizedNames(city, values.city)
            : company?.location_data?.city,
          location: is_new_address
            ? values?.location
            : company?.location_data?.location,
          has_interview: values?.has_interview,
          interview_type: findLocalizedNames(
            interview_type,
            values.interview_type
          ),
          personal_interview_link: values?.personal_interview_link,
          required_skills:  values.required_skills.map((item: any) =>
          findLocalizedNames(skills, item)
        ),
          
          additional_instructions: values?.additional_instructions,
          filtration_test: values?.filtration_test,
          has_filtration_test: (values?.has_filtration_test && !!values?.filtration_test),
          request_date: is_for_approval ? new Date() : null,
        };
        console.log("datadddwef", data);
        const formData = new FormData(); // Use the form data from the event
        if (values?.attachment_1) {
          formData.append("attachment_1", values?.attachment_1);
        }

        if (values?.attachment_2) {
          formData.append("attachment_2", values?.attachment_2);
        }

        // Fetching program codes...
        const programCodes = await pb
          .collection("program_codes")
          .getList(1, 5, {
            sort: "-code_number",
            filter: `code_type = "${data.type?.en.charAt(0)}"`,
          });
        // Fetching program codes... for request
        const requestsCodes = await pb
          .collection("program_request_codes")
          .getList(1, 5, {
            sort: "-code_number",
          });

        // Finding the maximum code number...
        const maxCodeNumber =
          _.maxBy(programCodes?.items, "code_number")?.code_number || 0;

        const maxCodeNumberRequest =
          _.maxBy(requestsCodes?.items, "code_number")?.code_number || 0;

        // Constructing the program code...
        const programCode = programCodes?.items?.[0]
          ? `${programCodes?.items[0]?.code_type}-${maxCodeNumber + 1}`
          : `${data.type?.en.charAt(0)}-1`;

        // Creating the program with the generated code...
        const result = await pb
          .collection("programs")
          .create({
            ...data,
            code: programCode,
            request_code:is_for_approval?
               `RI-${maxCodeNumberRequest + 1}`
                : null,
          });
        const result_update = await pb
          .collection("programs")
          .update(result?.id, formData);

        // Handling the result...
        if (result?.id) {
          setReloader(!reloader);
          onClose({});
        }
      })}
    >
      <ProgramForm
        form={form}
        data={data}
        title={tn("Add a new program")}
        company={company}
        buttons={
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "end",
              padding: "20px 0px",
            }}
          >
            {accessible_data == "All data" ? (
              <>
                <Button
                  loading={loading}
                  type="submit"
                  mx="xs"
                  compact
                  style={{
                    backgroundColor: "#69DCBF",
                    color: "white",
                    minWidth: "100px",
                    borderRadius: "5.112px",
                  }}
                  onClick={() => {
                    form.setFieldValue("status", {
                      en: "new",
                      ar: "جديد",
                    });
                  }}
                >
                  {tn("Create and publish")}
                </Button>
                <Button
                  loading={loading}
                  type="submit"
                  // mx="xs"
                  compact
                  style={{
                    backgroundColor: "#69DCBF",
                    color: "white",
                    width: "100px",
                    borderRadius: "5.112px",
                  }}
                  onClick={() => {
                    form.setFieldValue("status", {
                      en: "hanging",
                      ar: "معلّق",
                    });
                  }}
                >
                  {tn("Save")}
                </Button>
              </>
            ) : (
              <Button
                type="submit"
                // mx="xs"
                compact
                style={{
                  backgroundColor: "#69DCBF",
                  color: "white",
                  width: "100px",
                  borderRadius: "5.112px",
                }}
                onClick={() => {
                  form.setFieldValue("status", {
                    en: "waiting for approval",
                    ar: "بإنتظار الموافقة",
                  });
                }}
              >
                {tn("Submit for approval")}
              </Button>
            )}

            <Button
              compact
              mx="sm"
              style={{
                color: "white",
                width: "100px",
                borderRadius: "5.112px",
                backgroundColor: "#F9837C",
              }}
              color="red"
              onClick={() => {
                onClose({});
              }}
            >
              {tn("Cancel")}
            </Button>
          </div>
        }
      />
    </form>
  );
};
