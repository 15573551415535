import { Button, Modal, Text } from "@mantine/core";
import { tn } from "../../../translation";
import { useState } from "react";

export function MobileCard({
  main_text,
  primary_texts,
  secondary_texts,
  actions,
}: any) {
  const [detailsModal, setDetailsModal] = useState(false);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "20px",
        backgroundColor: "#FAF9F9",
        borderRadius: "10px",
        boxShadow: "1px 3px 15px 0px #00000015",
        margin: "0px 10px",
        marginBottom: "20px",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <Text
          fz="md"
          style={{
            color: "#595959",
            fontWeight: 400,
          }}
        >
          {main_text}
        </Text>
        <Button
          color="color3"
          radius={10}
          size="sm"
          variant="outline"
          style={{
            fontSize: "12px",
            fontWeight: 700,
            border: "3px solid #FFC107",
          }}
          onClick={() => setDetailsModal(true)}
        >
          {tn("details")}
        </Button>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          marginBottom: "10px",
        }}
      >
        {primary_texts?.[0] && primary_texts?.map((item: any, index: number) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <Text
                key={index}
                fz="sm"
                style={{
                  color: "#21033F",
                  fontWeight: 700,
                }}
              >
                {item?.name}
              </Text>
              <Text
                key={index}
                fz="sm"
                style={{
                  color: "#595959",
                  fontWeight: 400,
                }}
              >
                {item?.value}
              </Text>
            </div>
          );
        })}
      </div>
      {/* now actions */}
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          gap: "10px",
          overflowX: "scroll",
        }}
      >
        {actions?.[0] && actions?.map((item: any, index: number) => {
          return (
            item
          );
        })}
      </div>
      <Modal
        opened={detailsModal}
        onClose={() => setDetailsModal(false)}
        size="md"
        padding="20px"
        style={{ zIndex: 10001, position: "relative" }}
        overlayProps={{
          zIndex: 10001,
          opacity: 0.55,
          blur: 3,
        }}
        styles={() => ({
          content: {
            zIndex: 10001,
            borderRadius: "10px",
            background: "linear-gradient(153deg, #69DCBF 100%, #21033f7a 100%)",
            // display: "flex",
            // justifyContent: "center",
            // alignItems: "center",
          },
          header: { display: "none" },
          body: { padding: "9px" },
          inner: { zIndex: 10001 },
        })}
        centered
      >
        <div
          style={{
            background: "white",
            width: "100%",
            height: "100%",
            marginTop: "9px",
            borderRadius: "5px",
            // paddingTop: "30px",
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "center",
            gap: "10px",
            padding: "20px",
          }}
        >
          <Text
            fz="md"
            style={{
              color: "#595959",
              fontWeight: 700,
              background:
                "var(--sc, linear-gradient(90deg, #21033F 0%, #69DCBF 100%))",
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            {tn("details")}
          </Text>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-around",
              gap: "10px",
              width: "100%",
            }}
          >
            {secondary_texts?.[0] && secondary_texts?.map((item: any, index: number) => {
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "10px",
                    width: "calc(50% - 20px)",
                    paddingBottom: "5px",
                    borderBottom: "1px solid #636363",
                  }}
                >
                  <Text
                    fz="sm"
                    style={{
                      color: "#21033F",
                      fontWeight: 700,
                    }}
                  >
                    {item?.name}
                  </Text>
                  <Text
                    fz="sm"
                    style={{
                      color: "#595959",
                      fontWeight: 400,
                    }}
                  >
                    {item?.value}
                  </Text>
                </div>
              );
            })}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Button
              color="gray"
              variant="outline"
              style={{
                fontWeight: 700,
                fontSize: "12px",
                borderRadius: "10px",
                padding: "10px",
              }}
              onClick={() => setDetailsModal(false)}
            >
              {tn("close")}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
