
import { Paper,
    createStyles,
    TextInput,
    PasswordInput,
    Checkbox,
    Button,
    Title,
    Text,
    Anchor,
    rem, } from "@mantine/core";
    import { useNavigate } from "react-router-dom";
    import { isEmail, useForm } from "@mantine/form";
  import { useContext, useState } from "react";
  import { useViewportSize } from "@mantine/hooks";
  import { AppContext } from "../../context/AppContext";
  import { TranslationContext } from "../../../../context/TranslationContext";
  import { ltn, tn } from "../../../../translation";
  import PocketBase from "pocketbase";
  import { CMS_ENDPOINT } from "../../../../constants";
  
  const useStyles = createStyles((theme) => ({
    wrapper: {
      height: "100vh",
      display: "flex",
    },
  
    form: {
      zIndex: 1,
      background: "#21033F",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      overflowY: "scroll",
      scrollbarWidth: "none",
      // justifyContent: 'center',
      height: "100vh",
  
      // 
      maxHeight: "100vh",
      maxWidth: rem(700),
      paddingTop: rem(80),
      width: "100%",
  
      [theme.fn.smallerThan("md")]: {
        maxWidth: "100%",
      },
    },
    inner: {
      maxWidth: rem(500),
      width: "100%",
    },
  
    title: {
      color: "#69DCBF",
      fontSize: "40px",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "normal",
    },
  }));
  export function ApplicantResetPasswordConfirmationMessage() {
    const pb: any = new PocketBase(CMS_ENDPOINT);
  
    const navigate = useNavigate();
   
    const { classes } = useStyles();
    const { height, width } = useViewportSize();
    const { locale } = useContext(TranslationContext);
  
    const divider = (
      <div
        style={{
          width: width < 500 ? "50%" : "1px",
          height: width < 500 ? "1px" : "15px",
          background: "white",
          margin: width < 500 ? "10px 0px" : "0px 5px",
        }}
      />
    );
  
     const errorMassage = true;
  
     
    return (
      
      <div className={classes.wrapper}>
       
        <Paper className={classes.form} radius={0} p={30}>
          <div className={classes.inner}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "20px",
                marginBottom: "100px",
                flexDirection: width > 500 ? "row" : "column",
              }}
            >
              
              <img
                style={{ height: "93px",cursor: "pointer" }}
                src="/white_text_logo.png"
                onClick={() => {
                  navigate("/applicant/login");
                }}
              />
            </div>
            <div style={{ maxWidth: "393px" }} >
            <Text style={{
  "textAlign": "right",
  "fontSize": "82px",
  "fontWeight": "700",
  "lineHeight": "100px",
  color:"#59AAA2"
}}>
                        {tn("Password changed successfully!")}
            </Text>
            </div>
            <div>
           {/* <Text mt={70} color="grey">{ltn("reset_password_confirm_massage")}</Text> */}
          
            <Button
           onClick={() => navigate("/applicant/login")}
            type="submit"
              style={{
                borderRadius: "5.112px",
                height: "70px",
                backgroundColor: "rgba(105, 220, 191, 0.77)",
                fontSize: "16px",
              }}
              fullWidth
              mt={100}
              size="md"
            >
               {tn("Return to the login page")}
            </Button>
            {/* {isError && (
              <Text mt="md" size="13px" color="red">
                 {errorMassage}
              </Text>
            )} */}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
  
                margin: "70px 5px 0px 5px",
                width: "100%",
                maxWidth: "500px",
                flexDirection: width > 500 ? "row" : "column",
              }}
            >
              <Text color="#ffffff"  weight={700}>
                {tn("terms and conditions")}
              </Text>{" "}
              {divider}
              <Text color="#ffffff"  weight={700}>
                {tn("Usage policy")}
                </Text>{" "}
              {divider}
              <Text color="#ffffff"  weight={700}>
                {tn("All rights are save")}
              </Text>{" "}
            </div>
          </div>
        </Paper>
       
        <div
          style={{
            height: "100%",
            width: `calc(100vw - 700px)`,
            backgroundImage: "url(/images/image1.jpg)",
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "absolute",
            left: locale == "en" ? "1" : "0",
            right: locale == "en" ? "0" : "1",
            filter: "grayscale(40%)",
          }}
        >
          <div
            style={{
              height: "100%",
              width: `100%`,
              background:
                "linear-gradient(312deg, rgba(2,0,36,1) 0%, rgba(88,170,162,1) 0%, rgba(183,254,247,0.4724483543417367) 100%)",
            }}
          >
            <div
              style={{
                height: "100%",
                width: "100%",
                backgroundImage: "url(/shapes/login_shape.svg)",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "bottom center",
                display: "flex",
                justifyContent: "center",
                alignItems: "start",
                fontSize: "40px",
                color: "white",
                fontWeight: 700,
                textAlign: "left",
                padding: "270px 0px 0px 0px",
              }}
            >
              خطوتك الأولى
              <br /> لمستقبل مهني واعد
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  export default ApplicantResetPasswordConfirmationMessage;
  