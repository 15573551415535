import { ltn, tn } from "../../../../../../../translation";
import { Button, Text } from "@mantine/core";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../../../../../../constants";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../../../../context/AppContext";
import { useDebouncedValue } from "@mantine/hooks";

export const AcceptWaitingInterview = (props: any) => {
  const pb: any = new PocketBase(CMS_ENDPOINT);
  const { data, onClose } = props;
  const [debounced_data] = useDebouncedValue(data, 200);
  const { reloader, setReloader } = useContext(AppContext);
  const [fullData, setFullData] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false)

  useEffect(() => {
    if (data?.record?.[0]) {
      const resultList = pb
        .collection("applications_view")
        .getList(1, 250, {
          filter: debounced_data?.record
            ?.map((item: any) => `id = "${item}"`)
            .join(" || "),
          expand: "program",
        })
        ?.then((res: any) => {
          setFullData(res?.items);
        });
    }
  }, [debounced_data]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "start",
        padding: "20px",
        width: "100%",
      }}
    >
      <Text fw={600} color="#5c5c5c">
        {debounced_data?.record?.[1]
          ? ltn("accept_applicants_no_ interview")
          : ltn("accept_applicant_no_ interview")}
      </Text>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <Button loading={buttonLoading}
          color="green"
          mx={5}
          onClick={
            debounced_data?.record?.[0]
              ? async () => {
                setButtonLoading(true)
                for (const record of data?.record || []) {
                    await pb
                      .collection(debounced_data?.table?.table)
                      .update(record, {
                        main_status: {
                          en: "document review",
                          ar: "مراجعة المستندات",
                        },
                        applicant_status: {
                          en: "document review",
                          ar: "مراجعة المستندات",
                        },
                        recruiter_status: {
                          ar: "تحت المراجعة",
                          en: "under review",
                        },
                        document_status: {
                          en: "waiting for the letter",
                          ar: "بإنتظار الخطاب",
                        },
                      });
                  };
                  onClose({});
                  setReloader(!reloader);
                }
              : async () => {
                setButtonLoading(true)
                  await pb
                    .collection(debounced_data?.table?.table)
                    .update(debounced_data?.record?.id, {
                      main_status: {
                        en: "document review",
                        ar: "مراجعة المستندات",
                      },
                      applicant_status: {
                        en: "document review",
                        ar: "مراجعة المستندات",
                      },
                      recruiter_status: {
                        ar: "تحت المراجعة",
                        en: "under review",
                      },
                      document_status: {
                        en: "waiting for the letter",
                        ar: "بإنتظار الخطاب",
                      },
                      date_of_commencement: new Date(
                        debounced_data?.record?.expand?.program?.start_date
                      ),
                      date_of_end: new Date(
                        debounced_data?.record?.expand?.program?.end_date
                      ),
                    });
                  onClose({});
                  setReloader(!reloader);
                }
          }
        >
          {tn("Accept")}
        </Button>
        <Button
          variant="outline"
          color="gray"
          mx={5}
          onClick={() => onClose({})}
        >
          {tn("Cancel")}
        </Button>
      </div>
    </div>
  );
};
