import { Helmet } from "react-helmet"
import { DataGrid } from "../../main_components/data-grid/DataGrid"
import { ttn, tn } from "../../translation"
import { Tabs, rem, Text } from "@mantine/core";
import { IconClock, IconListCheck, IconSquareRoundedCheck } from "@tabler/icons-react";
import { useContext, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { pb } from "../../config";
import { AppContext } from "../../context/AppContext";




export const ProgramHistoryPage  = () => {
  const iconStyle = { width: rem(16), height: rem(16) };
  const { page = "published_programs" , innerPage = "internal_requests" } = useParams();
  const navigate = useNavigate();
  
  const { reloader }: any = useContext(AppContext);
  const [numbers, setNumbers]: any = useState({});
  const [numbersDepartment, setNumbersDepartment]: any = useState({});
  // const programs_requests_allowed = pb.authStore.model.expand?.permission?.accessible_data?.en == "All data" 
  const programs_requests_allowed = true

  useEffect(() => {
    const record = pb
      .collection("recruiters_numbers")
      .getFirstListItem("", {})
      ?.then((e: any) => {
        setNumbers(e);
      });
      const record_ = pb
      .collection("recruiters_department_number")
      .getFirstListItem("", {})
      ?.then((e: any) => {
        setNumbersDepartment(e);
      });
  }, [reloader]);
  const numbers_main = pb.authStore.model.expand?.permission?.accessible_data?.en == "All data"  ?numbers :numbersDepartment
  return ( 
    <div
      style={{
        width: "100%",
        height: "calc(100vh - 84px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        flexDirection: "column",
        padding: "20px",
        paddingTop: "20px",
        background: "#fcfcfc",
      }}
    >

    <Text style={{
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "start",
        "color": "rgba(0, 0, 0, 0.70)",
        "fontSize": "24px",
        "fontWeight": "500",
        padding: "0px 10px 5px",
    }} 
   
    >
        {tn("Programs history")}
    </Text>
      {" "}
      <Tabs
        style={{
          width: "100%",
        }}
        defaultValue="published_programs"
        value={page}
        onTabChange={(e) => {
          navigate(`/recruiter/program_history/${e}`);
        }}
      >
        <Tabs.List>
          <Tabs.Tab
            style={{}}
            value="published_programs"
            icon={<IconSquareRoundedCheck style={iconStyle} />}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                gap: "10px",
              }}
            >
              {tn("New requests")}
             {numbers_main?.published_program != undefined && <div style={{
                  padding: "3px 5px",
                borderRadius: "10000px",
                background: "#520f96",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#fff",
                fontSize: "10px",
                fontWeight: "bold",
              }}>{numbers_main?.published_program}</div>}
            </div>
          </Tabs.Tab>
         {programs_requests_allowed && <Tabs.Tab
            style={{}}
            value="programs_requests"
            icon={<IconListCheck style={iconStyle} />}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                gap: "10px",
              }}
            >
              {tn("Programs requests")}
             {numbers_main?.programs_requests != undefined && <div style={{
                  padding: "3px 5px",
                borderRadius: "10000px",
                background: "#520f96",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#fff",
                fontSize: "10px",
                fontWeight: "bold",
              }}>{numbers_main?.programs_requests}</div>}
            </div>
          </Tabs.Tab>}
          <Tabs.Tab
            value="programs_history"
            icon={<IconClock style={iconStyle} />}
          >
           
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                gap: "10px",
              }}
            >
               {tn("Programs history")}
             {numbers_main?.programs != undefined && <div style={{
                  padding: "3px 5px",
                borderRadius: "10000px",
                background: "#520f96",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#fff",
                fontSize: "10px",
                fontWeight: "bold",
              }}>{numbers_main?.programs}</div>}
            </div>
          </Tabs.Tab>
        
        </Tabs.List>
      </Tabs>
      {page == "published_programs" ? (
        <div
          style={{
            height: "calc(100% - 24px)",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
            <Helmet>
      <title>{ttn("published_programs") + " / " + tn("Coophub")}</title>
    </Helmet>
    <DataGrid name="published_programs"/>
        </div>
      ) : (
        <></>
      )}
      {page == "programs_history" ? (
        <div
          style={{
            height: "calc(100% - 24px)",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
             <Helmet>
      <title>{ttn("programs_history") + " / " + tn("Coophub")}</title>
    </Helmet>
    <DataGrid name="programs_history"/>
        </div>
      ) : (
        <></>
      )}
      {page == "programs_requests" ? (
        <div
          style={{
            height: "calc(100% - 24px)",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
             {/* <Helmet>
      <title>{tn("programs_requests") + " / " + tn("Coophub")}</title>
    </Helmet> */}
   <ProgramsRequests innerPage={innerPage} numbers_main={numbers_main}/>
        </div>
      ) : (
        <></>
      )}

     
    </div>
  );
};





const ProgramsRequests = ({innerPage,numbers_main}:any) => {

  const navigate = useNavigate();
  if(
    pb?.authStore?.model?.expand?.permission?.accessible_data?.en == "All data" 
  ){
    return <div style={{
      width: "100%",
      height: "calc(100% - 20px)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    }}>
      {/* // add tabs here
      External requests
  Internal requests
   */}
   <Tabs
          style={{
            width: "100%",
  
          }}
          value={innerPage}
          onTabChange={(e) => {
            navigate(`/recruiter/program_history/programs_requests/${e}`);
          }
          }
  
        >
          <Tabs.List>
          <Tabs.Tab
              value="internal_requests"
            >
               <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  gap: "10px",
                }}
              >
                {tn("Internal requests")}
               {numbers_main?.programs_requests != undefined && <div style={{
                    padding: "3px 5px",
                  borderRadius: "10000px",
                  background: "#520f96",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#fff",
                  fontSize: "10px",
                  fontWeight: "bold",
                }}>{numbers_main?.programs_requests}</div>}
              </div>
            </Tabs.Tab>
            <Tabs.Tab
            disabled
              value="external_requests"
            >
              {tn("External requests")}
            </Tabs.Tab>
          
          </Tabs.List>
        </Tabs>
        {innerPage == "internal_requests" ? (
          <div
            style={{
              height: "calc(100% - 24px)",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
               <Helmet>
        <title>{tn("Internal requests") + " / " + tn("Coophub")}</title>
      </Helmet>
      <DataGrid name="internal_requests"/>
          </div>
        ) : (
          <></>
        )}
        {innerPage == "external_requests" ? (
          <div
            style={{
              height: "calc(100% - 24px)",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
               <Helmet>
        <title>{tn("External requests") + " / " + tn("Coophub")}</title>
      </Helmet>
      <DataGrid name="external_requests"/>
          </div>
        ) : (
          <></>
        )}
          
  
    </div>
  }else{
    return <div
    style={{
      height: "calc(100% - 24px)",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
       <Helmet>
      <title>{tn("programs_requests") + " / " + tn("Coophub")}</title>
    </Helmet>
    <DataGrid name="my_requests"/>
  </div>
  }
 
};
