import {
  Paper,
  createStyles,
  TextInput,
  PasswordInput,
  Checkbox,
  Button,
  Title,
  Text,
  Anchor,
  rem,
} from "@mantine/core";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { isEmail, useForm } from "@mantine/form";
import { useContext, useEffect, useState } from "react";
import { useViewportSize } from "@mantine/hooks";
import { AppContext } from "../../context/AppContext";
import { TranslationContext } from "../../../context/TranslationContext";
import { tn, vtn } from "../../../translation";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../../constants";

const useStyles = createStyles((theme) => ({
  wrapper: {
    height: "100vh",
    display: "flex",
  },

  form: {
    zIndex: 1,
    background: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowY: "scroll",
    scrollbarWidth: "none",
    // justifyContent: 'center',
    height: "100vh",

    //
    maxHeight: "100vh",
    maxWidth: rem(700),
    paddingTop: rem(80),
    width: "100%",

    [theme.fn.smallerThan("md")]: {
      maxWidth: "100%",
    },
  },
  inner: {
    maxWidth: rem(500),
    width: "100%",
  },

  title: {
    color: "#21033F",
    fontSize: "40px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  },
}));
export function SetPassword() {
  const pb: any = new PocketBase(CMS_ENDPOINT);

  const navigate = useNavigate();
  const [isError, setisError]: any = useState(false);
  const validators = [
    vtn("password"),
    vtn("password1"),
    vtn("password2"),
    vtn("password3"),
    vtn("password4"),
    vtn("password5"),
    vtn("password_comfirm"),
  ];
  const validatePassword = (value: any, values: any) => {
    if (!value) return validators?.[0];

    if (value === values.oldPassword) return validators[1];

    // Check for minimum 8 characters
    if (value.length < 8) return validators[2];

    // Check for at least one uppercase letter
    if (!/[A-Z]/.test(value)) return validators[3];

    // Check for at least one lowercase letter
    if (!/[a-z]/.test(value)) return validators[4];

    // Check for at least one of the special characters !@#%?
    if (!/[!@#%?]/.test(value)) return validators[5];

    return null;
  };
  const form: any = useForm({
    initialValues: {
      password: "",
      passwordComfirm: "",
    },

    validate: {
      password: validatePassword,
      passwordComfirm: (value) =>
        value !== form.values.password && validators[6],
      // email: (value) =>
      //   value !== "" ? null : "please write your email or usename",
      // password: (value) => (value !== "" ? null : "please write your password"),
    },
  });
  const { classes } = useStyles();
  const { height, width } = useViewportSize();
  const { locale } = useContext(TranslationContext);

  const divider = (
    <div
      style={{
        width: width < 500 ? "50%" : "1px",
        height: width < 500 ? "1px" : "15px",
        background: "white",
        margin: width < 500 ? "10px 0px" : "0px 5px",
      }}
    />
  );

  const errorMassage = true;
  const { id } = useParams();


  return (
    <form
      onSubmit={form.onSubmit(async (e) => {
        try {
          const data = {
            password: e.password,
            passwordConfirm: e.passwordComfirm,
            oldPassword: "husam1234567890",
          };

          const record = await pb
            .collection("recruiters")
            .update(id, data)
            .then(() => {
              navigate("/recruiter/set_password_confirmation_message");
            });
        } catch (err) {
          setisError(true);
        }
        // }
      })}
    >
      <div className={classes.wrapper}>
        <Paper className={classes.form} radius={0} p={30}>
          <div className={classes.inner}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "20px",
                marginBottom: "100px",
                flexDirection: width > 500 ? "row" : "column",
              }}
            >
              <img style={{ height: "93px" }} src="/dark_text_logo.svg" />
            </div>
            <div style={{ maxWidth: "393px" }}>
              <Text
                style={{
                  textAlign: "right",
                  fontSize: "82px",
                  fontWeight: "700",
                  lineHeight: "100px",
                  color: "#21033F",
                }}
              >
                {tn("Set Password")}
              </Text>
            </div>
            <div>
              <PasswordInput
                withAsterisk={false}
                error={
                  errorMassage
                    ? locale == "en"
                      ? "there is error"
                      : "هناك خطأ"
                    : null
                }
                {...form.getInputProps("password")}
                required
                mt={90}
                variant="filled"
                styles={(theme) => ({
                  label: {
                    color: "#21033F",
                  },
                  innerInput: {
                    color: "#21033F",
                  },
                  input: {
                    borderRadius: "0px",
                    border: "0px solid #69DCBF",

                    color: "white",
                    backgroundColor: "transparent",
                    borderBottom: "1px solid #21033F86",
                    "&:focus-within": {
                      border: "0px solid #21033F",
                      borderBottom: "1px solid #21033F",
                    },
                  },
                })}
                label={tn("New Password")}
                placeholder={tn("Your password")}
                size="md"
              />
              <PasswordInput
                withAsterisk={false}
                error={
                  errorMassage
                    ? locale == "en"
                      ? "there is error"
                      : "هناك خطأ"
                    : null
                }
                {...form.getInputProps("passwordComfirm")}
                required
                mt={20}
                variant="filled"
                styles={(theme) => ({
                  label: {
                    color: "#21033F",
                  },
                  innerInput: {
                    color: "#21033F",
                  },
                  input: {
                    borderRadius: "0px",
                    border: "0px solid #69DCBF",

                    color: "#21033F",
                    backgroundColor: "transparent",
                    borderBottom: "1px solid #21033F86",
                    "&:focus-within": {
                      border: "0px solid #21033F",
                      borderBottom: "1px solid #21033F",
                    },
                  },
                })}
                label={tn("Password Confirm")}
                placeholder={tn("Retype Your password")}
                size="md"
              />
              <Button
                type="submit"
                style={{
                  borderRadius: "0px",
                  height: "70px",
                  backgroundColor: "#21033Fc4",
                  fontSize: "16px",
                }}
                fullWidth
                mt={100}
                size="md"
              >
                {tn("Set Password")}
              </Button>
              {/* {isError && (
              <Text mt="md" size="13px" color="red">
                 {errorMassage}
              </Text>
            )} */}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",

                margin: "70px 5px 0px 5px",
                width: "100%",
                maxWidth: "500px",
                flexDirection: width > 500 ? "row" : "column",
              }}
            >
              <Text color="#21033F" weight={700}>
                {tn("terms and conditions")}
              </Text>{" "}
              {divider}
              <Text color="#21033F" weight={700}>
                {tn("Usage policy")}
              </Text>{" "}
              {divider}
              <Text color="#21033F" weight={700}>
                {tn("All rights are save")}
              </Text>{" "}
            </div>
          </div>
        </Paper>

        <div
          style={{
            height: "100%",
            width: `calc(100vw - 700px)`,
            backgroundImage: "url(/shapes/hole_image.png)",
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "absolute",
            left: locale == "en" ? "1" : "0",
            right: locale == "en" ? "0" : "1",
            filter: "grayscale(100%)",
          }}
        ></div>
        <div
          style={{
            position: "absolute",
            left: locale == "en" ? "1" : "0",
            right: locale == "en" ? "0" : "1",
            height: "100%",
            width: `calc(100vw - 700px)`,
            background:
              "linear-gradient(127deg, rgba(33,3,63,0.0970982142857143) 0%, rgba(33,3,63,0.6657256652661064) 100%)",
          }}
        >
          {/* <div
            style={{
              height: "100%",
              width: "100%",
              backgroundImage: "url(/shapes/yellow_blue.svg)",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "bottom center",
              display: "flex",
              justifyContent: "center",
              alignItems: "start",
              fontSize: "40px",
              color: "white",
              fontWeight: 700,
              textAlign: "left",
              padding: "270px 0px 0px 0px",
            }}
          >
            خطوتك الأولى
            <br /> لمستقبل مهني واعد
          </div> */}
        </div>
      </div>
    </form>
  );
}

export default SetPassword;
