import { ltn, tn } from "../../../../../../translation";

import {
  Avatar,
  Button,
  Checkbox,
  Loader,
  Text,
} from "@mantine/core";

import { useContext, useState } from "react";
import { TranslationContext } from "../../../../../../context/TranslationContext";
import { CMS_ENDPOINT } from "../../../../../../constants";
import PocketBase from "pocketbase";
import { AppContext } from "../../../../../../context/AppContext";
import { useDebouncedValue, useViewportSize } from "@mantine/hooks";
import { BannersContext } from "../../../../../../context/BannersContext";
import { useNavigate } from "react-router-dom";
import ReactMarkdown from "react-markdown";
const pb = new PocketBase(CMS_ENDPOINT);
export const TestInstructions = ({ data, onClose }: any) => {
  const [debounced_data] = useDebouncedValue(data?.record, 200);
    const [buttonLoading, setButtonLoading]: any = useState(false);


  const { translation, locale }: any = useContext(TranslationContext);
  const { reloader , setReloader }: any = useContext(AppContext);

  const { width } = useViewportSize();
  const userData = pb?.authStore?.model;
  const [accept, setAccept] = useState(false);
  const { setTopBanner }: any = useContext(BannersContext);
  const navigate = useNavigate();

  console.log("debounced_datadebounced_data", debounced_data);

  const components = {
    // Applying style to headings
    h1: ({ node, ...props }) => <h1 style={{ color: 'blue', fontSize: '24px' }} {...props} />,
    h2: ({ node, ...props }) => <h2 style={{ color: 'green', fontSize: '20px' }} {...props} />,
    // Applying style to paragraphs
    p: ({ node, ...props }) => <p style={{ color: '#373737', fontSize: '16px' }} {...props} />,
    // Applying style to list and list items
    ul: ({ node, ...props }) => <ul style={{ listStyleType: 'circle', marginLeft: '20px' }} {...props} />,
    li: ({ node, ...props }) => <li style={{ color: '#373737', fontSize: '18px' }} {...props} />,
  };

  return width > 10 ? (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
        <Text style={{
            width: "100%",  
            padding: "10px 30px 10px ",
            color: "rgba(0, 0, 0, 0.792)", 
            fontSize: "18px",
            fontWeight: "700",
          }}>
         {
          locale === "ar"? (` إختبار الترشيح للتقديم على ${debounced_data?.name} من ${debounced_data?.company_name}`):
          `Test for applying to ${debounced_data?.name} from ${debounced_data?.company_name}`
         }
          </Text>
        <div style={{
          width: "calc(100% - 20px)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "start",
          padding: "0px 10px",
          backgroundColor: "#F7F7F7",
          borderRadius: "5.112px",
          gap: "10px",
          margin: "10px 10px 0px",
        }}>
           <div
            style={{
              width: "93%",
              background: "#FFB004",
              height: "8px",
              borderRadius: "522px",
            }}
          />
          <ReactMarkdown components={components} children={`**تعليمات مهمة:**
- **مدة الاختبار:** ${debounced_data?.test_duration + (locale == "en" ? "m":"د")}.
- **يحق لك محاولة واحدة لدخول اختبار الترشيح و في حال عدم اجتيازه فلا يمكنك التقديم على البرنامج مره آخرى**
- **لا تقم بالخروج من الصفحة أو تحديثها لتفادي انهاء الاختبار.**
- **تأكد من وجود اتصال جيد بالانترنت لتفادي انقطاع الإتصال.**

**إخلاء مسؤولية:**
تم إنشاء هذا الاختبار من قبل الجهة التدريبية و ليس لكووب-هب اي مسؤولية عن أي شيء يتعلق بهذا الاختبار وفي حال وجود ملاحظة على النموذج الرجاء التواصل مع الجهة بشكل مباشر.
 `} />
        </div>
      
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "end",
          padding: "10px 10px",
          gap: "10px",
        }}
      >
       {<Button
          compact
          style={{
            // color: "white",
            borderRadius: "5.112px",
            backgroundColor:   "#69DCBF",
          }}
          onClick={() => {
            navigate(`/test_page/${debounced_data?.filtration_test}/${debounced_data?.id}`);
            onClose({});

          }}
          // color="red"
        >
          {tn("Start the test")}
        </Button>}
        <Button
          compact
          style={{
            color: "white",
            width: "60px",
            borderRadius: "5.112px",
            backgroundColor: "#F9837C",
          }}
          color="red"
          onClick={() => {
            navigate("/applicant/programs_search")
            onClose({});
          }}
        >
          {tn("close")}
        </Button>
      </div>
    </div>
  ) : (
    <div
      style={{
        height: "1270px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Loader color="color1" />
    </div>
  );
};





