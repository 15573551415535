import { useContext } from "react";
import { DataContext } from "../../../../../../context/DataContext";
import { ActionComponent } from "../../../ActionComponent";

export const RejectProgram = (props: any) => {
  const { setRejectProgram }: any = useContext(DataContext);

  const { data, action , table } = props;
  const when_be_able = data?.status?.en == "waiting for approval";
  
  return (
    <ActionComponent 
    data={data}
    action={action}
    table={table}
    setData={()=>setRejectProgram({
      id: data?.id,
      record: data,
      table: table,
      action: action
    })}
    enabled={when_be_able}
    visible={when_be_able}
    />
  );
};
