import { useContext, useEffect, useState } from "react";
import { useTimeout, useViewportSize } from "@mantine/hooks";

import { DataGrid } from "../../../main_components/data-grid/DataGrid";
import {
  ActionIcon,
  Button,
  Center,
  Loader,
  Modal,
  Progress,
  RingProgress,
  Table,
  Text,
  rem,
} from "@mantine/core";
import { tn } from "../../../translation";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT, WEB_ENDPOINT } from "../../../constants";
import { TranslationContext } from "../../../context/TranslationContext";
import { motion } from "framer-motion";
import { IconCheck } from "@tabler/icons-react";
import { EvaluationProgram } from "../components/EvaluationProgram";
import { AppContext } from "../../../context/AppContext";
import { DataContext } from "../../../context/DataContext";
import { WithdrawalRequest } from "../../../main_components/data-grid/components/modals/applicant_action/withdrawal_request/WithdrawalRequest";
import { set } from "lodash";
import { Helmet } from "react-helmet";
const pb: any = new PocketBase(CMS_ENDPOINT);

 function CurrentProgram() {
  const [evaluationOpen, setEvaluationOpen] = useState(false);
  const { locale, translation }: any = useContext(TranslationContext);
  const { reloader, setReloader } = useContext(AppContext);

  const [data, setData]: any = useState({});
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(0);
  const [withdrawalRequest,setWithdrawalRequest] = useState({})
  // useEffect(() => {
  //   if (bannerOpen) {
  //     start();
  //   }
  // }, [bannerOpen]);

  useEffect(() => {
    const record = pb
      .collection("applications_view")
      .getList(1, 10, {
        expand: "program,recruiter,company",
        filter: "main_status.en = 'accepted'",
      })
      ?.then((res: any) => {

        setLoading(1);
        setData(res?.items?.[0]);
        const records = pb
          .collection("program_plans_view")
          .getFullList({
            sort: "start_date",
            filter: `application = "${res?.items?.[0]?.id}"`,
            expand: "sub_department",
          })
          ?.then((res: any) => {
            setPlans(res);
          });
      });
  }, [reloader]);

  const { height, width }: any = useViewportSize();
  const { contractViewerData, setContractViewerData }: any =
  useContext(AppContext);
const openNewTab = () => {
  const url = WEB_ENDPOINT + "/contract_viewer"; // Replace with your desired URL
  window.open(url, "_blank", "noopener,noreferrer");
};
  function convertToArabicNumbers(number) {
    const arabicNumbers = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
    return number.toString().replace(/[0-9]/g, (digit) => arabicNumbers[digit]);
  }
  return width > 10 && loading == 1 ? (
    data?.id ? (
      <>
        <Helmet>
        <title>{tn("Current program") + " / " + tn("Coophub")}</title>
      </Helmet>
        <div
          style={{
            width: "100%",
            height: "calc(100vh - 84px)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "start",
            padding: "20px",
            paddingTop: "20px",
            background: "#fcfcfc",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              maxWidth: "1600px",
            }}
          >
            <Text
              style={{
                backgroundColor: "#f3ec78",
                backgroundImage:
                  "linear-gradient(113deg, rgba(105,220,191,1) 0%, rgba(222,140,134,1) 19%, rgba(33,3,63,1) 56%)",
                WebkitBackgroundClip: "text",
                MozBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                textAlign: "right",
                fontSize: width > 450 ? "42px" : "30px",
                fontStyle: "normal",
                fontWeight: "700",
                // lineHeight: "95%",
                minHeight: width > 450 ? "70px" : "30px",
              }}
            >
              {tn("Current program")}
            </Text>
            <Button
              style={{
                backgroundColor: "#F9837C",
                borderRadius: "5.112px",
              }}
              onClick={()=>{
                setWithdrawalRequest(
                  {
                    record:data,
                    id:data.id,
                    table:{
                      table:"applications",
                      name:"applications"
                    }
                  }
                )
              }}
              size="xs"
            >
              {tn("Request to withdraw from the program")}
            </Button>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: width > 905 ? "row" : "column",
              alignItems: "stretch",
              justifyContent: "space-between",
              marginTop: height > 950 ? "calc(6vh)" : "3px",
              maxWidth: "1400px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",
                justifyContent: "start",
                width: width > 905 ? "calc(80% - 20px)" : "100%",
              }}
            >
              <MainBox>
                <div
                  style={{
                    background: "#fff",
                    borderRadius: "10px",
                    width: "calc(100% - 20px)",
                    minWidth: "550px",
                    height: "100px",
                    margin: "10px 0px",
                    boxShadow: "0px 0px 3px #51515133",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "0px 5px",
                  }}
                >
                  <Table>
                    <thead>
                      <tr>
                        <TH>{tn("program title")}</TH>
                        <TH>{tn("Program type")}</TH>
                        <TH>{tn("Program start date")}</TH>
                        <TH>{tn("Program end date")}</TH>
                        <TH>{tn("Period")}</TH>
                        <TH>{tn("City")}</TH>
                      </tr>
                    </thead>
                    <tbody>
                      {" "}
                      <tr key={1}>
                        <TD>{data?.expand?.program?.name}</TD>
                        <TD>{data?.expand?.program?.type?.[locale]}</TD>
                        <TD>
                          {new Date(
                            data?.date_of_commencement
                          )?.toLocaleDateString("en-GB")}
                        </TD>
                        <TD>
                          {new Date(data?.date_of_end)?.toLocaleDateString(
                            "en-GB"
                          )}
                        </TD>
                        <TD>{data?.expand?.program?.period?.[locale]}</TD>
                        <TD>{data?.expand?.program?.city?.[locale]}</TD>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </MainBox>
              <MainBox
                style={{
                  height: "155px",
                  marginTop: "30px",
                }}
              >
                <Text
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "#5c5c5c",
                    textAlign: "center",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "7px",
                    marginTop: "10px",
                  }}
                >
                  {tn("Program coordinator")}
                </Text>
                <div
                  style={{
                    background: "#fff",
                    borderRadius: "10px",
                    width: "calc(100% - 20px)",
                    minWidth: "550px",
                    height: "100px",
                    margin: "10px 0px",
                    boxShadow: "0px 0px 3px #51515133",
                    display: "flex",
                    alignItems: "stretch",
                    justifyContent: "center",
                    padding: "0px 5px",
                  }}
                >
                  <Table>
                    <thead>
                      <tr>
                        <TH>{tn("name")}</TH>
                        <TH>{tn("Email Address")}</TH>
                        <TH>{tn("Company mobile")}</TH>
                        <TH>{tn("Extension")}</TH>
                      </tr>
                    </thead>
                    <tbody>
                      {" "}
                      <tr key={1}>
                        <TD>{data?.recruiter_name}</TD>
                        <TD>{data?.recruiter_email}</TD>
                        <TD>{data?.company_tel}</TD>
                        <TD>{data?.recruiter_extension}</TD>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </MainBox>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",
                justifyContent: "center",
                width: width > 905 ? "calc(20% - 20px)" : "100%",
              }}
            >
              <MainBox
                style={{
                  height: "105px",
                }}
              >
                <img
                  style={{
                    width: "calc(100% - 20px)",
                    height: "calc(100% - 30px)",
                    margin: "10px 10px",
                    objectFit: "contain",
                  }}
                  src={
                    `${CMS_ENDPOINT}/api/files/yv4pwbt2ii9l1jo/${data?.company_id}/${data?.company_logo}?token=`
                    // `https://upload.wikimedia.org/wikipedia/commons/6/6c/01_NUPCO_LOGO_DUAL_RGB-01.png`
                  }
                />
              </MainBox>
              <MainBox
                style={{
                  height: "255px",
                  marginTop: "10px",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "#5c5c5c",
                    textAlign: "center",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "10px",
                  }}
                >
                  {tn("Program progress")}
                </Text>

                {data?.progress_percentage != "100" ? (
                  <RingProgress
                    size={190}
                    thickness={26}
                    roundCaps
                    rootColor="#dddddd"
                    sections={[
                      {
                        value: parseInt(data?.progress_percentage),
                        color: "#FFB004",
                      },
                    ]}
                    label={
                      <Center>
                        <Text
                          style={{
                            fontSize: "30px",
                            fontWeight: "900",
                            color: "#5c5c5c",
                            textAlign: "center",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          %
                          {locale == "ar"
                            ? convertToArabicNumbers(
                                parseInt(data?.progress_percentage)
                              )
                            : parseInt(data?.progress_percentage)}
                        </Text>
                      </Center>
                    }
                  />
                ) : (
                  <RingProgress
                    size={190}
                    thickness={26}
                    roundCaps
                    sections={[{ value: 100, color: "teal" }]}
                    label={
                      <Center>
                        <ActionIcon
                          color="teal"
                          variant="light"
                          radius="1000px"
                          size="70px"
                        >
                          <IconCheck
                            style={{ width: rem(52), height: rem(52) }}
                          />
                        </ActionIcon>
                      </Center>
                    }
                  />
                )}
                {data?.progress_percentage != "100" ? (
                  <>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      <div />
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "30px",
                            height: "13px",
                            background: "#FFB004",
                            borderRadius: "50px",
                          }}
                        />
                        <Text
                          style={{
                            color: "#5c5c5c",
                            fontSize: "13px",
                            fontWeight: "600",
                          }}
                          mx="sm"
                        >
                          {tn("Progress")}
                        </Text>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "30px",
                            height: "13px",
                            background: "#dddddd",
                            borderRadius: "50px",
                          }}
                        />
                        <Text
                          style={{
                            color: "#5c5c5c",
                            fontSize: "13px",
                            fontWeight: "600",
                          }}
                          mx="sm"
                        >
                          {tn("Remaining short")}
                        </Text>
                      </div>
                      <div />
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Text
                          style={{
                            color: "#5c5c5c",
                            fontSize: "13px",
                            fontWeight: "600",
                          }}
                          mx="3px"
                        >
                          {tn("Progress")}
                        </Text>
                        <Text
                          style={{
                            color: "#29a644",
                            fontSize: "17px",
                            fontWeight: "800",
                          }}
                          mx="3px"
                        >
                          {"100%"}
                        </Text>
                      </div>
                    </div>
                  </>
                )}
              </MainBox>
            </div>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: width > 905 ? "row" : "column",
              alignItems: "stretch",
              justifyContent: "space-between",
              marginTop: "20px",
              maxWidth: "1400px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",
                justifyContent: "center",
                width: width > 905 ? "calc(20% - 20px)" : "100%",
              }}
            >
              <MainBox
                style={{
                  marginTop: "0px",
                }}
              >
                <Text
                  style={{
                    fontSize: "14px",
                    color: "#5c5c5c",
                    textAlign: "center",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "7px",
                    marginTop: "10px",
                  }}
                >
                  {tn("Program attachments")}
                </Text>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "14px",
                      color: "#5c5c5c",
                      textAlign: "center",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "7px",
                      marginTop: "10px",
                    }}
                  >
                    {tn("Training request letter")}
                  </Text>
                  <Button
                    disabled={!data?.training_request_letter}
                    color="color3"
                    radius="10px"
                    onClick={() => {
                      window.open(
                        `${CMS_ENDPOINT}/api/files/${data?.collectionId}/${data?.id}/${data?.training_request_letter}?token=`
                      );
                    }}
                  >
                    {tn("Show")}
                  </Button>
                </div>
               
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    margin: "10px 0px",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "14px",
                      color: "#5c5c5c",
                      textAlign: "center",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "7px",
                      marginTop: "10px",
                    }}
                  >
                    {tn("Training acceptance letter")}
                  </Text>
                  <Button
                    disabled={!data?.acceptance_letter}
                    color="color3"
                    radius="10px"
                    onClick={() => {
                      window.open(
                        `${CMS_ENDPOINT}/api/files/${data?.collectionId}/${data?.id}/${data?.acceptance_letter}?token=`
                      );
                    }}
                  >
                    {tn("Show")}
                  </Button>
                  
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    marginBottom: "7px",

                  }}
                >
                  <Text
                    style={{
                      fontSize: "14px",
                      color: "#5c5c5c",
                      textAlign: "center",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "7px",
                      marginTop: "10px",
                    }}
                  >
                    {tn("Taining contract")}
                  </Text>
                  <div/>
                  <Button
                    disabled={!data?.contract_data || data?.contract_status.en != "finished"}
                    color="color3"
                    radius="10px"
                    onClick={() => {
                      setContractViewerData(
                        {
                          applicant:{
                            ...data?.contract_data?.applicant,
                            religion:data?.contract_data?.applicant?.religion?.[locale],
                            marital_status:data?.contract_data?.applicant?.marital_status?.[locale],
                            gender:data?.contract_data?.applicant?.gender?.[locale]
                          },
                          company:{
                            ...data?.contract_data?.company,
                            job_title: data?.contract_data?.company?.job_title?.[locale],
                          },
                          program:{
                            ...data?.contract_data?.program,
                            programs_majors: data?.contract_data?.program?.programs_majors?.map((item:any)=>item?.[locale]).join(', '),
                            program_type_translated: data?.contract_data?.program?.program_type_translated?.[locale],
        
                          
                          }
                        }
                      );
                      openNewTab()
                    }}
                  >
                    {tn("Show")}
                  </Button>
                </div>
              </MainBox>
              <MainBox
                style={{
                  marginTop: "10px",
                  alignItems: "center",
                  opacity:
                    data?.progress_percentage == "100"
                      ? !data?.program_evaluation
                        ? "1"
                        : "0.5"
                      : "0.5",
                }}
              >
                <Text
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "#5c5c5c",
                    textAlign: "center",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "10px",
                  }}
                >
                  {tn("Program evaluation")}
                </Text>
                <Button
                  disabled={
                    data?.progress_percentage == "100"
                      ? !data?.program_evaluation
                        ? false
                        : true
                      : true
                  }
                  my="md"
                  color="color3"
                  radius="10px"
                  onClick={() => {
                    setEvaluationOpen(true);
                  }}
                >
                  {tn("evaluation ")}
                </Button>
                <EvaluationProgram
                  open={evaluationOpen}
                  setOpen={setEvaluationOpen}
                  id={data?.id}
                />
              </MainBox>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",
                justifyContent: "start",
                width: width > 905 ? "calc(80% - 20px)" : "100%",
              }}
            >
              <MainBox
                style={{
                  height: "267px",
                  marginBottom: "10px",
                }}
              >
                <Text
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "#5c5c5c",
                    textAlign: "center",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "7px",
                    marginTop: "10px",
                  }}
                >
                  {tn("Program plan")}
                </Text>
                <div
                  style={{
                    background: "#fff",
                    borderRadius: "10px",
                    width: "calc(100% - 20px)",
                    minWidth: "550px",
                    height: "300px",
                    margin: "10px 0px",
                    boxShadow: "0px 0px 3px #51515133",
                    display: "flex",
                    alignItems: "stretch",
                    justifyContent: "center",
                    padding: "0px 5px",
                    overflowX: "scroll",
                  }}
                >
                  {plans?.[0] ? (
                    <Table striped>
                      <thead
                        style={{
                          position: "sticky",
                          top: "0px",
                          zIndex: 100,
                          background: "#fff",
                          borderBottom: "1px solid #000000",
                        }}
                      >
                        <tr>
                          <TH>#</TH>
                          <TH>{tn("Internal departments")}</TH>
                          <TH>{tn("Beginning of the period")}</TH>
                          <TH>{tn("End of period")}</TH>
                        </tr>
                      </thead>
                      <tbody>
                        {" "}
                        {plans?.map((item: any, index: number) => {
                          return (
                            <tr key={index}>
                              <TD>{index + 1}</TD>
                              <TD>{item?.expand?.sub_department?.name}</TD>
                              <TD>
                                {new Date(item?.start_date)?.toLocaleDateString(
                                  "en-GB"
                                )}
                              </TD>
                              <TD>
                                {new Date(item?.end_date)?.toLocaleDateString(
                                  "en-GB"
                                )}
                              </TD>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {tn("nothing")}
                    </div>
                  )}
                </div>
              </MainBox>
            </div>
          </div>
        </div>
        <Modal
      size={"500px"}
      style={{ zIndex: 9999999, position: "relative" }}
      overlayProps={{
        zIndex: 2000,
        opacity: 0.55,
        blur: 3,
      }}
      styles={() => ({
        content: {
          zIndex: 10001,
          borderRadius: "10px",
          background: "linear-gradient(153deg, #69DCBF 100%, #21033f7a 100%)",
          // display: "flex",
          // justifyContent: "center",
          // alignItems: "center",
        },
        header: { display: "none" },
        body: { padding: "9px" },
        inner: { zIndex: 10001 },  

      })}
      opened={withdrawalRequest?.record}
      onClose={() => {
        setWithdrawalRequest({});
      }}
      centered
    >
      <div
        style={{
          background: "white",
          width: "100%",
          height: "100%",
          marginTop: "9px",
          borderRadius: "5px",
          // paddingTop: "30px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
       <WithdrawalRequest data={withdrawalRequest}
          onClose={setWithdrawalRequest}/>
      </div>
    </Modal>
      </>
    ) : (
      <div
        style={{
          width: "100%",
          height: "calc(100vh - 84px)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >  <Helmet>
      <title>{tn("Current program") + " / " + tn("Coophub")}</title>
    </Helmet>
        <Text
          style={{
            fontSize: "18px",
            fontWeight: "bold",
            color: "#5c5c5c",
            textAlign: "center",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "7px",
            marginTop: "10px",
          }}
        >
          {tn("No current program")}
        </Text>
      </div>
    )
  ) : (
    <div
      style={{
        width: "100%",
        height: "calc(100vh - 84px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Loader size={60} />
    </div>
  );
}

const TH = (props: any) => {
  return (
    <th>
      {" "}
      <Text
        style={{
          fontSize: "13px",
          fontWeight: "bold",
          color: "#5c5c5c",
          textAlign: "center",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "7px",
          height: "30px",
        }}
      >
        {props.children}
      </Text>
    </th>
  );
};

const TD = (props: any) => {
  return (
    <td>
      {" "}
      <Text
        style={{
          fontSize: "12px",
          color: "#5c5c5c",
          textAlign: "center",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "30px",
        }}
      >
        {props.children}
      </Text>
    </td>
  );
};

const MainBox = (props: any) => {
  const { height, width }: any = useViewportSize();

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "rgba(79, 81, 82, 0.05)",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        alignItems: width > 600 ? "center" : "start",
        justifyContent: "start",
        marginTop: "20px",
        overflowX: "hidden",
        overflowY: "hidden",
        ...props.style,
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <div
          style={{
            background: "#21033F",
            borderRadius: "50px",
            width: "calc(100% - 20px)",
            minHeight: "5px",
          }}
        />
      </div>
      {props.children}
    </div>
  );
};
export default CurrentProgram;