import { ltn, tn, vtn } from "../../../../../../../../translation";

import {
  Button,
  Center,
  Checkbox,
  FileButton,
  FileInput,
  Loader,
  Select,
  Text,
  TextInput,
  Textarea,
  rem,
} from "@mantine/core";

import { useContext, useEffect, useState } from "react";
import { TranslationContext } from "../../../../../../../../context/TranslationContext";
import { CMS_ENDPOINT, WEB_ENDPOINT } from "../../../../../../../../constants";
import PocketBase from "pocketbase";
import { useDebouncedValue, useViewportSize } from "@mantine/hooks";
import { isNotEmpty, useForm } from "@mantine/form";
import { AppContext } from "../../../../../../../../context/AppContext";
import { MIME_TYPES } from "@mantine/dropzone";
import { IconUpload, IconPhoto } from "@tabler/icons-react";
import { DateInput } from "@mantine/dates";
import { DataContext } from "../../../../../../../../context/DataContext";
const pb = new PocketBase(CMS_ENDPOINT);
export const ApproveContractApproval = ({ data, onClose }: any) => {
  const { translation, locale }: any = useContext(TranslationContext);
  const [debounced_data] = useDebouncedValue(data, 200);
  const { reloader, setReloader }: any = useContext(AppContext);
  const [buttonLoading1, setButtonLoading1] = useState(false);
  const [buttonLoading2, setButtonLoading2] = useState(false);
  const { setCorrectionRequestContractApproval }: any = useContext(DataContext);
  const [companyData, setCompanyData]: any = useState({});

  useEffect(() => {
    const record = pb
      .collection("companies_view")
      .getFirstListItem("", {})
      ?.then((res) => {
        setCompanyData(res);
      });
  }, []);
  const { contractViewerData, setContractViewerData }: any =
    useContext(AppContext);
  const openNewTab = () => {
    const url = WEB_ENDPOINT + "/contract_viewer"; // Replace with your desired URL
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const applicant_data = debounced_data?.record?.contract_data?.applicant;

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "start",
        padding: "20px 40px",
      }}
    >
      <Text
        style={{
          width: "100%",
          fontSize: "20px",
          fontWeight: "700",
          padding: "10px 0px",
          color: "#4F5152",
        }}
      >
        {tn("Complete the contract information")}
      </Text>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "start",
          background: "#4f515217",
          borderRadius: "10px",
        }}
      >
        <div
          style={{
            background: "#21033F",
            borderRadius: "25px",
            width: "calc(100% - 20px)",
            height: "5px",
          }}
        />
        <Text
          style={{
            width: "calc(100% - 20px)",
            fontSize: "15px",
            fontWeight: "700",
            padding: "10px 0px",
            color: "#4F5152",
            margin: "5px 10px",
          }}
        >
          {tn(
            "To complete the training contract requirements, fill out the following information"
          )}
        </Text>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "start",
            padding: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <InputBox
              title={tn("ID Number")}
              width="calc(50% - 10px)"
              value={applicant_data?.ID_number}
            />
            <InputBox
              title={tn("Religion")}
              width="calc(50% - 10px)"
              value={applicant_data?.religion?.[locale]}
            />
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <InputBox
              title={tn("Date of birth")}
              width="calc(50% - 10px)"
              value={applicant_data?.date_of_birth}
            />
            <InputBox
              title={tn("Marital status")}
              width="calc(50% - 10px)"
              value={applicant_data?.marital_status?.[locale]}
            />
          </div>

          {debounced_data?.record?.incentive &&
            debounced_data?.record?.incentive > 0 && (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                {" "}
                <InputBox
                  title={tn("Bank name")}
                  width="calc(50% - 10px)"
                  value={applicant_data?.bank_name}
                />
                <InputBox
                  title={tn("IBAN number")}
                  width="calc(50% - 10px)"
                  value={applicant_data?.iban_number}
                />
              </div>
            )}
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <InputBox
              title={tn("National address")}
              width="calc(50% - 10px)"
              value={applicant_data?.national_address}
            />
            <div
              style={{
                width: "calc(50% - 10px)",
                display: "flex",
                alignItems: "center",
                margin: "5px 0px",
              }}
            >
              <Text
                style={{
                  minWidth: "110px",
                }}
              ></Text>
              <Button
                mx="10px"
                style={{
                  width: "100%",
                }}
                color="gray"
                size="compact-lg"
                onClick={() => {
                  setContractViewerData({
                    applicant: {
                      ...debounced_data?.record?.contract_data?.applicant,
                      religion:
                        debounced_data?.record?.contract_data?.applicant
                          ?.religion?.[locale],
                      marital_status:
                        debounced_data?.record?.contract_data?.applicant
                          ?.marital_status?.[locale],
                      gender:
                        debounced_data?.record?.contract_data?.applicant
                          ?.gender?.[locale],
                    },

                    company: {
                      national_address: companyData?.national_address,
                      HRSD_ID: companyData?.HRSD_ID,
                      tel: companyData?.tel,
                      recruiter_email: companyData?.recruiter_email,
                      job_title:
                        locale === "ar" ? "مدير الموارد البشرية" : "HR Manager",
                      recruiter_name: companyData?.recruiter_name,
                      approved_date: new Date().toLocaleDateString(),
                      logo: `${CMS_ENDPOINT}/api/files/${data?.record?.collectionName}/${data?.record?.id}/${companyData?.logo}?token=`,
                    },
                    program: {
                      programs_majors: debounced_data?.record?.programs_majors
                        ?.map((item: any) => item[locale])
                        .join(", "),
                      program_objective:
                        debounced_data?.record?.program_objective,
                      program_type_translated:
                        debounced_data?.record?.program_type?.[locale],
                      program_type: debounced_data?.record?.program_type,

                      program_location:
                        debounced_data?.record?.program_location,
                      date_of_commencement: new Date(
                        debounced_data?.record?.date_of_commencement
                      ).toLocaleDateString(),
                      incentive: debounced_data?.record?.incentive ?? "",
                    },
                  });
                  openNewTab();
                }}
              >
                {tn("Contract file")}
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          margin: "20px 0px",
          gap: "10px",
        }}
      >
       
        <Button
          style={{
            background: "#69DCBF",
          }}
          // radius was 0 here
          size="compact-lg"
          loading={buttonLoading1}
          onClick={async () => {
            setButtonLoading1(true);
            const record = await pb
              .collection("applications")
              .update(data?.record?.id, {
                contract_data: {
                  ...debounced_data?.record?.contract_data,
                  company: {
                    national_address: companyData?.national_address,
                    HRSD_ID: companyData?.HRSD_ID,
                    tel: companyData?.tel,
                    recruiter_email: companyData?.recruiter_email,
                    job_title: {
                      en: "HR Manager",
                      ar: "مدير الموارد البشرية",
                    },
                    recruiter_name: companyData?.recruiter_name,
                    approved_date: new Date().toLocaleDateString(),
                    logo: `${CMS_ENDPOINT}/api/files/${data?.record?.collectionName}/${data?.record?.id}/${companyData?.logo}?token=`,
                  },
                  program: {
                    programs_majors: debounced_data?.record?.programs_majors,
                    program_objective:
                      debounced_data?.record?.program_objective,
                    program_type_translated:
                      debounced_data?.record?.program_type,
                    program_type: debounced_data?.record?.program_type,

                    program_location: debounced_data?.record?.program_location,
                    date_of_commencement: new Date(
                      debounced_data?.record?.date_of_commencement
                    ).toLocaleDateString(),
                    incentive: debounced_data?.record?.incentive ?? "",
                  },
                },
                contract_status: {
                  en: "finished",
                  ar: "منتهي",
                },

                contract_applicant_status: {
                  en: "accepted",
                  ar: "مقبول",
                },
              })
              .then((res) => {
                setReloader(!reloader);
                setButtonLoading1(false);
                onClose();
              });
          }}
        >
          {tn("Upload and approve")}
        </Button>
        <Button
          style={{
            background: "#FFB004",
          }}
          // radius was 0 here
          size="compact-lg"
          loading={buttonLoading2}
          onClick={async () => {
            setCorrectionRequestContractApproval(debounced_data);
            onClose();
          }}
        >
          {tn("Correction request")}
        </Button>
        <Button
          style={{
            background: "#F9837C",
          }}
          // radius was 0 here
          type="submit"
          size="compact-lg"
          onClick={() => {
            onClose();
          }}
        >
          {tn("Cancel")}
        </Button>
      </div>
    </div>
  );
};
const InputBox = ({ title, value, width }: any) => {
  return (
    <div
      style={{
        width: width,
        display: "flex",
        alignItems: "center",
        margin: "5px 0px",
      }}
    >
      <Text
        style={{
          minWidth: "110px",
        }}
      >
        {title}
      </Text>
      <Text
        style={{
          width: "100%",
          margin: "0px 10px",
          background: "#ffffff",
          padding: "5px",
          borderRadius: "5px",
          color: "#4F5152",
          border: "1px solid #4F5152",
        }}
      >
        {value}
      </Text>
    </div>
  );
};
