import { useContext } from "react";
import { DataContext } from "../../../../../../context/DataContext";
import { ActionComponent } from "../../../ActionComponent";

export const CancelInvitation = (props: any) => {
  const { setCancelInvitation }: any = useContext(DataContext);

  const { data, action, table } = props;
  return (
    <ActionComponent
      data={data}
      action={action}
      table={table}
      setData={async () => {
        setCancelInvitation({
          id: data.id,
          record: data,
          action: action,
          table: table,
        });
      }}
      enabled={data?.status?.en == "waiting for the candidate"}
    />
  );
};

