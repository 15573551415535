import {
  Paper,
  createStyles,
  TextInput,
  PasswordInput,
  Checkbox,
  Button,
  Title,
  Text,
  Anchor,
  rem,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useForm } from "@mantine/form";
import { useContext, useState } from "react";
import { useViewportSize } from "@mantine/hooks";
import { AppContext } from "../../context/AppContext";
import { TranslationContext } from "../../context/TranslationContext";
import { tn } from "../../translation";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../constants";
import { UserContext } from "../../context/UserContext";
import { BannersContext } from "../../context/BannersContext";
import { IconCheck, IconX } from "@tabler/icons-react";
import { Helmet } from "react-helmet";

const useStyles = createStyles((theme): any => ({
  wrapper: {
    height: "100vh",
    display: "flex",
  },

  form: {
    zIndex: 1,
    background: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowY: "scroll",
    scrollbarWidth: "none",
    // justifyContent: 'center',
    height: "100vh",

    //
    maxHeight: "100vh",
    maxWidth: rem(700),
    paddingTop: rem(80),
    width: "100%",

    [theme.fn.smallerThan("md")]: {
      maxWidth: "100%",
    },
  },
  inner: {
    maxWidth: rem(500),
    width: "100%",
    padding: "0px 30px 30px 30px",
  },

  title: {
    color: "#21033F",
    fontSize: "40px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  },
}));
export function RecruiterLoginPage() {
  const pb: any = new PocketBase(CMS_ENDPOINT);

  const navigate = useNavigate();
  const [isError, setisError]: any = useState(false);
  const form: any = useForm({
    initialValues: {
      email: "",
      password: "",
    },

    validate: {
      // email: (value) =>
      //   value !== "" ? null : "please write your email or usename",
      // password: (value) => (value !== "" ? null : "please write your password"),
    },
  });

  const { classes } = useStyles();
  const { height, width } = useViewportSize();
  const { locale }: any = useContext(TranslationContext);
  const [buttonLoading, setButtonLoading]: any = useState(false);

  const divider = (
    <div
      style={{
        width: width < 500 ? "50%" : "1px",
        height: width < 500 ? "1px" : "15px",
        background: "white",
        margin: width < 500 ? "10px 0px" : "0px 5px",
      }}
    />
  );
  const { setTopBanner, setDialogBanner }: any = useContext(BannersContext);
  const errorMassage = tn("Invalid email or password");
  const [isValidatedError, setIsValidatedError] = useState(false);
  const errorValidatedMassage = tn("Please verify your email first");
  const dialogMassage = tn("Activation message sent");

  return (
    <>
    <Helmet>
    <title>{tn("Login") + " / " + tn("Coophub")}</title>
  </Helmet>
    <form
      onSubmit={form.onSubmit(async () => {
        // if(form.isValid()){
        try {
          const authData = await pb
            .collection("recruiters")
            .authWithPassword(
              form.values.email?.toLowerCase(),
              form.values.password,
              {},
              {
                expand: "company, permission",
              }
            )
            .then((res: any) => {
              setButtonLoading(false);
              if (res?.record.verified) {
                if (res.record?.expand?.company?.is_partial) {
                  navigate("/recruiter_partial/program_history");
                } else {
                  navigate("/recruiter/task_board");
                }
              } else {
                setIsValidatedError(true);
                pb.authStore.clear();
              }
            });
        } catch (err) {
          setisError(true);
          setButtonLoading(false);
        }
        // }
      })}
    >
      <div className={classes.wrapper}>
        <Paper className={classes.form} radius={0}>
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div
              style={{
                clipPath: "polygon(100% 0, 100% 100%, 13% 100%, 0 63%, 0 0)",
                background: "#21033F",
                height: "40px",
                width: "150px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text color="white" size="16px" fw="bold">
                {tn("Recruiters")}
              </Text>
            </div>
          </div>
          <div className={classes.inner}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "20px",
                flexDirection: width > 500 ? "row" : "column",
              }}
            >
              <Text className={classes.title}>{tn("you are welcome in")}</Text>
              <img style={{ height: "93px" }} src="/dark_text_logo.svg" />
            </div>
            <div
              style={{
                display: "flex",

                justifyContent: "center",
                alignItems: "center",
                marginTop: "30px",
              }}
            >
              <div
                style={{
                  // background:
                  //   "#21033F",
                  border: "1px solid #21033F",
                  // width: "208px",
                  width: "100%",
                  height: "60px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // color:  "white",
                  color: "#8c8c8c",
                  fontSize: "16px",
                  margin: width > 500 ? "0px 20px" : "0px 7px",
                  // cursor: "pointer",
                }}
              >
                {tn("Login")}
              </div>
              {/* <div
              style={{
                width: "208px",
                height: "60px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#8c8c8c",
                fontSize: "16px",
                margin: width > 500 ? "0px 20px" : "0px 7px",
                border:  "1px solid #21033F",
                cursor: "pointer",
              }}
              onClick={() => navigate("/recruiter/register")}
            >
            {tn("Create a new account")}
            </div> */}
            </div>
            <div>
              <TextInput
                {...form.getInputProps("email")}
                required
                mt={90}
                variant="filled"
                styles={(theme) => ({
                  label: {
                    color: "#21033F",
                  },
                  input: {
                    borderRadius: "0px",
                    color: "#21033F",
                    backgroundColor: "transparent",
                    borderBottom: "1px solid #21033F86",
                    "&:focus-within": {
                      border: "0px solid #21033F",
                      borderBottom: "1px solid #21033F",
                    },
                  },
                })}
                label={tn("Email Address")}
                placeholder="hello@coophub.co"
                size="md"
              />
              <PasswordInput
                {...form.getInputProps("password")}
                required
                variant="filled"
                styles={(theme) => ({
                  label: {
                    color: "#21033F",
                  },
                  input: {
                    borderRadius: "0px",
                    color: "#21033F",
                    backgroundColor: "transparent",
                    borderBottom: "1px solid #21033F9a",
                    "&:focus-within": {
                      border: "0px solid #21033F",
                      borderBottom: "1px solid #21033F",
                    },
                  },
                  innerInput: {
                    color: "#21033F",
                  },
                })}
                label={tn("Password")}
                placeholder={tn("Your password")}
                mt={50}
                size="md"
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "30px",
                }}
              >
                <Checkbox
                  defaultChecked={true}
                  label={tn("Remember me")}
                  mt="xl"
                  size="md"
                  styles={() => ({
                    label: { cursor: "pointer", color: "#21033F" },
                    input: {
                      cursor: "pointer",
                      "&:checked": {
                        backgroundColor: "#21033F",
                        border: "0px solid #21033F",
                      },
                    },
                  })}
                />
                <Anchor<"a">
                  color="#FFB004"
                  href="#"
                  weight={700}
                  onClick={() => navigate("/recruiter/forgot_password")}
                >
                  {tn("Forgot Password?")}
                </Anchor>
              </div>
              <Button
                loading={buttonLoading}
                onClick={() => {
                  if (form?.values?.email && form?.values?.password) {
                    setButtonLoading(true);
                  }
                }}
                type="submit"
                style={{
                  borderRadius: "5.112px",
                  height: "70px",
                  backgroundColor: "#21033F",
                  fontSize: "16px",
                }}
                fullWidth
                mt="xl"
                size="md"
              >
                {tn("Login")}
              </Button>
              {!buttonLoading && isError && (
                <Text mt="md" size="13px" color="red">
                  {errorMassage}
                </Text>
              )}
              {!buttonLoading && isValidatedError && (
                <Text mt="md" size="13px" color="red">
                  {errorValidatedMassage}
                  {"  "}
                  <span>
                    <Anchor
                      onClick={async () => {
                        try {
                          await pb
                            .collection("recruiters")
                            .requestVerification(form.values.email)
                            .then(() => {
                              setDialogBanner({
                                message: dialogMassage,
                                color: "green",
                                backgroundColor: "#9be1d0",
                                icon: (
                                  <IconCheck
                                    style={{
                                      margin: "0px 10px",
                                    }}
                                  />
                                ),
                              });
                            });
                        } catch (err) {
                          setDialogBanner({
                            message: "Something went wrong",
                            color: "red",
                            backgroundColor: "#e19b9b",
                            icon: (
                              <IconX
                                style={{
                                  margin: "0px 10px",
                                }}
                              />
                            ),
                          });
                        }
                      }}
                      color="#ff9900"
                    >
                      {tn("Click here to send the verification link")}
                    </Anchor>
                  </span>
                </Text>
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",

                margin: "70px 5px 0px 5px",
                width: "100%",
                maxWidth: "500px",
                flexDirection: width > 500 ? "row" : "column",
              }}
            >
              <Text color="#21033F" weight={700}>
                {tn("terms and conditions")}
              </Text>{" "}
              {divider}
              <Text color="#21033F" weight={700}>
                {tn("Usage policy")}
              </Text>{" "}
              {divider}
              <Text color="#21033F" weight={700}>
                {tn("All rights are save")}
              </Text>{" "}
            </div>
          </div>
        </Paper>

        <div
          style={{
            height: "100%",
            width: `calc(100vw - 700px)`,
            backgroundImage: "url(/shapes/hole_image.svg)",
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "absolute",
            left: locale == "en" ? "1" : "0",
            right: locale == "en" ? "0" : "1",
            filter: "grayscale(100%)",
          }}
        ></div>
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: `100%`,
            background:
              "linear-gradient(127deg, rgba(33,3,63,0.0970982142857143) 0%, rgba(33,3,63,0.6657256652661064) 100%)",
          }}
        >
          {/* <div
            style={{
              height: "100%",
              width: "100%",
              backgroundImage: "url(/shapes/white.svg)",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              display: "flex",
              justifyContent: "center",
              alignItems: "start",
              fontSize: "40px",
              color: "white",
              fontWeight: 700,
              textAlign: "left",
              padding: "270px 0px 0px 0px",
            }}
          >
           
          </div> */}
        </div>
      </div>
    </form>
    </>
  );
}

export default RecruiterLoginPage;
