import { useContext } from "react";
import { DataContext } from "../../../../../context/DataContext";
import { ActionComponent } from "../../ActionComponent";

export const PublishPartialProgram = (props: any) => {
  const { setPublishPartialProgram }: any = useContext(DataContext);

  const { data, action , table } = props;
  if(data?.partial_status?.en == "published" ){
    return <></>
  }
  return (
    <ActionComponent
    data={data}
    action={action}
    table={table}
    setData={async () => {
      setPublishPartialProgram({
        id: data.id,
        record: data,
        action: action,
        table: table,
      });
      
    }}
    enabled={data?.completion_letter}
  />
  );
};
