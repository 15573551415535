import { ActionIcon, Button, Loader, Text } from "@mantine/core";
import { tn } from "../../../../../../../../translation";
import { useEffect, useState } from "react";
import { pb } from "../../../../../../../../config";
import { FiltrationForm } from "./FiltrationForm";
import { FiltrationView } from "./FiltrationView";
import { styled } from "styled-components";
import { IconX } from "@tabler/icons-react";
import { random, set } from "lodash";
import _ from "lodash";
import { nanoid } from 'nanoid';

export const FiltrationTest = ({
  form,
  locale,
  translation,
  company,
  setFiltrationTestModal,
}: any) => {
  const [filtration_tests, setFiltration_tests] = useState([]);
  const [currentModel, setCurrentModel] = useState(form.values.filtration_test ?? "");
  const [isEdit, setIsEdit] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    // Set loader to true at the start of data fetching
  
    const fetchRecords = async () => {
      setLoading(true);

      try {
        const records = await pb.collection("filtration_tests").getFullList({ sort: "created" });
        setFiltration_tests(records);
        setLoading(false);

      } catch (error) {
        console.error("Failed to fetch filtration tests:", error);
        // Handle error if needed
      } finally {
        // Set loader to false after fetching is complete or if there's an error
        // setLoading(false);
      }
    };
  
    fetchRecords();
  }, [loader]); 
  if(loading) {return <div
    style={{
      background: "white",
      width: "100%",
      height: "100%",
      marginTop: "9px",
      borderRadius: "5px",
      // paddingTop: "30px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      padding: "10px",
      minHeight: "620px",
    }}
  >
    <Loader />
  </div>}
  return (
    <div
      style={{
        background: "white",
        width: "100%",
        height: "100%",
        marginTop: "9px",
        borderRadius: "5px",
        // paddingTop: "30px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "start",
        padding: "10px",
        minHeight: "600px",
      }}
    >
      <Text
        style={{
          color: "rgba(0, 0, 0, 0.70)",
          fontSize: "18px",
          fontWeight: "800",
          width: "100%",
          padding: "0px 20px",
          marginBottom: "10px",
        }}
      >
        {tn("Choose a filter test template or create one")}
      </Text>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          gap: "10px",
          width: "100%",
          padding: "0px 20px",
        }}
      >
        <Text
          style={{
            color: "rgba(0, 0, 0, 0.70)",
            fontSize: "15px",
            fontWeight: "500",
          }}
        >
          {tn("Models:")}
        </Text>
        <ScrollableDiv
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            gap: "10px",
            width: "100%",
            padding: "0px 20px",
            overflowX: "scroll",
          }}
        >
          {filtration_tests?.[0] ? (
            filtration_tests?.map((item: any) => {
              return (
                <div
                  style={{
                    backgroundColor:
                      item.id == currentModel ? "#21033F" : "#fff",
                    borderRadius: "10px",
                    color: item.id == currentModel ? "#fff" : "#21033F",
                    fontSize: "15px",
                    padding: "5px 15px",
                    whiteSpace: "nowrap",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                  }}
                  onClick={() => {
                    setCurrentModel(item.id);
                    setIsEdit(false);
                  }}
                >
                  <Text> {item?.name}</Text>
                </div>
              );
            })
          ) : (
            <></>
          )}
          <div
            style={{
              backgroundColor: "#FFF",
              borderRadius: "10px",
              color: "#21033F",
              fontSize: "15px",
              padding: "5px 15px",
              gap: "5px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              whiteSpace: "nowrap",
            }}
            onClick={async () => {
              const record = await pb.collection("filtration_tests").create({
                name:
                  locale === "en"
                    ? "Model " + (filtration_tests?.length + 1)
                    : "نموذج " + (filtration_tests?.length + 1),
                company: company?.id,
                minimum_to_pass: "70",
                duration: "15",
                questions: [
                  {
                    question:
                      locale === "en"
                        ? "Write the question here"
                        : "اكتب السؤال هنا",
                        id:nanoid(),
                    answers: [
                      {
                        answer:
                          locale === "en"
                            ? "Write the answer here"
                            : "اكتب الاجابة هنا",
                        correct: true,
                        id:nanoid()
                      },
                      {
                        answer:
                          locale === "en"
                            ? "Write the answer here"
                            : "اكتب الاجابة هنا",
                        correct: false,
                        id:nanoid()
                      },
                    ],
                  },
                ],
              });
              setCurrentModel(record.id);
              setLoader(!loader);
              setIsEdit(true);
            }}
          >
            {ADD_TEMPLATE}
            <Text>{tn("New model")}</Text>
          </div>
        </ScrollableDiv>
      </div>
      {filtration_tests?.[0] ? (
        filtration_tests?.map((item: any) => {
          if (currentModel == item?.id) {
            return isEdit ? (
              <FiltrationForm
                data={item}
                setIsEdit={setIsEdit}
                setCurrentModel={setCurrentModel}
                filtration_tests={filtration_tests}
                currentModel={currentModel}
                setLoader={setLoader}
                setFiltration_tests={setFiltration_tests}
              />
            ) : (
              <FiltrationView
                data={item}
                setIsEdit={setIsEdit}
                setCurrentModel={setCurrentModel}
                filtration_tests={filtration_tests}
                currentModel={currentModel}
                setLoader={setLoader}
              />
            );
          }
        })
      ) : (
        <></>
      )}
      {!currentModel && (
        <div
          style={{
            width: "100%",
            background: "white",
            borderRadius: "5px",
            padding: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "503px",
          }}
        >
          <Text>{tn("Choose a filter test template or create one")}</Text>
        </div>
      )}
       <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          padding: "0px 10px",
          gap: "10px",
          margin: "10px 0px",
        }}
      >

        <Button
         disabled={!currentModel}
          compact
          style={{
            backgroundColor: currentModel ?"#69DCBF":"",
            color: currentModel?"white":"",
            width: "100px",
            borderRadius: "5.112px",
          }}
          onClick={async () => {
            form.setFieldValue("filtration_test", currentModel);
            setFiltrationTestModal(false);
          }}
        >
          {tn("Choose")}
        </Button>
        <Button
          // mx="xs"
          compact
          style={{
            backgroundColor: "#F9837C",
            color: "white",
            width: "100px",
            borderRadius: "5.112px",
          }}
          onClick={() => {
            setFiltrationTestModal(false);
          }}
        >
          {tn("Cancel")}
        </Button>
      </div>
    </div>
  );
};

const ADD_TEMPLATE = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
  >
    <path
      d="M7.5 1.40625C6.29477 1.40625 5.11661 1.76364 4.1145 2.43323C3.11238 3.10282 2.33133 4.05454 1.87011 5.16802C1.40889 6.28151 1.28821 7.50676 1.52334 8.68883C1.75847 9.8709 2.33884 10.9567 3.19107 11.8089C4.0433 12.6612 5.1291 13.2415 6.31117 13.4767C7.49324 13.7118 8.71849 13.5911 9.83198 13.1299C10.9455 12.6687 11.8972 11.8876 12.5668 10.8855C13.2364 9.88339 13.5938 8.70523 13.5938 7.5C13.5917 5.88446 12.9491 4.33566 11.8067 3.19329C10.6643 2.05093 9.11555 1.40827 7.5 1.40625ZM9.84375 7.96875H7.96875V9.84375C7.96875 9.96807 7.91937 10.0873 7.83146 10.1752C7.74355 10.2631 7.62432 10.3125 7.5 10.3125C7.37568 10.3125 7.25645 10.2631 7.16855 10.1752C7.08064 10.0873 7.03125 9.96807 7.03125 9.84375V7.96875H5.15625C5.03193 7.96875 4.9127 7.91936 4.8248 7.83146C4.73689 7.74355 4.6875 7.62432 4.6875 7.5C4.6875 7.37568 4.73689 7.25645 4.8248 7.16854C4.9127 7.08064 5.03193 7.03125 5.15625 7.03125H7.03125V5.15625C7.03125 5.03193 7.08064 4.9127 7.16855 4.82479C7.25645 4.73689 7.37568 4.6875 7.5 4.6875C7.62432 4.6875 7.74355 4.73689 7.83146 4.82479C7.91937 4.9127 7.96875 5.03193 7.96875 5.15625V7.03125H9.84375C9.96807 7.03125 10.0873 7.08064 10.1752 7.16854C10.2631 7.25645 10.3125 7.37568 10.3125 7.5C10.3125 7.62432 10.2631 7.74355 10.1752 7.83146C10.0873 7.91936 9.96807 7.96875 9.84375 7.96875Z"
      fill={"#21033F"}
    />
  </svg>
);
const ScrollableDiv = styled.div`
  scrollbar-width: none; /* For Firefox */
  &::-webkit-scrollbar {
    display: none; /* For WebKit browsers like Chrome and Safari */
  }
`;
