import { TranslationContext } from "../../../../../../context/TranslationContext";
import { useContext } from "react";
import { DataContext } from "../../../../../../context/DataContext";
import { ActionComponent } from "../../../ActionComponent";

export const CorrectionRequest = (props: any) => {
  const { setCorrectionRequest }: any = useContext(DataContext);
  
  const { data, action , table } = props;
  return (
    <ActionComponent
    data={data}
    action={action}
    table={table}
    setData={async () => {
      setCorrectionRequest({
        id: data.id,
        record: data,
        action: action,
        table: table,
      });
    }}
    enabled={data?.document_status?.en != "accepted"}
  />
);
};
