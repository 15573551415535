
import {ActionIcon, Popover, Text} from "@mantine/core"
import { IconBell } from "@tabler/icons-react"
import { tn } from "../../translation"
import { NotiBox } from "./NotiBox"
import { useContext, useEffect, useState } from "react"
import { TranslationContext } from "../../context/TranslationContext"
import { pb } from "../../config"
import { CMS_ENDPOINT } from "../../constants"
import dayjs from "dayjs"


export const NotiPopup =  ({width = 400}) => {
    const {locale} = useContext(TranslationContext);
    const [notifications, setNotifications] = useState([])
          useEffect(() => {
            const getNotifications = async () => {
              const records = await pb.collection('notifications_view').getFullList({
                sort: '-created',
            });
            setNotifications(records);
            };
            getNotifications();
          }, []);

console.log("recordsrecordsrecords",notifications);
    return  <Popover width={width} position="bottom" withArrow shadow="md">
    <Popover.Target>
      <ActionIcon mx="md">
        <IconBell />
      </ActionIcon>
    </Popover.Target>
    <Popover.Dropdown
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "start",
        gap: "10px",
        maxHeight: "400px",
        overflowY: "scroll",
        transform: `translate(${locale == "ar" ? "10px" : "-10px"}, 0px)`,
      }}
    >
      <Text
        style={{
          width: "100%",
          backgroundColor: "#f3ec78",
          backgroundImage:
            "linear-gradient(113deg, rgba(105,220,191,1) 0%, rgba(222,140,134,1) 19%, rgba(33,3,63,1) 56%)",
          WebkitBackgroundClip: "text",
          MozBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          textAlign: "right",
          fontSize: "22px",
          fontStyle: "normal",
          fontWeight: "700",
          marginBottom: "10px",
          // lineHeight: "95%",
        }}
      >
        {tn("Notifications")}
      </Text>
     {notifications?.[0]? notifications?.map((notification:any)=>{
        return <NotiBox 
        text={replaceWordsWithValues(notification?.[locale], notification)}
        image={notification?.company_logo ?`${CMS_ENDPOINT}/api/files/${notification?.collectionId}/${notification?.id}/${notification?.company_logo}?token=`:null}
        date={dayjs(notification?.created).format("D/MM/YY")}
        time={dayjs(notification?.created).format("HH:mm")}
        path={notification?.path}

        />
     }):"No Notifications"}
     
    </Popover.Dropdown>
    </Popover>
}





function replaceWordsWithValues(text, json) {
  // Regular expression to match words within curly braces
  const regex = /{([^}]+)}/g;

  // Replace each word within curly braces with its value from the JSON object
  const replacedText = text.replace(regex, (match, key) => {
      const value = json[key.trim()];
      return value !== undefined ? value : match;
  });

  return replacedText;
}
