import { ltn, tn } from "../../../../../../translation";
import { Button, List, Text, Textarea } from "@mantine/core";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../../../../../constants";
import { useContext, useState } from "react";
import { AppContext } from "../../../../../../context/AppContext";
import { useDebouncedValue, useMediaQuery } from "@mantine/hooks";
import { TranslationContext } from "../../../../../../context/TranslationContext";

export const WithdrawalRequest = (props: any) => {
  const pb: any = new PocketBase(CMS_ENDPOINT);
  const { data, onClose } = props;
  const [debounced_data] = useDebouncedValue(data, 200);

  const { reloader, setReloader } = useContext(AppContext);
  const { translation, locale }: any = useContext(TranslationContext);
  const [reason, setReason] = useState("")
  const isMobile = useMediaQuery("(max-width: 550px)");

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "start",
        padding: "20px",
        width: "100%",
      }}
    >
      <Text fw={600} color="#5c5c5c">
        {locale == "ar"
          ? `هل أنت واثق من رغبتك في سحب طلب التقديم على ${debounced_data?.record?.expand?.program?.name} من ${debounced_data?.record?.company_name}؟`
          : `Are you sure you want to withdraw your application for ${debounced_data?.record?.expand?.program?.name} from ${debounced_data?.record?.company_name}?`}
      </Text>
      <Textarea
      value={reason}
      onChange={(e) => setReason(e.currentTarget.value)}
       styles={(error: any) => ({
  description: {
    marginBottom: "-17px",
    transform: "translate(5px, 35px)",
    fontSize: "8px",
    opacity: error ? "0" : "1",
  },
  label: {
    zIndex: "10",
    position: "relative",
    margin: "0px 10px",
    transform: "translate(0px, 7px)",
    background: "#f6f6f6",
    borderRadius: "5px",
    padding: "0px 5px",
    fontSize: "10px",
  },
  input: {
    borderRadius: "5px",
    border: "1px solid #000000",
    height: "100px",
  },
  error: {
    marginBottom: "-17px",
    transform: "translate(4px, 3px)",
    // fontSize: "8px",
  },
})} label={tn("Reasons for withdrawal")} mt = "xl" style={{
        width: "100%",
      }}/>
      <List style={{
        margin:"20px 10px",
        color:"#F9837C",
        fontWeight:600,
        fontSize:"12px"
      }}>
        <List.Item>
         {locale == "ar"
          ? `لن يكون بإمكانك التقديم على هذا البرنامج مرة أخرى.`
          : "You will not be able to apply to this program again."}
          
        </List.Item>
        <List.Item>
       {locale == "ar"
            ? `يقلل طلب الانسحاب من فرصك في الحصول على فرص تدريبية اخرى.`
            : "A withdrawal request reduces your chances of obtaining other training opportunities."}
        </List.Item>
        <List.Item>
       {locale == "ar"
            ? `لن يعتبر طلب الانسحاب مكتمل حتى تتم الموافقة عليه من قبل جهة التدريب.`
            : "The withdrawal request will not be considered complete until it is approved by the training body."}
        </List.Item>
      </List>
      {/* <Text style={{
        margin:"20px 10px",
        color:"#F9837C",
        fontWeight:600,
        fontSize:"14px"
      }}>{ltn("withdraw_note")}</Text> */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: isMobile?"center":"end",
          marginTop: "20px",
          width: "100%",
        }}
      >
        <Button
        disabled={!reason}
          // radius was 0 here
          compact={!isMobile}
          radius={isMobile ? "md" : ""}
          size={isMobile ? "md" : ""}
          style={{
            minWidth: "80px",
            backgroundColor: reason?"#F9837C":"",
          }}
          mx={5}
          onClick={
            debounced_data?.record?.[0]
              ? () => {
                  debounced_data?.record?.forEach(async (record: any) => {
                    await pb
                      .collection(debounced_data?.table?.table)
                      .update(record, { 
                        main_status: { 	
                          en: "withdrawal request",
                          ar: "طلب انسحاب",
                        },
                        applicant_status: { 	
                          en: "withdrawal request",
                          ar: "طلب انسحاب",
                        },
                        withdrawal_history:{
                          main_status: debounced_data?.record?.main_status,
                          applicant_status: debounced_data?.record?.applicant_status,
                        },
                        withdrawal_stage: debounced_data?.record?.main_status.en == "document review"?{
                          en:"upload documents",
                          ar:"رفع المستندات"
                        }:(debounced_data?.record?.main_status.en == "accepted"&& new Date(debounced_data?.record?.date_of_commencement) > new Date())?{
                          en:"program not started",
                          ar:"لم يبدأ البرنامج",
                        }:{
                          en:"program in progress",
                          ar:"برنامج جاري"
                        },
                        withdrawal_reason:reason
                        
                      });
                  });
                  onClose({});
                  setReloader(!reloader);
                }
              : async () => {
                  await pb
                    .collection(debounced_data?.table?.table)
                    .update(debounced_data?.record?.id, {
                      main_status: { 	
                        en: "withdrawal request",
                        ar: "طلب انسحاب",
                      },
                      applicant_status: { 	
                        en: "withdrawal request",
                        ar: "طلب انسحاب",
                      },
                      withdrawal_history:{
                        main_status: debounced_data?.record?.main_status,
                        applicant_status: debounced_data?.record?.applicant_status,
                      },
                      withdrawal_stage: debounced_data?.record?.main_status.en == "document review"?{
                        en:"upload documents",
                        ar:"رفع المستندات"
                      }:(debounced_data?.record?.main_status.en == "accepted"&& new Date(debounced_data?.record?.date_of_commencement) > new Date())?{
                        en:"program not started",
                        ar:"لم يبدأ البرنامج",
                      }:{
                        en:"program in progress",
                        ar:"برنامج جاري"
                      },
                      withdrawal_reason:reason
                    })?.then((res:any)=>{
                      onClose({});
                      setReloader(!reloader);
                    });
                 
                }
          }
        >
          {tn("Submit a withdrawal request")}
        </Button>
        <Button
          // radius was 0 here
          compact={!isMobile}
          radius={isMobile ? "md" : ""}
          size={isMobile ? "md" : ""}
          style={{
            minWidth: "80px",
            backgroundColor: "#69DCBF",
          }}
          mx={5}
          onClick={() => onClose({})}
        >
          {tn("Cancel")}
        </Button>
      </div>
    </div>
  );
};
