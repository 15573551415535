import { useContext, useEffect, useState } from "react";
import { SystemContext } from "../../context/SystemContext";
import { TranslationContext } from "../../context/TranslationContext";
import {
  ActionIcon,
  Button,
  HoverCard,
  Loader,
  Modal,
  MultiSelect,
  Select,
  Text,
  TextInput,
} from "@mantine/core";
import * as XLSX from "xlsx";
import { tn } from "../../translation";
import { IconSearch } from "@tabler/icons-react";
import alasql from "alasql";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../constants";
import { useDebouncedValue } from "@mantine/hooks";
import { useForm } from "@mantine/form";
import { Action } from "./components/ActionsMap";
import { AppContext } from "../../context/AppContext";
import { DataContext } from "../../context/DataContext";
import { ClickableActionsMap } from "./components/ClickableActionsMap";
import dayjs from "dayjs";
import { MobileCard } from "./components/MobileCard";

const pb: any = new PocketBase(CMS_ENDPOINT);

export function DataGridMobile({ name }: any) {
  const { systemData }: any = useContext(SystemContext);
  const { locale, translation }: any = useContext(TranslationContext);
  const { reloader }: any = useContext(AppContext);

  const { setAccountAdd }: any = useContext(DataContext);
  const [data, setData]: any = useState("loading");
  const [totalItems, setTotalItems]: any = useState(0);
  const [pagenation, setPagenation]: any = useState(1);
  const [loader]: any = useState(false);
  const [mainChecked, setMainChecked]: any = useState(false);
  const [largeSetData, setLargeSetData]: any = useState([]);
  const [filtersModal, setFiltersModal] = useState(false);
  const table = systemData?.tables?.find((t: any) => t.name === name);
  if (!table) return;
  <></>;
  const actions = alasql(`select * from ? ORDER BY order_by`, [
    systemData?.actions,
  ])?.filter((a: any) =>
    table?.actions?.map((a: any) => a?.name)?.includes(a?.name)
  );

  const columns = systemData?.columns?.filter(
    (c: any) => c.table_name === name
  );
  const filtered_columns = columns?.[0]
    ? alasql(`select * from ? where enable = true ORDER BY order_list`, [
        columns,
      ])
    : [];

  const expanded_columns = filtered_columns?.[0]
    ? alasql(
        `select * from ? where relation_table IS NOT NULL GROUP BY relation_table`,
        [filtered_columns ?? []]
      )
    : [];
  const [sortStatus, setSortStatus]: any = useState({
    columnAccessor: "",
    direction: "",
  });
  function generatePocketBaseFilter(json: any) {
    const filterParts: any = [];

    Object.entries(json).forEach(
      ([key, { type, value, table, relation_table }]: any) => {
        // Replace '—' with '.' to separate table and column
        const tableColumn = key.replace("--", ".");

        switch (type) {
          case "search":
            if (value) {
              filterParts.push(`${tableColumn} ~ '${value.trim()}'`); // PocketBase wraps the string with "%" automatically
            }
            break;
          case "multi_select":
          case "select":
            if (Array.isArray(value)) {
              if (value.length > 0) {
                // Create a series of OR conditions for English and Arabic values
                const orConditionsEn = value
                  .map((v) => `${tableColumn}.en = '${v.en}'`)
                  .join(" || ");
                const orConditionsAr = value
                  .map((v) => `${tableColumn}.ar = '${v.ar}'`)
                  .join(" || ");
                filterParts.push(`(${orConditionsEn}) || (${orConditionsAr})`);
              }
            } else {
              if (value) {
                filterParts.push(
                  `${tableColumn}.en = '${value.en}' || ${tableColumn}.ar = '${value.ar}'`
                );
              }
            }
            break;
          case "multi_select_data":
            if (value?.length > 0) {
              const orConditions = value
                .map(
                  (column_value: any) => `${tableColumn} = '${column_value}'`
                )
                .join(" || ");
              filterParts.push(`(${orConditions})`);
            }

            break;
          default:
            break;
        }
      }
    );

    return filterParts.join(" && "); // Use '&&' as per PocketBase documentation for AND
  }

  // Example usage:
  const jsonFilterObject = {
    // ... your JSON structure here ...
  };

  const filterQuery = generatePocketBaseFilter(jsonFilterObject);
  const form = useForm();

  function FetchData(isPagenation: any, onFetch: any) {
    const resultList = pb
      .collection(table?.view_table)
      .getList(isPagenation ? pagenation : 1, isPagenation ? 10 : 1000000, {
        filter:
          (table.filtered_columns && generatePocketBaseFilter(form?.values)
            ? "("
            : "") +
          generatePocketBaseFilter(form?.values) +
          (table.filtered_columns && generatePocketBaseFilter(form?.values)
            ? ") &&"
            : "") +
          (table.filtered_columns ? " " + table.filtered_columns : ""),
        sort: sortStatus?.columnAccessor
          ? `${
              sortStatus?.direction == "desc" ? "-" : ""
            }${sortStatus?.columnAccessor?.replace("--", ".")}`
          : table?.sorted_columns
          ? table?.sorted_columns
          : `-created`,
        expand: expanded_columns?.map((item: any) => item?.relation_table),
      })
      .then(onFetch);

    return () => {};
  }

  const [debounced_form] = useDebouncedValue(form?.values, 200);

  useEffect(() => {
    FetchData(true, (records: any) => {
      setData(records?.items);
      setTotalItems(records?.totalItems);
      setLargeSetData(records?.items);
    });
  }, [debounced_form, pagenation, sortStatus, loader, reloader]);

  const handleDownload = async () => {
    const columns = systemData?.excel_templates?.filter(
      (c: any) => c.table_name === name
    );

    try {
      const records = await pb.collection(table?.view_table).getFullList(
        table?.filtered_columns
          ? {
              sort: "created",
              filter: table?.filtered_columns,
            }
          : {
              sort: "created",
            }
      );

      // Map over the records to create the excelData array
      const excelData = records.map((item: any) => {
        // Create an object for each record where the key is the column's label_en
        // and the value is the item's value for the column name
        return columns.reduce((obj: any, column: any) => {
          if (column.translatable) {
            obj[column?.[`label_${locale}`]] = item[column.name]?.[locale];
            return obj;
          } else {
            obj[column?.[`label_${locale}`]] = item[column.name];
            return obj;
          }
        }, {});
      });

      // Proceed with creating the Excel file using the processed excelData
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(excelData);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "data.xlsx");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  console.log("table", filtered_columns);
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        maxWidth: "1300px",
        height: "100%",
        display: "flex",
        alignItems: "start",
        justifyContent: "start",
        flexDirection: "column",
      }}
    >
      <div style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        padding: "0px 10px",
      }}>
 <Text
        style={
          table?.title_styled
            ? {
                backgroundColor: "#f3ec78",
                backgroundImage:
                  "linear-gradient(113deg, #376a5d 0%, #784d4a 19%, rgba(33,3,63,1) 56%)",
                WebkitBackgroundClip: "text",
                MozBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                textAlign: "right",
                fontSize: "17px",
                fontStyle: "normal",
                fontWeight: "700",
                // lineHeight: "95%",
                // height: "60px",
              }
            : {
                color: "#737373",
                textAlign: "center",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "normal",
              }
        }
      >
        {table?.[`title_${locale}`]}
      </Text>
      <div style={{
        display:"flex",
        gap:"10px"
      }}>
        {/* check if filters are empty and if not, show clear button*/}
        {   form?.values && Object.keys(form?.values).length > 0 && 
         <Button
    color="color3"
    size="xs"
    variant="outline"
     style={{
      height: "28px",
      borderRadius: "5.112px",
      padding: "5px 10px",
      fontSize: "12px",
      fontWeight: "700",
    }} onClick={()=>{
      form.reset();
    
    }} >
      {tn("Clear filters")}
    </Button>}
      <ActionIcon onClick={()=>{
        setFiltersModal(true)
      }} variant="outline" aria-label="filter" color="color3">
      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="17" viewBox="0 0 15 17" fill="none">
  <path d="M10.125 15.395C10.16 15.6575 10.0725 15.9375 9.87124 16.1212C9.79029 16.2024 9.69414 16.2667 9.58829 16.3106C9.48244 16.3545 9.36897 16.3771 9.25437 16.3771C9.13977 16.3771 9.0263 16.3545 8.92045 16.3106C8.81459 16.2667 8.71844 16.2024 8.63749 16.1212L5.12874 12.6125C5.03331 12.5192 4.96075 12.4051 4.91673 12.2791C4.87271 12.1531 4.85842 12.0187 4.87499 11.8862V7.40625L0.683743 2.0425C0.54165 1.86009 0.477535 1.62885 0.505407 1.39931C0.533279 1.16978 0.650873 0.960603 0.832493 0.8175C0.998743 0.695 1.18249 0.625 1.37499 0.625H13.625C13.8175 0.625 14.0012 0.695 14.1675 0.8175C14.3491 0.960603 14.4667 1.16978 14.4946 1.39931C14.5225 1.62885 14.4583 1.86009 14.3162 2.0425L10.125 7.40625V15.395ZM3.15999 2.375L6.62499 6.8025V11.6325L8.37499 13.3825V6.79375L11.84 2.375H3.15999Z" fill="#FFB004"/>
</svg>

    </ActionIcon>
   
      </div>
     
      </div>
     
      {table?.excel && (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            style={{
              backgroundColor: "#69DCBF",
              borderRadius: "5.112px",
              marginTop: "10px",
              marginLeft: "50px",
            }}
            onClick={handleDownload}
          >
            {tn("Export to Excel")}
          </Button>
        </div>
      )}

      <div
        // ref={ref}
        style={{
          width: "calc(100% )",
          // margin: `${!table?.action_bar ? "30px" : "0px"} 50px`,
          height: "calc(100%)",
          maxHeight: "calc(100% - 75px)",
        }}
      >
        {table?.action_bar && (
          <ControlBar
            data={data}
            form={form}
            table={table}
            setAccountAdd={setAccountAdd}
          />
        )}
        <Text
          mx={20}
          my={30}
          style={{
            color: "#2e2e2e",
            textAlign: "right",
            fontSize: "16px",
            fontWeight: "700",
          }}
        >
          {table?.["description_" + locale]}
        </Text>
        {data != "loading" ? (
          <div
            style={{
              position: "relative",
              overflowY: "scroll"
            }}
          >
            {" "}
            {data?.length > 0 ? (
              [...data]?.map((item: any, i: any) => {
                const main_column = filtered_columns?.find(
                  (column) => column?.mobile_view?.name == "main"
                );
                const main_after_expand = main_column?.relation_table
                  ? item?.expand?.[main_column?.relation_table]
                  : item;
                return (
                  <MobileCard
                    main_text={
                      main_column?.translatable
                        ? main_after_expand?.[main_column.name]?.[locale]
                        : main_after_expand?.[main_column.name]
                    }
                    primary_texts={
                      filtered_columns?.[0]
                        ? filtered_columns
                            ?.filter(
                              (column) => column?.mobile_view?.name == "primary"
                            )
                            ?.map((column) => {
                              const data_after_expand = column?.relation_table
                                ? item?.expand?.[column?.relation_table]
                                : item;
                              if (column?.translatable) {
                                return {
                                  name: column?.[`title_${locale}`],
                                  value: (
                                    <RenderCell
                                      item={
                                        data_after_expand?.[column.name]?.[
                                          locale
                                        ]
                                      }
                                      itemFull={
                                        data_after_expand?.[column.name]
                                      }
                                      data={data_after_expand}
                                      column={column}
                                      i={i}
                                      locale={locale}
                                      translation={translation}
                                      table={table}
                                    />
                                  ),
                                };
                              }
                              return {
                                name: column?.[`title_${locale}`],
                                value: (
                                  <RenderCell
                                    item={data_after_expand?.[column.name]}
                                    itemFull={data_after_expand?.[column.name]}
                                    data={data_after_expand}
                                    column={column}
                                    i={i}
                                    locale={locale}
                                    translation={translation}
                                    table={table}
                                  />
                                ),
                              };
                            })
                        : []
                    }
                    secondary_texts={
                      filtered_columns?.[0]
                        ? filtered_columns?.map((column) => {
                            const data_after_expand = column?.relation_table
                              ? item?.expand?.[column?.relation_table]
                              : item;
                            if (column?.translatable) {
                              return {
                                name: column?.[`title_${locale}`],
                                value: (
                                  <RenderCell
                                    item={
                                      data_after_expand?.[column.name]?.[locale]
                                    }
                                    itemFull={data_after_expand?.[column.name]}
                                    data={data_after_expand}
                                    column={column}
                                    i={i}
                                    locale={locale}
                                    translation={translation}
                                    table={table}
                                  />
                                ),
                              };
                            }
                            return {
                              name: column?.[`title_${locale}`],
                              value: (
                                <RenderCell
                                  item={data_after_expand?.[column.name]}
                                  itemFull={data_after_expand?.[column.name]}
                                  data={data_after_expand}
                                  column={column}
                                  i={i}
                                  locale={locale}
                                  translation={translation}
                                  table={table}
                                />
                              ),
                            };
                          })
                        : []
                    }
                    actions={
                      // this action is array of objects  icon and name and onClick and color
                      actions?.map((action: any) => {
                        return   <Action
                        data={item}
                        action={action}
                        table={table}
                      />
                      })
                    }
                  />
                );
              })
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "100px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text size="sm">{table?.["no_data_massage_" + locale]}</Text>
              </div>
            )}

<Modal
        opened={filtersModal}
        onClose={() => setFiltersModal(false)}
        size="md"
        padding="20px"
        style={{ zIndex: 10001, position: "relative" }}
        overlayProps={{
          zIndex: 10001,
          opacity: 0.55,
          blur: 3,
        }}
        styles={() => ({
          content: {
            zIndex: 10001,
            borderRadius: "10px",
            background: "linear-gradient(153deg, #69DCBF 100%, #21033f7a 100%)",
            // display: "flex",
            // justifyContent: "center",
            // alignItems: "center",
          },
          header: { display: "none" },
          body: { padding: "9px" },
          inner: { zIndex: 10001 },
        })}
        centered
      >
        <div
          style={{
            background: "white",
            width: "100%",
            height: "100%",
            marginTop: "9px",
            borderRadius: "5px",
            // paddingTop: "30px",
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "center",
            gap: "10px",
            padding: "20px",
          }}
        >
          <Text
            fz="md"
            style={{
              color: "#595959",
              fontWeight: 700,
              background:
                "var(--sc, linear-gradient(90deg, #21033F 0%, #69DCBF 100%))",
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            {tn("Filters")}
          </Text>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-around",
              gap: "10px",
              width: "100%",
            }}
          >
            {filtered_columns?.filter((column)=>column?.filter_type?.name)?.map((column: any) => {
              return (
                <RenderFilter
                  column={column}
                  i={0}
                  locale={locale}
                  translation={translation}
                  form={form}
                />
              );
            })}
            {/* {secondary_texts?.[0] && secondary_texts?.map((item: any, index: number) => {
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "10px",
                    width: "calc(50% - 20px)",
                    paddingBottom: "5px",
                    borderBottom: "1px solid #636363",
                  }}
                >
                  <Text
                    fz="sm"
                    style={{
                      color: "#21033F",
                      fontWeight: 700,
                    }}
                  >
                    {item?.name}
                  </Text>
                  <Text
                    fz="sm"
                    style={{
                      color: "#595959",
                      fontWeight: 400,
                    }}
                  >
                    {item?.value}
                  </Text>
                </div>
              );
            })} */}

          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <Button
              color="gray"
              variant="outline"
              style={{
                fontWeight: 700,
                fontSize: "12px",
                borderRadius: "10px",
                padding: "10px",
              }}
              onClick={() => setFiltersModal(false)}
            >
              {tn("close")}
            </Button>
          </div>
        </div>
      </Modal>
          </div>
        ) : (
          <div
            style={{
              width: "100%",
              height: "100px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader color={table?.primary_color ?? "#58aaa2"} />
          </div>
        )}
      </div>
    </div>
  );
}

function ControlBar({ table, setAccountAdd }: any) {
  const { systemData }: any = useContext(SystemContext);
  const { locale, translation }: any = useContext(TranslationContext);

  // function filterdData(itemName ,) {

  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "30px 0px 0px 0px",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "40px",
            display: "flex",
            alignItems: "center",

            justifyContent: "start",
            borderRadius: "5px",
            border: "1px solid #E6E6E6",
            background: "#F9F9F9",
          }}
        ></div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          // margin: "10px 0px 30px 0px",
          position: "absolute",
          top: "80px",
          right: "50px",
        }}
      ></div>
    </div>
  );
}

function RenderCell({
  item,
  itemFull,
  data,
  column,
  i,
  locale,
  translation,
  table,
}: any) {
  const { systemData }: any = useContext(SystemContext);
  const clickActionHandler = ClickableActionsMap(column, data, table);
  const isClickable = true;
  function onClick() {
    if (isClickable) {
      if ((column.onClickable, clickActionHandler)) {
        // Execute the handler directly
        clickActionHandler();
      }
    }
  }
  let bg_color =
    translation?.[column?.options]?.find(
      (option: any) => option?.name_en == itemFull?.en
    )?.bg_color ?? "";
  const commonStyle: any = {
    cursor: column.onClickable && isClickable && "pointer",
    display: "flex",
    justifyContent: "center",
    padding: "0px 0px",
    color: column?.color && isClickable ? column?.color : "#6c6c6c",
    textAlign: "center",
    fontSize: "12px",
    fontWeight: "700",
  };
  switch (column?.type?.name) {
    case "text":
      return (
        <div onClick={onClick} style={commonStyle}>
          {item ? item : column?.[`null_text_${locale}`]}
        </div>
      );

    case "defined_text":
      return (
        <div onClick={onClick} style={commonStyle}>
          {item
            ? column?.[`defined_text_${locale}`]
            : column?.[`null_text_${locale}`]}
        </div>
      );

    case "bg_color":
      return (
        <div
          onClick={onClick}
          style={{
            ...commonStyle,
            border: "2px solid " + bg_color,
            padding: "5px",
            borderRadius: "5px",
            color: bg_color ? bg_color : "",
            minWidth: "50px",
            fontWeight: "700",
          }}
        >
          {item ? item : column?.[`null_text_${locale}`]}
        </div>
      );
    case "bg_color_hover_upload":
      return (
        <HoverCard width={280} shadow="md">
          <HoverCard.Target>
            <div
              onClick={onClick}
              style={{
                ...commonStyle,
                border: "2px solid " + bg_color,
                padding: "5px",
                borderRadius: "5px",
                color: bg_color ? bg_color : "",
                minWidth: "50px",
                fontWeight: "700",
              }}
            >
              {item ? item : column?.[`null_text_${locale}`]}
            </div>
          </HoverCard.Target>
          {item?.document_status?.en == "anti-correction" && (
            <HoverCard.Dropdown>
              <Text size="sm">{item?.correction_note}</Text>
            </HoverCard.Dropdown>
          )}
        </HoverCard>
      );

    case "date":
      let currentDate = item && dayjs(item).format("DD/MM/YYYY");
      return (
        <div onClick={onClick} style={commonStyle}>
          {currentDate ? currentDate : column?.[`null_text_${locale}`]}
        </div>
      );

    case "remaining":
      const remaining = Math.floor(item);
      const finalRemaining =
        remaining > 0
          ? item
            ? remaining +
              " " +
              (locale == "en"
                ? remaining > 1
                  ? "days"
                  : "day"
                : remaining == 1
                ? "يوم"
                : remaining == 2
                ? "يومان"
                : remaining < 11
                ? "ايام"
                : "يوم")
            : column?.[`null_text_${locale}`]
          : locale == "en"
          ? "Completed"
          : "مكتمل";
      return (
        <div onClick={onClick} style={commonStyle}>
          {finalRemaining ? finalRemaining : column?.[`null_text_${locale}`]}
        </div>
      );
    default:
      return (
        <div onClick={onClick} style={commonStyle}>
          {item ? item : column?.[`null_text_${locale}`]}
        </div>
      );
  }
}

function RenderFilter({ column, i, locale, translation, form }: any) {
  const column_name = column?.relation_table
    ? column?.relation_table + "--" + column.name
    : column.name;
  const [optionsData, setOptionsData] = useState([]);
  useEffect(() => {
    if (column?.options_table) {
      const records = pb
        .collection(column?.options_table)
        .getFullList({
          sort: "-created",
        })
        ?.then((records: any) => {
          setOptionsData(records);
        });
    }
  }, []);
  switch (column?.filter_type?.name) {
    case "search":
      return (
        <TextInput
          label={column?.[`title_${locale}`]}
          icon={<IconSearch size={16} />}
          value={form?.values?.[column_name]?.value}
          size="sm"
          onChange={(e) => {
            form.setFieldValue(column_name, {
              type: "search",
              value: e.currentTarget.value,
            });
          }}
          style={{ width: "calc(100% - 10px)" }}
          placeholder={tn("Search")}
          //@ts-ignore
          styles={inputs_styles()}
        />
      );
    case "select":
      return (
        <Select
          label={column?.[`title_${locale}`]}
          value={form?.values?.[column_name]?.value?.en ?? ""}
          searchable
          clearable
          onChange={(e) => {
            if (e) {
              form.setFieldValue(column_name, {
                type: "select",
                value: {
                  en: translation?.[column?.options]?.find(
                    (option: any) => option?.name_en == e
                  )?.name_en,
                  ar: translation?.[column?.options]?.find(
                    (option: any) => option?.name_en == e
                  )?.name_ar,
                },
              });
            } else [form.setFieldValue(column_name, {})];
          }}
          style={{ width: "calc(100% - 10px)" }}
          //@ts-ignore
          styles={inputs_styles()}
          data={
            translation?.[column?.options]?.[0]
              ? translation?.[column?.options]?.map((option: any) => ({
                  value: option?.name_en,
                  label: option?.[`name_${locale}`],
                }))
              : []
          }
        />
      );

    case "multi_select":
      return (
        //make it MultiSelect
        <MultiSelect
          label={column?.[`title_${locale}`]}
          value={form?.values?.[column_name]?.value?.map(
            (item: any) => item?.en
          )}
          searchable
          clearable
          onChange={(e) => {
            form.setFieldValue(column_name, {
              type: "multi_select",
              value: e?.map((item: any) => ({
                en: translation?.[column?.options]?.find(
                  (option: any) => option?.name_en == item
                )?.name_en,
                ar: translation?.[column?.options]?.find(
                  (option: any) => option?.name_en == item
                )?.name_ar,
              })),
            });
          }}
          style={{ width: "calc(100% - 10px)" }}
          //@ts-ignore
          styles={inputs_styles()}
          data={
            translation?.[column?.options]?.[0]
              ? translation?.[column?.options]?.map((option: any) => ({
                  value: option?.name_en,
                  label: option?.[`name_${locale}`],
                }))
              : []
          }
        />
      );
    case "select_data":
      return (
        <Select
          label={column?.[`title_${locale}`]}
          value={form?.values?.[column_name]?.value}
          searchable
          clearable
          onChange={(e) => {
            form.setFieldValue(column_name, {
              type: "select_data",
              value: e,
              table: column?.options_table,
            });
          }}
          style={{ width: "calc(100% - 10px)" }}
          //@ts-ignore
          styles={inputs_styles()}
          data={
            optionsData?.[0]
              ? optionsData?.map((option: any) => ({
                  value: option?.[column?.options_column],
                  label: option?.[column?.options_column],
                }))
              : []
          }
        />
      );
    case "multi_select_data":
      return (
        <MultiSelect
          label={column?.[`title_${locale}`]}
          value={form?.values?.[column_name]?.value}
          searchable
          clearable
          onChange={(e) => {
            form.setFieldValue(column_name, {
              type: "multi_select_data",
              value: e,
              table: column?.options_table,
              relation_table: column?.relation_table,
            });
          }}
          style={{ width: "calc(100% - 10px)" }}
          //@ts-ignore
          styles={inputs_styles()}
          data={
            optionsData?.[0]
              ? optionsData?.map((option: any) => ({
                  value: option?.[column?.options_column],
                  label: option?.[column?.options_column],
                }))
              : []
          }
        />
      );
    default:
      <div></div>;
  }
}
const inputs_styles = () => ({
  label: {
    backgroundColor: "#fff",
    borderRadius: "5px",
    color: "#6e6e6e",
    padding: "0px 5px",
    fontSize: "12px",
    fontWeight: "700",
    zIndex: 100,
    transform: "translateY(10px)",
    margin: "0px 8px",
    position: "sticky",
  },
  input: {
    width: "100%",
    maxWidth: "300px",
    borderRadius: "10px",
    border: "1px solid #000",
  },
});
