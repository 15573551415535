import { Avatar, Button, Loader, Text } from "@mantine/core";
import { DataGrid } from "../../main_components/data-grid/DataGrid";
import { CMS_ENDPOINT } from "../../constants";
import { useContext, useEffect, useState } from "react";
import PocketBase from "pocketbase";
import { tn } from "../../translation";
import dayjs from "dayjs";
import { TranslationContext } from "../../context/TranslationContext";
import styled from "styled-components";
import _ from "lodash";

import { he } from "date-fns/locale";
import { SystemContext } from "../../context/SystemContext";
import { AppContext } from "../../context/AppContext";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../../context/DataContext";
import { Modals } from "../../main_components/data-grid/components/modals/Modals";
import { transform } from "framer-motion";
import { Helmet } from "react-helmet";
const pb: any = new PocketBase(CMS_ENDPOINT);

export const TaskBoard = () => {
  const { setAccountAdd }: any = useContext(DataContext);

  const { systemData }: any = useContext(SystemContext);

  const published_programs = systemData.tables?.find((item: any) => {
    return item?.name == "published_programs";
  });
  const navigate = useNavigate();
  // const main_page_stats = systemData?.main_page_stats;
  const { reloader }: any = useContext(AppContext);

  const [numbers, setNumbers]: any = useState({});
  const [numbersDepartment, setNumbersDepartment]: any = useState({});
  const main_page_stats = _.sortBy(systemData?.main_page_stats, [
    "tabs_order",
  ])?.filter((page: any) =>
    page?.permissions != null
      ? pb.authStore.model?.expand?.permission?.permissions
          ?.map((item:any) => item?.split(".")?.[0] + "." + item?.split(".")?.[1])
          ?.includes(page?.permissions)
      : true
  );
  useEffect(() => {
    const record = pb
      .collection("recruiters_numbers")
      .getFirstListItem("", {})
      ?.then((e: any) => {
        setNumbers(e);
      });
    const record_ = pb
      .collection("recruiters_department_number")
      .getFirstListItem("", {})
      ?.then((e: any) => {
        setNumbersDepartment(e);
      });
  }, [reloader]);
  const numbers_main =
    pb.authStore.model.expand?.permission?.accessible_data?.en == "All data"
      ? numbers
      : numbersDepartment;
  const { locale }: any = useContext(TranslationContext);
  const [userData, setUserData] = useState(pb?.authStore?.model);
  const days_en = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const days_ar = [
    "الأحد",
    "الاثنين",
    "الثلاثاء",
    "الأربعاء",
    "الخميس",
    "الجمعة",
    "السبت",
  ];
  if (numbers?.new_applications == undefined) {
    return (
      <div
        style={{
          width: "100%",
          height: "calc(100vh - 84px)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      > <Helmet>
      <title>{tn("Welcome to Coophub")}</title>
  </Helmet>
        <Loader />
      </div>
    );
  }
  return (
    <>
     <Helmet>
                <title>{tn("Welcome to Coophub")}</title>
            </Helmet>
    <div
      style={{
        width: "100%",
        height: "calc(100vh - 84px)",

        padding: "20px",
        paddingTop: "30px",
        background: "#fcfcfc",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "auto",
      }}
    >
      <div
        style={{
          maxWidth: "1440px",
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "start",
        }}
      >
        <WIDTH_620
          style={{
            width: "100%",
            display: "flex",
            alignItems: "stretch",
            justifyContent: "space-between",
            marginBottom: "20px",
            gap: "20px",
          }}
        >
          <div
            style={{
              background: "#ffffff",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              borderRadius: "10px",
              alignItems: "center",
              backgroundImage: "url(/bg_waves.png)",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div
              style={{
                width: "calc(100% - 15px)",
                minHeight: "7px",
                background: "#21033F",
                borderRadius: "100px",
              }}
            />
            <WIDTH_768
              style={{
                height: "calc(100% - 7px)",
                width: "100%",
                display: "flex",

                alignItems: "center",
                justifyContent: "space-between",
                margin: "20px 0px",
              }}
            >
              <WIDTH_620
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Avatar
                  mx="xl"
                  styles={() => ({
                    placeholderIcon: {
                      width: "120%",
                      height: "120%",
                      color: "#4F5152",
                      minWidth: "120%",
                      minHeight: "120%",
                    },
                  })}
                  src={`${CMS_ENDPOINT}/api/files/${userData?.collectionName}/${userData?.id}/${userData?.avatar}?token=`}
                  size={120}
                  radius="100%"
                  variant="outline"
                >
                  <img
                    style={{
                      width: "125%",
                      height: "125%",
                    }}
                    src={"/avatar_placeholder.svg"}
                  />
                </Avatar>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      color: "#4F5152",
                      fontWeight: "700",
                      fontSize: "26px",
                    }}
                  >
                    {tn("welcome back")}
                  </Text>
                  <Text
                    style={{
                      color: "#ffffff",
                      fontWeight: "700",
                      fontSize: "28px",
                    }}
                  >
                    {userData?.first_name + " " + userData?.last_name + "،"}
                  </Text>
                </div>
              </WIDTH_620>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  justifyContent: "center",
                  margin: "0px 20px",
                }}
              >
                <Text
                  style={{
                    color: "#4F5152",
                    fontWeight: "700",
                    fontSize: "26px",
                  }}
                >
                  {locale == "ar"
                    ? days_ar[dayjs().day()]
                    : days_en[dayjs().day()]}
                </Text>
                <Text
                  style={{
                    color: "#4F5152",
                    fontWeight: "500",
                    fontSize: "22px",
                  }}
                >
                  {dayjs().format("DD/M/YYYY")}
                </Text>
              </div>
            </WIDTH_768>
          </div>
          <WIDTH_620_100
            onClick={() => {
              setAccountAdd({
                id: "1",
                name: published_programs?.name,
                table: published_programs,
              });
            }}
            style={{
              background: "#C7C0CF",
              display: "flex",
              flexDirection: "column",
              borderRadius: "10px",
              alignItems: "center",
              backgroundSize: "cover",
              backgroundPosition: "center",
              minHeight: "140px",
              cursor: "pointer",
            }}
          >
            <div
              style={{
                width: "calc(100% - 15px)",
                minHeight: "7px",
                background: "#21033F",
                borderRadius: "100px",
              }}
            />
            <Text
              style={{
                color: "#ffffffa4",
                fontWeight: "700",
                fontSize: "26px",
                textAlign: "center",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "0px 10px",
              }}
            >
              {tn("create new Program")}
            </Text>
          </WIDTH_620_100>
        </WIDTH_620>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
            gap: "20px",
          }}
        >
          {main_page_stats?.map((item) => {
            return (
              <StatBox
                title={item?.["label_" + locale]}
                number={
                  numbers_main?.[item?.number_column] ??
                  numbers?.[item?.number_column]
                }
                background={item?.color}
                line_color={item?.line_color}
                path={item?.path}
              />
            );
          })}
        </div>
        <div
          style={{
            width: "100%",
            height: "calc(100vh - 84px)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "start",
            padding: "20px",
            background: "#f5f5f5",
            marginTop: "20px",
            borderRadius: "10px",
          }}
        >
          <div
            style={{
              width: "100%",
              maxWidth: "1240px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              transform: "translateY(55px)",
              zIndex: 1,
            }}
          >
            <div />
            <Text
              style={{
                color: "#4F5152",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/recruiter/under_process/scheduled_interviews");
              }}
            >
              {tn("More")}
            </Text>
          </div>
          <DataGrid name="scheduled_interview_today" />
        </div>{" "}
        <div
          style={{
            width: "20px",
            minHeight: "30px",
          }}
        />
      </div>

     
    </div>
    </>
  );
};
// main_page_stats
const WIDTH_768 = styled.div`
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
const WIDTH_620 = styled.div`
  @media (max-width: 620px) {
    flex-direction: column;
  }
`;
const WIDTH_620_100 = styled.div`
  width: 200px;
  @media (max-width: 620px) {
    width: 100%;
  }
`;
const StatBox_1320 = styled.div`
  width: calc(100% / 5 - 16px);
  @media (max-width: 1320px) {
    width: calc(100% / 4 - 15px);
  }
  @media (max-width: 800px) {
    width: calc(100% / 3 - 14px);
  }
  @media (max-width: 550px) {
    width: calc(100% / 2 - 11px);
  }
  @media (max-width: 330px) {
    width: calc(100% / 1 - 11px);
  }
`;

// const WIDTH_768 = styled.div`
//   @media (max-width: 768px) {
//     flex-direction: column;
//   }
// `;

const StatBox = ({ title, number, background, line_color, path }: any) => {
  const navigate = useNavigate();
  return (
    <StatBox_1320
      style={{
        background: background,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "start",
        borderRadius: "5px",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "7px",
          background: line_color,
          borderRadius: "100px",
        }}
      />
      <Text
        style={{
          width: "100%",
          color: "#4F5152",
          fontWeight: "500",
          fontSize: "14px",
          padding: "3px 13px",
        }}
      >
        {title}
      </Text>
      <Text
        style={{
          width: "100%",
          color: "#4F5152",
          fontWeight: "500",
          fontSize: "24px",
          padding: "0px 13px",
        }}
      >
        {number}
      </Text>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
        }}
      >
        <Button
          onClick={() => {
            navigate(path);
          }}
          mb="5px"
          color="yellow"
          size="xs"
          compact
          mx="sm"
          variant="light"
          radius="xl"
        >
          {tn("View details")}
        </Button>
      </div>
    </StatBox_1320>
  );
};
