import { ltn, tn, vtn } from "../../../../../../translation";
import { Button, Text, Textarea } from "@mantine/core";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../../../../../constants";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../../../context/AppContext";
import { useDebouncedValue } from "@mantine/hooks";
import { isNotEmpty, useForm } from "@mantine/form";

export const RejectProgram = (props: any) => {
  const pb: any = new PocketBase(CMS_ENDPOINT);
  const { data, onClose } = props;
  const [debounced_data] = useDebouncedValue(data, 200);
  const [buttonLoading, setButtonLoading] = useState(false);

  const { reloader, setReloader } = useContext(AppContext);

 console.log("debounced_data",debounced_data)
  const form = useForm({
    initialValues:{
      reason:""
    },
    validate:{
      reason:isNotEmpty(vtn("required_field"))
    }
  })

  return (
    <form
    onSubmit={form.onSubmit(
      debounced_data?.record?.[0]
              ? async (values) => {
                  setButtonLoading(true);

                  for (const record of debounced_data?.record || []) {
                    await pb
                      .collection(debounced_data?.table?.table)
                      .update(record, {
                       status:{
                        en: "rejected",
                        ar: "مرفوض",
                       },
                       reject_reason: values.reason,
                      });
                  }
                  onClose({});
                  setReloader(!reloader);
                }
              : async (values) => {
                  setButtonLoading(true);

                  await pb
                    .collection(debounced_data?.table?.table)
                    .update(debounced_data?.record?.id, {
                      status:{
                        en: "rejected",
                        ar: "مرفوض",
                       },
                        reject_reason: values.reason,
                    });
                  onClose({});
                  setReloader(!reloader);
                }
    )}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "start",
        padding: "20px",
        width: "100%",
      }}
    >
      <Text fw={600} color="#5c5c5c">
        {debounced_data?.record?.[1]
          ? ltn("reject_programs")
          : ltn("reject_program")}
      </Text>
      <div
        style={{
          width: "100%",
          padding: "10px 0px",
        }}
      >
        <Textarea
          {...form.getInputProps("reason")}
          label={tn("Notes")}
          style={{
            marginTop: "20px",
            width: "100%",
          }}
          styles={() => ({
            input: {
              border: "1px solid black",
              height: "150px",
              width: "100%",
            },
            label: {
              backgroundColor: "#f6f6f6",
              transform: "translate(13px,-15px)",
              zIndex: "1",
              position: "absolute",
              padding: "3px",
              borderRadius: "5px",
            },
          })}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <Button
        type="submit"
          loading={buttonLoading}
          color="red"
          mx={5}
          onClick={()=>{}
            
          }
        >
          {tn("Yes")}
        </Button>
        <Button
          variant="outline"
          color="gray"
          mx={5}
          onClick={() => onClose({})}
        >
          {tn("Cancel")}
        </Button>
      </div>
    </form>
  );
};
