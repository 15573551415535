import PocketBase from "pocketbase";
import "./App.css";
import styled from "styled-components";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainLayoutR from "./recruiter/layout/MainLayout";
import MainLayoutApplicantWeb from "./applicant/web/layout/MainLayout";
import MainLayoutApplicantMobile from "./applicant/mobile/layout/MainLayout";
import ApplicantLoginPageWeb from "./applicant/web/pages/Login";
import ApplicantLoginPageMobile from "./applicant/mobile/pages/Login";

import ApplicantRegisterPageWeb from "./applicant/web/pages/Register";
import ApplicantRegisterPageMobile from "./applicant/mobile/pages/Register";

import RecruiterRegisterPage from "./recruiter/pages/Register";
import AdminLoginPage from "./admin/pages/Login";
import MainLayoutAdmin from "./admin/layout/MainLayout";
import { useContext, useEffect } from "react";
import { AppContext } from "./context/AppContext";
import AppLayout from "./layout/AppLayout";
import Redirecter from "./layout/Redirecter";
import { WrongRole } from "./pages/wrong_role";
import { WrongPath } from "./pages/wrong_path";
import { MantineProvider } from "@mantine/core";
import { CMS_ENDPOINT } from "./constants";
import ApplicantProfileWeb from "./applicant/web/pages/Profile";
import ApplicantProfileMobile from "./applicant/mobile/pages/Profile";
import { TranslationContext } from "./context/TranslationContext";
import { createEmotionCache } from "@mantine/core";
import rtlPlugin from "stylis-plugin-rtl";

import ApplicantRegisterSuccessWeb from "./applicant/web/pages/RegisterSuccess";
import ApplicantRegisterSuccessMobile from "./applicant/mobile/pages/RegisterSuccess";

import ApplicantVerificationSuccessWeb from "./applicant/web/pages/VerificationSuccess";
import ApplicantVerificationSuccessMobile from "./applicant/mobile/pages/VerificationSuccess";
import FillPersonalProfileWeb from "./applicant/web/pages/FillPersonalProfile";
import FillPersonalProfileMobile from "./applicant/mobile/pages/FillPersonalProfile";
import ApplicantForgotPassword from "./applicant/web/pages/reset-password/ForgotPassword";
import ApplicantForgotPasswordConfirmationMessage from "./applicant/web/pages/reset-password/ForgotPasswordConfirmationMessage";
import ApplicantResetPassword from "./applicant/web/pages/reset-password/ResetPassword";
import ApplicantResetPasswordConfirmationMessage from "./applicant/web/pages/reset-password/ResetPasswordConfirmationMessage";
import AdminForgotPassword from "./admin/pages/reset-password/ForgotPassword";
import AdminForgotPasswordConfirmationMessage from "./admin/pages/reset-password/ForgotPasswordConfirmationMessage";
import AdminResetPassword from "./admin/pages/reset-password/ResetPassword";
import AdminResetPasswordConfirmationMessage from "./admin/pages/reset-password/ResetPasswordConfirmationMessage";
import AdminDashboard from "./admin/pages/Dashboard";
import { ApplicantManagement } from "./admin/pages/ApplicantManagement";
import { AdminProfile } from "./admin/pages/Profile";
import { VerificationDirector } from "./pages/verification_director";
import { ResetPasswordDirector } from "./pages/reset_password_director";
import RegisterRegisterSuccess from "./recruiter/pages/RegisterSuccess";
import { RecruiterVerificationSuccess } from "./recruiter/pages/VerificationSuccess";
import RecruiterLoginPage from "./recruiter/pages/Login";
import { RecruitersPage } from "./recruiter/pages/RecruitersPage";
import RecruiterForgotPassword from "./recruiter/pages/reset-password/ForgotPassword";
import RecruiterForgotPasswordConfirmationMessage from "./recruiter/pages/reset-password/ForgotPasswordConfirmationMessage";
import RecruiterResetPassword from "./recruiter/pages/reset-password/ResetPassword";
import RecruiterResetPasswordConfirmationMessage from "./recruiter/pages/reset-password/ResetPasswordConfirmationMessage";
import { RecruitersActivation } from "./admin/pages/RecruitersActivation";
import { RecruiterManagement } from "./admin/pages/RecruiterManagement";
import ProgramsSearchWeb from "./applicant/web/pages/ProgramsSearch";
import ProgramsSearchMobile from "./applicant/mobile/pages/ProgramsSearch";
import { CompnaiesManagement } from "./admin/pages/CompnaiesManagement";
import { EditCompanyPage } from "./admin/pages/EditCompanyPage";
import { BannersBuilder } from "./main_components/banners/BannersBuilder";
import { RecruiterProfile } from "./recruiter/pages/Profile";
import { DepartmentsPage } from "./recruiter/pages/DepartmentsPage";
import { NewApplications } from "./recruiter/pages/NewApplications";
import { UnderProcess } from "./recruiter/pages/UnderProcess";
import SentRequestsWeb from "./applicant/web/pages/SentRequests";
import SentRequestsMobile from "./applicant/mobile/pages/SentRequests";
import  PersonalInterviewsWeb  from "./applicant/web/pages/PersonalInterviews";
import  PersonalInterviewsMobile  from "./applicant/mobile/pages/PersonalInterviews";
import { DocumentReview } from "./recruiter/pages/DocumentReview";
import  UploadDocumentsWeb  from "./applicant/web/pages/UploadDocuments";
import  UploadDocumentsMobile  from "./applicant/mobile/pages/UploadDocuments";
import { ApplicationsHistory } from "./recruiter/pages/ApplicationsHistory";
import { ActivePrograms } from "./recruiter/pages/ActivePrograms";
import CurrentProgramWeb from "./applicant/web/pages/CurrentProgram";
import CurrentProgramMobile from "./applicant/mobile/pages/CurrentProgram";
import { CompletedPrograms } from "./recruiter/pages/CompletedPrograms";
import  ProgramsHistorWeb  from "./applicant/web/pages/ProgramsHistory";
import  ProgramsHistoryMobile  from "./applicant/mobile/pages/ProgramsHistory";
import { CompanyAccount } from "./recruiter/pages/CompanyAccount";
import { ProgramHistoryPage } from "./recruiter/pages/ProgramHistoryPage";
import { WithdrawalRequests } from "./recruiter/pages/WithdrawalRequests";
import { Permissions } from "./recruiter/pages/Permissions";
import SetPassword from "./recruiter/pages/set-password/SetPassword";
import { SetPasswordConfirmationMessage } from "./recruiter/pages/set-password/SetPasswordConfirmationMessage";
import { TaskBoard } from "./recruiter/pages/TaskBoard";
import MainLayoutPartial from "./recruiter/partial_pages/MainLayoutPartial";
import { ProgramsPartial } from "./recruiter/partial_pages/ProgramsPartial";
import { PartialRecruitersPage } from "./recruiter/partial_pages/PartialRecruitersPage";
import Dashboard from "./recruiter/pages/Dashboard";
import  TrainingContractWeb  from "./applicant/web/pages/TrainingContract";
import  TrainingContractMobile  from "./applicant/mobile/pages/TrainingContract";
import { ContractViewer } from "./recruiter/components/pdf/ContractViewer";
import { SearchApplicants } from "./recruiter/pages/SearchApplicants";
import { InvitationsSent } from "./recruiter/pages/InvitationsSent";
import InvitationsToJoinWeb from "./applicant/web/pages/InvitationsToJoin";
import InvitationsToJoinMobile from "./applicant/mobile/pages/InvitationsToJoin";
import { Modals } from "./main_components/data-grid/components/modals/Modals";
import TestPage from "./applicant/web/pages/TestPage";
import TestLayout from "./applicant/web/layout/TestLayout";
import { tn } from "./translation";
import { Helmet } from "react-helmet";
import { ViewChanger } from "./applicant/ViewChanger";
import ReactGA from "react-ga4";
import { Test } from "./Test";
import Models from "./recruiter/pages/Models";
import Overview from "./recruiter/pages/Overview";

const rtlCache = createEmotionCache({
  key: "mantine-rtl",
  stylisPlugins: [rtlPlugin],
});

ReactGA.initialize("G-21L8DG923E");

function App() {
  
  const pb: any = new PocketBase(CMS_ENDPOINT);
  // const { theme, setTheme }: any = useContext(AppContext);
  const Body = styled.div``;

  const { locale }: any = useContext(TranslationContext);
  // useEffect(() => {
  //   pb.collection(pb?.authStore?.model?.collectionName).authRefresh();
  // }, []);
  return (
    <MantineProvider
      withGlobalStyles
      withNormalizeCSS
      //@ts-ignore
      emotionCache={locale == "ar" && rtlCache}
      theme={{
        fontFamily: "IBM Plex Sans Arabic",
        primaryColor: "color1",
        // colorScheme: theme ? theme : "light",
        dir: locale == "ar" ? "rtl" : "ltr",
        colors: {
          color1: [
            "#f5ecfe",
            "#e6d3f9",
            "#cba2f4",
            "#b06ef1",
            "#9944ee",
            "#8a2aec",
            "#841eed",
            "#7114d3",
            "#650fbc",
            "#5708a6",
          ],
          color2: [
            "#e1fef8",
            "#d3f8ef",
            "#aaeddd",
            "#80e2ca",
            "#5cd9b9",
            "#44d3af",
            "#34d0aa",
            "#20b994",
            "#0ca483",
            "#008f6f",
          ],
          color3: [
            "#fff9e1",
            "#fff0cc",
            "#ffe09b",
            "#ffce64",
            "#ffc038",
            "#ffb61c",
            "#ffb209",
            "#e39c00",
            "#ca8a00",
            "#af7600",
          ],
          color4: [
            "#ffeae6",
            "#ffd3d0",
            "#fca49f",
            "#f8736b",
            "#f54a3f",
            "#f43023",
            "#f42114",
            "#da1309",
            "#c30a06",
            "#ab0001",
          ],
        },
      }}
    >
      <Helmet>
        <title>{tn("Coophub")}</title>
        <meta property="og:title" content={"title"} />
        <meta property="og:description" content={"user.description"} />
        {/* Other tags as needed */}
      </Helmet>
      <Body dir={locale == "ar" ? "rtl" : "ltr"}>
        <BrowserRouter>
          <Routes>
            <Route path="*" element={<WrongPath />}></Route>
            <Route
              path="/test"
              element={<Test />}
            />
            <Route
              path="test_page/:filtration_test_id/:program_id"
              element={<TestPage />}
            />
            <Route
              path="/verification_director"
              element={<VerificationDirector />}
            />
            <Route
              path="/reset_password_director"
              element={<ResetPasswordDirector />}
            />
            <Route element={<AppLayout />}>
              {/* other routes */}
              <Route path="/wrong_role" element={<WrongRole />} />
              {/* auth routes */}

              <Route
                path="/applicant/login"
                element={
                  <ViewChanger
                    web={<ApplicantLoginPageWeb />}
                    mobile={<ApplicantLoginPageMobile />}
                  />
                }
              />
              <Route
                path="/applicant/register"
                element={
                  <ViewChanger
                    web={<ApplicantRegisterPageWeb />}
                    mobile={<ApplicantRegisterPageMobile />}
                  />
                }
              />
              <Route
                path="/applicant/register_success"
                element={
                  <ViewChanger
                    web={<ApplicantRegisterSuccessWeb />}
                    mobile={<ApplicantRegisterSuccessMobile />}
                  />
                }
              />
              <Route
                path="/applicant/verification_success"
                element={
                  <ViewChanger
                    web={<ApplicantVerificationSuccessWeb />}
                    mobile={<ApplicantVerificationSuccessMobile />}
                  />
                }
              />
              <Route
                path="/applicant/fill_personal_info"
                element={
                  <ViewChanger
                    web={<FillPersonalProfileWeb />}
                    mobile={<FillPersonalProfileMobile />}
                  />
                }
              />
              <Route
                path="/applicant/forgot_password"
                element={<ApplicantForgotPassword />}
              />
              <Route
                path="/applicant/forgot_password_confirmation_message"
                element={<ApplicantForgotPasswordConfirmationMessage />}
              />
              <Route
                path="/applicant/reset_password"
                element={<ApplicantResetPassword />}
              />
              <Route
                path="/applicant/reset_password_confirmation_message"
                element={<ApplicantResetPasswordConfirmationMessage />}
              />

              <Route
                path="/admin/forgot_password"
                element={<AdminForgotPassword />}
              />
              <Route
                path="/admin/forgot_password_confirmation_message"
                element={<AdminForgotPasswordConfirmationMessage />}
              />
              <Route
                path="/admin/reset_password"
                element={<AdminResetPassword />}
              />
              <Route
                path="/admin/reset_password_confirmation_message"
                element={<AdminResetPasswordConfirmationMessage />}
              />

              <Route
                path="/recruiter/forgot_password"
                element={<RecruiterForgotPassword />}
              />
              <Route
                path="/recruiter/forgot_password_confirmation_message"
                element={<RecruiterForgotPasswordConfirmationMessage />}
              />
              <Route
                path="/recruiter/reset_password"
                element={<RecruiterResetPassword />}
              />
              <Route
                path="/recruiter/reset_password_confirmation_message"
                element={<RecruiterResetPasswordConfirmationMessage />}
              />

              <Route path="/recruiter/login" element={<RecruiterLoginPage />} />
              <Route
                path="/recruiter/register"
                element={<RecruiterRegisterPage />}
              />
              <Route
                path="/recruiter/register_success"
                element={<RegisterRegisterSuccess />}
              />
              <Route
                path="/recruiter/verification_success"
                element={<RecruiterVerificationSuccess />}
              />
              <Route
                path="/recruiter/new_recruiter/:id"
                element={<SetPassword />}
              />
              <Route
                path="/recruiter/set_password_confirmation_message"
                element={<SetPasswordConfirmationMessage />}
              />
              <Route path="/admin/login" element={<AdminLoginPage />} />
              {/*home routes*/}
              <Route
                path="/"
                element={
                  <ViewChanger
                    web={<ApplicantLoginPageWeb />}
                    mobile={<ApplicantLoginPageMobile />}
                  />
                }
              ></Route>
              {/*admin routes*/}
              <Route path="/admin">
                <Route path="" element={<MainLayoutAdmin />}>
                  <Route path="" element={<AdminDashboard />} />
                  <Route path="dashboard" element={<AdminDashboard />} />
                  <Route
                    path="applicant_management"
                    element={<ApplicantManagement />}
                  />
                  <Route
                    path="recruiters_management"
                    element={<RecruiterManagement />}
                  />
                  <Route
                    path="compnaies_management"
                    element={<CompnaiesManagement />}
                  />
                  <Route
                    path="compnaies_management/:id"
                    element={<EditCompanyPage />}
                  />

                  <Route path="profile" element={<AdminProfile />} />
                  <Route
                    path="activation_requests/recruiters"
                    element={<RecruitersActivation />}
                  />
                </Route>
              </Route>
              {/*applicant routes*/}

              <Route path="/applicant">
                <Route
                  path=""
                  element={
                    <ViewChanger
                      web={<MainLayoutApplicantWeb />}
                      mobile={<MainLayoutApplicantMobile />}
                    />
                  }
                >
                  <Route
                    path="profile"
                    element={
                      <ViewChanger
                        web={<ApplicantProfileWeb />}
                        mobile={<ApplicantProfileMobile />}
                      />
                    }
                  />
                  <Route
                    path="programs_search"
                    element={
                      <ViewChanger
                        web={<ProgramsSearchWeb />}
                        mobile={<ProgramsSearchMobile />}
                      />
                    }
                  />
                  <Route
                    path="programs_search/:id"
                    element={
                      <ViewChanger
                        web={<ProgramsSearchWeb />}
                        mobile={<ProgramsSearchMobile />}
                      />
                    }
                  />

                  <Route path="sent_requests" element={
                    <ViewChanger
                      web={<SentRequestsWeb />}
                      mobile={<SentRequestsMobile />}
                    />
                  } />
                  <Route
                    path="personal_interviews"
                    element={
                      <ViewChanger
                        web={<PersonalInterviewsWeb />}
                        mobile={<PersonalInterviewsMobile />} />
                     
                    }
                  />
                  <Route
                    path="upload_documents"
                    element={
                      <ViewChanger
                        web={<UploadDocumentsWeb />}
                        mobile={<UploadDocumentsMobile />}
                      />
                    }
                  />
                  <Route
                    path="current_program"
                    element={
                      <ViewChanger
                        web={<CurrentProgramWeb />}
                        mobile={<CurrentProgramMobile />}
                      />
                    }
                  />
                  <Route
                    path="my_programs_history"
                    element={
                      <ViewChanger
                        web={<ProgramsHistorWeb />}
                        mobile={<ProgramsHistoryMobile />}
                      />
                    }
                  />
                  <Route
                    path="training_contract"
                    element={
                      <ViewChanger
                        web={<TrainingContractWeb />}
                        mobile={<TrainingContractMobile />}
                      />
                    }
                  />
                  <Route
                    path="invitations_to_join"
                    element={
                      <ViewChanger
                        web={<InvitationsToJoinWeb />}
                        mobile={<InvitationsToJoinMobile />}
                      />
                    }
                  />
                </Route>
              </Route>
            </Route>
            {/*recruiter routes*/}

            <Route path="/recruiter">
              <Route path="" element={<MainLayoutR />}>
                <Route path="" element={<TaskBoard />} />
                <Route path="task_board" element={<TaskBoard />} />
                <Route path="dashboard" element={<Dashboard />} />
                
                <Route path="recruiters" element={<RecruitersPage />} />
                <Route path="departments" element={<DepartmentsPage />} />
                <Route path="profile" element={<RecruiterProfile />} />
                <Route
                  path="program_history"
                  element={<ProgramHistoryPage />}
                />
                <Route
                  path="program_history/:page"
                  element={<ProgramHistoryPage />}
                />
                 <Route
                  path="program_history/:page/:innerPage"
                  element={<ProgramHistoryPage />}
                />
                <Route path="new_applications" element={<NewApplications />} />
                <Route path="under_process" element={<UnderProcess />} />
                <Route path="under_process/:page" element={<UnderProcess />} />
                <Route
                  path="document_review/:page"
                  element={<DocumentReview />}
                />
                <Route path="document_review" element={<DocumentReview />} />

                <Route
                  path="applications_history"
                  element={<ApplicationsHistory />}
                />
                <Route path="active_programs" element={<ActivePrograms />} />
                <Route
                  path="completed_programs"
                  element={<CompletedPrograms />}
                />
                <Route path="company_account" element={<CompanyAccount />} />
                <Route
                  path="withdrawal_requests"
                  element={<WithdrawalRequests />}
                />
                <Route
                  path="withdrawal_requests/:page"
                  element={<WithdrawalRequests />}
                />
                  <Route path="overview" element={<Overview />} />
                <Route path="models" element={<Models />} />
                <Route path="models/:id" element={<Models />} />
                <Route path="models/:id/:model" element={<Models />} />

                <Route path="permissions" element={<Permissions />} />
                <Route
                  path="search_applicants"
                  element={<SearchApplicants />}
                />
                <Route path="invitations_sent" element={<InvitationsSent />} />
              </Route>
            </Route>
            <Route path="/recruiter_partial">
              <Route path="" element={<MainLayoutPartial />}>
                <Route path="profile" element={<RecruiterProfile />} />
                <Route path="company_account" element={<CompanyAccount />} />
                <Route path="recruiters" element={<PartialRecruitersPage />} />

                <Route path="program_history" element={<ProgramsPartial />} />
              </Route>
            </Route>
            <Route path="contract_viewer" element={<ContractViewer />} />
          </Routes>
          <Modals />
        </BrowserRouter>
      </Body>
      <BannersBuilder />
    </MantineProvider>
  );
}

export default App;
