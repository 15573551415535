import { TranslationContext } from "../../../../../../context/TranslationContext";
import { useContext } from "react";
import { AppContext } from "../../../../../../context/AppContext";
import { CMS_ENDPOINT, WEB_ENDPOINT } from "../../../../../../constants";
import { ActionComponent } from "../../../ActionComponent";

export const DownloadTheDraftContract = (props: any) => {
  const { locale }: any = useContext(TranslationContext);

  const { data, action, table } = props;
  const { setContractViewerData }: any = useContext(AppContext);
  const openNewTab = () => {
    const url = WEB_ENDPOINT + "/contract_viewer"; // Replace with your desired URL
    window.open(url, "_blank", "noopener,noreferrer");
  };
  //  console.log("contractfViewerData",data?.contract_data)
  const when_be_able = true;
  return (
    <ActionComponent
    is_mobile={true}
      data={data}
      action={action}
      table={table}
      setData={async () => {
        setContractViewerData({
          applicant: {
            ...data?.contract_data?.applicant,
            religion: data?.contract_data?.applicant?.religion?.[locale],
            marital_status:
              data?.contract_data?.applicant?.marital_status?.[locale],
            gender: data?.contract_data?.applicant?.gender?.[locale],
          },
          company: {
            ...data?.contract_data?.company,
            job_title: data?.contract_data?.company?.job_title?.[locale],
            logo: `${CMS_ENDPOINT}/api/files/${data?.collectionName}/${data?.id}/${data?.company_logo}?token=`,
          },
          program: {
            ...data?.contract_data?.program,
            programs_majors: data?.contract_data?.program?.programs_majors
              ?.map((item: any) => item?.[locale])
              .join(", "),
            program_type_translated:
              data?.contract_data?.program?.program_type_translated?.[locale],
          },
        });
        openNewTab();
      }}
      enabled={when_be_able}
    />
  );
};
