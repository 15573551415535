import { ltn, tn } from "../../../../../../translation";
import { Button, Text } from "@mantine/core";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../../../../../constants";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../../../context/AppContext";
import alasql from "alasql";
import { useDebouncedValue } from "@mantine/hooks";

export const DeleteDepartment = (props: any) => {
  const pb: any = new PocketBase(CMS_ENDPOINT);
  const { data, onClose } = props;
  const { reloader, setReloader } = useContext(AppContext);
  const [debounced_data] = useDebouncedValue(data, 400);
  const [buttonLoading, setButtonLoading] = useState(false);

  const [departmentsNumber, setDepartmentsNumber] = useState(0);
  useEffect(() => {
    if(debounced_data?.record?.[0]){
      const resultList = pb
      .collection("departments_view")
      .getList(1, 120, {
        filter: debounced_data?.record?.map((item: any) => `id = "${item}"`).join(" || "),
      })
      ?.then((records: any) => {
        setDepartmentsNumber( alasql(`SELECT SUM(sub_departments_count) AS totale FROM ?`, [
          records?.items,
        ])?.[0]?.totale )
      });
    }
   
  }, [debounced_data]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "start",
        padding: "20px",
        width: "100%",
      }}
    >
      <Text fw={600} color="#5c5c5c">
        {debounced_data?.record?.[1]
          ? ltn("delete_department_massages")
          : ltn("delete_department_massage")}
      </Text>
      <Text mt="md" fz="xs" fw={600} color="#5c5c5c">
        {debounced_data?.record?.[1]
          ? ltn("departments_numbers")
          : ltn("departments_number")}{" "}
        {"(" + (debounced_data?.record?.[0]?departmentsNumber : debounced_data?.record?.sub_departments_count )+ ")"}
      </Text>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <Button
        loading={buttonLoading}
          color="red"
          mx={5}
          onClick={
            debounced_data?.record?.[0]
              ? () => {
                setButtonLoading(true);
                   pb
                    .collection("sub_departments")
                    .getList(1, 150, {
                      filter: debounced_data?.record?.map((item:any)=>`department = "${item}"`).join(" || "),
                    })
                    ?.then((records: any) => {
                      for (const element of records?.items || []) {
                        pb.collection("sub_departments").update(element?.id,{
                          status:{en:"deleted",ar:"محذوف"}
                        });
                      };
                    });
                    for (const element of debounced_data?.record || []) {
                      pb.collection("departments").update(element,{
                        status:{en:"deleted",ar:"محذوف"}
                      });
                      onClose({});
                      setReloader(!reloader);
                    };
                  
              }
              : async () => {
                setButtonLoading(true);

                  await pb
                    .collection("sub_departments")
                    .getList(1, 50, {
                      filter: `department = "${debounced_data?.record?.id}"`,
                    })
                    ?.then(async (records: any) => {
                      for (const record of records?.items || []) {
                        await pb.collection("sub_departments").update(record?.id,{
                          status:{en:"deleted",ar:"محذوف"}
                        });
                      };
                      onClose({});
                  setReloader(!reloader);
                    });
                  await pb
                    .collection(debounced_data?.table?.table)
                    .update(debounced_data?.record?.id,{
                      status:{en:"deleted",ar:"محذوف"}
                    });

                  
                }
          }
        >
          {tn("Delete")}
        </Button>
        <Button
          variant="outline"
          color="gray"
          mx={5}
          onClick={() => onClose({})}
        >
          {tn("Cancel")}
        </Button>
      </div>
    </div>
  );
};
