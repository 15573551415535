import { tn, vtn } from "../../../../../translation";

import { Button, Loader, Text, Textarea } from "@mantine/core";

import { useContext, useState } from "react";
import { TranslationContext } from "../../../../../context/TranslationContext";
import { CMS_ENDPOINT } from "../../../../../constants";
import PocketBase from "pocketbase";
import { useDebouncedValue, useViewportSize } from "@mantine/hooks";
import { isNotEmpty, useForm } from "@mantine/form";
import { AppContext } from "../../../../../context/AppContext";
import { DatePicker } from "@mantine/dates";
import dayjs from "dayjs";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
const pb = new PocketBase(CMS_ENDPOINT);
export const ChangeCommencementDate = ({ data, onClose }: any) => {
  const { translation, locale }: any = useContext(TranslationContext);
  const [debounced_data] = useDebouncedValue(data, 200);
  const { reloader, setReloader } = useContext(AppContext);

  const applicant_exclude_verbs = translation?.applicant_exclude_verbs;
  const { width } = useViewportSize();
  const form = useForm({
    initialValues: {
      date: new Date(data?.date_of_commencement),
    },
    validate: {
      date: isNotEmpty(vtn("required_field")),
    },
  });
  const programPeriod = translation?.period?.find(
    (period) => period.name_en == debounced_data?.expand?.program?.period?.en
  )?.value;
  return width > 10 ? (
    <form
      onSubmit={form.onSubmit((value) => {
        pb?.collection("applications")
          .update(data?.id, {
            date_of_commencement: new Date(value?.date),
            date_of_end: dayjs(new Date(value?.date))
              .add(programPeriod, "month")
              .toDate(),
          })
          ?.then(() => {
            setReloader(!reloader);
            onClose({});
          });
      })}
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Text
        style={{
          width: "100%",
          padding: "10px 20px 10px ",
          color: "rgba(0, 0, 0, 0.70)",
          textAlign: "right",
          fontSize: "22px",
          fontWeight: "700",
        }}
      >
        {tn("Change the date of commencement")}
      </Text>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          padding: "0px 10px",
          flexDirection: width > 850 ? "row" : "column",
          alignItems: width > 850 ? "stretch" : "stretch",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            width: width > 850 ? "calc(55% - 10px)" : "calc(100% - 10px)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "start",
            background: "rgba(79, 81, 82, 0.05)",
            borderRadius: "10px",
          }}
        >
          <div
            style={{
              width: "calc(100% - 30px)",
              background: "#21033F",
              height: "5px",
              borderRadius: "522px",
            }}
          />
          <TextBox
            value={debounced_data?.expand?.program?.name}
            name={tn("Program") + ":"}
          />
          <TextBox
            value={debounced_data?.department_name}
            name={tn("Department") + ":"}
          />
          <TextBox
            value={debounced_data?.expand?.program?.type?.[locale]}
            name={tn("Program Type") + ":"}
          />
          <TextBox
            value={new Date(
              debounced_data?.expand?.program?.start_date
            )?.toLocaleDateString("en-GB")}
            name={tn("Program start date") + ":"}
          />
          <TextBox
            value={new Date(
              debounced_data?.expand?.program?.end_date
            )?.toLocaleDateString("en-GB")}
            name={tn("Program end date") + ":"}
          />
          <Text
            style={{
              width: "100%",
              textAlign: "center",
              color: "#4F5152",
              fontSize: "18px",
              margin: "5px 0px",
              fontWeight: "600",
            }}
          >
            {tn("Applicant data")}
          </Text>
          <TextBox
            value={debounced_data?.applicant_name}
            name={tn("Applicant name") + ":"}
          />
          <TextBox
            value={debounced_data?.university?.[locale]}
            name={tn("University") + ":"}
          />
          <TextBox
            value={debounced_data?.major?.[locale]}
            name={tn("Major") + ":"}
          />
          <TextBox
            value={debounced_data?.interview_evaluation?.precentage + "%"}
            name={tn("Interview evaluation") + ":"}
          />
        </div>
        <div
          style={{
            width: width > 850 ? "calc(45% - 10px)" : "calc(100% - 10px)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            background: "rgba(79, 81, 82, 0.05)",
            borderRadius: "10px",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "calc(100% - 30px)",
                background: "#21033F",
                height: "5px",
                borderRadius: "522px",
              }}
            />
            <Text
              style={{
                width: "100%",
                padding: "10px 20px 10px ",
                color: "rgba(0, 0, 0, 0.70)",
                textAlign: "right",
                fontSize: "22px",
                fontWeight: "700",
              }}
            >
              {tn("Select a date")}
            </Text>
          </div>
          <div
            style={{
              background: "#ffffff",
              borderRadius: "10px",
              padding: "10px 0px",
              direction: "ltr",
            }}
          >
            {" "}
            <DatePicker
              nextIcon={
                <IconChevronRight size={20} style={{ color: "#1d604f" }} />
              }
              previousIcon={
                <IconChevronLeft size={20} style={{ color: "#1d604f" }} />
              }
              firstDayOfWeek={0}
              {...form.getInputProps("date")}
            />{" "}
          </div>
          <div />
        </div>
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "end",
          padding: "20px 0px",
        }}
      >
        <Button
          disabled={!form.isDirty()}
          type="submit"
          compact
          style={{
            backgroundColor: form.isDirty() ? "#69DCBF" : "",
            color: form.isDirty() ? "white" : "",
            width: "100px",
            borderRadius: "5.112px",
          }}
        >
          {tn("Change")}
        </Button>

        <Button
          compact
          mx="sm"
          style={{
            color: "white",
            width: "100px",
            borderRadius: "5.112px",
            backgroundColor: "#F9837C",
          }}
          color="red"
          onClick={() => {
            onClose({});
          }}
        >
          {tn("Cancel")}
        </Button>
      </div>
    </form>
  ) : (
    <div
      style={{
        height: "1270px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Loader color="color1" />
    </div>
  );
};
const TextInputStyle = (error: any) => ({
  description: {
    marginBottom: "-17px",
    transform: "translate(5px, 35px)",
    fontSize: "8px",
    opacity: error ? "0" : "1",
  },
  label: {
    zIndex: "10",
    position: "relative",
    margin: "0px 10px",
    transform: "translate(0px, 7px)",
    background: "#f6f6f6",
    borderRadius: "5px",
    padding: "0px 5px",
    fontSize: "10px",
  },
  input: {
    borderRadius: "5px",
    border: "1px solid #000000",
  },
  error: {
    marginBottom: "-17px",
    transform: "translate(4px, 3px)",
    // fontSize: "8px",
  },
});

const TextBox = ({ value, name }: any) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        margin: "3px 0px",
        padding: "0px 10px",
      }}
    >
      <Text
        style={{
          padding: "10px 3px 10px ",
          color: "#4F5152",
          textAlign: "right",
          fontSize: "14px",
          fontWeight: "700",
        }}
      >
        {name}
      </Text>
      <Text
        style={{
          minHeight: "34.8px",
          width: "180px",
          color: "#4F5152",
          padding: "5px 10px",
          borderRadius: "5px",
          background: "#ffffff",
          fontSize: value?.length > 23 ? "13px" : "16px",
          textAlign: "center",
          fontWeight: "400",
        }}
      >
        {value}
      </Text>
    </div>
  );
};
