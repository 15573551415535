import { useContext } from "react";
import { DataContext } from "../../../../../../context/DataContext";
import { ActionComponent } from "../../../ActionComponent";

export const AdoptProgram = (props: any) => {
  const { setAdoptProgram }: any = useContext(DataContext);

  const { data, action , table } = props;
  if(data?.status?.en != "waiting for approval"){
    return <></>
  }
  return (
    <ActionComponent 
    data={data}
    action={action}
    table={table}
    setData={()=>setAdoptProgram(
      {
        id: data.id,
        record:data,
        action:action,
        table:table
      }
    )}
    enabled={data?.status?.en == "waiting for approval"}
    />
  );
};
