import { ltn, tn } from "../../../../../../translation";
import { Button, List, Text } from "@mantine/core";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../../../../../constants";
import { useContext, useState } from "react";
import { AppContext } from "../../../../../../context/AppContext";
import { useDebouncedValue, useMediaQuery } from "@mantine/hooks";
import { TranslationContext } from "../../../../../../context/TranslationContext";

export const Withdrawal = (props: any) => {
  const pb: any = new PocketBase(CMS_ENDPOINT);
  const { data, onClose } = props;
  const [debounced_data] = useDebouncedValue(data, 200);

  const { reloader, setReloader } = useContext(AppContext);
  const { translation, locale }: any = useContext(TranslationContext);
  const [buttonLoading, setButtonLoading] = useState(false);
  const isMobile = useMediaQuery("(max-width: 550px)");

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "start",
        padding: "20px",
        width: "100%",
      }}
    >
      <Text fw={600} color="#5c5c5c">
        {locale == "ar"
          ? `هل أنت واثق من رغبتك في سحب طلب التقديم على ${debounced_data?.record?.expand?.program?.name} من ${debounced_data?.record?.company_name}؟`
          : `Are you sure you want to withdraw your application for ${debounced_data?.record?.expand?.program?.name} from ${debounced_data?.record?.company_name}?`}
      </Text>
      {/* <List style={{
        margin:"20px 10px",
        color:"#F9837C",
        fontWeight:600,
        fontSize:"12px"
      }}>
        <List.Item>
         {locale == "ar"
          ? `لن يكون بإمكانك التقديم على هذا البرنامج مرة أخرى.`
          : "You will not be able to apply to this program again."}
          
        </List.Item>
        <List.Item>
       {locale == "ar"
            ? `يقلل طلب الانسحاب من فرصك في الحصول على فرص تدريبية اخرى.`
            : "A withdrawal request reduces your chances of obtaining other training opportunities."}
        </List.Item>
        <List.Item>
       {locale == "ar"
            ? `لن يعتبر طلب الانسحاب مكتمل حتى تتم الموافقة عليه من قبل جهة التدريب.`
            : "The withdrawal request will not be considered complete until it is approved by the training body."}
        </List.Item>
      </List> */}
      <Text style={{
        margin:"20px 10px",
        color:"#F9837C",
        fontWeight:600,
        fontSize:"14px"
      }}>{ltn("withdraw_note")}</Text>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: isMobile?"center":"end",
          marginTop: "20px",
          width: "100%",
        }}
      >
        <Button loading={buttonLoading}
          // radius was 0 here
          compact={!isMobile}
          radius={isMobile ? "md" : ""}
          size={isMobile ? "md" : ""}
          style={{
            minWidth: "80px",
            backgroundColor: "#F9837C",
          }}
          mx={5}
          onClick={
            debounced_data?.record?.[0]
              ? () => {
                  debounced_data?.record?.forEach(async (record: any) => {
                    await pb
                      .collection(debounced_data?.table?.table)
                      .update(record, { 
                        main_status: { 	
                          en: "withdrawn",
                          ar: "منسحب",
                        },
                        applicant_status: { 	
                          en: "withdrawn",
                          ar: "منسحب",
                        },
                        recruiter_status: { 	
                          en: "withdrawn",
                          ar: "منسحب",
                        },
                      });
                  });
                  onClose({});
                  setReloader(!reloader);
                }
              : async () => {
                setButtonLoading(true);

                  await pb
                    .collection(data?.table?.table)
                    .update(data?.record?.id, {
                      applicant_status: {
                        en: "withdrawn",
                        ar: "منسحب",
                      },
                      recruiter_status: {
                        en: "withdrawn",
                        ar: "منسحب",
                      },
                      main_status: {
                        en: "withdrawn",
                        ar: "منسحب",
                      },
                    })
                    ?.then((application: any) => {
                      const record = pb
                        .collection("programs")
                        .getOne(application?.program, {})
                        ?.then((program: any) => {
                          const records = pb
                            .collection("applications")
                            .getFullList({
                              sort: "-created",
                              filter: `program = "${program?.id}"`,
                            })
                            ?.then((applications: any) => {
                              // if all applications are excluded, or rejected, and the program last_submission_date is passed, then the program status will be {
                              //   en:"canceled",
                              //   ar:"ملغي"
                              // }
                              const all_applications_excluded_or_rejected =
                                applications?.every(
                                  (item: any) =>
                                    item?.main_status?.en === "excluded" ||
                                    item?.main_status?.en === "rejected" ||
                                    item?.main_status?.en === "withdrawn"
                                );
                              const program_last_submission_date_passed =
                                new Date(program?.last_submission_date) <
                                new Date();
                              if (
                                all_applications_excluded_or_rejected &&
                                program_last_submission_date_passed
                              ) {

                                pb?.collection("programs").update(program?.id, {
                                  status: {
                                    en: "canceled",
                                    ar: "ملغي",
                                  },
                                });
                              }
                            })
                            ?.then((res: any) => {
                              setReloader(!reloader);
                              onClose({});
                            });
                        });
                    });
                }
          }
        >
          {tn("Withdraw")}
        </Button>
        <Button
          // radius was 0 here
          compact={!isMobile}
          radius={isMobile ? "md" : ""}
          size={isMobile ? "md" : ""}
          style={{
            minWidth: "80px",
            backgroundColor: "#69DCBF",
          }}
          mx={5}
          onClick={() => onClose({})}
        >
          {tn("Cancel")}
        </Button>
      </div>
    </div>
  );
};
