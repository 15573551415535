import { ltn, tn, vtn } from "../../../../../../translation";
import {
  ActionIcon,
  Button,
  Checkbox,
  Group,
  List,
  Loader,
  Menu,
  NativeSelect,
  Popover,
  Radio,
  SegmentedControl,
  Select,
  Table,
  Text,
  TextInput,
} from "@mantine/core";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";

import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../../../../../constants";
import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../../../../../context/AppContext";
import {
  useDebouncedValue,
  useListState,
  useViewportSize,
} from "@mantine/hooks";
import { DatePicker, TimeInput } from "@mantine/dates";
import { IconChevronLeft, IconChevronRight, IconClock, IconX } from "@tabler/icons-react";
import { TranslationContext } from "../../../../../../context/TranslationContext";
import { isNotEmpty, useForm } from "@mantine/form";
import { Action } from "../../../ActionsMap";
import dayjs from "dayjs";
import alasql from "alasql";
import { Icon } from "@iconify/react/dist/iconify.js";

export const SchedulePlan = (props: any) => {
  const pb: any = new PocketBase(CMS_ENDPOINT);
  const { data, onClose } = props;
  const [debounced_data] = useDebouncedValue(data, 200);
  const { reloader, setReloader }: any = useContext(AppContext);
  const { height, width }: any = useViewportSize();
  const { locale, translation }: any = useContext(TranslationContext);
  const [allApplicants, setAllApplicants] = useState([]);
  const [allSubDepartments, setAllSubDepartments]: any = useState([]);
  const durations = translation?.durations;
  const [buttonLoading, setButtonLoading] = useState(false);

  const form: any = useForm({
    initialValues: {
      all_applicants: false,
      subDepartments: [],
    },
  });
  useEffect(() => {
    if (!debounced_data?.record?.[0]) {
      const record = pb
        .collection("applications_view")
        .getFullList({
          sort: "created",
          expand: "program,applicant",
          filter: `program = "${debounced_data?.record?.program}" && main_status.en = "accepted"`,
        })
        ?.then((res: any) => {
          setAllApplicants(res);
        });
    } else {
      const record = pb
        .collection("applications_view")
        .getFullList({
          expand: "program,applicant",
          sort: "created",
          filter: debounced_data?.record
            ?.map((item: any) => `id = "${item}"`)
            .join(" || "),
        })
        ?.then((res: any) => {
          setAllApplicants(res);
        });
    }
  }, [debounced_data, form?.values?.all_applicants]);
  useEffect(() => {
    const record = pb
      .collection("program_plans_view")
      .getFullList({
        sort: "start_date",
        expand: "program,applicant",
        filter: `application = "${debounced_data?.record?.id}"`,
      })
      ?.then((res: any) => {
        const records = pb
          .collection("sub_departments")
          .getFullList({
            filter: `department = "${debounced_data?.record?.department}"`,
          })
          ?.then((res__: any) => {
            setAllSubDepartments(res__);
            if (res?.[0]) {
              form.setFieldValue("subDepartments", res);
            } else {
              form.setFieldValue(
                "subDepartments",
                res__?.map((item: any) => ({
                  sub_department_name: item?.name,
                  sub_department: item?.id,
                  start_date: "-",
                  end_date: "-",
                }))
              );
            }
          });
      });

  }, []);
  // useEffect(() => {
  //   if(!isPlans){

  //   }

  // }, [isPlans]);
  const isButonDisabled =
    form?.values?.subDepartments?.find((v: any) => v.start_date == "-") ||
    form?.values?.subDepartments?.find((v: any) => v.end_date == "-");
  return (
    <form
      onSubmit={form.onSubmit(async (values: any) => {
        try {
          if (values?.all_applicants) {
            // Fetch all accepted applications for the program
            const applications = await pb
              .collection("applications_view")
              .getFullList({
                expand: "program,applicant",
                filter: `program = "${debounced_data?.record?.program}" && main_status.en = "accepted"`,
              });

            // Loop through each application
            for (const application of applications) {
              // Delete existing plans for each application
              const updatedApplication = await pb.collection('applications').update(application?.id, {
                program_plan:{
                  en:"scheduled",
                  ar:"مجدولة"
                }
              });
              const existingPlans = await pb
                .collection("program_plans_view")
                .getFullList({
                  filter: `application = "${application.id}"`,
                });
                const record = await pb.collection('applications').update(debounced_data?.record?.id, {
                program_plan:{
                  en:"scheduled",
                  ar:"مجدولة"
                }
              });
              for (const plan of existingPlans) {
                await pb.collection("program_plans").delete(plan.id);
              }

              // Create program plans for each sub-department for the application
              for (const subDepartment of values.subDepartments) {
                await pb.collection("program_plans").create({
                  application: application.id,
                  program: debounced_data?.record?.program,
                  sub_department: subDepartment.sub_department,
                  start_date: subDepartment.start_date,
                  end_date: subDepartment.end_date,
                });
              }
            }
          } else {
            // Delete existing program plans for the single application
            const existingPlans = await pb
              .collection("program_plans_view")
              .getFullList({
                filter: `application = "${debounced_data?.record?.id}"`,
              });
              const record = await pb.collection('applications').update(debounced_data?.record?.id, {
                program_plan:{
                  en:"scheduled",
                  ar:"مجدولة"
                }
              });

            for (const plan of existingPlans) {
              await pb.collection("program_plans").delete(plan.id);
            }
            

            // Create new program plans for the single application
            for (const subDepartment of values.subDepartments) {
              await pb.collection("program_plans").create({
                application: debounced_data?.record?.id,
                program: debounced_data?.record?.program,
                sub_department: subDepartment.sub_department,
                start_date: subDepartment.start_date,
                end_date: subDepartment.end_date,
              });
            }
          }

          // Close the form and reload the data
          onClose();
          setReloader(!reloader);
        } catch (error) {
          console.error("Error handling form submission:", error);
          // Handle the error appropriately
          // e.g., show an error message to the user
        }
      })}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "start",
        padding: "20px",
        width: "100%",
        paddingBottom: "60px",
        position: "relative",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "end",
          padding: "20px 0px",
          position: "absolute",
          bottom: "0px",
          left: "10px",
        }}
      >
        <Button
          type="submit"
          // mx="xs"
          disabled={isButonDisabled}
          compact
          style={{
            backgroundColor: !isButonDisabled ? "#69DCBF" : "",
            color: !isButonDisabled ? "white" : "",
            width: "100px",
            borderRadius: "5.112px",
          }}
          loading={buttonLoading}
          onClick={() => {
            setButtonLoading(true);
          }}
        >
          {tn("Schedule")}
        </Button>

        <Button
          compact
          mx="sm"
          style={{
            color: "white",
            width: "100px",
            borderRadius: "5.112px",
            backgroundColor: "#F9837C",
          }}
          color="red"
          onClick={() => {
            onClose({});
          }}
        >
          {tn("Cancel")}
        </Button>
      </div>
      <Text
        style={{
          color: "rgba(0, 0, 0, 0.70)",
          fontSize: "20px",
          fontWeight: "700",
        }}
      >
        {tn("Schedule the program plan")}
      </Text>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            width: "calc(100% - 20px)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "start",
            backgroundColor: "rgba(79, 81, 82, 0.05)",
            borderRadius: "5px",
            marginTop: "10px",
            height: "245px",
          }}
        >
          <div
            style={{
              width: "calc(100% - 20px)",
              borderRadius: "500px",
              backgroundColor: "#21033F",
              height: "5px",
            }}
          />
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "0px 20px",
              marginTop: "10px",
            }}
          >
            <Text
              mt="xs"
              style={{
                color: "rgba(0, 0, 0, 0.70)",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              {tn("Applicant data")}
            </Text>
            {!debounced_data?.record?.[0] && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <DataBox
                  text={debounced_data?.record?.expand?.program?.name}
                  label={tn("Program:")}
                />
                <DataBox
                  text={debounced_data?.record?.department_name}
                  label={tn("Department:")}
                />
              </div>
            )}
          </div>
          <div
            style={{
              width: "calc(100% - 20px)",
              background: "white",
              borderRadius: "10px",
              margin: "20px",
              padding: "10px",
              // height: "200px",
            }}
          >
            <div
              style={{
                width: "100%",
                overflowY: "scroll",
                height: "137px",
                position: "relative",
              }}
            >
              <Table fontSize="xs" style={{}}>
                <thead
                  style={{
                    position: "sticky",
                    top: 0,
                    backgroundColor: "#fff",
                    zIndex: 100,
                    boxShadow: "0px 0px 5px rgba(87, 87, 87, 0.2)",
                  }}
                >
                  <tr>
                    {(form?.values?.all_applicants ||
                      debounced_data?.record?.[1]) &&
                      allApplicants?.[1] && (
                        <th>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          ></div>
                        </th>
                      )}

                    <th>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {tn("Program code")}
                      </div>
                    </th>

                    <th>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {tn("Applicant name")}
                      </div>
                    </th>
                    <th>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {tn("University")}
                      </div>
                    </th>
                    <th>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {tn("Major")}
                      </div>
                    </th>
                    <th>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {tn("graduation year")}
                      </div>
                    </th>
                    <th>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {tn("Semester")}
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {!debounced_data?.record?.[0] ? (
                    form.values?.all_applicants ? (
                      allApplicants?.map((item: any) => (
                        <TdBox
                          item={item}
                          locale={locale}
                          setAllApplicants={setAllApplicants}
                          allApplicants={allApplicants}
                          all_applicants={form?.values?.all_applicants}
                          data={debounced_data?.record}
                        />
                      ))
                    ) : (
                      <TdBox
                        item={debounced_data?.record}
                        locale={locale}
                        setAllApplicants={setAllApplicants}
                        allApplicants={allApplicants}
                        all_applicants={form?.values?.all_applicants}
                        data={debounced_data?.record}
                      />
                    )
                  ) : (
                    allApplicants?.map((item: any) => (
                      <TdBox
                        item={item}
                        locale={locale}
                        setAllApplicants={setAllApplicants}
                        allApplicants={allApplicants}
                        all_applicants={form?.values?.all_applicants}
                        data={debounced_data?.record}
                      />
                    ))
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
        <div
          style={{
            width: "calc(100% - 20px)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "rgba(79, 81, 82, 0.05)",
            borderRadius: "5px",
            marginTop: "10px",
          }}
        >
          <div
            style={{
              width: "calc(100% - 20px)",
              borderRadius: "500px",
              backgroundColor: "#21033F",
              height: "5px",
            }}
          />

          <div
            style={{
              marginTop: "10px",
              width: "calc(100% - 20px)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <div
              style={{
                width: "calc(100% - 20px)",
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "start",
              }}
            >
              <Text
                mt="xs"
                style={{
                  color: "rgba(0, 0, 0, 0.70)",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                {tn("Program scheduling")}
              </Text>
              <List mt="md" size="sm">
                <List.Item>{ltn("information_program_plan_1")}</List.Item>
                <List.Item>{ltn("information_program_plan_2")}</List.Item>
              </List>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "end",
                  justifyContent: "end",
                  marginTop: "10px",
                }}
              >
                {/* <Radio.Group mt="md" defaultValue="automatically">
                <Radio
                  mt="xs"
                  size="xs"
                  value="automatically"
                  label={ltn("automatically_plan")}
                  color="color2"
                />
                <Radio
                  mt="xs"
                  size="xs"
                  value="manually_plan"
                  label={ltn("manually_plan")}
                  color="color2"
                />
              </Radio.Group> */}
                <Button
                  size="xs"
                  mx="sm"
                  variant="outline"
                  color="color2"
                  style={{
                    borderRadius: "500px",
                    // width: "100px",
                    height: "30px",
                  }}
                  onClick={async () => {
                    function addBusinessDays(originalDateInput, numDaysToAdd) {
                      let currentDate = new Date(originalDateInput);
                      let daysAdded = 0;

                      while (daysAdded < numDaysToAdd) {
                        // Move to the next day
                        currentDate.setDate(currentDate.getDate() + 1);
                        // Check if the current day is a weekday
                        if (
                          currentDate.getDay() !== 5 &&
                          currentDate.getDay() !== 6
                        ) {
                          daysAdded++;
                        }
                      }

                      return currentDate;
                    }

                    // Ensure that startDate and endDate are valid Date objects
                    const startDate = new Date(
                      debounced_data?.record?.date_of_commencement
                    );
                    const endDate = new Date(
                      debounced_data?.record?.date_of_end
                    );

                    // Check if startDate and endDate are valid
                    if (
                      isNaN(startDate.getTime()) ||
                      isNaN(endDate.getTime())
                    ) {
                      console.error("Invalid start date or end date");
                      return; // Exit the function if dates are invalid
                    }

                    const diffTime = Math.abs(endDate - startDate);
                    const diffDays = Math.ceil(
                      diffTime / (1000 * 60 * 60 * 24)
                    );
                    const numSubDepartments =
                      form.values.subDepartments?.length;
                    const diffDaysPerSubDepartment = Math.floor(
                      diffDays / numSubDepartments
                    );

                    let subDepartmentStartDate = startDate;

                    const updatedSubDepartments =
                      form.values.subDepartments?.map((item, index) => {
                        let subDepartmentEndDate;

                        if (index === numSubDepartments - 1) {
                          subDepartmentEndDate = endDate;
                        } else {
                          subDepartmentEndDate = addBusinessDays(
                            subDepartmentStartDate,
                            diffDaysPerSubDepartment - 1
                          );
                        }

                        const subDepartment = {
                          ...item,
                          start_date: subDepartmentStartDate,
                          end_date: subDepartmentEndDate,
                        };

                        // Set the next sub department's start date to the day after the current end date
                        subDepartmentStartDate = addBusinessDays(
                          subDepartmentEndDate,
                          1
                        );

                        return subDepartment;
                      });

                    // Set the form field value with the updated sub-departments
                    form.setFieldValue("subDepartments", updatedSubDepartments);
                  }}
                >
                  {ltn("automatically_plan")}
                </Button>
                <Button
                  size="xs"
                  variant="outline"
                  color="color2"
                  style={{
                    borderRadius: "500px",
                    // width: "100px",
                    height: "30px",
                  }}
                  onClick={() => {
                    form.setFieldValue(
                      "subDepartments",
                      allSubDepartments?.map((item: any) => ({
                        sub_department_name: item?.name,
                        sub_department: item?.id,
                        start_date: "-",
                        end_date: "-",
                      }))
                    );
                  }}
                >
                  {tn("Reset sub departments")}
                </Button>
              </div>
              <div
                style={{
                  width: "calc(100% - 0px)",
                  background: "white",
                  borderRadius: "10px",
                  marginTop: "10px",
                  padding: "10px",
                  // height: "200px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    overflowY: "scroll",
                    height: "137px",
                    // position: "relative",
                  }}
                >
                  {form.values.subDepartments?.[0]?<Table striped fontSize="xs" style={{}}>
                    <thead
                      style={{
                        position: "sticky",
                        top: 0,
                        backgroundColor: "#fff",
                        zIndex: 100,
                        boxShadow: "0px 0px 5px rgba(87, 87, 87, 0.2)",
                      }}
                    >
                      <tr>
                        <th>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            #
                          </div>
                        </th>

                        <th>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {tn("Sub Department")}
                          </div>
                        </th>
                        <th>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {tn("Start date")}
                          </div>
                        </th>
                        <th>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {tn("End Date")}
                          </div>
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        form?.values?.subDepartments?.map((item, i) => (
                          <TdBoxDepartments
                            item={item}
                            locale={locale}
                            form={form}
                            index={i}
                            allSubDepartments={allSubDepartments}
                            debounced_data={debounced_data}
                          />
                        ))}
                    </tbody>
                  </Table>:<div style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                        }}><Loader/></div>}
                </div>
              </div>
              <Checkbox
                disabled={debounced_data?.record?.[0]}
                {...form.getInputProps("all_applicants")}
                styles={() => ({
                  label: {
                    fontWeight: "400",
                    color: "rgba(0, 0, 0, 0.70)",
                  },
                })}
                size="sm"
                mt="md"
                mb="md"
                label={tn(
                  "Would you like to schedule all applicants for this program?"
                )}
              />
            </div>
          </div>
        </div>{" "}
      </div>
    </form>
  );
};

const TdBoxDepartments = ({
  item,
  locale,
  index,
  form,
  allSubDepartments,
  debounced_data,
}: any) => {
  return (
    <tr>
      <td>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {index + 1}
        </div>
      </td>
      <td>
        <NativeSelect
          value={item?.sub_department}
          onChange={(e: any) => {
            if (
              form.values.subDepartments?.find(
                (item_: any, i: any) =>
                  i != index && item_?.sub_department == e.target.value
              )
            ) {
              form.setFieldValue(
                `subDepartments.${form.values.subDepartments?.findIndex(
                  (item_: any, i: any) =>
                    i != index && item_?.sub_department == e.target.value
                )}.sub_department`,
                form.values.subDepartments?.[index]?.sub_department
              );
              form.setFieldValue(
                `subDepartments.${form.values.subDepartments?.findIndex(
                  (item_: any, i: any) =>
                    i != index && item_?.sub_department == e.target.value
                )}.sub_department_name`,
                form.values.subDepartments?.[index]?.sub_department_name
              );
            }
            form.setFieldValue(
              `subDepartments.${index}.sub_department`,
              e.target.value
            );
            form.setFieldValue(
              `subDepartments.${index}.sub_department_name`,
              allSubDepartments?.find(
                (item_: any) => item_?.id == e.target.value
              )?.name
            );
            // if the user select a sub department and already its in the list then change the already selected sub department to the new one
          }}
          size="xs"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
          data={allSubDepartments?.map((department: any) => ({
            label: department?.name,
            value: department?.id,
          }))}
        />
      </td>
      <td style={{direction: "ltr",}}>
        <Popover position="bottom" withArrow shadow="md">
          <Popover.Target>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <div />
              <div />
              {item?.start_date == "-"
                ? item?.start_date
                : new Date(item?.start_date)?.toLocaleDateString("en-GB")}
              <Icon icon="zondicons:date-add" />
              <div />
            </div>
          </Popover.Target>
          <Popover.Dropdown>
            <DatePicker 
             nextIcon={
              <IconChevronRight size={20} style={{ color: "#1d604f" }} />
            }
            previousIcon={
              <IconChevronLeft size={20} style={{ color: "#1d604f" }} />
            }
            firstDayOfWeek={0}
             
              minDate={
                index > 0
                  ? new Date(
                      dayjs(
                        form?.values?.subDepartments?.[index - 1]?.end_date ||
                          new Date()
                      )
                        .add(1, "day")
                        .format("YYYY-MM-DD")
                    )
                  : new Date(
                      debounced_data?.record?.date_of_commencement
                    )
              }
              maxDate={
                form?.values?.subDepartments?.[index]?.end_date != "-"
                  ? new Date(
                      dayjs(
                        form?.values?.subDepartments?.[index]?.end_date ||
                          new Date()
                      )
                        .add(-1, "day")
                        .format("YYYY-MM-DD")
                    )
                  : new Date(debounced_data?.record?.date_of_end)
              }
              value={
                item?.start_date == "-"
                  ? new Date()
                  : new Date(item?.start_date)
              }
              onChange={(date: any) => {
                form.setFieldValue(`subDepartments.${index}.start_date`, date);
              }}
              // withSelect
              size="xs"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            ></DatePicker>
          </Popover.Dropdown>
        </Popover>
      </td>{" "}
      <td style={{direction: "ltr",}}>
        <Popover position="bottom" withArrow shadow="md">
          <Popover.Target>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <div />
              <div />
              {item?.end_date == "-"
                ? item?.end_date
                : new Date(item?.end_date)?.toLocaleDateString("en-GB")}
              <Icon icon="zondicons:date-add" />
              <div />
            </div>
          </Popover.Target>
          <Popover.Dropdown>
            <DatePicker  nextIcon={
                <IconChevronRight size={20} style={{ color: "#1d604f" }} />
              }
              previousIcon={
                <IconChevronLeft size={20} style={{ color: "#1d604f" }} />
              }
              firstDayOfWeek={0}
           
              minDate={
                new Date(
                  dayjs(
                    form?.values?.subDepartments?.[index]?.start_date ||
                      new Date()
                  )
                    .add(1, "day")
                    .format("YYYY-MM-DD")
                )
              }
              maxDate={
                form?.values?.subDepartments?.[index + 1]?.start_date != "-"
                  ? new Date(
                      dayjs(
                        form?.values?.subDepartments?.[index + 1]?.start_date ||
                          new Date()
                      )
                        .add(-1, "day")
                        .format("YYYY-MM-DD")
                    )
                  : new Date(debounced_data?.record?.date_of_end)
              }
              value={
                item?.end_date == "-" ? new Date() : new Date(item?.end_date)
              }
              onChange={(date: any) => {
                form.setFieldValue(`subDepartments.${index}.end_date`, date);
              }}
              // withSelect
              size="xs"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            ></DatePicker>
          </Popover.Dropdown>
        </Popover>
      </td>{" "}
      { item && (
        <td>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
           {form?.values?.subDepartments?.[1] && <ActionIcon
              color="red"
              size="xs"
              onClick={() => {
                form.setFieldValue(
                  "subDepartments",
                  form?.values?.subDepartments?.filter(
                    (item_: any) =>
                      item_?.sub_department != item?.sub_department
                  )
                );
              }}
            >
              <Icon icon="carbon:close-filled" />
            </ActionIcon>}
          </div>
        </td>
      )}
    </tr>
  );
};

const TdBox = ({
  item,
  locale,
  setAllApplicants,
  allApplicants,
  all_applicants,
  data,
}: any) => (
  <tr>
    {(all_applicants || data?.[1]) && allApplicants?.[1] && (
      <td>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ActionIcon
            color="red"
            size="xs"
            onClick={() => {
              setAllApplicants((prev: any) =>
                prev.filter((item_: any) => item_?.id != item?.id)
              );
            }}
          >
            <IconX size="0.7rem" stroke={1.5} />
          </ActionIcon>
        </div>
      </td>
    )}
    <td>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {item?.expand?.program?.code}
      </div>
    </td>
    <td>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {item?.applicant_name}
      </div>
    </td>
    <td>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {item.university?.[locale]}
      </div>
    </td>{" "}
    <td>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {item?.major?.[locale]}
      </div>
    </td>{" "}
    <td>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {item?.graduation_year}
      </div>
    </td>{" "}
    <td>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {item?.semester?.[locale]}
      </div>
    </td>
  </tr>
);

const DataBox = ({ text, label }: any) => (
  <div
    style={{
      marginTop: "10px",
      padding: "5px",
      borderRadius: "5px",
      backgroundColor: "#ffffff",
      position: "relative",
      border: "1px solid rgba(0, 0, 0 , 0.70)",
    }}
  >
    <Text
      style={{
        color: "rgba(0, 0, 0, 0.70)",
        fontSize: "12px",
        fontWeight: "500",
        position: "absolute",
        transform: "translate(-5px, -14px)",
        background: "white",
        borderRadius: "5px",
        padding: "0px 5px",
      }}
    >
      {label}
    </Text>
    <Text
      style={{
        color: "rgba(0, 0, 0, 0.70)",
        fontSize: "14px",
        fontWeight: "500",
      }}
    >
      {text}
    </Text>
  </div>
);
