import { isNotEmpty, useForm } from "@mantine/form";
import {  tn, vtn } from "../../../../../../translation";
import _ from "lodash";

import {
  Button,
  Loader,
} from "@mantine/core";

import { useContext, useEffect, useState } from "react";
import { CMS_ENDPOINT } from "../../../../../../constants";
import PocketBase from "pocketbase";
import { AppContext } from "../../../../../../context/AppContext";
import { useDebouncedValue, useViewportSize } from "@mantine/hooks";
import { ProgramForm } from "./program_form/ProgramForm";
import { DataContext } from "../../../../../../context/DataContext";
const pb = new PocketBase(CMS_ENDPOINT);
export const AdoptProgram = ({ data, onClose }: any) => {



  const [debounced_data] = useDebouncedValue(data?.record, 200);
 const {setEditProgram}:any = useContext(DataContext);
  const { setReloader,reloader }: any = useContext(AppContext);

  const required_field = vtn("required_field");

  const address_validation = (value:any, values:any) => {
    if (values?.address_type === "New address") {
      return value ? null : required_field;
    } else {
      return null;
    }
  };
  const [company, setCompany]: any = useState({});

  const [departments, setDepartments] = useState([]);
 
  const fill_all_fields = vtn("fill_all_fields");
  const form: any = useForm({
    initialValues: {
      code : debounced_data?.code,
      department: debounced_data?.department,
      type: debounced_data?.type?.en,
      nature: debounced_data?.nature?.en,
      name: debounced_data?.name,
      brief: debounced_data?.brief,
      objective: debounced_data?.objective,
      period: debounced_data?.period?.en,
      degree: debounced_data?.degree?.[0]
      ? debounced_data?.degree?.map((item: any) => item?.en)
      : [],
      targeted_majors: debounced_data?.major?.[0]
        ? debounced_data?.major?.map((item: any) => item?.en)
        : [],
      target_educational_segment: debounced_data
        ?.target_educational_segment?.[0]
        ? debounced_data?.target_educational_segment?.map(
            (item: any) => item?.en
          )
        : [],
     
      candidate_in_need: parseInt(debounced_data?.candidate_in_need),
      incentive: parseFloat(debounced_data?.incentive)
        ? parseFloat(debounced_data?.incentive)
        : 0,
        is_incentive: debounced_data?.incentive ? true : false,
        last_submission_date:debounced_data?.last_submission_date? new Date(debounced_data?.last_submission_date):null,
        start_date:debounced_data?.start_date? new Date(debounced_data?.start_date):null,
        address_type: debounced_data?.address_type?.en,
      city: debounced_data?.city?.en,
      location: debounced_data?.location,
      building_number: debounced_data?.building_number,
      floor: debounced_data?.floor,
      office_no: debounced_data?.office_no,
          
      has_interview: debounced_data?.has_interview,
      interview_type: debounced_data?.interview_type?.en,
      personal_interview_link:  debounced_data?.personal_interview_link,
      has_filtration_test:debounced_data?.has_filtration_test,
      other_requirements: debounced_data?.other_requirements?.[0]
        ? debounced_data?.other_requirements
        : [],
        required_skills: debounced_data?.required_skills?.[0]
        ? debounced_data?.required_skills?.map((item: any) => item?.en)
        : [],
        additional_instructions: debounced_data?.additional_instructions,
        attachment_1:debounced_data?.attachment_1?{name: debounced_data?.attachment_1,initial:true}:null,
        attachment_2: debounced_data?.attachment_2?{name: debounced_data?.attachment_2,initial:true}:null,
      universities: debounced_data?.universities?.[0]
        ? debounced_data?.universities?.map((item: any) => item?.en)
        : [],
      academic_average_4: parseFloat(debounced_data?.GPA_4),
      academic_average_5: parseFloat(debounced_data?.GPA_5),
          allUniversities:  data?.universities?.[0]?.en == "all universities",
      // status: {},
      filtration_test: debounced_data?.filtration_test ?? "",
    },

    validate: {
      personal_interview_link: (value, values) => {
        if (values?.has_interview) {
          if (values?.interview_type == "remote") {
            return value ? null : required_field;
          } else {
            return null;
          }
        } else {
          return null;
        }
      },

      department: isNotEmpty(required_field),
      type: isNotEmpty(required_field),
      name: isNotEmpty(required_field),
      brief: isNotEmpty(required_field),
      objective: isNotEmpty(required_field),
      targeted_majors: isNotEmpty(required_field),
      target_educational_segment: isNotEmpty(required_field),
      period: isNotEmpty(required_field),
      candidate_in_need: (value) => {
        const current_department: any = departments?.find(
          (item: any) => item?.id == form?.values?.department
        );
        if (value) {
          if (current_department?.[`current_acceptance_ceiling`] < value) {
            return `لا يمكن ان يكون عدد المتدربين اكثر من ${
              current_department?.[`current_acceptance_ceiling`]
            }`;
          }
        } else {
          return required_field;
        }
      },
      city: address_validation,
      location: address_validation,
      building_number: address_validation,
      floor: address_validation,
      office_no: address_validation,
      last_submission_date: isNotEmpty(required_field),
      start_date: isNotEmpty(required_field),
      degree: isNotEmpty(required_field),
      universities:  (value,values) => {
        if(!values?.allUniversities){
          return !value?.[0] ? required_field : null
        }
      },
      other_requirements: (value: any) => {
        if (value?.[0]) {
          if (value?.filter((item: any) => item?.name == "")?.[0]) {
            return fill_all_fields;
          } else {
            return null;
          }
        } else {
          return null;
        }
      },
    },
  });
  useEffect(() => {
    const resultList = pb
      .collection("departments_view")
      .getList(1, 150, {})
      .then((res: any) => {
        setDepartments(res?.items);
      });
  }, []);
  useEffect(() => {
    const resultList = pb
      .collection("companies_view")
      .getFirstListItem("")
      .then((res: any) => {
        setCompany(res);
      });
  }, []);
  
  useEffect(() => {
    if (form.values?.incentive == "") {
      form.setFieldValue("incentive", 0);
    }
  }, [form.values?.incentive]);
 
  const { width } = useViewportSize();
  return width > 10 ? (
    <form
      style={{
        width: "100%",
      }}
    >
       <ProgramForm
       disabled={true}
        company={company}
        form={form}
        data={data}
        title={tn("Adopt program")}
        buttons={
          <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "end",
            padding: "20px 0px",
          }}
        >
         <Button  mx="sm"
            compact
            style={{
              backgroundColor: "#69DCBF",
              color: "white",
              minWidth: "100px",
              borderRadius: "5.112px",
            }}
            onClick={()=>{
              pb.collection("programs").update(debounced_data?.id,{
                status:{
                  en:"new",
                  ar:"جديد"
                },
                publish_date: new Date()
                	
              })?.then(()=>{
                setReloader(!reloader);
                onClose({});
              })
            }}
          >
            {tn("Adopt and publish")}
          </Button>
          <Button
            compact
            style={{
              backgroundColor: "#FFB004",
              color: "white",
              minWidth: "100px",
              borderRadius: "5.112px",
            }}
            onClick={()=>{
              setEditProgram(debounced_data);
              onClose({});
            }}
          >
            {tn("Edit Program")}
          </Button>
         
          <Button
            compact
            mx="sm"
            style={{
              color: "white",
              width: "100px",
              borderRadius: "5.112px",
              backgroundColor: "#F9837C",
            }}
            color="red"
            onClick={() => {
              onClose({});
            }}
          >
            {tn("Cancel")}
          </Button>
        </div>
        }
      />
     
    </form>
  ) : (
    <div
      style={{
        height: "1270px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Loader color="color1" />
    </div>
  );
};



 