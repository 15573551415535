import { ltn, tn } from "../../../../../../translation";
import { Button, Text } from "@mantine/core";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../../../../../constants";
import { useContext, useState } from "react";
import { AppContext } from "../../../../../../context/AppContext";
import { useDebouncedValue } from "@mantine/hooks";

export const RejectInvitation = (props: any) => {
  const pb: any = new PocketBase(CMS_ENDPOINT);
  const { data, onClose } = props;
  const [debounced_data] = useDebouncedValue(data, 200);
  const [buttonLoading, setButtonLoading] = useState(false);

  const { reloader, setReloader } = useContext(AppContext);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "start",
        padding: "20px",
        width: "100%",
      }}
    >
      <Text fw={600} color="#5c5c5c">
          {ltn("reject_invetaion")}
      </Text>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <Button
          loading={buttonLoading}
          color="red"
          mx={5}
          onClick={
            debounced_data?.record?.[0]
              ? async () => {
                  setButtonLoading(true);

                  for (const record of debounced_data?.record || []) {
                    await pb
                      .collection(debounced_data?.table?.table)
                      .update(record, {
                        status: {	
                          en: "rejected",
                          ar: "مرفوضة",
                        },
                      });
                  }
                  onClose({});
                  setReloader(!reloader);
                }
              : async () => {
                  setButtonLoading(true);
                  	
                  await pb
                    .collection(debounced_data?.table?.table)
                    .update(debounced_data?.record?.id, {
                      status: {
                        en: "rejected",
                          ar: "مرفوضة",
                      },
                    });
                  onClose({});
                  setReloader(!reloader);
                }
          }
        >
          {tn("Reject")}
        </Button>
        <Button
          variant="outline"
          color="gray"
          mx={5}
          onClick={() => onClose({})}
        >
          {tn("Cancel")}
        </Button>
      </div>
    </div>
  );
};
