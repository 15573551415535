import { ltn, tn, vtn } from "../../../../../../translation";
import dayjs from "dayjs";
import { DateInput } from "@mantine/dates";

import {
  Button,
  Checkbox,
  Divider,
  Group,
  Loader,
  MultiSelect,
  NativeSelect,
  NumberInput,
  RangeSlider,
  Select,
  Slider,
  Text,
  TextInput,
  Textarea,
  Transition,
} from "@mantine/core";

import { useContext, useEffect, useState } from "react";
import { TranslationContext } from "../../../../../../context/TranslationContext";
import { CMS_ENDPOINT } from "../../../../../../constants";
import PocketBase from "pocketbase";
import { UserContext } from "../../../../../../context/UserContext";
import { AppContext } from "../../../../../../context/AppContext";
import { useDebouncedValue, useViewportSize } from "@mantine/hooks";
import { BannersContext } from "../../../../../../context/BannersContext";
import { IconSquareRoundedCheck } from "@tabler/icons-react";
import { RequirementsList } from "./program_form/RequirementsList";
import { de, it } from "date-fns/locale";
import { parse } from "alasql";
import { RequirementsListView } from "../../../../../../recruiter/components/RequirementsListView";
import { DataContext } from "../../../../../../context/DataContext";
const pb = new PocketBase(CMS_ENDPOINT);
export const ViewProgram = ({ data, onClose }: any) => {
  const [debounced_data] = useDebouncedValue(data, 200);

  const { translation, locale }: any = useContext(TranslationContext);
  const { reloader, setReloader }: any = useContext(AppContext);
  const { setEditProgram }: any = useContext(DataContext);

  const requirements = translation?.requirements;
  const [departments, setDepartments] = useState([]);
  const dialogMassage = ltn("user_added_successfully");
  const program_type = translation?.program_type;
  const city = translation?.city;
  const major = translation?.major;
  const degree = translation?.degree;

  const case_study = translation?.case_study;
  const period = translation?.period;
  const university = translation?.university;
  const fill_all_fields = vtn("fill_all_fields");
  
  useEffect(() => {
    const resultList = pb
      .collection("departments")
      .getList(1, 150, {})
      .then((res: any) => {
        setDepartments(res?.items);
      });
  }, []);
  const { width } = useViewportSize();
  return width > 10 ? (
  
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text
          style={{
            width: "100%",
            padding: "10px 20px 10px ",
            color: "rgba(0, 0, 0, 0.70)",
            textAlign: "right",
            fontSize: "22px",
            fontWeight: "700",
          }}
        >
          {tn("View program")}
        </Text>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            padding: "0px 10px",
            flexDirection: width > 850 ? "row" : "column",
            alignItems: width > 850 ? "stretch" : "stretch",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              width: width > 850 ? "calc(50% - 10px)" : "calc(100% - 10px)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "start",
              background: "rgba(79, 81, 82, 0.05)",
              borderRadius: "10px",
            }}
          >
            <div
              style={{
                width: "93%",
                  background: "#21033F",
                height: "5px",
                borderRadius: "522px",
              }}
            />
            <Text
              style={{
                width: "100%",
                padding: "10px 20px",
                color: "rgba(0, 0, 0, 0.70)",
                textAlign: "right",
                fontSize: "12px",
                fontWeight: "700",
              }}
            >
              {tn("Basic data of the program:")}
            </Text>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
                gap: "10px",
                padding: "0px 10px",
              }}
            >
              <Select
                data={
                  departments?.[0]
                    ? departments?.map((item: any) => {
                        return { value: item?.id, label: item?.name };
                      })
                    : []
                }
                value={debounced_data?.department}
                disabled
                label={tn("Select section")}
                styles={() => TextInputStyle(null)}
                size="xs"
                style={{ width: "100%" }}
              />{" "}
              <Select
                data={
                  program_type?.[0]
                    ? program_type?.map((item: any) => {
                        return {
                          value: item?.name_en,
                          label: item?.[`name_${locale}`],
                        };
                      })
                    : []
                }
                disabled
                value={debounced_data?.type?.en}
                
                label={tn("Program type")}
                styles={() => TextInputStyle(null)}
                size="xs"
                style={{ width: "100%" }}
              />
            </div>
            <TextInput
              style={{
                marginTop: "10px",
                width: "100%",
                padding: "0px 10px",
              }}
              disabled
              value={debounced_data?.name}
              
              label={tn("Program name")}
              styles={() => TextInputStyle(null)}
              size="xs"
            />
            <div
              style={{
                marginTop: "10px",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
                gap: "10px",
                padding: "0px 10px",
              }}
            >
              <Textarea autosize
              disabled  
                  value={debounced_data?.brief}                
                label={tn("Program brief")}
                styles={() => TextInputStyle(null)}
                size="xs"
                style={{ width: "100%" }}
              />{" "}
              <Textarea autosize
               disabled
                value={debounced_data?.objective}
                
                label={tn("Program objectives")}
                styles={() => TextInputStyle(null)}
                size="xs"
                style={{ width: "100%" }}
              />
            </div>{" "}
            <div
              style={{
                marginTop: "10px",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
                gap: "10px",
                padding: "0px 10px",
              }}
            >
              <MultiSelect
                maxValues={2}
                searchable
                data={
                  degree?.[0]
                    ? degree?.map((item: any) => {
                        return {
                          value: item?.name_en,
                          label: item?.[`name_${locale}`],
                        };
                      })
                    : []
                }
                disabled
                value={debounced_data?.degree?.map((item: any) => item?.en)}
                
                label={tn("Targeted degrees")}
                styles={() => TextInputStyle(null)}
                size="xs"
                style={{ width: "100%" }}
              />{" "}
              <MultiSelect
                data={
                  case_study?.[0]
                    ? case_study?.map((item: any) => {
                        return {
                          value: item?.name_en,
                          label: item?.[`name_${locale}`],
                        };
                      })
                    : []
                }
                disabled
                value={debounced_data?.target_educational_segment?.map((item: any) => item?.en)}
                
                label={tn("Target educational segment")}
                styles={() =>
                  TextInputStyle(null)
                }
                size="xs"
                style={{ width: "100%" }}
              />
            </div>
            <div
              style={{
                marginTop: "10px",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
                gap: "10px",
                padding: "0px 10px",
              }}
            >
               <MultiSelect
                maxValues={2}
                searchable
                data={
                  major?.[0]
                    ? major?.map((item: any) => {
                        return {
                          value: item?.name_en,
                          label: item?.[`name_${locale}`],
                        };
                      })
                    : []
                }
                disabled
                value={debounced_data?.major?.map((item: any) => item?.en)}
                
                label={tn("Targeted majors")}
                styles={() => TextInputStyle(null)}
                size="xs"
                style={{ width: "100%" }}
              />
             {" "}
              <TextInput
                // hideControls={true}
                disabled
                value={debounced_data?.candidate_in_need}
                
                label={tn("Candidate in need")}
                styles={() => TextInputStyle(null)}
                size="xs"
                style={{ width: "100%" }}
              />
            </div>
            <div
              style={{
                marginTop: "10px",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
                gap: "10px",
                padding: "0px 10px",
              }}
            >
               <Select
                data={
                  period?.[0]
                    ? period?.map((item: any) => {
                        return {
                          value: item?.name_en,
                          label: item?.[`name_${locale}`],
                        };
                      })
                    : []
                }
                disabled
                value={debounced_data?.period?.en}
                
                label={tn("program period")}
                styles={() => TextInputStyle(null)}
                size="xs"
                style={{ width: "100%" }}
              />
             
              <TextInput
               disabled
                value={debounced_data?.location}
                
                label={tn("location")}
                styles={() => TextInputStyle(null)}
                size="xs"
                style={{ width: "100%" }}
              />
            </div>
            
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0px 5px",
                gap: "10px",

              }}
            >
               <Select
                data={
                  city?.[0]
                    ? city?.map((item: any) => {
                        return {
                          value: item?.name_en,
                          label: item?.[`name_${locale}`],
                        };
                      })
                    : []
                }
                disabled
                value={debounced_data?.city?.en}
                
                label={tn("City")}
                styles={() => TextInputStyle(null)}
                size="xs"
                style={{ width: "100%" }}
              />{" "}
                <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
              <Checkbox
                mt="23px"
                disabled
                checked={debounced_data?.incentive != null}
                size="xs"
                styles={() => ({
                  label: {
                    fontSize: "12px",
                    color: "#FFB004",
                  },
                })}
               
              />
              <TextInput
              disabled
                value={debounced_data?.incentive}
                style={{
                  width: "100%",
                }}
                label={tn("Program incentive")}

                rightSection={<Text color="#5c5c5c">{tn("riyal")}</Text>}
                rightSectionProps={{
                  fontSize: "10px",
                }}
                rightSectionWidth={40}
                size="xs"
                hideControls={true}
                styles={() => TextInputStyle(null)}
              />
              </div>
            </div>
            <div
              style={{
                marginTop: "10px",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
                gap: "10px",
                padding: "0px 10px",
                marginBottom: "10px",
              }}
            >
              <TextInput
                disabled
                value={new Date(debounced_data?.last_submission_date)?.toLocaleDateString('en-GB')}
                
                label={tn("Last submission date")}
                styles={() => TextInputStyle(null)}
                size="xs"
                style={{ width: "100%" }}
              />{" "}
                <TextInput
                disabled
                value={new Date(debounced_data?.start_date)?.toLocaleDateString('en-GB')}
                
                label={tn("Start Date")}
                styles={() => TextInputStyle(null)}
                size="xs"
                style={{ width: "100%" }}
              />
            </div>
          </div>
          <div
            style={{
              width: width > 850 ? "calc(50% - 10px)" : "calc(100% - 10px)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "start",
              background: "rgba(79, 81, 82, 0.05)",
              borderRadius: "10px",
              marginTop: width > 850 ? "" : "10px",
            }}
          >
            <div
              style={{
                width: "93%",
                  background: "#21033F",
                height: "5px",
                borderRadius: "522px",
              }}
            />
            <Text
              style={{
                width: "100%",
                padding: "10px 20px",
                color: "rgba(0, 0, 0, 0.70)",
                textAlign: "right",
                fontSize: "12px",
                fontWeight: "700",
              }}
            >
              {tn("Requirements to join the program:")}
            </Text>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                // gap:"10px",
                padding: "0px 10px",
              }}
            >
              {requirements?.map((item: any) => (
                <div style={{
                  width:"100%",
                  display:"flex",
                  justifyContent:"space-between",
                  alignItems:"center",
                  position:"relative",
                  // padding:"0px 10px",
                  // margin:"5px 0px"
                }}>
                  <div style={{
                    position: "absolute",
                    width: "100%",
                    height: "40px",
                    background: "#ffffff36",
                    zIndex: 1,
                  }}/>
                <Checkbox
                
                 checked={
                  debounced_data?.requirements?.find(
                    (x: any) => x?.en == item?.name_en
                  )
                 }
                 
                  size={"xs"}
                  color="color1"
                  //@ts-ignore
                  styles={() => CheckboxStyle}
                  label={item?.[`name_${locale}`]}
                /></div>
              ))}

              <Divider
                my="xs"
                style={{ width: "100%" }}
                label={tn("Other requirements")}
                labelPosition="center"
              />
              <RequirementsListView data={debounced_data?.other_requirements} />
              
            
              <Text
                fz="xs"
                style={{
                  color: "rgba(0, 0, 0, 0.70)",
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "100%",
                  margin: "10px 0px 0px",
                  padding: "0px 10px",
                }}
              >
                {tn("Candidate filter tools:")}
              </Text>
              {data?.universities?.[0]?.en=="all universities"?<Select data={[
                {
                  value: data?.universities?.[0]?.en,
                  label: data?.universities?.[0]?.[locale],
                }
              ]}
              disabled
              value={data?.universities?.[0]?.en}
              label={tn("Target universities")}
              styles={TextInputStyle(null)}
              style={{
                marginTop: "0px",
                width: "100%",
                padding: "0px 10px",
              }}
              size="xs"
              />:<MultiSelect
                searchable
                data={
                  university?.[0]
                    ? university?.map((item: any) => {
                        return {
                          value: item?.name_en,
                          label: item?.[`name_${locale}`],
                        };
                      })
                    : []
                }
                style={{
                  marginTop: "0px",
                  width: "100%",
                  padding: "0px 10px",
                }}
                disabled
                value={debounced_data?.universities?.map((item: any) => item?.en)}
                
                label={tn("Target universities")}
                styles={TextInputStyle(null)}
                size="xs"
              />}
              <div
                style={{
                  height: "82px",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginBottom: "28px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    margin: "20px 0px 0px",
                    padding: "0px 10px",
                  }}
                >
                  <Text fz="xs">{tn("Academic average of 5")}</Text>
                  <TextInput
                  disabled
                    value={debounced_data?.GPA_5}
                    styles={TextInputStyle(null)}
                    size="xs"
                    style={{
                      width: "70%",
                      maxWidth: "240px",
                    }}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    margin: "10px 0px 0px",
                    padding: "0px 10px",
                  }}
                >
                  <Text fz="xs">{tn("Academic average of 4")}</Text>
                  <TextInput
                  disabled
                    value={debounced_data?.GPA_4}
                    styles={TextInputStyle(null)}
                    size="xs"
                    style={{
                      width: "70%",
                      maxWidth: "240px",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "end",
            padding: "20px 0px",
          }}
        >
           {/* <Button
            type="submit"
            mx="xs"
            compact
            style={{
              backgroundColor: "#69DCBF",
              color: "white",
              minWidth: "100px",
              borderRadius: "5.112px",
            }}
            onClick={()=>{
              form.setFieldValue("status",{
                en:"Current",
                ar:"جاري"
              })
            }}
          >
            {tn("Create and publish")}
          </Button> */}
          <Button
            compact
            style={{
              backgroundColor: "#69DCBF",
              color: "white",
              minWidth: "100px",
              borderRadius: "5.112px",
            }}
            onClick={()=>{
              setEditProgram(debounced_data)
              onClose({});
            }}
          >
            {tn("Edit Program")}
          </Button>
         
          <Button
            compact
            mx="sm"
            style={{
              color: "white",
              width: "100px",
              borderRadius: "5.112px",
              backgroundColor: "#F9837C",
            }}
            color="red"
            onClick={() => {
              onClose({});
            }}
          >
            {tn("Cancel")}
          </Button>
        </div>
      </div>
  ) : (
    <div
      style={{
        height: "1270px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Loader color="color1" />
    </div>
  );
};
const TextInputStyle = (error: any) => ({
  description: {
    marginBottom: "-17px",
    transform: "translate(5px, 35px)",
    fontSize: "8px",
    opacity: error ? "0" : "1",
  },
  label: {
    zIndex: "10",
    position: "relative",
    margin: "0px 10px",
    transform: "translate(0px, 7px)",
    background: "#f6f6f6",
    borderRadius: "5px",
    padding: "0px 5px",
    fontSize: "10px",
  },
  input: {
    overflow: "scroll",
    borderRadius: "5px",
    border: "1px solid #000000",
    "&:disabled": {
      color: "#000000",
      opacity: "1",
      backgroundColor: "#fbfafa",
  },},
  error: {
    marginBottom: "-17px",
    transform: "translate(4px, 3px)",
    // fontSize: "8px",
  },
});

const CheckboxStyle = {
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    background: "rgb(255, 255, 255)",
    padding: "10px",
    borderRadius: "5px",
    marginBottom: "5px",
  },
};

const CheckIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 15 15"
    fill="none"
  >
    <path
      d="M7.5 1.40625C6.29477 1.40625 5.11661 1.76364 4.1145 2.43323C3.11238 3.10282 2.33133 4.05454 1.87011 5.16802C1.40889 6.28151 1.28821 7.50676 1.52334 8.68883C1.75847 9.8709 2.33884 10.9567 3.19107 11.8089C4.0433 12.6612 5.1291 13.2415 6.31117 13.4767C7.49324 13.7118 8.71849 13.5911 9.83198 13.1299C10.9455 12.6687 11.8972 11.8876 12.5668 10.8855C13.2364 9.88339 13.5938 8.70523 13.5938 7.5C13.5917 5.88446 12.9491 4.33566 11.8067 3.19329C10.6643 2.05093 9.11555 1.40827 7.5 1.40625ZM9.84375 7.96875H7.96875V9.84375C7.96875 9.96807 7.91937 10.0873 7.83146 10.1752C7.74355 10.2631 7.62432 10.3125 7.5 10.3125C7.37568 10.3125 7.25645 10.2631 7.16855 10.1752C7.08064 10.0873 7.03125 9.96807 7.03125 9.84375V7.96875H5.15625C5.03193 7.96875 4.9127 7.91936 4.8248 7.83146C4.73689 7.74355 4.6875 7.62432 4.6875 7.5C4.6875 7.37568 4.73689 7.25645 4.8248 7.16854C4.9127 7.08064 5.03193 7.03125 5.15625 7.03125H7.03125V5.15625C7.03125 5.03193 7.08064 4.9127 7.16855 4.82479C7.25645 4.73689 7.37568 4.6875 7.5 4.6875C7.62432 4.6875 7.74355 4.73689 7.83146 4.82479C7.91937 4.9127 7.96875 5.03193 7.96875 5.15625V7.03125H9.84375C9.96807 7.03125 10.0873 7.08064 10.1752 7.16854C10.2631 7.25645 10.3125 7.37568 10.3125 7.5C10.3125 7.62432 10.2631 7.74355 10.1752 7.83146C10.0873 7.91936 9.96807 7.96875 9.84375 7.96875Z"
      fill="#69DCBF"
    />
  </svg>
);

const Xicon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <g opacity="0.85">
      <path
        d="M9 1.6875C7.55373 1.6875 6.13993 2.11637 4.9374 2.91988C3.73486 3.72339 2.7976 4.86544 2.24413 6.20163C1.69067 7.53781 1.54586 9.00811 1.82801 10.4266C2.11017 11.8451 2.80661 13.148 3.82928 14.1707C4.85196 15.1934 6.15492 15.8898 7.57341 16.172C8.99189 16.4541 10.4622 16.3093 11.7984 15.7559C13.1346 15.2024 14.2766 14.2651 15.0801 13.0626C15.8836 11.8601 16.3125 10.4463 16.3125 9C16.3105 7.06123 15.5394 5.20246 14.1685 3.83154C12.7975 2.46063 10.9388 1.68955 9 1.6875ZM11.648 10.852C11.7002 10.9043 11.7417 10.9663 11.77 11.0346C11.7983 11.1029 11.8128 11.1761 11.8128 11.25C11.8128 11.3239 11.7983 11.3971 11.77 11.4654C11.7417 11.5337 11.7002 11.5957 11.648 11.648C11.5957 11.7002 11.5337 11.7417 11.4654 11.77C11.3971 11.7983 11.3239 11.8128 11.25 11.8128C11.1761 11.8128 11.1029 11.7983 11.0346 11.77C10.9663 11.7417 10.9043 11.7002 10.852 11.648L9 9.79523L7.14797 11.648C7.09571 11.7002 7.03367 11.7417 6.96538 11.77C6.8971 11.7983 6.82391 11.8128 6.75 11.8128C6.67609 11.8128 6.60291 11.7983 6.53462 11.77C6.46634 11.7417 6.4043 11.7002 6.35203 11.648C6.29977 11.5957 6.25832 11.5337 6.23003 11.4654C6.20175 11.3971 6.18719 11.3239 6.18719 11.25C6.18719 11.1761 6.20175 11.1029 6.23003 11.0346C6.25832 10.9663 6.29977 10.9043 6.35203 10.852L8.20477 9L6.35203 7.14797C6.24649 7.04242 6.18719 6.89927 6.18719 6.75C6.18719 6.60073 6.24649 6.45758 6.35203 6.35203C6.45758 6.24648 6.60074 6.18719 6.75 6.18719C6.89927 6.18719 7.04242 6.24648 7.14797 6.35203L9 8.20477L10.852 6.35203C10.9043 6.29977 10.9663 6.25831 11.0346 6.23003C11.1029 6.20174 11.1761 6.18719 11.25 6.18719C11.3239 6.18719 11.3971 6.20174 11.4654 6.23003C11.5337 6.25831 11.5957 6.29977 11.648 6.35203C11.7002 6.40429 11.7417 6.46634 11.77 6.53462C11.7983 6.6029 11.8128 6.67609 11.8128 6.75C11.8128 6.82391 11.7983 6.8971 11.77 6.96538C11.7417 7.03366 11.7002 7.09571 11.648 7.14797L9.79524 9L11.648 10.852Z"
        fill="#F1623F"
      />
    </g>
  </svg>
);
