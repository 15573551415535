import {
  Paper,
  createStyles,
  TextInput,
  PasswordInput,
  Select,
  Checkbox,
  Button,
  Title,
  Text,
  Anchor,
  rem,
  Modal,
  NativeSelect,
  FileInput,
  Center,
  FileButton,
} from "@mantine/core";
import {
  useForm,
  isNotEmpty,
  isEmail,
  isInRange,
  hasLength,
  matches,
} from "@mantine/form";

import { useNavigate } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { useDebouncedValue, useDisclosure, useViewportSize } from "@mantine/hooks";
import { AppContext } from "../../context/AppContext";
import { TranslationContext } from "../../context/TranslationContext";
import { ltn, tn, vtn } from "../../translation";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../constants";
import { UserContext } from "../../context/UserContext";
import { MIME_TYPES } from "@mantine/dropzone";
import { IconPhoto, IconUpload } from "@tabler/icons-react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import { Helmet } from "react-helmet";

const useStyles = createStyles((theme) => ({
  wrapper: {
    height: "100vh",
    display: "flex",
  },

  form: {
    zIndex: 1,
    background: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowY: "scroll",
    scrollbarWidth: "none",
    // justifyContent: 'center',
    height: "100vh",

    //
    maxHeight: "100vh",
    maxWidth: rem(700),
    paddingTop: rem(80),
    width: "100%",

    [theme.fn.smallerThan("md")]: {
      maxWidth: "100%",
    },
  },
  inner: {
    maxWidth: rem(500),
    width: "100%",
  },

  title: {
    color: "#21033F",
    fontSize: "40px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  },
}));
export function RecruiterRegisterPage() {
  const [opened, { open, close }] = useDisclosure(false);

  const pb: any = new PocketBase(CMS_ENDPOINT);
  const [isErrorE, setisErrorE]: any = useState(false);
  // const {userCurrentData , setUserCurrentData} = useContext(UserContext);
  const validate_text = [
    vtn("company_type"),
    vtn("first_name"),
    vtn("last_name"),
    vtn("taken_email"),
    vtn("email"),
    vtn("password"),
    vtn("password_comfirm"),
    vtn("arabic_name"),
    vtn("mobile"),
    vtn("commercial"),
    vtn("link"),
    vtn("personal_email")

  ];
  const validatorsPassword =[
    vtn("password"),
    vtn("password1"),
    vtn("password2"),
    vtn("password3"),
    vtn("password4"),
    vtn("password5"),
    vtn("password_comfirm"),
  ]
  const validatePassword = (value:any,values:any) => {
      

    // Check for minimum 8 characters
    if (value.length < 8) return validatorsPassword[2];
  
    // Check for at least one uppercase letter
    if (!/[A-Z]/.test(value)) return validatorsPassword[3];
  
    // Check for at least one lowercase letter
    if (!/[a-z]/.test(value)) return validatorsPassword[4];
  
    // Check for at least one of the special characters !@#%?
    if (!/[!”#$%&’()*+,-./:;<=>?@[\]^_`{|}~]/.test(value)) return validatorsPassword[5];
    if(/^\S+ \S+$/.test(value) ) return validatorsPassword?.[0];



    return null;
  };
  const form: any = useForm({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      companyType: "",
      companyName: "",
      companyWebsite: "",
      companyPhone: "",
      logo: "",
      password: "",
      confirm: "",
      terms: false,
      swear: false,
      commercialRecord: "",
    },

    validate: {
      // companyWebsite:(value) => {
      //   if (!value) return 'Link is required'; // Check if the input is not empty
      //   try {
      //     new URL(value); // Try to create a URL object with the input value
      //   } catch (error) {
      //     return validate_text?.[10]; // If it throws an error, it's not a valid URL
      //   }
      // },
      terms: (value) => (value ? null : " "),
      swear: (value) => (value ? null : " "),
      companyType: isNotEmpty(validate_text?.[0]),
      firstName:(value) =>
      !/^\S+ \S+$/.test(value) && value.length > 2 && !/[0-9]/.test(value)
        ? /^[\u0621-\u064A\s]+$/.test(value)? null:validate_text?.[7]
        : validate_text?.[1],
          lastName: (value) =>
          /^[\u0621-\u064A\s]+$/.test(value)? null:validate_text?.[7],
      companyName: (value) => (value.length > 2 ? null : validate_text?.[4]),
      mobile: (value) => (phoneNumber.length == 9 ? null :validate_text?.[8] ),
       email: (value) =>
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
          ? isErrorE
            ? validate_text?.[3]
            :
             (!value.split('@')[1] || [
              'gmail.com',
              'yahoo.com',
              'hotmail.com',
            ].includes(value.split('@')[1])? validate_text?.[11]:null)
            // null
          : validate_text?.[4],
          commercialRecord: (value) => {
            if(form.values.companyType != "Government"){
              const isValid = /^\d{10}$/.test(value) && value[0] === '1';
              return isValid ? null : validate_text?.[9];
            }
            return null
          
          },
      password: validatePassword,
      confirm: (value) =>
        value === form.values.password ? null : validate_text?.[6],
    },
  });

  const [debounced] = useDebouncedValue(form.values.email, 400);

  useEffect(() => {
    const resultList = pb
      .collection("recruiters")
      .getList(1, 2, {
        filter: `email = "${form.values.email}"`,
      })
      .then((res: any) => {

        if (
          res.items
            .length === 0
        ) {
          setisErrorE(false);
        } else {
          setisErrorE(true);
        }
      });
  }, [debounced]);
  const navigate = useNavigate();
  const [termsError, setTermsError]: any = useState(false);

  const [isError, setisError]: any = useState(false);

  const { classes } = useStyles();
  const { height, width } = useViewportSize();
  const { locale, translation }: any = useContext(TranslationContext);
  const { userCurrentData, setUserCurrentData } = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const [countryNum, setCountryNum] = useState("+966");
  const [phoneNumber, setPhoneNumber] = useState("");
  const divider = (
    <div
      style={{
        width: width < 500 ? "50%" : "1px",
        height: width < 500 ? "1px" : "15px",
        background: "#21033F",
        margin: width < 500 ? "10px 0px" : "0px 5px",
      }}
    />
  );

  const errorMassage = tn("Invalid email or password");
  const termsTXT = tn("You must accept the terms and conditions");
  const terms = translation?.terms?.[0];

  const companyType = translation?.company_type;
  useEffect(() => {
    form.setFieldValue("mobile", countryNum + phoneNumber);
  }, [countryNum]);
  useEffect(() => {
    form.setFieldValue("mobile", countryNum + phoneNumber);
  }, [phoneNumber]);
  return (
    <>
    <Helmet>
    <title>{tn("Create a new account") + " / " + tn("Coophub")}</title>
  </Helmet>
    <form
      onSubmit={form.onSubmit(async (e: any) => {
      
        if (!form.values.terms || !form.values.swear) {
          setTermsError(true);
        } else {
          
          const companyData = {
            tel: e.companyPhone,
            web: e.companyWebsite,
            password:"1234567890",
            passwordConfirm:"1234567890",
            status:{
              "ar": "قيد الانتظار",
              "en": "pending"
            },
            type: {
              en: companyType?.find((item) => item.name_en == e.companyType)
                .name_en,
              ar: companyType?.find((item) => item.name_en == e.companyType)
                .name_ar,
            },
            name: e.companyName,

            commercial_record: e.companyType != "Government" ? e.commercialRecord : "",
          };
          try {
            setLoading(true);
            const formData = new FormData();
          formData.append("logo", e?.logo);

            const record = await pb
              .collection("companies")
              .create(companyData)
              .then(async (res: any) => {
                const recordg = await pb
              .collection("companies")
              .update(res.id,formData)
              
                const record = await pb
                .collection("recruiters")
                .create({
                  email: e.email?.toLowerCase(),
                  emailVisibility: true,
                  password: e.password,
                  mobile: e.mobile,
                  country_mobile_number: countryNum,
                  passwordConfirm: e.confirm,
                  // name: e.firstName + " " + e.lastName,
                  first_name: e.firstName,
                  last_name: e.lastName,
                  role: {en:"HR Manager",ar:"مدير الموارد البشرية"},
                  status:{
                    "ar": "قيد الانتظار",
                    "en": "pending"
                  },
                  department:{en:"HR",ar:"الموارد البشرية"},
                  company: res.id,
                  creator:true,
                })
                .then(async (res: any) => {
                  const senEmail = await pb
                    .collection("recruiters")
                    .requestVerification(res.email?.toLowerCase()).then((res:any)=>{
                      navigate("/recruiter/register_success");
                    });
                });
              });
           

           
            setUserCurrentData(form?.values);
          } catch (err) {
            setisError(true);
          }
        }

        // (optional) send an email verification request
        // await pb
        //   .collection("recruiters")
        //   .requestVerification(email);
      }
    )}
    >
      <div className={classes.wrapper}>
        <Paper className={classes.form} radius={0} p={30}>
          <div className={classes.inner}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "60px",
                flexDirection: width > 500 ? "row" : "column",
              }}
            >
              <Text className={classes.title}>{tn("you are welcome in")}</Text>
              <img style={{ height: "93px" }} src="/dark_text_logo.svg" />
            </div>
            <div
              style={{
                display: "flex",

                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "30px",
                width: "100%",
              }}
            >
              <div
                style={{
                  width: "45%",
                  height: "60px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#8c8c8c",
                  fontSize: "16px",
                  // margin: width > 500 ? "0px 20px" : "0px 7px",
                  border: "1px solid #21033F",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/recruiter/login")}
              >
                {tn("Login")}
              </div>
              <div
                style={{
                  background: "#21033F",
                  width: "45%",
                  height: "60px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                  fontSize: "16px",
                  // margin: width > 500 ? "0px 20px" : "0px 7px",
                  cursor: "pointer",
                }}
              >
                {tn("Create a new account")}
              </div>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <TextInput
                  withAsterisk={false}
                  style={{
                    width: "45%",
                  }}
                  {...form.getInputProps("firstName")}
                  required
                  mt={15}
                  variant="filled"
                  styles={(theme) => ({
                    error: {
                      marginBottom: "-10px"
                    },
                   
                    label: {
                      color: "#21033F",
                    },
                    input: {
                       borderRadius: "0px",
                      color: "#21033F",
                      backgroundColor: "transparent",
                      border: "0px solid #21033F",
                      borderBottom: "1px solid #21033F86",
                      "&:focus-within": {
                        border: "0px solid #21033F",
                        borderBottom: "1px solid #21033F",
                      },
                    },
                    
                  })}
                  label={tn("First Name")}
                  // placeholder="hello@coophub.co"
                  size="sm"
                />
                <TextInput
                  withAsterisk={false}
                  style={{
                    width: "45%",
                  }}
                  {...form.getInputProps("lastName")}
                  required
                  mt={15}
                  variant="filled"
                  styles={(theme) => ({
                    error: {
                      marginBottom: "-10px"
                    },
                    label: {
                      color: "#21033F",
                    },
                    input: {
                       borderRadius: "0px",
                      color: "#21033F",
                      backgroundColor: "transparent",
                      border: "0px solid #21033F",
                      borderBottom: "1px solid #21033F86",
                      "&:focus-within": {
                        border: "0px solid #21033F",
                        borderBottom: "1px solid #21033F",
                      },
                    },
                  })}
                  label={tn("Last Name")}
                  // placeholder="hello@coophub.co"
                  size="sm"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <TextInput
                  withAsterisk={false}
                  mt={10}
                  style={{
                    width: "45%",
                  }}
                  size="sm"
                  error={form.errors.mobile}
                  required
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  type="number"
                  // placeholder="1234567890"
                  label={tn("Mobile")}
                  rightSection={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {/* <Text
                        dir="ltr"
                        color="#21033F"
                        fz="sm"
                        style={
                          {
                            // borderBottom: "1px solid #21033F86",
                            // paddingBottom: "7px",
                          }
                        }
                      >
                        {countryNum}
                      </Text> */}
                      <NativeSelect
                       
                        onChange={(e) => setCountryNum(e.target.value)}
                        value={countryNum}
                        data={[
                          { value: "+1", label: "🇺🇸" },
                          { value: "+44", label: "🇬🇧" },
                          { value: "+33", label: "🇫🇷" },
                          { value: "+49", label: "🇩🇪" },
                          { value: "+81", label: "🇯🇵" },
                          { value: "+86", label: "🇨🇳" },
                          { value: "+91", label: "🇮🇳" },
                          { value: "+61", label: "🇦🇺" },
                          { value: "+7", label: "🇷🇺" },
                          { value: "+55", label: "🇧🇷" },
                          { value: "+20", label: "🇪🇬" },
                          { value: "+966", label: "🇸🇦" },
                          { value: "+971", label: "🇦🇪" },
                          { value: "+962", label: "🇯🇴" },
                          { value: "+965", label: "🇰🇼" },
                          { value: "+961", label: "🇱🇧" },
                          { value: "+964", label: "🇮🇶" },
                          { value: "+973", label: "🇧🇭" },
                          { value: "+968", label: "🇴🇲" },
                          { value: "+974", label: "🇶🇦" },
                          { value: "+52", label: "🇲🇽" },
                          { value: "+45", label: "🇩🇰" },
                          { value: "+64", label: "🇳🇿" },
                          { value: "+34", label: "🇪🇸" },
                          { value: "+39", label: "🇮🇹" },
                          { value: "+41", label: "🇨🇭" },
                        ]}
                        styles={(theme) => ({
                    error: {
                      marginBottom: "-10px"
                    },
                          label: {
                            color: "#ffffff",
                          },
                          input: {
                            border: "0px solid #21033F",
                            borderRadius: "0px",
                            color: "#21033F",
                            backgroundColor: "transparent",
                            // borderBottom: "1px solid #21033F86",
                            "&:focus-within": {
                              // borderBottom: "1px solid #21033F",
                            },
                          },
                        })}
                      />
                    </div>
                  }
                  rightSectionWidth={62}
                  styles={(theme) => ({
                    error: {
                      marginBottom: "-10px"
                    },
                    label: {
                      color: "#21033F",
                      fontSize: "16px",
                    },
                    input: {
                      borderRadius: "0px",
                      border: "0px solid #21033F",
                      color: "#21033F",
                      backgroundColor: "transparent",
                      borderBottom: "1px solid #21033F86",
                      "&:focus-within": {
                        border: "0px solid #21033F",
                        borderBottom: "1px solid #21033F",
                      },
                    },
                  })}
                />
                <TextInput
                
                  withAsterisk={false}
                  {...form.getInputProps("email")}
                  mt={10}
                  style={{
                    width: "45%",
                  }}
                  size="sm"
                  required
                  variant="filled"
                  styles={() => ({
                    error: {
                      marginBottom: "-10px"
                    },
                    label: {
                      color: "#21033F",
                    },
                    input: {
                       borderRadius: "0px",
                      color: "#21033F",
                      backgroundColor: "transparent",
                      border: "0px solid #21033F",
                      borderBottom: "1px solid #21033F86",
                      "&:focus-within": {
                        border: "0px solid #21033F",
                        borderBottom: "1px solid #21033F",
                      },
                    },
                  })}
                  label={tn("Email Address")}
                  // placeholder="hello@coophub.co"
                />
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <PasswordInput
                  withAsterisk={false}
                  mt={10}
                  style={{
                    width: "45%",
                  }}
                  size="sm"
                  {...form.getInputProps("password")}
                  required
                  variant="filled"
                  styles={(theme) => ({
                    error: {
                      marginBottom: "-10px"
                    },
                    label: {
                      color: "#21033F",
                    },
                    input: {
                       borderRadius: "0px",
                      color: "#21033F",
                      backgroundColor: "transparent",
                      border: "0px solid #21033F",
                      borderBottom: "1px solid #21033F9a",
                      "&:focus-within": {
                        border: "0px solid #21033F",
                        borderBottom: "1px solid #21033F",
                      },
                    },
                    innerInput: {
                      color: "#21033F",
                    },
                  })}
                  label={tn("Password")}
                  // placeholder={tn("Your password")}
                />
                <PasswordInput
                  withAsterisk={false}
                  mt={10}
                  style={{
                    width: "45%",
                  }}
                  size="sm"
                  {...form.getInputProps("confirm")}
                  required
                  variant="filled"
                  styles={(theme) => ({
                    error: {
                      marginBottom: "-10px"
                    },
                    label: {
                      color: "#21033F",
                    },
                    input: {
                       borderRadius: "0px",
                      color: "#21033F",
                      backgroundColor: "transparent",
                      border: "0px solid #21033F",
                      borderBottom: "1px solid #21033F9a",
                      "&:focus-within": {
                        border: "0px solid #21033F",
                        borderBottom: "1px solid #21033F",
                      },
                    },
                    innerInput: {
                      color: "#21033F",
                    },
                  })}
                  label={tn("Password Confirm")}
                  // placeholder={tn("Retype Your password")}
                />
              </div>
              <Select
                withAsterisk={false}
                data={
                  companyType?.[0]
                    ? companyType?.map((option:any) => ({
                        value: option?.name_en,
                        label: option?.[`name_${locale}`],
                      }))
                    : []
                }
                {...form.getInputProps("companyType")}
                mt={10}
                style={
                  {
                    //  width: "45%",
                  }
                }
                size="sm"
                required
                variant="filled"
                styles={(theme) => ({
                    error: {
                      marginBottom: "-10px"
                    },
                  label: {
                    color: "#21033F",
                  },
                  input: {
                    "&:data-selected": {
                      backgroundColor: "#00d",
                    },
                    borderRadius: "0px",
                    color: "#21033F",
                    backgroundColor: "transparent",
                    border: "0px solid #21033F",
                    borderBottom: "1px solid #21033F86",
                    "&:focus-within": {
                      border: "0px solid #21033F",
                      borderBottom: "1px solid #21033F",
                    },
                  },
                })}
                label={tn("Company Type")}
              />
              {form.values.companyType != "Government" && <TextInput
                withAsterisk={false}
                {...form.getInputProps("commercialRecord")}
                mt={10}
                style={{}}
                size="sm"
                type="number"
                required
                variant="filled"
                styles={(theme) => ({
                    error: {
                      marginBottom: "-10px"
                    },
                  label: {
                    color: "#21033F",
                  },
                  input: {
                    borderRadius: "0px",
                    color: "#21033F",
                    backgroundColor: "transparent",
                    border: "0px solid #21033F",
                    borderBottom: "1px solid #21033F86",
                    "&:focus-within": {
                      border: "0px solid #21033F",
                      borderBottom: "1px solid #21033F",
                    },
                  },
                })}
                label={tn("Commercial Record")}
                // placeholder="hello@coophub.co"
              />}
              <TextInput
                withAsterisk={false}
                {...form.getInputProps("companyName")}
                mt={10}
                style={{}}
                size="sm"
                required
                variant="filled"
                styles={(theme) => ({
                    error: {
                      marginBottom: "-10px"
                    },
                  label: {
                    color: "#21033F",
                  },
                  input: {
                    borderRadius: "0px",
                    color: "#21033F",
                    backgroundColor: "transparent",
                    border: "0px solid #21033F",
                    borderBottom: "1px solid #21033F86",
                    "&:focus-within": {
                      border: "0px solid #21033F",
                      borderBottom: "1px solid #21033F",
                    },
                  },
                })}
                label={tn("Company Name")}
                // placeholder="hello@coophub.co"
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <TextInput
                  withAsterisk={false}
                  {...form.getInputProps("companyWebsite")}
                  mt={10}
                  style={{
                    width: "45%",
                  }}
                  size="sm"
                  required
                  variant="filled"
                  styles={(theme) => ({
                    error: {
                      marginBottom: "-10px"
                    },
                    label: {
                      color: "#21033F",
                    },
                    input: {
                       borderRadius: "0px",
                      color: "#21033F",
                      backgroundColor: "transparent",
                      border: "0px solid #21033F",
                      borderBottom: "1px solid #21033F86",
                      "&:focus-within": {
                        border: "0px solid #21033F",
                        borderBottom: "1px solid #21033F",
                      },
                    },
                  })}
                  label={tn("Company Website")}
                  // placeholder="hello@coophub.co"
                />

                <TextInput
                  withAsterisk={false}
                  {...form.getInputProps("companyPhone")}
                  mt={10}
                  style={{
                    width: "45%",
                  }}
                  size="sm"
                  required
                  variant="filled"
                  styles={(theme) => ({
                    error: {
                      marginBottom: "-10px"
                    },
                    label: {
                      color: "#21033F",
                    },
                    input: {
                       borderRadius: "0px",
                      color: "#21033F",
                      backgroundColor: "transparent",
                      border: "0px solid #21033F",
                      borderBottom: "1px solid #21033F86",
                      "&:focus-within": {
                        border: "0px solid #21033F",
                        borderBottom: "1px solid #21033F",
                      },
                    },
                  })}
                  label={tn("Phone")}
                  // placeholder="hello@coophub.co"
                />
              </div>
              <div
                style={{
                  // border: "1px solid #21033Fdc",
                  borderRadius: "4px",
                  display: "flex",
                  alignItems: "end",
                }}
              >
                <FileInput
                   maxSize={1024 ** 2}
                  accept={"image/png,image/jpeg"}
                  {...form.getInputProps("logo")}
                  // style={{ width: "calc(100% - 60px)",}}
                  withAsterisk={false}
                  //   {...form.getInputProps("firstName")}
                  icon={<IconUpload size={rem(14)} />}
                  required
                  mt={10}
                  variant="filled"
                  style={{ width: "calc(100% - 50px)" }}
                  styles={(theme) => ({
                    error: {
                      marginBottom: "-10px"
                    },
                    label: {
                      color: "#21033F",
                    },
                    input: {
                       borderRadius: "0px",
                      color: "#21033F",
                      backgroundColor: "transparent",
                      border: "0px solid #21033F",
                      borderBottom: "1px solid #21033F86",
                      "&:focus-within": {
                        border: "0px solid #21033F",
                        borderBottom: "1px solid #21033F",
                      },
                    },
                  })}
                  label={tn("Company logo (optional)")}
                  // placeholder="hello@coophub.co"
                  size="md"
                  valueComponent={(value) => {
                    return (
                      value?.value?.name && (
                        <Center
                          mx="md"
                          inline
                          sx={(theme) => ({
                            backgroundColor: " #a0a0a094",
                            fontSize: theme.fontSizes.xs,
                            padding: `${rem(3)} ${rem(7)}`,
                            borderRadius: theme.radius.sm,
                          })}
                        >
                          <IconPhoto
                            size={rem(14)}
                            style={{
                              margin: rem(5),
                              marginTop: "0",
                              marginBottom: "0",
                            }}
                          />
                          <span
                            style={{
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              maxWidth: rem(200),
                              display: "inline-block",
                            }}
                          >
                            {value?.value?.name}
                          </span>
                        </Center>
                      )
                    );
                  }}
                />{" "}
                <div
                  style={{
                    borderBottom: "1px solid #21033F86",
                    paddingBottom: "10px",
                  }}
                >
                  <FileButton  maxSize={1024 ** 2}
                    {...form.getInputProps("logo")}
                    // multiple
                    accept={"image/png,image/jpeg"}
                  >
                    {(props) => (
                      <Button
                        style={{
                          background: "#21033F",
                          borderRadius: "5.112px",
                          height: "30px",
                        }}
                        {...props}
                      >
                        {tn("Upload")}
                      </Button>
                    )}
                  </FileButton>
                </div>
              </div>
              <Text
                style={{
                  color: "#FFB004",
                  textAlign: "right",
                  fontFamily: "IBM Plex Sans Arabic",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "28px",
                }}
              >
                {tn("png massage")}
              </Text>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  marginTop: "40px",
                }}
              >
                <Checkbox
                  checked={form.values.terms}
                  {...form.getInputProps("terms")}
                  // mt="xl"
                  size="md"
                  styles={() => ({
                    label: { cursor: "pointer", color: "#21033F" },
                    input: {
                      cursor: "pointer",
                      "&:checked": {
                        backgroundColor: "#21033F",
                        border: "0px solid #21033F",
                      },
                    },
                  })}
                />
                <Anchor<"a">
                  fz="sm"
                  mx="md"
                  color="#FFB004"
                  href="#"
                  weight={700}
                  onClick={open}
                >
                  {tn("I agree to the CoopHub Privacy Policy and Terms of Use")}
                </Anchor>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <Checkbox
                  checked={form.values.swear}
                  {...form.getInputProps("swear")}
                  // mt="xl"
                  size="md"
                  styles={() => ({
                    label: { cursor: "pointer", color: "#21033F" },
                    input: {
                      cursor: "pointer",
                      "&:checked": {
                        backgroundColor: "#21033F",
                        border: "0px solid #21033F",
                      },
                    },
                  })}
                />
                <Text
                  fz="sm"
                  mx="md"
                  color="#FFB004"
                  weight={700}
                  onClick={open}
                >
                  {ltn("company_registraion_massage")}
                </Text>
              </div>
              {(termsError || form.errors.terms || form.errors.swear) && (
                <Text size="13px" color="red">
                  {termsTXT}
                </Text>
              )}
              <Button
                loading={loading}
                type="submit"
                style={{
                  borderRadius: "5.112px",
                  height: "70px",
                  backgroundColor: "#21033F",
                  fontSize: "16px",
                }}
                fullWidth
                mt="xl"
                size="md"
              >
                {tn("Create a new account")}
              </Button>
              {isError && (
                <Text mt="md" size="13px" color="red">
                  {errorMassage}
                </Text>
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",

                margin: "70px 5px 0px 5px",
                width: "100%",
                maxWidth: "500px",
                flexDirection: width > 500 ? "row" : "column",
              }}
            >
              <Text color="#21033F" fz="16px" weight={700}>
                {tn("terms and conditions")}
              </Text>{" "}
              {divider}
              <Text color="#21033F" fz="16px" weight={700}>
                {tn("Usage policy")}
              </Text>{" "}
              {divider}
              <Text color="#21033F" fz="16px" weight={700}>
                {tn("All rights are save")}
              </Text>{" "}
            </div>
          </div>
        </Paper>

        <div
          style={{
            height: "100%",
            width: `calc(100vw - 700px)`,
            backgroundImage: "url(/shapes/hole_image.png)",
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "absolute",
            left: locale == "en" ? "1" : "0",
            right: locale == "en" ? "0" : "1",
            filter: "grayscale(100%)",
          }}
        ></div>
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: `100%`,
            background:
              "linear-gradient(127deg, rgba(33,3,63,0.0970982142857143) 0%, rgba(33,3,63,0.6657256652661064) 100%)",
          }}
        >
          {/* <div
            style={{
              height: "100%",
              width: "100%",
              backgroundImage: "url(/shapes/#21033F.svg)",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              display: "flex",
              justifyContent: "center",
              alignItems: "start",
              fontSize: "40px",
              color: "#21033F",
              fontWeight: 700,
              textAlign: "left",
              padding: "270px 0px 0px 0px",
            }}
          >
           
          </div> */}
        </div>
      </div>
      <Modal
        size="xl"
        opened={opened}
        onClose={close}
        centered
        styles={() => ({
          content: {
            borderRadius: "10px",
            background:
              "linear-gradient(153deg, #c2feff6a 100%, #d9b3ff5c 100%)",
            // display: "flex",
            // justifyContent: "center",
            // alignItems: "center",
          },
          header: { display: "none" },
          body: { padding: "20px" },
        })}
      >
        <div
          style={{
            marginTop: "20px",
            width: "100%",
            height: "70vh",
            background: "#ffffff",
            borderRadius: "5px",
            padding: "20px",
            overflowY: "scroll",
          }}
        >
        <MarkDownContainer>

          
<ReactMarkdown children={terms?.[locale]}/>
</MarkDownContainer>
        </div>
        <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
          <Button
            onClick={close}
            mt="md"
            style={{ background: "#21033F", height: "30px" }}
          >
            {tn("close")}
          </Button>
        </div>
      </Modal>
    </form></>
  );
}

const MarkDownContainer = styled.div`
// add styles for markdown here
/* Headings */
h1, h2, h3, h4, h5, h6 {
    margin-top: 24px;
    margin-bottom: 16px;
}

h1 { font-size: 2em;color: #21033F; }
h2 { font-size: 1.5em;color: #21033F; }
h3 { font-size: 1.17em; }
h4 { font-size: 1em; }
h5 { font-size: 0.83em; }
h6 { font-size: 0.67em; }

/* Paragraphs */
p {
    line-height: 1.6;
    margin-top: 1em;
    margin-bottom: 1em;
}

/* Links */
a {
    color: #0077cc;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

/* Images */
img {
    max-width: 100%;
    display: block;
    margin: 0 auto;
}

/* Lists */
ul, ol {
    margin: 1em 0;
    padding-left: 2em;
}

li {
    margin-bottom: 0.5em;
}

/* Blockquotes */
blockquote {
    border-left: 4px solid #ddd;
    padding-left: 1em;
    margin-left: 0;
    color: #666;
}

/* Code blocks and inline code */
pre, code {
    font-family: "Courier New", monospace;
}

pre {
    overflow-x: scroll;
    padding: 1em;
    border: 1px solid #ddd;
    border-radius: 4px;
}

code {
    background-color: #f5f5f5;
    padding: 0 0.5em;
    border-radius: 3px;
}

/* Horizontal Rules */
hr {
    border: none;
    border-top: 1px solid #eee;
    margin: 2em 0;
}

/* Tables */
table {
    border-collapse: collapse;
    width: 100%;
    margin: 1em 0;
}

th, td {
    border: 1px solid #ddd;
    padding: 8px 12px;
}

th {
    background-color: #f5f5f5;
}

tr:nth-child(even) {
    background-color: #f9f9f9;
}


`
export default RecruiterRegisterPage;
