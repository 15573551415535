import { TranslationContext } from "../../../../../context/TranslationContext";
import { useContext } from "react";
import { DataContext } from "../../../../../context/DataContext";
import { ActionComponent } from "../../ActionComponent";

export const HangPartialProgram = (props: any) => {
  const { locale }: any = useContext(TranslationContext);
  const { setHangPartialProgram }: any = useContext(DataContext);

  const { data, action , table } = props;
  if(
    data?.partial_status?.en == "canceled" ||
    data?.partial_status?.en == "closed"
  ){
    return <></>
  }
  return (
    <ActionComponent
    data={data}
    action={action}
    table={table}
    setData={async () => {
      setHangPartialProgram({
        id: data.id,
        record: data,
        action: action,
        table: table,
      });
      
    }}
    enabled={data?.completion_letter}
  />
  );
};
