import { Button, Dialog, Menu, Tooltip } from "@mantine/core";
import { TranslationContext } from "../../../../../../context/TranslationContext";
import { useContext, useEffect, useState } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import PocketBase from "pocketbase";
import { DataContext } from "../../../../../../context/DataContext";
import { motion } from "framer-motion";
import { CMS_ENDPOINT } from "../../../../../../constants";
import { IconAlertTriangle, IconSquareRoundedX } from "@tabler/icons-react";
import { ltn } from "../../../../../../translation";
import { BannersContext } from "../../../../../../context/BannersContext";
import { ActionComponent } from "../../../ActionComponent";
const pb = new PocketBase(CMS_ENDPOINT);

export const AcceptInvitation = (props: any) => {
  const { locale }: any = useContext(TranslationContext);
  const { setAcceptInvitation }: any = useContext(DataContext);
  const { setTopBanner }: any = useContext(BannersContext);

  const [isApplied, setIsApplied] = useState(false);
  const [allApplications, setAllApplications] = useState([]);
  const user_data = pb.authStore.model;
  const { data, action, table } = props;
  useEffect(() => {
    const record = pb
      .collection("applications")
      .getFirstListItem(
        `program="${data?.program_id}" && main_status != "accepted"`,
        {}
      )
      ?.then((res) => {
        if (res?.id) {
          setIsApplied(true);
        }
      });
    const all_applications = pb
      .collection("applications")
      .getFullList({})
      ?.then((res: any) => {
        if (res?.[0]) {
          setAllApplications(res);
        }
      });
  }, []);
  const massageTaken = ltn("applied_already");
  const massageUserData = ltn("fill_your_data");
  const massageAccepted = ltn("accepted_already");

  return (
    <ActionComponent
    is_mobile={true}
    data={data}
    action={action}
    table={table}
    setData={() =>{ if (
      user_data?.university &&
      user_data?.major &&
      user_data?.GPA &&
      parseFloat(user_data?.GPA) > 0 &&
      user_data?.semester &&
      user_data?.graduation_year &&
      user_data?.mobile &&
      user_data?.cv
    ) {
      if (
        allApplications.filter(
          (application: any) =>
            application?.main_status?.en == "accepted" ||
            application?.main_status?.en == "document review"
        )?.[0]
      ) {
        setTopBanner({
          message: massageAccepted,
          color: "#E77C40",
          top: "83px",
          backgroundColor: "rgba(255, 246, 239, 0.90)",
          icon: <IconSquareRoundedX />,
        });
      } else {
        if (!isApplied) {
          setAcceptInvitation({
            id: data.id,
            record: data,
            action: action,
            table: table,
          });
        } else {
          setTopBanner({
            message: massageTaken,
            color: "#E77C40",
            top: "83px",
            backgroundColor: "rgba(255, 246, 239, 0.90)",
            icon: <IconSquareRoundedX />,
          });
        }
      }
    } else {
      setTopBanner({
        message: massageUserData,
        color: "#E77C40",
        top: "83px",
        backgroundColor: "rgba(255, 246, 239, 0.90)",
        icon: <IconAlertTriangle />,
      });
    }
  }}
    enabled={true}
  />
   
  );
};
