export const convertArabicNumbersToEnglish = (input) => {
    const arabicToEnglishMap = {
      "٠": "0",
      "١": "1",
      "٢": "2",
      "٣": "3",
      "٤": "4",
      "٥": "5",
      "٦": "6",
      "٧": "7",
      "٨": "8",
      "٩": "9",
    };
    return input.replace(/[٠-٩]/g, (d) => arabicToEnglishMap[d]);
  };
  export const convertEnglishNumbersToArabic = (input) => {
    const englishToArabicMap = {
      "0": "٠",
      "1": "١",
      "2": "٢",
      "3": "٣",
      "4": "٤",
      "5": "٥",
      "6": "٦",
      "7": "٧",
      "8": "٨",
      "9": "٩",
    };

    return input?.replace(/[0-9]/g, (d) => englishToArabicMap[d]);
  };

  export const containsArabicNumbers = (value) => {
    const arabicNumbers = [
      "٠",
      "١",
      "٢",
      "٣",
      "٤",
      "٥",
      "٦",
      "٧",
      "٨",
      "٩",
      "."
    ];
    
    return value?.length ? [...value].some((char) => arabicNumbers.includes(char)) : false;
  };