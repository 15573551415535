// import { ltn, tn } from "../../../../../../translation";
// import { Button, Slider, Text } from "@mantine/core";
// import PocketBase from "pocketbase";
// import { CMS_ENDPOINT } from "../../../../../../constants";
// import { useContext, useState } from "react";
// import { AppContext } from "../../../../../../context/AppContext";
// import { useDebouncedValue } from "@mantine/hooks";

// export const TerminateApplicant = (props: any) => {
//   const pb: any = new PocketBase(CMS_ENDPOINT);
//   const { data, onClose } = props;
//   const [debounced_data] = useDebouncedValue(data, 200);

//   const { reloader, setReloader } = useContext(AppContext);

//   const [evaluation, setEvaluation] = useState(
//     debounced_data?.applicant_evaluation
//       ? parseInt(debounced_data?.applicant_evaluation.slice(0, -1))
//       : 50
//   );

//   return (
//     <div
//       style={{
//         display: "flex",
//         flexDirection: "column",
//         alignItems: "start",
//         justifyContent: "start",
//         padding: "20px",
//         width: "100%",
//       }}
//     >
//       <Text fw={600} color="#5c5c5c">
//         {ltn("rate_applicant")}
//       </Text>
//       <Slider
//         marks={[
//           { value: 20, label: "20%" },
//           { value: 50, label: "50%" },
//           { value: 80, label: "80%" },
//         ]}
//         label={(value: any) => value + "%"}
//         value={evaluation}
//         onChange={(e: any) => {
//           setEvaluation(e);
//         }}
//         style={{
//           width: "100%",
//           marginTop: "20px",
//           marginBottom: "10px",
//         }}
//         min={0}
//         max={100}
//         step={1}
//       />
//       <div
//         style={{
//           display: "flex",
//           flexDirection: "row",
//           alignItems: "center",
//           justifyContent: "space-between",
//           marginTop: "20px",
//         }}
//       >
//         <Button
//           mx={5}
//           onClick={async () => {
//             await pb
//               .collection("applications")
//               .update(debounced_data?.id, {
//                 applicant_evaluation: evaluation + "%",
//                 main_status: {
//                   en: "terminated",
//                   ar: "تم الإنهاء",
//                 },
//                 termination_status: {
//                   en: "pending",
//                   ar: "قيد الإنتظار",
//                 },
//                 evaluator: pb.authStore?.model?.id,
//               })
//               ?.then((res: any) => {
//                 const record = pb
//                   .collection("programs")
//                   .getOne(res.program, {})
//                   ?.then((program: any) => {
//                     const records = pb
//                       .collection("applications_view")
//                       .getFullList({
//                         filter: `program = "${program?.id}" && main_status.en = "accepted"`,
//                       })?.then((applications: any) => {
//                         //check if there is any accepted application
//                         //if there is no applications then update the program status to "completed"

//                         if (applications?.length === 0) {
//                           pb.collection("programs").update(program?.id, {
//                             status: {
//                               en: "completed",
//                               ar: "مكتمل",
//                             },
//                           });
//                         }

//                       });
//                   });
//               });
//             onClose({});
//             setReloader(!reloader);
//           }}
//         >
//           {tn("Save")}
//         </Button>
//         <Button
//           variant="outline"
//           color="gray"
//           mx={5}
//           onClick={() => onClose({})}
//         >
//           {tn("Cancel")}
//         </Button>
//       </div>
//     </div>
//   );
// };
import { ltn, tn } from "../../../../../../translation";
import {
  Button,
  Loader,
  Progress,
  Rating,
  Slider,
  Text,
  TextInput,
} from "@mantine/core";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../../../../../constants";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../../../context/AppContext";
import { useDebouncedValue } from "@mantine/hooks";
import { useForm } from "@mantine/form";
import { set } from "lodash";

export const TerminateApplicantView = (props: any) => {
  const pb: any = new PocketBase(CMS_ENDPOINT);
  const { data, onClose } = props;
  const [debounced_data] = useDebouncedValue(data?.record, 200);

  const { reloader, setReloader } = useContext(AppContext);
  const [company, setCompany]: any = useState({});
  const [evaluation, setEvaluation]: any = useState({});
  const [buttonLoading, setButtonLoading] = useState(false)
  useEffect(() => {
    const getCompany = async () => {
      const company = await pb
        .collection("companies")
        .getOne(pb.authStore.model.company);
      const evaluation_model = await pb
        .collection("evaluation_models")
        .getOne(
          company?.current_models
            ?.trainee_evaluation_questionnaire_after_the_end_of_the_program
        );
      setEvaluation(evaluation_model);
      setCompany(company);
    };
    getCompany();
  }, []);

  const form = useForm({
    initialValues: {
      precentage: 0,
      criterias: [],
    },
  });
  useEffect(() => {
    form.setValues({
      precentage: data?.record?.applicant_evaluation?.precentage ?? 0,
      criterias:
      data?.record?.applicant_evaluation?.criterias ??
        evaluation?.criterias?.map((criterias) => {
          return {
            name: criterias.name,
            description: criterias.description,
            rating: 2,
            comment: "",
            weight: criterias.weight,
          };
        }),
    });
  }, [evaluation, company]);

  const [precentage, setPrecentage] = useState(0);
  useEffect(() => {
    // get the precentage ot the evaluation by using the waight of each criteria
    let total_weight = 0;
    let total_score = 0;
    form.values?.criterias?.map((item) => {
      total_weight += item.weight;
      total_score += (item.rating / 5) * item.weight;
    });
    setPrecentage(Math.round((total_score / total_weight) * 100));
  }, [form.values?.criterias]);
  if (!form.values?.criterias) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "700px",
        }}
      >
        <Loader />
      </div>
    );
  }

  console.log("evaluationevaluationevaluation", debounced_data);
  return (
    <form
      onSubmit={form.onSubmit(async (values) => {
        setButtonLoading(true)
        await pb
          .collection("applications")
          .update(debounced_data?.id, {
            applicant_evaluation: {
              precentage: precentage,
              criterias: values.criterias,
            },
            main_status: {
              en: "terminated",
              ar: "تم الإنهاء",
            },
            termination_status: {
              en: "pending",
              ar: "قيد الإنتظار",
            },
            applicant_evaluation_evaluator: pb.authStore?.model?.id,
          })
        onClose({});
        setReloader(!reloader);
        setButtonLoading(false)
      })}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "start",
        padding: "20px",
        width: "100%",
      }}
    >
      {/* <Text size="xl" fw={600} color="#444444">
        {tn("Trainee evaluation form after completion of the program")}
      </Text> */}
       <Text size="xl" fw={600} color="#444444">
       {
        /// applicant name
        debounced_data?.applicant_name
       }
      </Text>
      <div
        style={{
          display: "flex",
          alignItems: "start",
          justifyContent: "space-between",
          marginTop: "20px",
          width: "100%",
          padding: "0px 10px",
        }}
      >
        <DataBox
          title={tn("evaluator")}
          value={
            debounced_data?.applicant_evaluation_evaluator_name ??
            pb?.authStore?.model?.first_name +
              " " +
              pb?.authStore?.model?.last_name
          }
          onClick={undefined}
        />
        {/* program name */}
       
        <DataBox
          title={tn("Evaluation date")}
          value={new Date(
            debounced_data?.program_evaluation_date
          ).toLocaleDateString()}
          onClick={undefined}
        />
        {/* السيرة الذاتية: */}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            width: "calc(50% - 20px)",
            padding: "0px 10px",
            gap: "10px",
          }}
        >
          <Text size="xl" fw="800" color="color3">
            {precentage > -1 && (precentage + "%")}
          </Text>
          <Progress
            color="color3"
            radius="lg"
            size="xl"
            value={precentage}
            style={{
              width: "66%",
            }}
          />
        </div>
      </div>

      <div
        style={{
          width: "100%",
          marginTop: "20px",
          border: "1px solid #E0E0E0",
          borderRadius: "10px",
          minHeight: "100px",
          maxHeight: "650px",
          overflowY: "auto",
        }}
      >
        {form?.values?.criterias?.map((item: any, index) => {
          return <CriteriaBox item={item} form={form} index={index} />;
        })}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "end",
          marginTop: "20px",
          width: "100%",
          gap: "10px",
        }}
      >
        <Button variant="outline" color="gray" onClick={() => onClose({})}>
          {tn("Cancel")}
        </Button>
        <Button loading={buttonLoading} color="color2" type="submit">
          {tn("Save")}
        </Button>
      </div>
    </form>
  );
};
{
  /* <Slider
       marks={[
        { value: 20, label: '20%' },
        { value: 50, label: '50%' },
        { value: 80, label: '80%' },
      ]}
      label={(value:any) => value + "%"} 
      value={evaluation}
      onChange={(e:any)=>{
        setEvaluation(e);
      }} style={{
        width:"100%",
        marginTop:"20px",
        marginBottom:"10px"
      }}  min={0} max={100} step={1}
  /> */
}

const DataBox = ({ value, title, onClick }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "start",
        justifyContent: "start",
        marginTop: "10px",
        gap: "5px",
        cursor: onClick ? "pointer" : "default",
      }}
      onClick={onClick}
    >
      <Text size="sm" color="#444444">
        {title}
        {": "}
      </Text>
      <Text size="sm" color={onClick ? "#FFB004" : "#444444"}>
        {value}
      </Text>
    </div>
  );
};

const CriteriaBox = ({ item, form, index }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        gap: "5px",
        backgroundColor: index % 2 === 0 ? "#F5F5F5" : "#FFFFFF",
        padding: "10px",
        borderRadius: "10px",
      }}
    >
      <div
        style={{
          width: "calc(40% - 10px)",
          display: "flex",
          gap: "15px",
          alignItems: "center",
        }}
      >
        <Text size="sm" color="#444444">
          {index + 1}
        </Text>
        <Text size="sm" color="#444444">
          {/* title with bold + - + description */}
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            {item.name}
          </span>
          {" - "}
          {item.description}
        </Text>
      </div>
      <Rating
        {...form.getInputProps(`criterias.${index}.rating`)}
        defaultValue={2}
        size="lg"
      />
      <TextInput
        placeholder={tn("Add comment")}
        {...form.getInputProps(`criterias.${index}.comment`)}
        style={{
          width: "calc(50% - 10px)",
        }}
        styles={() => ({
          input: {
            height: "40px",
            borderRadius: "10px",
            border: "1px solid #E0E0E0",
            padding: "10px",
            fontSize: "14px",
            color: "#444444",
          },
        })}
      />
    </div>
  );
};
