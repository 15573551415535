import { Modal } from "@mantine/core";
import { useContext } from "react";
import { DataContext } from "../../../../context/DataContext";
import { AccountDelete } from "./acount/AccountDelete";
import { AccountActivate } from "./acount/AccountActivate";
import { AccountDeactivate } from "./acount/AccountDeactivate";
import { EditRecruiter } from "./admin_action/EditRecruiter";
import { AddRecruiter } from "./recruiter_action/recruiters_manage/AddRecruiter";
import { CompanyActivate } from "./admin_action/CompanyActivate";
import { CompanyDelete } from "./admin_action/CompanyDelete";
import { AdminAddApplicants } from "./admin_action/AdminAddApplicants";
import { ApplicantModal } from "../../../../admin/components/AdminApplicantModal";
import { DeleteDepartment } from "./recruiter_action/departments_manage/DeleteDepartment";
import { ViewRecruiter } from "./admin_action/ViewRecruiter";
import { AddDepartment } from "./recruiter_action/departments_manage/AddDepartment";
import { AccountSoftDelete } from "./acount/AccountSoftDelete";
import { EditDepartment } from "./recruiter_action/departments_manage/EditDepartment";
import { ViewDepartment } from "./recruiter_action/departments_manage/ViewDepartment";
import { AddProgram } from "./recruiter_action/published_programs/AddProgram";
import { ca, hr } from "date-fns/locale";
import { CancelProgram } from "./recruiter_action/published_programs/CancelProgram";
import { EditProgram } from "./recruiter_action/published_programs/EditProgram";
import { ViewProgram } from "./recruiter_action/published_programs/ViewProgram";
import { ApplicantViewProgram as ApplicantViewProgramWeb } from "./applicant_action/ApplicantViewProgram";
import { ApplicantViewProgram as ApplicantViewProgramMobile } from "./applicant_action/mobile/ApplicantViewProgram";

import { ApplyForProgram as ApplyForProgramWeb } from "./applicant_action/programs_search/ApplyForProgram";
import { ApplyForProgram as ApplyForPrograMobile } from "./applicant_action/programs_search/mobile/ApplyForProgram";

import { PublishProgram } from "./recruiter_action/published_programs/PublishProgram";
import { AcceptNewApplicant } from "./recruiter_action/new/AcceptNewApplicant";
import { RejectNewApplicant } from "./recruiter_action/new/RejectNewApplicant";
import { AcceptWaitingInterview } from "./recruiter_action/interview/waiting_for_scheduling/AcceptWaitingInterview";
import { ExcludeInterview } from "./recruiter_action/interview/ExcludeInterview";
import { SchedulingInterview } from "./recruiter_action/interview/waiting_for_scheduling/SchedulingInterview";
import { AcceptScheduledInterview } from "./recruiter_action/interview/scheduled_interview/AcceptScheduledInterview";
import { CancelInterview } from "./recruiter_action/interview/scheduled_interview/CancelInterview";
import { Reschedule } from "./recruiter_action/interview/scheduled_interview/Reschedule";
import { DidNotAttend } from "./recruiter_action/interview/scheduled_interview/DidNotAttend";
import { EvaluationInterview } from "./recruiter_action/interview/scheduled_interview/EvaluationInterview";
import { AcceptAppointment } from "./applicant_action/personal_interviews/AcceptAppointment";
import { ExcludeDocument } from "./recruiter_action/documents/ExcludeDocument";
import { CorrectionRequest } from "./recruiter_action/documents/CorrectionRequest";
import { AcceptLetter } from "./recruiter_action/documents/AcceptLetter";
import { UploadDocuments } from "./applicant_action/upload_documents/UploadDocuments";
import { ChangeCommencementDate } from "./recruiter_action/ChangeCommencementDate";
import { TerminateApplicant } from "./recruiter_action/active_programs/TerminateApplicant";
import { CancelProgramApplicant } from "./recruiter_action/active_programs/CancelProgramApplicant";
import { SchedulePlan } from "./recruiter_action/active_programs/SchedulePlan";
import { ActiveProgramsApplicant } from "./recruiter_action/active_programs/ActiveProgramsApplicant";
import { ActiveProgramRecruiter } from "./recruiter_action/active_programs/ActiveProgramRecruiter";
import { TerminationProgramWithoutDocument } from "./recruiter_action/completed_programs/TerminationProgramWithoutDocument";
import { UploadingProgramCompletionLetter } from "./recruiter_action/completed_programs/UploadingProgramCompletionLetter";
import {  ProgramEvaluation } from "./recruiter_action/programs_history/ProgramEvaluation";
import { Withdrawal } from "./applicant_action/withdrawal_request/Withdrawal";
import { WithdrawalRequest } from "./applicant_action/withdrawal_request/WithdrawalRequest";
import { WithdrawalReason } from "./recruiter_action/withdrawal_requests/WithdrawalReason";
import { AddPermission } from "./recruiter_action/permissions/AddPermission";
import { DeletePermission } from "./recruiter_action/permissions/DeletePermission";
import { EditPermission } from "./recruiter_action/permissions/EditPermission";
import { ViewPermission } from "./recruiter_action/permissions/ViewPermission";
import { AcceptWithdraw } from "./recruiter_action/withdrawal_requests/AcceptWithdraw";
import { RejectWithdraw } from "./recruiter_action/withdrawal_requests/RejectWithdraw";
import { RejectProgram } from "./recruiter_action/published_programs/RejectProgram";
import { AdoptProgram } from "./recruiter_action/published_programs/AdoptProgram";
import { RejectProgramReason } from "./recruiter_action/published_programs/RejectProgramReason";
import { AddPartialProgram } from "./partial_action/AddPartialProgram";
import { HangPartialProgram } from "./partial_action/HangPartialProgram";
import { CancelPartialProgram } from "./partial_action/CancelPartialProgram";
import { PublishPartialProgram } from "./partial_action/PublishPartialProgram";
import { ResendForApproval } from "./recruiter_action/published_programs/ResendForApproval";
import { CorrectionRequestContractIssuing } from "./recruiter_action/documents/contract/CorrectionRequestContractIssuing";
import { FillContractData } from "./applicant_action/contract/FillContractData";
import { ApproveContract } from "./recruiter_action/documents/contract/issuing/ApproveContract";
import { CorrectionMessageApplicant } from "./applicant_action/contract/CorrectionMessageApplicant";
import { ApproveContractApproval } from "./recruiter_action/documents/contract/approval/ApproveContractApproval";
import { CorrectionRequestContractApproval } from "./recruiter_action/documents/contract/CorrectionRequestContractApproval";
import { CorrectionMessageIssuing } from "./recruiter_action/documents/contract/issuing/CorrectionMessageIssuing";
import { CorrectionMessageDocuments } from "./applicant_action/upload_documents/CorrectionMessageDocuments";
import { ShowAbout } from "./recruiter_action/search_applicants/ShowAbout";
import { InviteApplicant } from "./recruiter_action/search_applicants/InviteApplicant";
import { CancelInvitation } from "./recruiter_action/search_applicants/CancelInvitation";
import { RejectInvitation } from "./applicant_action/invitation/RejectInvitation";
import { AcceptInvitation } from "./applicant_action/invitation/AcceptInvitation";
import { RepublishProgram } from "./recruiter_action/published_programs/RepublishProgram";
import { LocationDataForm } from "../../../../recruiter/components/LocationDataForm";
import {  TestInstructions as TestInstructionsWeb } from "./applicant_action/programs_search/TestInstructions";
import {  TestInstructions as TestInstructionsMobile } from "./applicant_action/programs_search/mobile/TestInstructions";
import { useMediaQuery, useViewportSize } from "@mantine/hooks";
import { ViewChanger } from "../../../../applicant/ViewChanger";
import { TerminateApplicantView } from "./recruiter_action/completed_programs/TerminateApplicantView";
import { ProgramEvaluationView } from "./recruiter_action/programs_history/ProgramEvaluationView";

export const Modals = () => {
  const {
    adminApplicantData,
    setAdminApplicantData,
    accountDelete,
    setDeleteAccount,
    activeAcount,
    setActiveAcount,
    deactiveAcount,
    setDeactiveAcount,
    roleEdit,
    setRoleEdit,
    setAccountAdd,
    accountAdd,
    deleteCompany,
    setDeleteCompany,
    activateCompany,
    setActivateCompany,
    deleteDepartment,
    setDeleteDepartment,
    roleView,
    setRoleView,
    softDeleteAccount,
    setSoftDeleteAccount,
    setEditDepartment,
    editDepartment,
    setViewDepartment,
    viewDepartment,
    setCancelProgram,
    cancelProgram,
    setEditProgram,
    editProgram,
    setHRviewProgram,
    hrViewProgram,
    setApplicantViewProgram,
    applicantViewProgram,
    setApplyForProgram,
    applyForProgram,
    setPublishProgram,
    publishProgram,
    setAcceptNewApplicant,
    acceptNewApplicant,
    setRejectNewApplicant,
    rejectNewApplicant,
    setAcceptWaitingInterview,
    acceptWaitingInterview,
    setExcludeInterview,
    excludeInterview,
    setSchedulingInterview,
    schedulingInterview,
    setAcceptScheduledInterview,
    acceptScheduledInterview,
    setCancelInterview,
    cancelInterview,
    setReschedule,
    reschedule,
    didNotAttend,
    setDidNotAttend,
    evaluationInterview, setEvaluationInterview,
    acceptAppointment,
    setAcceptAppointment,
    setExcludeDocument,
    excludeDocument,
    setCorrectionRequest,
    correctionRequest,
    setAcceptLetter,
    acceptLetter,
    uploadDocuments,
    setUploadDocuments,
    changeCommencementDate,
    setChangeCommencementDate,
    terminateApplicant,
    setTerminateApplicant,
    cancelProgramApplicant,
    setCancelProgramApplicant,
    schedulePlan,
    setSchedulePlan,
    setActiveProgramRecruiter,
    activeProgramRecruiter,
    setActiveProgramsApplicant,
    activeProgramsApplicant,
    terminationProgramWithoutDocument,
    setTerminationProgramWithoutDocument,
    uploadingProgramCompletionLetter,
    setUploadingProgramCompletionLetter,
    programEvaluation,
    setProgramEvaluation,
    withdrawalRequest,
    setWithdrawalRequest,
    withdrawal,
    setWithdrawal,
    setWithdrawalReason,
    withdrawalReason,
    deletePermission,
    setDeletePermission,
    editPermission,
    setEditPermission,
    viewPermission,
    setViewPermission,
    acceptWithdraw,
    setAcceptWithdraw,
    rejectWithdraw,
    setRejectWithdraw,
    rejectProgram,
    setRejectProgram,
    adoptProgram,
    setAdoptProgram,
    rejectProgramReason,
    setRejectProgramReason,
    hangPartialProgram,
    setHangPartialProgram,
    cancelPartialProgram,
    setCancelPartialProgram,
    publishPartialProgram,
    setPublishPartialProgram,
    resendForApproval,
    setResendForApproval,
    correctionRequestContractIssuing,
    setCorrectionRequestContractIssuing,
    fillContractData,
    setFillContractData,
    approveContract,
    setApproveContract,
    correctionMessageApplicant,
    setCorrectionMessageApplicant,
    approveContractApproval,
    setApproveContractApproval,
    correctionRequestContractApproval,
    setCorrectionRequestContractApproval,
    setCorrectionMessageIssuing,
    correctionMessageIssuing,
    correctionMessageDocuments,
    setCorrectionMessageDocuments,
    showAbout,
    setShowAbout,
    inviteApplicant,
    setInviteApplicant, 
    cancelInvitation,
    setCancelInvitation,
    rejectInvitation,
    setRejectInvitation,
    setAcceptInvitation,
    acceptInvitation,
    republishProgram,
    setRepublishProgram,
    setLocationDataForm,
    locationDataForm,
    testInstructions,
    setTestInstructions,
    terminateApplicantView,
    setTerminateApplicantView,
    programEvaluationView,
    setProgramEvaluationView,
  }: any = useContext(DataContext);
  console.log("locationDaetaForm",locationDataForm)
  return (
    <>
      <StyledModal
        setAction={setAdminApplicantData}
        action={adminApplicantData}
        style={{ size: "1330px" }}
      >
        <ApplicantModal
          data={adminApplicantData}
          onClose={setAdminApplicantData}
        />
      </StyledModal>
      <StyledModal
        style={{ size: "sm" }}
        setAction={setDeleteAccount}
        action={accountDelete}
      >
        <AccountDelete data={accountDelete} onClose={setDeleteAccount} />
      </StyledModal>
      <StyledModal
        style={{ size: "sm" }}
        setAction={setSoftDeleteAccount}
        action={softDeleteAccount}
      >
        <AccountSoftDelete
          data={softDeleteAccount}
          onClose={setSoftDeleteAccount}
        />
      </StyledModal>
      <StyledModal
        style={{ size: "sm" }}
        setAction={setDeleteDepartment}
        action={deleteDepartment}
      >
        <DeleteDepartment
          data={deleteDepartment}
          onClose={setDeleteDepartment}
        />
      </StyledModal>
      <StyledModal
        style={{ size: "sm" }}
        setAction={setActiveAcount}
        action={activeAcount}
      >
        <AccountActivate data={activeAcount} onClose={setActiveAcount} />
      </StyledModal>
      <StyledModal
        style={{ size: "sm" }}
        setAction={setDeactiveAcount}
        action={deactiveAcount}
      >
        <AccountDeactivate data={deactiveAcount} onClose={setDeactiveAcount} />
      </StyledModal>
      <StyledModal
        style={{ size: "xl" }}
        setAction={setEditDepartment}
        action={editDepartment}
      >
        <EditDepartment
          data={editDepartment}
          onClose={setEditDepartment}
        />
      </StyledModal>
      <StyledModal
        style={{ size: "xl" }}
        setAction={setViewDepartment}
        action={viewDepartment}
      >
        <ViewDepartment
          data={viewDepartment}
          onClose={setViewDepartment}
        />
      </StyledModal>
      <StyledModal
        style={{ size: "xl" }}
        setAction={setRoleEdit}
        action={roleEdit}
      >
        <EditRecruiter data={roleEdit} onClose={setRoleEdit} />
      </StyledModal>
      <StyledModal
        style={{ size: "xl" }}
        setAction={setRoleView}
        action={roleView}
      >
        <ViewRecruiter data={roleView} onClose={setRoleView} />
      </StyledModal>
      <StyledModal
        style={{ size: "md" }}
        setAction={setAccountAdd}
        action={accountAdd}
        equel="HR_recruiters_manage"
      >
        <AddRecruiter data={accountAdd} onClose={setAccountAdd} />
      </StyledModal>
      <StyledModal
        style={{ size: "md" }}
        setAction={setAccountAdd}
        action={accountAdd}
        equel="partial_recruiters_manage"
      >
        <AddRecruiter data={accountAdd} onClose={setAccountAdd} />
      </StyledModal>
      <StyledModal
        style={{ size: "1250px" }}
        setAction={setAccountAdd}
        action={accountAdd}
        equel="published_programs"
      >
        <AddProgram data={accountAdd} onClose={setAccountAdd} />
      </StyledModal>
      <StyledModal
        style={{ size: "md" }}
        setAction={setAccountAdd}
        action={accountAdd}
        equel="applicant_manage"
      >
        <AdminAddApplicants data={accountAdd} onClose={setAccountAdd} />
      </StyledModal>
      <StyledModal
        style={{ size: "xl" }}
        setAction={setAccountAdd}
        action={accountAdd}
        equel="HR_departments_manage"
      >
        <AddDepartment data={accountAdd} onClose={setAccountAdd} />
      </StyledModal>
      <StyledModal
        style={{ size: "md" }}
        setAction={setActivateCompany}
        action={activateCompany}
      >
        <CompanyActivate data={activateCompany} onClose={setActivateCompany} />
      </StyledModal>
      <StyledModal
        style={{ size: "md" }}
        setAction={setDeleteCompany}
        action={deleteCompany}
      >
        <CompanyDelete data={deleteCompany} onClose={setDeleteCompany} />
      </StyledModal>
      <StyledModal
        style={{ size: "md" }}
        setAction={setCancelProgram}
        action={cancelProgram}
      >
        <CancelProgram data={cancelProgram} onClose={setCancelProgram} />
      </StyledModal>
      <StyledModal
        style={{ size: "1250px" }}
        setAction={setEditProgram}
        action={editProgram}
      >
        <EditProgram data={editProgram} onClose={setEditProgram} />
      </StyledModal>
      <StyledModal
        style={{ size: "850px" }}
        setAction={setHRviewProgram}
        action={hrViewProgram}
      >
        <ViewProgram data={hrViewProgram} onClose={setHRviewProgram} />
      </StyledModal>
      <StyledModal
        style={{ size: "1050px" }}
        setAction={setApplicantViewProgram}
        action={applicantViewProgram}
      >
        <ViewChanger
        web={<ApplicantViewProgramWeb data={applicantViewProgram} onClose={setApplicantViewProgram} />}
        mobile={<ApplicantViewProgramMobile data={applicantViewProgram} onClose={setApplicantViewProgram} />}
        />
      </StyledModal>
      <StyledModal
        style={{ size: "1050px" }}
        setAction={setApplyForProgram}
        action={applyForProgram}
      >
        <ViewChanger
        web={<ApplyForProgramWeb data={applyForProgram} onClose={setApplyForProgram} />}
        mobile={<ApplyForPrograMobile data={applyForProgram} onClose={setApplyForProgram} />}
        />
      </StyledModal>
      <StyledModal
        style={{ size: "1250px" }}
        setAction={setPublishProgram}
        action={publishProgram}
      >
        <PublishProgram data={publishProgram} onClose={setPublishProgram} />
      </StyledModal>
      <StyledModal
        style={{ size: "md" }}
        setAction={setAcceptNewApplicant}
        action={acceptNewApplicant}
      >
        <AcceptNewApplicant
          data={acceptNewApplicant}
          onClose={setAcceptNewApplicant}
        />
      </StyledModal>
      <StyledModal
        style={{ size: "md" }}
        setAction={setRejectNewApplicant}
        action={rejectNewApplicant}
      >
        <RejectNewApplicant
          data={rejectNewApplicant}
          onClose={setRejectNewApplicant}
        />
      </StyledModal>
      <StyledModal
        style={{ size: "md" }}
        setAction={setAcceptWaitingInterview}
        action={acceptWaitingInterview}
      >
        <AcceptWaitingInterview
          data={acceptWaitingInterview}
          onClose={setAcceptWaitingInterview}
        />
      </StyledModal>
      <StyledModal
        style={{ size: "md" }}
        setAction={setExcludeInterview}
        action={excludeInterview}
      >
        <ExcludeInterview
          data={excludeInterview}
          onClose={setExcludeInterview}
        />
      </StyledModal>
      <StyledModal
        style={{ size: "1050px" }}
        setAction={setSchedulingInterview}
        action={schedulingInterview}
      >
        <SchedulingInterview
          data={schedulingInterview}
          onClose={setSchedulingInterview}
        />
      </StyledModal>
      <StyledModal
        style={{ size: "md" }}
        setAction={setAcceptScheduledInterview}
        action={acceptScheduledInterview}
      >
        <AcceptScheduledInterview
          data={acceptScheduledInterview}
          onClose={setAcceptScheduledInterview}
        />
      </StyledModal>
      <StyledModal
        style={{ size: "md" }}
        setAction={setCancelInterview}
        action={cancelInterview}
      >
        <CancelInterview data={cancelInterview} onClose={setCancelInterview} />
      </StyledModal>
      <StyledModal
        style={{ size: "1050px" }}
        setAction={setReschedule}
        action={reschedule}
      >
        <Reschedule data={reschedule} onClose={setReschedule} />
      </StyledModal>

      <StyledModal
        style={{ size: "md" }}
        setAction={setDidNotAttend}
        action={didNotAttend}
      >
        <DidNotAttend data={didNotAttend} onClose={setDidNotAttend} />
      </StyledModal>
      <StyledModal style={{ size: "1050px" }} setAction={setEvaluationInterview} action={evaluationInterview}>
        <EvaluationInterview data={evaluationInterview} onClose={setEvaluationInterview} />
      </StyledModal>
      <StyledModal
        style={{ size: "md" }}
        setAction={setAcceptAppointment}
        action={acceptAppointment}
        fullscreen={false}
      >
        <AcceptAppointment
          data={acceptAppointment}
          onClose={setAcceptAppointment}
        />
      </StyledModal>
      <StyledModal
        style={{ size: "1050px" }}
        setAction={setExcludeDocument}
        action={excludeDocument}
      >
        <ExcludeDocument data={excludeDocument} onClose={setExcludeDocument} />
      </StyledModal>
      <StyledModal
        style={{ size: "1050px" }}
        setAction={setCorrectionRequest}
        action={correctionRequest}
      >
        <CorrectionRequest
          data={correctionRequest}
          onClose={setCorrectionRequest}
        />
      </StyledModal>
      <StyledModal
        style={{ size: "1050px" }}
        setAction={setAcceptLetter}
        action={acceptLetter}
      >
        <AcceptLetter data={acceptLetter} onClose={setAcceptLetter} />
      </StyledModal>
      <StyledModal
        style={{ size: "lg" }}
        setAction={setUploadDocuments}
        action={uploadDocuments}
      >
        <UploadDocuments data={uploadDocuments} onClose={setUploadDocuments} />
      </StyledModal>
      <StyledModal
        style={{ size: "700px" }}
        setAction={setChangeCommencementDate}
        action={changeCommencementDate}
      >
        <ChangeCommencementDate
          data={changeCommencementDate}
          onClose={setChangeCommencementDate}
        />
      </StyledModal>
      <StyledModal
        style={{ size: "1050px" }}
        setAction={setTerminateApplicant}
        action={terminateApplicant}
      >
        <TerminateApplicant
          data={terminateApplicant}
          onClose={setTerminateApplicant}
        />
      </StyledModal>
      <StyledModal
        style={{ size: "1050px" }}
        setAction={setTerminateApplicantView}
        action={terminateApplicantView}
      >
        <TerminateApplicantView
          data={terminateApplicantView}
          onClose={setTerminateApplicantView}
        />
      </StyledModal>
      <StyledModal
        style={{ size: "1050px" }}
        setAction={setCancelProgramApplicant}
        action={cancelProgramApplicant}
      >
        <CancelProgramApplicant
          data={cancelProgramApplicant}
          onClose={setCancelProgramApplicant}
        />
      </StyledModal>
      <StyledModal
        style={{ size: "xl" }}
        setAction={setSchedulePlan}
        action={schedulePlan}
      >
        <SchedulePlan
          data={schedulePlan}
          onClose={setSchedulePlan}
        />
      </StyledModal>
      <StyledModal
        style={{ size: "xl" }}
        setAction={setActiveProgramRecruiter}
        action={activeProgramRecruiter}
      >
        <ActiveProgramRecruiter
          data={activeProgramRecruiter}
          onClose={setActiveProgramRecruiter}
        />
      </StyledModal>
      <StyledModal
        style={{ size: "950px" }}
        setAction={setActiveProgramsApplicant}
        action={activeProgramsApplicant}
      >
        <ActiveProgramsApplicant
          data={activeProgramsApplicant}
          onClose={setActiveProgramsApplicant}
        />
      </StyledModal>
      <StyledModal
        style={{ size: "md" }}
        setAction={setTerminationProgramWithoutDocument}
        action={terminationProgramWithoutDocument}
      >
        <TerminationProgramWithoutDocument
          data={terminationProgramWithoutDocument}
          onClose={setTerminationProgramWithoutDocument}
        />
      </StyledModal>
      <StyledModal
        style={{ size: "1050px" }}
        setAction={setUploadingProgramCompletionLetter}
        action={uploadingProgramCompletionLetter}
      >
        <UploadingProgramCompletionLetter
          data={uploadingProgramCompletionLetter}
          onClose={setUploadingProgramCompletionLetter}
        />
      </StyledModal>
      <StyledModal
        style={{ size: "1050px" }}
        setAction={setProgramEvaluation}
        action={programEvaluation}
      >
        <ProgramEvaluation
          data={programEvaluation}
          onClose={setProgramEvaluation}
        />
      </StyledModal>
      <StyledModal
        style={{ size: "1050px" }}
        setAction={setProgramEvaluationView}
        action={programEvaluationView}
      >
        <ProgramEvaluationView
          data={programEvaluationView}
          onClose={setProgramEvaluationView}
        />
      </StyledModal>
      <StyledModal
        style={{ size: "md" }}
        setAction={setWithdrawal}
        action={withdrawal}
        fullscreen={false}
      >
        <Withdrawal
          data={withdrawal}
          onClose={setWithdrawal}
        />
      </StyledModal>
      <StyledModal
        style={{ size: "500px" }}
        setAction={setWithdrawalRequest}
        action={withdrawalRequest}
        fullscreen={false}
      >
        <WithdrawalRequest
          data={withdrawalRequest}
          onClose={setWithdrawalRequest}
        />
      </StyledModal>
      <StyledModal
        style={{ size: "1050px" }}
        setAction={setWithdrawalReason}
        action={withdrawalReason}
      >
        <WithdrawalReason
          data={withdrawalReason}
          onClose={setWithdrawalReason}
        />
      </StyledModal>
      <StyledModal
        style={{ size: "xl" }}
        setAction={setAccountAdd}
        action={accountAdd}
        equel="permissions"
      >
        <AddPermission data={accountAdd} onClose={setAccountAdd} />
      </StyledModal>

      <StyledModal
        style={{ size: "md" }}
        setAction={setDeletePermission}
        action={deletePermission}
      >
        <DeletePermission data={deletePermission} onClose={setDeletePermission} />
      </StyledModal>
      <StyledModal
        style={{ size: "xl" }}
        setAction={setEditPermission}
        action={editPermission}
      >
        <EditPermission data={editPermission} onClose={setEditPermission} />
      </StyledModal>
      <StyledModal
        style={{ size: "xl" }}
        setAction={setViewPermission}
        action={viewPermission}
      >
        <ViewPermission data={viewPermission} onClose={setViewPermission} />
      </StyledModal>
      <StyledModal
        style={{ size: "md" }}
        setAction={setAcceptWithdraw}
        action={acceptWithdraw}
      >
        <AcceptWithdraw data={acceptWithdraw} onClose={setAcceptWithdraw} />
      </StyledModal>
      <StyledModal
        style={{ size: "md" }}
        setAction={setRejectWithdraw}
        action={rejectWithdraw}
      >
        <RejectWithdraw data={rejectWithdraw} onClose={setRejectWithdraw} />
      </StyledModal>
      <StyledModal
        style={{ size: "md" }}
        setAction={setRejectProgram}
        action={rejectProgram}
      >
        <RejectProgram data={rejectProgram} onClose={setRejectProgram} />
      </StyledModal>
      <StyledModal
        style={{ size: "1250px" }}
        setAction={setAdoptProgram}
        action={adoptProgram}
      >
        <AdoptProgram data={adoptProgram} onClose={setAdoptProgram} />
      </StyledModal>
      <StyledModal
        style={{ size: "md" }}
        setAction={setRejectProgramReason}
        action={rejectProgramReason}
      >
        <RejectProgramReason data={rejectProgramReason} onClose={setRejectProgramReason} />
      </StyledModal>
      <StyledModal
        style={{ size: "md" }}
        setAction={setAccountAdd}
        action={accountAdd}
        equel="partial_programs"
      >
        <AddPartialProgram data={accountAdd} onClose={setAccountAdd} />
      </StyledModal>
      <StyledModal
        style={{ size: "md" }}
        setAction={setHangPartialProgram}
        action={hangPartialProgram}
      >
        <HangPartialProgram data={hangPartialProgram} onClose={setHangPartialProgram} />
      </StyledModal>
      <StyledModal
        style={{ size: "md" }}
        setAction={setCancelPartialProgram}
        action={cancelPartialProgram}
      >
        <CancelPartialProgram data={cancelPartialProgram} onClose={setCancelPartialProgram} />
      </StyledModal>
      <StyledModal
        style={{ size: "md" }}
        setAction={setPublishPartialProgram}
        action={publishPartialProgram}
      >
        <PublishPartialProgram data={publishPartialProgram} onClose={setPublishPartialProgram} />
      </StyledModal>
      <StyledModal
        style={{ size: "1250px" }}
        setAction={setResendForApproval}
        action={resendForApproval}
      >
        <ResendForApproval data={resendForApproval} onClose={setResendForApproval} />
      </StyledModal>
      <StyledModal
        style={{ size: "1050px" }}
        setAction={setCorrectionRequestContractIssuing}
        action={correctionRequestContractIssuing}
      >
        <CorrectionRequestContractIssuing data={correctionRequestContractIssuing} onClose={setCorrectionRequestContractIssuing} />
      </StyledModal>
      <StyledModal
        style={{ size: "750px" }}
        setAction={setFillContractData}
        action={fillContractData}
      >
        <FillContractData data={fillContractData} onClose={setFillContractData} />
      </StyledModal>
      <StyledModal
        style={{ size: "750px" }}
        setAction={setApproveContract}
        action={approveContract}
      >
        <ApproveContract data={approveContract} onClose={setApproveContract} />
      </StyledModal>
      <StyledModal
        style={{ size: "lg" }}
        setAction={setCorrectionMessageApplicant}
        action={correctionMessageApplicant}
      >
        <CorrectionMessageApplicant data={correctionMessageApplicant} onClose={setCorrectionMessageApplicant} />
      </StyledModal>
      <StyledModal
        style={{ size: "750px" }}
        setAction={setApproveContractApproval}
        action={approveContractApproval}
      >
        <ApproveContractApproval data={approveContractApproval} onClose={setApproveContractApproval} />
      </StyledModal>
      <StyledModal
        style={{ size: "1050px" }}
        setAction={setCorrectionRequestContractApproval}
        action={correctionRequestContractApproval}
      >
        <CorrectionRequestContractApproval data={correctionRequestContractApproval} onClose={setCorrectionRequestContractApproval} />
      </StyledModal>
      <StyledModal
        style={{ size: "lg" }}
        setAction={setCorrectionMessageIssuing}
        action={correctionMessageIssuing}
      >
        <CorrectionMessageIssuing data={correctionMessageIssuing} onClose={setCorrectionMessageIssuing} />
      </StyledModal>
      <StyledModal
        style={{ size: "lg" }}
        setAction={setCorrectionMessageDocuments}
        action={correctionMessageDocuments}
      >
        <CorrectionMessageDocuments data={correctionMessageDocuments} onClose={setCorrectionMessageDocuments} />
      </StyledModal>
      <StyledModal
        style={{ size: "lg" }}
        setAction={setShowAbout}
        action={showAbout}
      >
        <ShowAbout data={showAbout} onClose={setShowAbout} />
      </StyledModal>
      <StyledModal
        style={{ size: "1050px" }}
        setAction={setInviteApplicant}
        action={inviteApplicant}
      >
        <InviteApplicant data={inviteApplicant} onClose={setInviteApplicant} />
      </StyledModal>
      <StyledModal
        style={{ size: "md" }}
        setAction={setCancelInvitation}
        action={cancelInvitation}
      >
        <CancelInvitation data={cancelInvitation} onClose={setCancelInvitation} />
      </StyledModal>
      <StyledModal
        style={{ size: "md" }}
        setAction={setRejectInvitation}
        action={rejectInvitation}
      >
        <RejectInvitation data={rejectInvitation} onClose={setRejectInvitation} />
      </StyledModal>
      <StyledModal
        style={{ size: "1050px" }}
        setAction={setAcceptInvitation}
        action={acceptInvitation}
      >
        <AcceptInvitation data={acceptInvitation} onClose={setAcceptInvitation} />
      </StyledModal>
      <StyledModal
        style={{ size: "1250px" }}
        setAction={setRepublishProgram}
        action={republishProgram}
      >
        <RepublishProgram data={republishProgram} onClose={setRepublishProgram} />
      </StyledModal>
      <StyledModal
        style={{ size: "md" }}
        setAction={setLocationDataForm}
        action={locationDataForm}
      >
        <LocationDataForm data={locationDataForm} onClose={setLocationDataForm} />
      </StyledModal>
      <StyledModal
        style={{ size: "lg" }}
        setAction={setTestInstructions}
        action={testInstructions}
        fullscreen={false}
      >
        <ViewChanger
        web={<TestInstructionsWeb data={testInstructions} onClose={setTestInstructions} />}
        mobile={<TestInstructionsMobile data={testInstructions} onClose={setTestInstructions} />}
        />
      </StyledModal>
    </> 
    
    
    
    
  );
};
const StyledModal = ({ setAction, action, children, style, equel, fullscreen=true }: any) => {
  const isMobile = useMediaQuery("(max-width: 550px)");
    const is_fullscreen = fullscreen?isMobile:false;
  return (
    <Modal
    fullScreen={is_fullscreen}
      size={style?.size}
      style={{ zIndex: 9999999, position: "relative" }}
      overlayProps={{
        zIndex: 2000,
        opacity: 0.55,
        blur: 3,
      }}
      styles={() => ({
        content: {
          zIndex: 10001,
          borderRadius: "10px",
          background: is_fullscreen?"":"linear-gradient(153deg, #69DCBF 100%, #21033f7a 100%)",
          // display: "flex",
          // justifyContent: "center",
          // alignItems: "center",
        },
        header: { display: !is_fullscreen?"none":"block" },
        body: { padding: "9px" },
        inner: { zIndex: 10001 },
      })}
      opened={equel ? action?.name == equel : action?.id}
      onClose={() => {
        setAction({});
      }}
      centered
    >
      <div
        style={{
          background: "white",
          width: "100%",
          height: "100%",
          marginTop: "9px",
          borderRadius: "5px",
          // paddingTop: "30px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {children}
      </div>
    </Modal>
  );
};
