import { ltn, tn } from "../../../../../../../translation";
import {
  Button,
  Loader,
  Progress,
  Rating,
  Slider,
  Text,
  TextInput,
} from "@mantine/core";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../../../../../../constants";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../../../../context/AppContext";
import { useDebouncedValue } from "@mantine/hooks";
import { useForm } from "@mantine/form";
import { set } from "lodash";

export const EvaluationInterview = (props: any) => {
  const pb: any = new PocketBase(CMS_ENDPOINT);
  const { data, onClose } = props;
  const [debounced_data] = useDebouncedValue(data, 200);

  const { reloader, setReloader } = useContext(AppContext);
  const [company, setCompany]: any = useState({});
  const [evaluation, setEvaluation]: any = useState({});
  useEffect(() => {
    const getCompany = async () => {
      const company = await pb
        .collection("companies")
        .getOne(pb.authStore.model.company);
      const evaluation_model = await pb
        .collection("evaluation_models")
        .getOne(
          company?.current_models
            ?.evaluation_questionnaire_for_personal_interview
        );
      setEvaluation(evaluation_model);
      setCompany(company);
    };
    getCompany();
  }, []);

  const form = useForm({
    initialValues: {
      precentage: 0,
      criterias: [],
    },
  });
  useEffect(() => {
    form.setValues({
      precentage: data?.interview_evaluation?.precentage ?? 0,
      criterias:
        data?.interview_evaluation?.criterias ??
        evaluation?.criterias?.map((criterias) => {
          return {
            name: criterias.name,
            description: criterias.description,
            rating: 2,
            comment: "",
            weight: criterias.weight,
          };
        }),
    });
  }, [evaluation, company]);

  const [precentage, setPrecentage] = useState(0);
  useEffect(() => {
    // get the precentage ot the evaluation by using the waight of each criteria
    let total_weight = 0;
    let total_score = 0;
    form.values?.criterias?.map((item) => {
      total_weight += item.weight;
      total_score += (item.rating / 5) * item.weight;
    });
    setPrecentage(
      Math.round((total_score / total_weight) * 100)
    );
  }, [form.values?.criterias]);
  if (!form.values?.criterias) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "700px",
        }}
      >
        <Loader />
      </div>
    );
  }

  console.log("evaluationevaluationevaluation", form.values);
  return (
    <form
    onSubmit={form.onSubmit(async(values) => {
      const evaluation = {
        precentage: precentage,
        criterias: values.criterias,
      };
      await pb.collection("applications").update(data.id, {
        interview_evaluation: evaluation,
        interview_evaluation_evaluator: pb.authStore.model.id,
      });
      setReloader(!reloader);
      onClose({});
    })}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "start",
        padding: "20px",
        width: "100%",
      }}
    >
      <Text size="xl" fw={600} color="#444444">
        {tn("Personal interview evaluation form")}
      </Text>
      <div
        style={{
          display: "flex",
          alignItems: "start",
          justifyContent: "space-between",
          marginTop: "20px",
          width: "100%",
          padding: "0px 10px",
        }}
      >
        <DataBox
          title={tn("evaluator")}
          value={
            debounced_data?.interview_evaluation_evaluator_name ??
            pb?.authStore?.model?.first_name +
              " " +
              pb?.authStore?.model?.last_name
          }
          onClick={undefined}
        />
        {/* program name */}
        <DataBox
          title={tn("Program name")}
          value={debounced_data?.program_name}
          onClick={undefined}
        />
        {/* اسم المتقدم: */}
        <DataBox
          title={tn("Applicant name")}
          value={debounced_data?.applicant_name}
          onClick={undefined}
        />
        {/* السيرة الذاتية: */}
        <DataBox
          title={tn("CV")}
          value={debounced_data?.cv}
          onClick={() => {
            window.open(
              `${CMS_ENDPOINT}/api/files/${data?.collectionId}/${data?.id}/${debounced_data?.cv}?token=`,
              "_blank"
            );
          }}
        />
        {/* البريد الإلكتروني: */}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          marginTop: "20px",
          width: "100%",
          padding: "0px 10px",
          gap: "10px",
        }}
      >
        <Text size="xl" fw="800" color="color3">
          {precentage && (precentage + "%")}
        </Text>
        <Progress
          color="color3"
          radius="lg"
          size="xl"
          value={precentage}
          style={{
            width: "33%",
          }}
        />
      </div>
      <div
        style={{
          width: "100%",
          marginTop: "20px",
          border: "1px solid #E0E0E0",
          borderRadius: "10px",
          minHeight: "100px",
          maxHeight: "650px",
          overflowY: "auto",
        }}
      >
        {form?.values?.criterias?.map((item: any, index) => {
          return <CriteriaBox item={item} form={form} index={index} />;
        })}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "end",
          marginTop: "20px",
          width: "100%",
          gap: "10px",
        }}
      >
         <Button
          variant="outline"
          color="gray"
          onClick={() => onClose({})}
        >
          {tn("Cancel")}
        </Button>
        <Button
         color="color2"
        type="submit"
        >
          {tn("Save")}
        </Button>
       
      </div>
    </form>
  );
};
{
  /* <Slider
       marks={[
        { value: 20, label: '20%' },
        { value: 50, label: '50%' },
        { value: 80, label: '80%' },
      ]}
      label={(value:any) => value + "%"} 
      value={evaluation}
      onChange={(e:any)=>{
        setEvaluation(e);
      }} style={{
        width:"100%",
        marginTop:"20px",
        marginBottom:"10px"
      }}  min={0} max={100} step={1}
  /> */
}

const DataBox = ({ value, title, onClick }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "start",
        justifyContent: "start",
        marginTop: "10px",
        gap: "5px",
        cursor: onClick ? "pointer" : "default",
      }}
      onClick={onClick}
    >
      <Text size="sm" color="#444444">
        {title}
        {": "}
      </Text>
      <Text size="sm" color={onClick ? "#FFB004" : "#444444"}>
        {value}
      </Text>
    </div>
  );
};

const CriteriaBox = ({ item, form, index }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        gap: "5px",
        backgroundColor: index % 2 === 0 ? "#F5F5F5" : "#FFFFFF",
        padding: "10px",
        borderRadius: "10px",
      }}
    >
      <div
        style={{
          width: "calc(40% - 10px)",
          display: "flex",
          gap: "15px",
          alignItems: "center",
        }}
      >
        <Text size="sm" color="#444444">
          {index + 1}
        </Text>
        <Text size="sm" color="#444444">
          {/* title with bold + - + description */}
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            {item.name}
          </span>
          {" - "}
          {item.description}
        </Text>
      </div>
      <Rating
        {...form.getInputProps(`criterias.${index}.rating`)}
        defaultValue={2}
        size="lg"
      />
      <TextInput
        placeholder={tn("Add comment")}
        {...form.getInputProps(`criterias.${index}.comment`)}
        style={{
          width: "calc(50% - 10px)",
        }}
        styles={() => ({
          input: {
            height: "40px",
            borderRadius: "10px",
            border: "1px solid #E0E0E0",
            padding: "10px",
            fontSize: "14px",
            color: "#444444",
          },
        })}
      />
    </div>
  );
};
