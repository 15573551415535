import { useContext } from "react";
import { DataContext } from "../../../../../../context/DataContext";
import { ActionComponent } from "../../../ActionComponent";

export const ExcludeDocument = (props: any) => {
  const { setExcludeDocument }: any = useContext(DataContext);
  
  const { data, action , table } = props;
  return  <ActionComponent
  data={data}
  action={action}
  table={table}
  setData={async () => {
    setExcludeDocument({
      id: data.id,
      record: data,
      action: action,
      table: table,
    });
  }}
  enabled={data?.document_status?.en != "accepted"}
/>
};
