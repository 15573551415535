import {
  Paper,
  createStyles,
  TextInput,
  PasswordInput,
  Checkbox,
  Button,
  Title,
  Text,
  Anchor,
  rem,
  Loader,
} from "@mantine/core";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm } from "@mantine/form";
import { useContext, useEffect, useState } from "react";
import { useViewportSize } from "@mantine/hooks";
import { AppContext } from "../../../context/AppContext";
import { TranslationContext } from "../../../context/TranslationContext";
import { lt, ltn, tn, vtn } from "../../../translation";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../../constants";
import { UserContext } from "../../../context/UserContext";
import jwt_decode from "jwt-decode";
import { TopShapeAuth } from "../components/TopShapeAuth";

 function VerificationSuccess() {
  const pb: any = new PocketBase(CMS_ENDPOINT);
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [isLogin, setIsLogin] = useState(false);

  const { locale } = useContext(TranslationContext);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");



  const [isVerified, setIsVerified]: any = useState(false);
  useEffect(() => {
    pb.collection("applicants").authRefresh();
    if (token) {
      try {
        pb.collection("applicants")
          .confirmVerification(token)
          .then((res: any) => {
            setIsVerified(true);
          });
      } catch (err) {}
    } else {
      // navigate("/applicant/register");
    }
  }, []);
  const form = useForm({
    initialValues: {
      password: "",
    },

    validate: {},
  });

  return isVerified ? (
    <form
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      height: "100vh",

    }}
      onSubmit={form.onSubmit(async (value) => {
        try {
          const authData = await pb
            .collection("applicants")
            .authWithPassword(jwt_decode(token)?.email, value?.password)
            ?.then(() => {
              setIsLogin(true);
            });
        } catch (err) {
          setError(true);
        }
      })}
    >
       <TopShapeAuth title={lt("applicant_acount_success_title")} />
      <div style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "center",
        width: "100%",
        padding: "0px 20px",
      
      }}>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                marginTop: "60px",
                flexDirection:  "column",
              }}
            >
           
            </div>

            <Text mt={45} >
              {lt("applicant_acount_success_subtitle")}
            </Text>

            {!isLogin && (
              <>
                <Text
                  style={{
                    color: "#69DCBF",
                    fontSize: "15px",
                    fontWeight: "700",
                    marginTop: "20px",
                    border: "1px solid #69DCBF",
                    borderRadius: "10px",
                    padding: "10px",
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  {jwt_decode(token)?.email}
                </Text>
                <PasswordInput
                style={{
                  width: "100%",
                
                }}
                  // error={error}
                  {...form.getInputProps("password")}
                  label={tn("Password")}
                  styles={() => ({
                    root: {
                      marginTop: "20px",
                    },
                    input: {
                      color: "#5a5a5a",
                      backgroundColor: "transparent",
                      border: "0px solid #69DCBF",
                      borderBottom: "1px solid #69DCBF",
                      borderRadius: "0px",
                    },
                    label: {
                      color: "#5a5a5a",
                      fontSize: "15px",
                      fontWeight: "700",
                      marginBottom: "0px",
                    },
                    innerInput: {
                      color: "#5a5a5a",
                      fontSize: "15px",
                      fontWeight: "700",
                      marginBottom: "0px",
                    },
                  })}
                />
              </>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column" ,
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              marginTop: "0px",
              position: "static",
              bottom: "0",
              marginBottom: "65px",
            }}
          >
            {!isLogin ? (
              <>
                {error && (
                  <Text
                    style={{
                      color: "#ff6060",
                      fontSize: "15px",
                      marginBottom: "10px",
                      borderRadius: "10px",
                      textAlign: "center",
                    }}
                  >
                    {vtn("Invalid password")}
                  </Text>
                )}
                <Button
                  type="submit"
                  size="lg"
                  // mt="auto"
                  variant="light"
                  styles={(theme) => ({
                    root: {
                      backgroundColor: "#69DCBF",
                      color: "#dcfff6",
                      borderRadius: "10px",
                      width: "80%",
                      height: "60px",
                      minHeight: "60px",
                      "&:not([data-disabled])": theme.fn.hover({
                        backgroundColor: theme.fn.darken("#8fffe3", 0.05),
                      }),
                    },
                  })}
                >
                  {tn("Login")}
                </Button>
              </>
            ) : (
              <>
                <Button
                  mx="xs"
                  mb="xs"
                  onClick={() => navigate("/applicant/fill_personal_info")}
                  // mt="auto"
                  variant="light"
                  styles={(theme) => ({
                    root: {
                      backgroundColor: "#dcfff6",
                      color: "#59c5aa",
                      borderRadius: "10px",
                      width: "80%",
                      height: "60px",
                      "&:not([data-disabled])": theme.fn.hover({
                        backgroundColor: theme.fn.darken("#dcfff6", 0.05),
                      }),
                    },
                  })}
                >
                  {tn("Fill in personal information")}
                </Button>
                <Button
                  mx="xs"
                  mb="xs"
                  onClick={() => {
                    navigate("/applicant/programs_search");
                  }}
                  // mt="auto"
                  variant="light"
                  color="gray"
                  styles={(theme) => ({
                    root: {
                      borderRadius: "10px",
                      width: "80%",
                      height: "60px",
                      "&:not([data-disabled])": theme.fn.hover({
                        backgroundColor: theme.fn.darken("#dddddd", 0.05),
                      }),
                    },
                  })}
                >
                  {tn("Skip fill in personal information")}
                </Button>
              </>
            )}{" "}
          </div>

          <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
          padding: "0px 20px",

          width: "100%",
          maxWidth: "500px",
          flexDirection: "row",
        }}
      >
        <Text
          style={{
            textAlign: "center",
          }}
          fz="12px"
          color="#767676"
          weight={700}
        >
          {tn("terms and conditions")}
        </Text>{" "}
        {divider}
        <Text
          style={{
            textAlign: "center",
          }}
          fz="12px"
          color="#767676"
          weight={700}
        >
          {tn("Usage policy")}
        </Text>{" "}
        {divider}
        <Text
          style={{
            textAlign: "center",
          }}
          fz="12px"
          color="#767676"
          weight={700}
        >
          {tn("All rights are save")}
        </Text>{" "}
      </div>

    </form>
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Loader color="#69DCBF" />
    </div>
  );
}
const divider = (
  <div
    style={{
      width: "1px",
      height: "15px",
      background: "#767676",
      margin: "0px 5px",
    }}
  />
);
export default VerificationSuccess;
