import { useContext } from "react";
import { DataContext } from "../../../../../../context/DataContext";
import { ActionComponent } from "../../../ActionComponent";

export const WithdrawalRequest = (props: any) => {
  const { setWithdrawalRequest, setWithdrawal }: any = useContext(DataContext);

  const { data, action, table } = props;
  // if main_status = accepted,canceled program,excluded,rejected,terminated then disable

  const disabled = [
    "canceled program",
    "excluded",
    "rejected",
    "terminated",
    "withdrawn",
    "withdrawal request",
  ].includes(data?.main_status?.en);

  const isRequest =
    ["document review"].includes(data?.main_status?.en) ||
    ["issuing the contract", "approval of the contract"].includes(
      data?.contract_status?.en
    );

  return (
    <ActionComponent
    is_mobile={true}
      data={data}
      action={action}
      table={table}
      setData={async () => {
        if (isRequest) {
          setWithdrawalRequest({
            id: data.id,
            record: data,
            action: action,
            table: table,
          });
        } else {
          setWithdrawal({
            id: data.id,
            record: data,
            action: action,
            table: table,
          });
        }
      }}
      enabled={!disabled}
    />
  );
};
