import { useViewportSize } from "@mantine/hooks";
import { Text, Avatar, Switch, Button, FileButton } from "@mantine/core";
import { CMS_ENDPOINT } from "../../../constants";
import PocketBase from "pocketbase";
import { ReactNode, useContext, useEffect, useState } from "react";
import { tn } from "../../../translation";
import { TranslationContext } from "../../../context/TranslationContext";
import { motion } from "framer-motion";
import { IconPencil, IconSquareRoundedCheck, IconX } from "@tabler/icons-react";
import { MIME_TYPES } from "@mantine/dropzone";
import { BannersContext } from "../../../context/BannersContext";
import { AppContext } from "../../../context/AppContext";

const pb: any = new PocketBase(CMS_ENDPOINT);

export function MainDataProfile() {
  const { height, width }: any = useViewportSize();
  const { locale, translation }: any = useContext(TranslationContext);
  const {reloader,setReloader}:any = useContext(AppContext)

  const {setTopBanner}:any = useContext(BannersContext);
  const [userData, setUserData] = useState(pb?.authStore?.model);
 
  
  const [discoverable, setDiscoverable] = useState(
    userData?.discoverable
  );
  const [onHover, setOnHover]: any = useState(false);

  const [avatar, setAvatar] = useState(
    userData?.avatar &&
      `${CMS_ENDPOINT}/api/files/${userData?.collectionName}/${userData?.id}/${userData?.avatar}?token=`
  );

  const CVdeleted = tn("CV deleted");
  const CVuploaded = tn("CV uploaded");
  useEffect(() => {
    setUserData(pb?.authStore?.model)
  }, [reloader])
  return (
    <div
      style={{
        borderRadius: "10px",
        background: "#FFF",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.116)",
        padding: "10px",
        width: width > 1500 ? "270px" : width > 600 ? "49%" : "100%",
        // height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <FileButton
       maxSize={1024 ** 2}
        onChange={async (file: any) => {
          const formData = new FormData();
          formData.append("avatar", file);
          const record = pb
            .collection("applicants")
            .update(userData?.id, formData);
          setAvatar(URL.createObjectURL(file));
          setReloader(!reloader)

        }}
        accept="image/png,image/jpeg"
      >
        {(props) => (
          <motion.div
            {...props}
            style={{
              cursor: "pointer",
              padding: "1px",
              position: "relative",
            }}
            onHoverStart={() => {
              setOnHover(true);
            }}
            onHoverEnd={() => {
              setOnHover(false);
            }}
          >
            <Avatar styles={()=>({
                  placeholderIcon:{
                    width: "120%",
                    height: "120%",
                    color: "#4F5152",
                    minWidth: "120%",
                    minHeight: "120%",
                   
                  }
                })} src={avatar} size={120} radius="100%" variant="outline" >
                   <img
                style={{
                  width: "125%",
                  height: "125%",
                }}
                src={"/avatar_placeholder.svg"}
              />
                </Avatar>
            <motion.div
              animate={{
                width: onHover ? "130px" : "36px",
                height: onHover ? "130px" : "36px",
              }}
              style={{
                width: "23px",
                height: "23px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#21033fac",
                border: "1px solid #ffffff9b",
                borderRadius: "1000px",
                position: "absolute",
                bottom: "0px",
                right: "0px",
                padding: "2px",
                transform: "translate(4px,4px)",
                flexDirection: "column",
              }}
            >
              {avatar && (
                <motion.div
                  onClick={async (e) => {
                    e.stopPropagation();
                    setAvatar("");
                    const record = pb
                      .collection("applicants")
                      .update(userData?.id, {
                        avatar: null,
                      })
                    setReloader(!reloader)
                  }}
                  animate={{
                    width: onHover ? "30px" : "0px",
                    height: onHover ? "30px" : "0px",
                  }}
                  transition={{ duration: 0.3 }}
                  style={{
                    width: "0px",
                    height: "0px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#F9837C",
                    borderRadius: "1000px",
                    position: "absolute",
                    top: "0px",
                    left: "0px",
                    padding: "2px",
                    transform: "translate(4px,4px)",
                    flexDirection: "column",
                  }}
                >
                  <IconX color="white" size={20} />
                </motion.div>
              )}
              <IconPencil color="white" />
              <motion.div
                style={{
                  marginTop: "2px",
                  fontSize: "12px",
                  fontWeight: "900",
                  display: "flex",
                }}
                animate={{
                  display: onHover ? "flex" : "none",
                  color: "#FFF",
                  width: "70%",
                }}
              >
                {tn("Uploading avatar")}
              </motion.div>
            </motion.div>
          </motion.div>
        )}
      </FileButton>

      <Text mt={20} color="#4D4D4D" size="lg" fw="700">
        {userData?.first_name?.split(" ")?.[0] +
          " " +
          userData?.last_name}
      </Text>
      <Text mt="sm" color="#4D4D4D" size="md" fw="400" fz={15}>
        {userData?.major?.[locale]}
      </Text>
      <Text color="#4D4D4D" size="md" fw="400" fz={15}>
        {userData?.university?.[locale]}
      </Text>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          marginTop: "20px",
        }}
      >
        <Switch
          checked={discoverable}
          onChange={async (e) => {
            const record = pb
              .collection("applicants")
              .update(userData?.id, {
                discoverable: e.target.checked,
              })
              .then((res: any) => {
                setDiscoverable(res?.discoverable);
                setReloader(!reloader)
              });
          }}
          mx="sm"
          size="lg"
          color="yellow"
        />
        <Text mx="sm" color="#4D4D4D" size="md" fw="700">
          {tn("Discoverable")}
        </Text>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          marginTop: "20px",
          padding: "0px 5px",
        }}
      >
        <FileButton  maxSize={1024 ** 2} accept={MIME_TYPES.pdf} onChange={async (file:any) => {
               const formData = new FormData();
               formData.append("cv", file);
               const record = pb
                 .collection("applicants")
                 .update(userData?.id, formData).then((res:any)=> setTopBanner({
                  message: CVuploaded,
                  color: "#2DC071",
                  top: "83px",
                  backgroundColor: "#F2FFF7EB",
                  icon: <IconSquareRoundedCheck/>,
                }));
               
               setReloader(!reloader);
     
        }}>
          {(props) => (
            <Button {...props}
              size="xs"
              compact
              style={{
                background: "#69DCBF",
                color: "#FFF",
                borderRadius: "5.112px",
                width: "49%",
                fontSize: "11px",
              }}
            >
                {tn("Upload CV")}
            </Button>
          )}
        </FileButton>

        <Button
       
        onClick={async ()=>{
            const record = pb
            .collection("applicants")
            .update(userData?.id, {
                cv: null,
            }).then((res:any)=> setTopBanner({
              message: CVdeleted,
              color: "#2DC071",
              top: "83px",
              backgroundColor: "#F2FFF7EB",
              icon: <IconSquareRoundedCheck/>,
            }));
            setReloader(!reloader);
        }}
          size="xs"
          compact
          style={{
            background: "#F9837C",
            color: "#FFF",
            borderRadius: "5.112px",
            width: "49%",
            fontSize: "11px",
          }}
        >
            {tn("Delete CV")}
        </Button>
      </div>
    </div>
  );
}
