import { TranslationContext } from "../../../../context/TranslationContext";
import { useContext } from "react";
import { DataContext } from "../../../../context/DataContext";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../../../constants";
import { ltn } from "../../../../translation";
import { BannersContext } from "../../../../context/BannersContext";
import { IconSquareRoundedX } from "@tabler/icons-react";
import alasql from "alasql";
import { SelectableActionComponent } from "../SelectableActionComponent";
export const AcceptWaitingInterview = (props: any) => {
  const { setAcceptWaitingInterview }: any = useContext(DataContext);
  const pb: any = new PocketBase(CMS_ENDPOINT);
  const { setTopBanner }: any = useContext(BannersContext);

  const { selectedRecords, action, table } = props;
  const dialogMassage = ltn("applicant_view_programs_full");
  const dialogMassages = ltn("applicants_view_programs_full");

  return (
    <SelectableActionComponent
    selectedRecords={selectedRecords}
       action={action}
       table={table}
       setData={async () => {
        const record_ = pb
        .collection("applications_view")
        .getList(1, 50, {
          filter: selectedRecords
            ?.map((record: any) => `id = "${record}"`)
            ?.join(" || "),
        })
        .then((result: any) => {
          const record = pb
            .collection("applicant_view_programs")
            .getList(1, 50, {
              filter: result?.items
                ?.map((record: any) => `id = "${record?.program}"`)
                ?.join(" || "),
            })
            ?.then((res: any) => {
              // const conditions = res?.items?.map((item: any) => alasql(`select * from ? where program = ?`,[result?.items , item?.id])?.length <= item?.free_seats);
              // const combinedCondition = conditions.reduce((acc, curr) => acc && curr, false);
              const combinedCondition = res?.items?.every(
                (item: any) =>
                  alasql(`select * from ? where program = ?`, [
                    result?.items,
                    item?.id,
                  ])?.length <= item?.free_seats
              );

              if (combinedCondition) {
                setAcceptWaitingInterview({
                  id: selectedRecords?.[0],
                  record: selectedRecords,
                  action: action,
                  table: table,
                });
              } else {
                setTopBanner({
                  message: selectedRecords?.[1]
                    ? dialogMassages
                    : dialogMassage,
                  color: "#c02d2d",
                  top: "83px",
                  backgroundColor: "#fff2f2eb",
                  icon: <IconSquareRoundedX />,
                  duration: 5000,
                });
              }
            });
        });
       }}
       enabled={true}
    />
    
  );
};
