import { isNotEmpty, useForm } from "@mantine/form";
import { ltn, tn, vtn } from "../../../../../../translation";
import dayjs from "dayjs";
import _ from "lodash";

import {
  Button,
  Loader,
} from "@mantine/core";

import { useContext, useEffect, useState } from "react";
import { TranslationContext } from "../../../../../../context/TranslationContext";
import { CMS_ENDPOINT } from "../../../../../../constants";
import PocketBase from "pocketbase";
import { AppContext } from "../../../../../../context/AppContext";
import { useDebouncedValue, useViewportSize } from "@mantine/hooks";
import { ProgramForm } from "./program_form/ProgramForm";
const pb = new PocketBase(CMS_ENDPOINT);
export const EditProgram = ({ data, onClose }: any) => {

  const findLocalizedNames = (list:any, value:any, field = 'name_en') => {
    const listItem = list.find((item:any) => item[field] === value)
    return (listItem?.value?{
      en: listItem?.name_en,
      ar: listItem?.name_ar,
      value: listItem?.value ?? null
    }:{
      en: listItem?.name_en,
      ar: listItem?.name_ar,
    })
  };

  const [debounced_data] = useDebouncedValue(data, 200);

  const { translation }: any = useContext(TranslationContext);
  const { setReloader }: any = useContext(AppContext);

  const [buttonLoading, setButtonLoading] = useState(false);
  const required_field = vtn("required_field");

  const address_validation = (value:any, values:any) => {
    if (values?.address_type === "New address") {
      return value ? null : required_field;
    } else {
      return null;
    }
  };
  const [company, setCompany]: any = useState({});

  const [departments, setDepartments] = useState([]);
  const dialogMassage = ltn("user_added_successfully");
  const program_type = translation?.program_type;
  const city = translation?.city;
  const major = translation?.major;
  const degree = translation?.degree;
  const case_study = translation?.case_study;
  const period = translation?.period;
  const university = translation?.university;
  const accessible_data =
    pb.authStore?.model?.expand?.permission?.accessible_data?.en;
    const program_nature = translation?.program_nature;
    const address_type = translation?.address_type;
    const interview_type = translation?.interview_type;
    const skills = translation?.skills;

  const fill_all_fields = vtn("fill_all_fields");
  useEffect(() => {
    const resultList = pb
      .collection("departments_view")
      .getList(1, 150, {})
      .then((res: any) => {
        setDepartments(res?.items);
      });
  }, []);
  useEffect(() => {
    const resultList = pb
      .collection("companies_view")
      .getFirstListItem("")
      .then((res: any) => {
        setCompany(res);
      });
  }, []);
  const form: any = useForm({
    initialValues: {
      code : debounced_data?.code,
      department: debounced_data?.department,
      type: debounced_data?.type?.en,
      nature: debounced_data?.nature?.en,
      name: debounced_data?.name,
      brief: debounced_data?.brief,
      objective: debounced_data?.objective,
      period: debounced_data?.period?.en,
      degree: debounced_data?.degree?.[0]
      ? debounced_data?.degree?.map((item: any) => item?.en)
      : [],
      targeted_majors: debounced_data?.major?.[0]
        ? debounced_data?.major?.map((item: any) => item?.en)
        : [],
      target_educational_segment: debounced_data
        ?.target_educational_segment?.[0]
        ? debounced_data?.target_educational_segment?.map(
            (item: any) => item?.en
          )
        : [],
     
      candidate_in_need: parseInt(debounced_data?.candidate_in_need),
      incentive: parseFloat(debounced_data?.incentive)
        ? parseFloat(debounced_data?.incentive)
        : 0,
        is_incentive: debounced_data?.incentive ? true : false,
        last_submission_date: new Date(debounced_data?.last_submission_date),
        start_date: new Date(debounced_data?.start_date),
        address_type: debounced_data?.address_type?.en,
      city: debounced_data?.city?.en,
      location: debounced_data?.location,
      building_number: debounced_data?.building_number,
      floor: debounced_data?.floor,
      office_no: debounced_data?.office_no,
          
      has_interview: debounced_data?.has_interview,
      interview_type: debounced_data?.interview_type?.en,
      personal_interview_link:  debounced_data?.personal_interview_link,
      has_filtration_test:debounced_data?.has_filtration_test,
      other_requirements: debounced_data?.other_requirements?.[0]
        ? debounced_data?.other_requirements
        : [],
        required_skills: debounced_data?.required_skills?.[0]
        ? debounced_data?.required_skills?.map((item: any) => item?.en)
        : [],
        additional_instructions: debounced_data?.additional_instructions,
        attachment_1:debounced_data?.attachment_1?{name: debounced_data?.attachment_1,initial:true}:null,
        attachment_2: debounced_data?.attachment_2?{name: debounced_data?.attachment_2,initial:true}:null,
      universities: debounced_data?.universities?.[0]
        ? debounced_data?.universities?.map((item: any) => item?.en)
        : [],
      academic_average_4: parseFloat(debounced_data?.GPA_4),
      academic_average_5: parseFloat(debounced_data?.GPA_5),
          allUniversities:  data?.universities?.[0]?.en == "all universities",
      // status: {},
      filtration_test: debounced_data?.filtration_test ?? "",
    },

    validate: {
      personal_interview_link: (value, values) => {
        if (values?.has_interview) {
          if (values?.interview_type == "remote") {
            return value ? null : required_field;
          } else {
            return null;
          }
        } else {
          return null;
        }
      },

      department: isNotEmpty(required_field),
      type: isNotEmpty(required_field),
      name: isNotEmpty(required_field),
      brief: isNotEmpty(required_field),
      objective: isNotEmpty(required_field),
      targeted_majors: isNotEmpty(required_field),
      target_educational_segment: isNotEmpty(required_field),
      period: isNotEmpty(required_field),
      candidate_in_need: (value) => {
        const current_department: any = departments?.find(
          (item: any) => item?.id == form?.values?.department
        );
        if (value) {
          if (current_department?.[`current_acceptance_ceiling`] + parseInt(debounced_data?.candidate_in_need) < value) {
            return `لا يمكن ان يكون عدد المتدربين اكثر من ${
              current_department?.[`current_acceptance_ceiling`] + parseInt(debounced_data?.candidate_in_need)
            }`;
          }
        } else {
          return required_field;
        }
      },
      city: address_validation,
      location: address_validation,
      building_number: address_validation,
      floor: address_validation,
      office_no: address_validation,
      last_submission_date: isNotEmpty(required_field),
      start_date: isNotEmpty(required_field),
      degree: isNotEmpty(required_field),
      universities:  (value,values) => {
        if(!values?.allUniversities){
          return !value?.[0] ? required_field : null
        }
      },
      other_requirements: (value: any) => {
        if (value?.[0]) {
          if (value?.filter((item: any) => item?.name == "")?.[0]) {
            return fill_all_fields;
          } else {
            return null;
          }
        } else {
          return null;
        }
      },
    },
  });
  useEffect(() => {
    if (form.values?.incentive == "") {
      form.setFieldValue("incentive", 0);
    }
  }, [form.values?.incentive]);
 
  async function handleProgramUpdate(values:any, debouncedData:any, setReloader:any, onClose:any) {
    const is_new_address = values.address_type === "New address";

    const prepareData = {
        department: values.department,
        name: values.name,
        brief: values.brief,
        objective: values.objective,
        candidate_in_need: values.candidate_in_need,
        incentive: values.is_incentive ? values.incentive.toString() : null,
        type: findLocalizedNames(program_type, values.type),
        period: findLocalizedNames(period, values.period),
        requirements: values.requirements,
        universities: values.allUniversities ? [{
          en: "all universities",
          ar: "جميع الجامعات",
        }] : values.universities.map((item: any) => findLocalizedNames(university, item)),
        target_educational_segment: values.target_educational_segment.map((item: any) => findLocalizedNames(case_study, item)),
        major: values.targeted_majors.map((item: any) => findLocalizedNames(major, item)),
        degree: values.degree.map((item: any) => findLocalizedNames(degree, item)),
        last_submission_date: dayjs(values.last_submission_date).hour(23).minute(59).toDate(),
        start_date: values.start_date,
        end_date: dayjs(values.start_date).add(parseInt(findLocalizedNames(period, values.period).value), "month").hour(23).minute(59).toDate(),
        other_requirements: values.other_requirements,
        GPA_4: values.academic_average_4,
        GPA_5: values.academic_average_5,
        status: values.status,
        company: pb?.authStore?.model?.company,
        code: "C-1",
        nature: findLocalizedNames(program_nature, values.nature),
        address_type: findLocalizedNames(address_type, values.address_type),
        building_number: is_new_address ? values.building_number : company?.location_data?.building_number,
        floor: is_new_address ? values.floor :company?.location_data?.floor,
        office_no: is_new_address ? values.office_no : company?.location_data?.office_no,
        city: is_new_address ? findLocalizedNames(city, values.city) : company?.location_data?.city,
        location: is_new_address ? values.location : company?.location_data?.location,
        has_interview: values.has_interview,
        interview_type: findLocalizedNames(interview_type, values.interview_type),
        personal_interview_link: values.personal_interview_link,
        has_filtration_test: (values?.has_filtration_test && !!values?.filtration_test),
        required_skills: values.required_skills.map((item: any) => findLocalizedNames(skills, item)),
        additional_instructions: values.additional_instructions,
        filtration_test: values.filtration_test,
      };
    try {
      setButtonLoading(true)
      const formData = new FormData();  // Use the form data from the event
      if(values?.attachment_1 && !values?.attachment_1?.initial){
        formData.append('attachment_1',values?.attachment_1);
      }
     
      if(values?.attachment_2 && !values?.attachment_2?.initial){
        formData.append('attachment_2', values?.attachment_2);
      }
      const { id } = debouncedData;
      const { type } = prepareData;
      const typeChar = type?.en.charAt(0);
      const filter = `code_type = "${typeChar}" && id != "${id}"`;
  
      // Fetch the program codes sorted and filtered
      const res = await pb.collection("program_codes").getList(1, 50, {
        sort: "-code_number",
        order: "desc",
        filter,
      });
  
      const items = res?.items;
      const maxItem = _.maxBy(items, "code_number");
      const newCode = typeChar != maxItem?.code_type ?   maxItem
      ? `${maxItem.code_type}-${maxItem.code_number + 1}`
      : `${typeChar}-1`: values?.code;

      // Update the program with new or default code
      const updateResult = await pb.collection("programs").update(id, { ...prepareData, code: newCode });
      const updateAttachments = await pb.collection("programs").update(id,formData);
      if(!values?.attachment_1){
        const updateAttachments = await pb.collection("programs").update(id,{
          attachment_1:null
        });
      }
      if(!values?.attachment_2){
        const updateAttachments = await pb.collection("programs").update(id,{
          attachment_2:null
        });
      }
      if (updateResult?.id) {
        // If the update is successful, handle related applications
        const applications = await pb.collection("applications_view").getFullList({
          filter: `program = "${updateResult.id}"`,
        });
  
        for (const application of applications || []) {
          const newEndDate = dayjs(application.date_of_commencement)
            .add(parseInt(period.find((p:any) => p.name_en === updateResult.period?.en)?.value), "month")
            .toDate();
  
          await pb.collection("applications").update(application.id, { date_of_end: newEndDate });
        }
      }
  
      // Toggle reloader state to trigger UI updates
      setReloader((prevState:any) => !prevState);
      onClose({});
    } catch (error) {
      console.error("Failed to update program code:", error);
      onClose({ error });
    }
  }
  

  const { width } = useViewportSize();
  return width > 10 ? (
    <form
      style={{
        width: "100%",
      }}
      onSubmit={form.onSubmit((values:any)=>handleProgramUpdate(
        values,
        debounced_data,
        setReloader,
        onClose
      ))}
    >
       <ProgramForm
        company={company}
        form={form}
        data={data}
        title={tn("Edit program")}
        buttons={
          <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "end",
            padding: "20px 0px",
          }}
        >
          <Button
            type="submit"
            // mx="xs"
            compact
            loading={buttonLoading}
            style={{
              backgroundColor: "#69DCBF",
              color: "white",
              width: "100px",
              borderRadius: "5.112px",
            }}
          >
            {tn("Save")}
          </Button>

          <Button
            compact
            mx="sm"
            style={{
              color: "white",
              width: "100px",
              borderRadius: "5.112px",
              backgroundColor: "#F9837C",
            }}
            color="red"
            onClick={() => {
              onClose({});
            }}
          >
            {tn("Cancel")}
          </Button>
        </div>
        }
      />
     
    </form>
  ) : (
    <div
      style={{
        height: "1270px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Loader color="color1" />
    </div>
  );
};

