import { ltn, tn, vtn } from "../../../../../../../translation";
import {
  ActionIcon,
  Button,
  Checkbox,
  SegmentedControl,
  Select,
  Table,
  Text,
  TextInput,
} from "@mantine/core";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../../../../../../constants";
import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../../../../../../context/AppContext";
import { useDebouncedValue, useViewportSize } from "@mantine/hooks";
import { DatePicker, TimeInput } from "@mantine/dates";
import { IconChevronLeft, IconChevronRight, IconClock, IconX } from "@tabler/icons-react";
import { TranslationContext } from "../../../../../../../context/TranslationContext";
import { isNotEmpty, useForm } from "@mantine/form";
import { Action } from "../../../../ActionsMap";
import dayjs from "dayjs";
import alasql from "alasql";

export const Reschedule = (props: any) => {
  const pb: any = new PocketBase(CMS_ENDPOINT);
  const { data, onClose } = props;
  const [debounced_data] = useDebouncedValue(data, 200);
  const { reloader, setReloader } = useContext(AppContext);
  const { height, width } = useViewportSize();
  const { locale, translation } = useContext(TranslationContext);
  const [allApplicants, setAllApplicants] = useState([]);
  const durations = translation?.durations;
  const [buttonLoading, setButtonLoading] = useState(false)


  const form = useForm({
    initialValues: {
      date: debounced_data?.record?.[0]?"":debounced_data?.record?.date,
      time: debounced_data?.record?.[0]?"":dayjs(debounced_data?.record?.date).format("HH:mm"),
      duration: debounced_data?.record?.[0]?"":debounced_data?.record?.duration?.en,
      all_applicants: false,
    },
    validate: {
      date: isNotEmpty(vtn("date")),
      time: isNotEmpty(vtn("time")),
      duration: isNotEmpty(vtn("duration")),
    },
  });
  useEffect(() => {
    if (!debounced_data?.record?.[0]) {
      const record = pb
        .collection("applications_view")
        .getFullList({
          expand: "program,applicant",
          filter: `program = "${debounced_data?.record?.program}" && main_status.en = "scheduled personal interviews"`,
        })
        ?.then((res: any) => {
          setAllApplicants(res);
        });
    } else {
      const record = pb
        .collection("applications_view")
        .getFullList({
          expand: "program,applicant",
          filter: debounced_data?.record
            ?.map((item: any) => `id = "${item}"`)
            .join(" || "),
        })
        ?.then((res: any) => {
          setAllApplicants(res);
        });
    }
  }, [debounced_data,form?.values?.all_applicants]);

  return (
    <form
      onSubmit={form.onSubmit(async (value: any) => {
        setButtonLoading(true)

        const date = new Date(dayjs(value?.date).format("YYYY-MM-DD") + " " + value?.time)
        const data = {
          applicant_status: {
            en: "schedule the interview",
            ar: "جدولة المقابلة",
          },
          main_status: {
            en: "scheduled personal interviews",
            ar: "المقابلات الشخصية المجدولة",
          },
          interview_status:{
            en:"waiting for confirmation",
            ar:"بإنتظار التأكيد"
            
          },
          date: date,
          time: dayjs(date).format("hh:mm A"),
          duration: {
            en: durations?.find((item) => item?.name_en == value?.duration)?.name_en,
            ar: durations?.find((item) => item?.name_en == value?.duration)?.name_ar,
          },
        };
        if (!value?.all_applicants && !debounced_data?.record?.[0]) {
          const record = await pb
            .collection("applications")
            .update(debounced_data?.record?.id, data)
            ?.then((res: any) => {
              setReloader(!reloader);
              onClose({});
            });

        } else {
         const applicationsPrograms = alasql(`SELECT * FROM ? GROUP BY program`, [allApplicants]);
         for (const item_ of applicationsPrograms || []) {
           const programApplicants = allApplicants?.filter(item => item?.program === item_?.program);
           for (const [i, item__] of programApplicants.entries()) {
             const durationValue = durations?.find(item => item?.name_en === value?.duration)?.value;
             const date = dayjs(`${new Date(dayjs(value?.date).format("YYYY-MM-DD") + " " + value?.time)}`).add(i * (parseInt(durationValue) + 5), "minutes").toDate();
             const record = await pb
               .collection("applications")
               .update(item__?.id, { ...data, date: date, time: dayjs(date).format("hh:mm A") })
               .then(res => {
                 // You can handle the response here if needed
               });
           }
         
           // Place these outside of the inner loop, but inside the outer loop
           setReloader(!reloader);
           onClose({});
         };}
      })}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "start",
        padding: "20px",
        width: "100%",
        paddingBottom: "60px",
        position: "relative",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "end",
          padding: "20px 0px",
          position: "absolute",
          bottom: "0px",
          left: "10px",
        }}
      >
        <Button
          type="submit"
          // mx="xs"
          loading={buttonLoading}

          compact
          style={{
            backgroundColor: "#69DCBF",
            color: "white",
            width: "100px",
            borderRadius: "5.112px",
          }}
        >
          {tn("Save")}
        </Button>

        <Button
          compact
          mx="sm"
          style={{
            color: "white",
            width: "100px",
            borderRadius: "5.112px",
            backgroundColor: "#F9837C",
          }}
          color="red"
          onClick={() => {
            onClose({});
          }}
        >
          {tn("Cancel")}
        </Button>
      </div>
      <Text
        style={{
          color: "rgba(0, 0, 0, 0.70)",
          fontSize: "20px",
          fontWeight: "700",
        }}
      >
        {tn("Scheduling the personal interview")}
      </Text>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: width > 880 ? "row" : "column",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            width: width > 880 ? "calc(40% - 10px)" : "calc(100% - 20px)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "rgba(79, 81, 82, 0.05)",
            borderRadius: "5px",
            marginTop: "10px",
          }}
        >
          <div
            style={{
              width: "calc(100% - 20px)",
              borderRadius: "500px",
              backgroundColor: "#21033F",
              height: "5px",
            }}
          />
          <Text
            mt="xs"
            style={{
              color: "rgba(0, 0, 0, 0.70)",
              fontSize: "16px",
              fontWeight: "500",
            }}
          >
            {tn("Date")}
          </Text>
          <div
            style={{
              marginTop: "10px",
              width: "calc(100% - 20px)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <div
              style={{
                width: "calc(50% - 0px)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "start",
                direction: "ltr",
              }}
            >
              <DatePicker
               nextIcon={
                <IconChevronRight size={20} style={{ color: "#1d604f" }} />
              }
              previousIcon={
                <IconChevronLeft size={20} style={{ color: "#1d604f" }} />
              }
              firstDayOfWeek={0}
              minDate={new Date()}
              maxDate={debounced_data?.record?.[0]?new Date(allApplicants?.[0]?alasql(`Select * from ? ORDER BY program_start_date`,[allApplicants])?.[0]?.expand?.program?.start_date:null):new Date(debounced_data?.record?.expand?.program?.start_date)}
                {...form.getInputProps("date")}
                style={{
                  margin: "10px",
                }}
              />
              {form?.errors?.date && (
                <Text
                  style={{
                    color: "red",
                    fontSize: "12px",
                    fontWeight: "400",
                  }}
                >
                  {form?.errors?.date}
                </Text>
              )}
            </div>
            <div
              style={{
                width: "calc(100% - 40px)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <TimeInput
                  {...form.getInputProps("time")}
                  style={{
                    width: "calc(50% - 10px)",
                  }}
                  label={tn("the time")}
                  styles={() => ({
                    label: {
                      zIndex: "10",
                      position: "relative",
                      margin: "0px 10px",
                      transform: "translate(0px, 10px)",
                      background: "#f6f6f6",
                      padding: "0px 5px",
                      borderRadius: "15px",
                    },
                    input: {
                      borderRadius: "5px",
                      border: "1px solid rgba(0, 0, 0)",
                    },
                  })}
                  rightSection={<IconClock size="1rem" stroke={1.5} />}
                  maw={400}
                />

                <Select
                  {...form.getInputProps("duration")}
                  style={{
                    width: "calc(50% - 10px)",
                  }}
                  label={tn("Duration of the interview")}
                  styles={() => ({
                    label: {
                      borderRadius: "15px",

                      zIndex: "10",
                      position: "relative",
                      margin: "0px 10px",
                      transform: "translate(0px, 10px)",
                      background: "#f6f6f6",
                      padding: "0px 5px",
                    },
                    input: {
                      borderRadius: "5px",
                      border: "1px solid rgba(0, 0, 0)",
                    },
                  })}
                  rightSection={<IconClock size="1rem" stroke={1.5} />}
                  maw={400}
                  data={
                    durations?.[0]
                      ? durations?.map((item: any) => ({
                          value: item?.name_en,
                          label: item?.[`name_${locale}`],
                        }))
                      : []
                  }
                />
              </div>
              <Checkbox
                disabled={debounced_data?.record?.[0]}
                {...form.getInputProps("all_applicants")}
                styles={() => ({
                  label: {
                    fontWeight: "400",
                    color: "rgba(0, 0, 0, 0.70)",
                  },
                })}
                size="md"
                mt="md"
                mb="md"
                label={tn(
                  "Would you like to schedule all applicants for this program?"
                )}
              />
            </div>
          </div>
        </div>{" "}
        <div
          style={{
            width: width > 880 ? "calc(60% - 10px)" : "calc(100% - 20px)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "start",
            backgroundColor: "rgba(79, 81, 82, 0.05)",
            borderRadius: "5px",
            marginTop: "10px",
            height: "445px",
          }}
        >
          <div
            style={{
              width: "calc(100% - 20px)",
              borderRadius: "500px",
              backgroundColor: "#21033F",
              height: "5px",
            }}
          />
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "0px 20px",
              marginTop: "10px",
            }}
          >
            <Text
              mt="xs"
              style={{
                color: "rgba(0, 0, 0, 0.70)",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              {tn("Applicant data")}
            </Text>
            {!debounced_data?.record?.[0] && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <DataBox
                  text={debounced_data?.record?.expand?.program?.name}
                  label={tn("Program:")}
                />
                <DataBox
                  text={debounced_data?.record?.department_name}
                  label={tn("Department:")}
                />
              </div>
            )}
          </div>
          <div
            style={{
              width: "calc(100% - 20px)",
              background: "white",
              borderRadius: "10px",
              marginTop: "20px",
              padding: "10px",
              overflowY: "scroll",

              // height: "200px",
            }}
          >
            <Table fontSize="xs">
              <thead>
                <tr>
                {((form?.values?.all_applicants || debounced_data?.record?.[1]) && allApplicants?.[1]) &&<th>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                    </div>
                  </th>}
                  <th>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {tn("Program code")}
                    </div>
                  </th>
                  <th>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {tn("Applicant name")}
                    </div>
                  </th>
                  <th>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {tn("University")}
                    </div>
                  </th>
                  <th>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {tn("Major")}
                    </div>
                  </th>
                  <th>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {tn("graduation year")}
                    </div>
                  </th>
                  <th>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {tn("Semester")}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {!debounced_data?.record?.[0] ? (
                  form.values?.all_applicants ? (
                    allApplicants?.map((item: any) => (
                      <TdBox item={item} locale={locale} setAllApplicants={setAllApplicants} allApplicants={allApplicants} all_applicants={form?.values?.all_applicants} data={debounced_data?.record}/>
                    ))
                  ) : (
                    <TdBox item={debounced_data?.record} locale={locale} setAllApplicants={setAllApplicants} allApplicants={allApplicants} all_applicants={form?.values?.all_applicants} data={debounced_data?.record}/>
                  )
                ) : (
                  allApplicants?.map((item: any) => (
                    <TdBox item={item} locale={locale} setAllApplicants={setAllApplicants} allApplicants={allApplicants} all_applicants={form?.values?.all_applicants} data={debounced_data?.record}/>
                  ))
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </form>
  );
};

const DataBox = ({ text, label }: any) => (
  <div
    style={{
      marginTop: "10px",
      padding: "5px",
      borderRadius: "5px",
      backgroundColor: "#ffffff",
      position: "relative",
      border: "1px solid rgba(0, 0, 0 , 0.70)",
    }}
  >
    <Text
      style={{
        color: "rgba(0, 0, 0, 0.70)",
        fontSize: "12px",
        fontWeight: "500",
        position: "absolute",
        transform: "translate(-5px, -14px)",
        background: "white",
        borderRadius: "5px",
        padding: "0px 5px",
      }}
    >
      {label}
    </Text>
    <Text
      style={{
        color: "rgba(0, 0, 0, 0.70)",
        fontSize: "14px",
        fontWeight: "500",
      }}
    >
      {text}
    </Text>
  </div>
);
const TdBox = ({ item, locale, setAllApplicants, allApplicants, all_applicants, data }: any) => (
  <tr>
   {((all_applicants || data?.[1]) && allApplicants?.[1]) && <td>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <ActionIcon color="red" size="xs" onClick={()=>{
          setAllApplicants((prev:any)=>prev.filter((item_:any)=>item_?.id != item?.id))
        }}>
        <IconX size="0.7rem" stroke={1.5} />
        </ActionIcon>
      </div>
    </td>}
    <td>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {item?.expand?.program?.code}
      </div>
    </td>
    <td>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {item?.applicant_name}
      </div>
    </td>
    <td>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {item.university?.[locale]}
      </div>
    </td>{" "}
    <td>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {item?.major?.[locale]}
      </div>
    </td>{" "}
    <td>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {item?.graduation_year}
      </div>
    </td>{" "}
    <td>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {item?.semester?.[locale]}
      </div>
    </td>
  </tr>
);
