import { ltn, tn } from "../../../../../../translation";
import {Button, Table, Text} from "@mantine/core"
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../../../../../constants";
import { useContext } from "react";
import { AppContext } from "../../../../../../context/AppContext";
import { useDebouncedValue, useViewportSize } from "@mantine/hooks";

export const  ActiveProgramRecruiter = (props:any) => {
  const pb: any = new PocketBase(CMS_ENDPOINT);
  const { data , onClose} = props;
  const [debounced_data] = useDebouncedValue(data, 200);

  const {reloader, setReloader} = useContext(AppContext)

     return <div style={{
        display:"flex",
        flexDirection:"column",
        alignItems:"start",
        justifyContent:"start",
        padding:"20px",
        width:"100%",

     }}>
     <Text fz="xl" fw={700} color="#4a4a4a">
      {tn("Program coordinator information")}
     </Text>
     <MainBox
                style={{
                  height: "155px",
                  marginTop: "30px",
                }}
              >
                <Text
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "#5c5c5c",
                    textAlign: "center",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "7px",
                    marginTop: "10px",
                  }}
                >
                  {tn("Program coordinator")}
                </Text>
                <div
                  style={{
                    background: "#fff",
                    borderRadius: "10px",
                    width: "calc(100% - 20px)",
                    minWidth: "550px",
                    height: "100px",
                    margin: "10px 0px",
                    boxShadow: "0px 0px 3px #51515133",
                    display: "flex",
                    alignItems: "stretch",
                    justifyContent: "center",
                    padding: "0px 5px",
                  }}
                >
                  <Table>
                    <thead>
                      <tr>
                        <TH>{tn("name")}</TH>
                        <TH>{tn("Email Address")}</TH>
                        <TH>{tn("Mobile")}</TH>
                        <TH>{tn("Extension")}</TH>
                      </tr>
                    </thead>
                    <tbody>
                      {" "}
                      <tr key={1}>
                        <TD>{debounced_data?.recruiter_name}</TD>
                        <TD>{debounced_data?.recruiter_email}</TD>
                        <TD>{debounced_data?.recruiter_phone}</TD>
                        <TD>{debounced_data?.recruiter_extension}</TD>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </MainBox>
              <div style={{
                width: "100%",
                display:"flex",
                flexDirection:"row",
                alignItems:"center",
                justifyContent:"end",
                marginTop:"20px"

              }}>
              <Button style={{
                background:"#F9837C",
                borderRadius: "5.112px",
                width:"100px",
                height:"30px",
              }} onClick={()=>{
                onClose({})
              }}>
                {tn("close")}
              </Button>
              </div>
     </div>
}
const TH = (props: any) => {
  return (
    <th>
      {" "}
      <Text
        style={{
          fontSize: "13px",
          fontWeight: "bold",
          color: "#5c5c5c",
          textAlign: "center",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "7px",
          height: "30px",
        }}
      >
        {props.children}
      </Text>
    </th>
  );
};

const TD = (props: any) => {
  return (
    <td>
      {" "}
      <Text
        style={{
          fontSize: "12px",
          color: "#5c5c5c",
          textAlign: "center",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "30px",
        }}
      >
        {props.children}
      </Text>
    </td>
  );
};

const MainBox = (props: any) => {
  const { height, width }: any = useViewportSize();

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "rgba(79, 81, 82, 0.05)",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        alignItems: width > 600 ? "center" : "start",
        justifyContent: "start",
        marginTop: "20px",
        overflowX: "scroll",
        overflowY: "hidden",
        ...props.style,
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <div
          style={{
            background: "#21033F",
            borderRadius: "50px",
            width: "calc(100% - 20px)",
            minHeight: "5px",
          }}
        />
      </div>
      {props.children}
    </div>
  );
};
