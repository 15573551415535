import { ltn, tn } from "../../../../../../translation";

import {
  Avatar,
  Button,
  Checkbox,
  Loader,
  Text,
} from "@mantine/core";

import { useContext, useState } from "react";
import { TranslationContext } from "../../../../../../context/TranslationContext";
import { CMS_ENDPOINT } from "../../../../../../constants";
import PocketBase from "pocketbase";
import { AppContext } from "../../../../../../context/AppContext";
import { useDebouncedValue, useViewportSize } from "@mantine/hooks";
import { BannersContext } from "../../../../../../context/BannersContext";
import { IconSquareRoundedCheck } from "@tabler/icons-react";
import { ProgramDetails } from "../../../../../../applicant/web/components/ProgramDetails";
import { useNavigate } from "react-router-dom";
const pb = new PocketBase(CMS_ENDPOINT);
export const AcceptInvitation = ({ data, onClose }: any) => {
  const [debounced_data] = useDebouncedValue(data?.record, 200);
  const [isErrorE, setisErrorE]: any = useState(false);

  const { translation, locale }: any = useContext(TranslationContext);
  const { reloader , setReloader }: any = useContext(AppContext);

  const { width } = useViewportSize();
  const userData = pb?.authStore?.model;
  const [accept, setAccept] = useState(false);
  const { setTopBanner }: any = useContext(BannersContext);
  const navigate = useNavigate();
  return width > 10 ? (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Text
        mx="xl"
        style={{
          width: "100%",
          padding: "10px 30px 10px ",
          color: "rgba(0, 0, 0, 0.70)",
          textAlign: "right",
          fontSize: "22px",
          fontWeight: "700",
        }}
      >
        {tn("Apply for a training program")}
      </Text>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          padding: "0px 10px",
          flexDirection: width > 850 ? "row" : "column",
          alignItems: width > 850 ? "stretch" : "stretch",
          justifyContent: "space-around",
        }}
      >
        <div
          style={{
            width: width > 850 ? "calc(30% - 20px)" : "calc(100% - 10px)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            background: "rgba(79, 81, 82, 0.05)",
            borderRadius: "10px",
            // height: "350px",
          }}
        >
          <div
            style={{
              width: "93%",
              background: "#21033F",
              height: "5px",
              borderRadius: "522px",
            }}
          />
          <Text
            mt="xs"
            style={{ color: "#4D4D4D", fontSize: "14px", fontWeight: "700" }}
          >
            {tn("Review personal information")}
          </Text>
          <div
            style={{
              width: "100%",
              height: "100%",
              maxWidth: "140px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <Avatar
              mt="xl"
              size="100%"
              style={{
                // height: "145px",
                width: "100%",
              }}
              src={`${CMS_ENDPOINT}/api/files/${userData?.collectionName}/${userData?.id}/${userData?.avatar}?token=`}
              styles={()=>({
                placeholder: {
                  height: "138px",
                }
              })}
            >
              <img
                style={{
                  width: "125%",
                  height: "125%",
                }}
                src={"/avatar_placeholder.svg"}
              />
            </Avatar>
            <Text
              mt="xs"
              style={{
                color: "#4D4D4D",
                fontSize: "20px",
                fontWeight: "700",
              }}
            >
              {userData?.first_name + " " + userData?.last_name}
            </Text>
            <Text
              style={{
                marginTop: "3px",
                color: "#4D4D4D",
                fontSize: "12px",
                fontWeight: "700",
              }}
            >
              {userData?.major?.[locale] +
                " | " +
                (userData?.GPA + "/" + userData?.GPA_out_of)}
            </Text>
            <Text
              style={{
                marginTop: "3px",
                color: "#4D4D4D",
                fontSize: "12px",
                fontWeight: "700",
              }}
            >
              {tn("graduation year") + " " + userData?.graduation_year}
            </Text>
            <Text
              style={{
                marginTop: "3px",
                color: "#4D4D4D",
                fontSize: "12px",
                fontWeight: "700",
              }}
            >
              {userData?.semester?.[locale]}
            </Text>
            <Text
              style={{
                marginTop: "3px",
                color: "#4D4D4D",
                fontSize: "12px",
                fontWeight: "700",
              }}
            >
              {userData?.university?.[locale]}
            </Text>
            <a
              href={`${CMS_ENDPOINT}/api/files/${userData?.collectionName}/${userData?.id}/${userData?.cv}?token=`}
              download={userData?.cv}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Text
                style={{
                  color: "#4D4D4D",
                  fontSize: "12px",
                  fontWeight: "700",
                  marginBottom: "4px",
                }}
              >
                {userData?.cv}
              </Text>
            </a>
          </div>
          <div />
        </div>
        <div
          style={{
            width: width > 850 ? "calc(70% - 20px)" : "calc(100% - 10px)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "start",
            background: "rgba(79, 81, 82, 0.05)",
            borderRadius: "10px",
            marginTop: width > 850 ? "" : "10px",
            // height: width > 850 ? "350px" : "",
          }}
        >
          <div
            style={{
              width: "93%",
              background: "#21033F",
              height: "5px",
              borderRadius: "522px",
            }}
          />
          <ProgramDetails data={debounced_data?.expand?.program_id} width={width} company_name={debounced_data.company_name} />
        </div>
      </div>

      <Checkbox
        onChange={(e) => {
          setAccept(e.currentTarget.checked);
        }}
        style={{
          width: "100%",
          marginTop: "10px",
          marginBottom: "10px",
          color: "#4D4D4D",
          fontSize: "12px",
          fontWeight: "700",
          padding: "5px 20px",
        }}
        label={ltn("apply_checkbox")}
        styles={() => ({
          label: {
            color: "#4D4D4D",
            fontWeight: "700",
          },
        })}
      />
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "end",
          padding: "10px 0px",
        }}
      >
        <Button
          disabled={!accept}
          compact
          style={{
            // color: "white",
            width: "100px",
            borderRadius: "5.112px",
            backgroundColor: accept ? "#69DCBF" : "",
          }}
          // color="red"
          onClick={async () => {
            if(debounced_data?.application_link && debounced_data?.partial_status?.en){
              window.open(
                debounced_data?.application_link,
                "_blank",
                "noreferrer"
              );
            }else{
              const data = {
                program: debounced_data?.program_id,
                applicant: userData?.id,
                applicant_status:{
                  en:"under review",
                  ar:"تحت المراجعة"
                },
                recruiter_status:{
                  en:"under review",
                  ar:"تحت المراجعة"
                },
                main_status:{
                  "en": "waiting for scheduling",
                  "ar": "انتظار الجدولة"
                },
                date_of_commencement: new Date(debounced_data?.expand?.program_id?.start_date),
                date_of_end:new Date(debounced_data?.expand?.program_id?.end_date)
                
              };
  
              const record = await pb
                .collection("applications")
                .create(data)
                ?.then((res: any) => {
                  if(debounced_data?.expand?.program_id?.status?.en == "new"){
                    const record =  pb.collection('programs').update(debounced_data?.id, {
                      status:{
                        en:"under the procedure",
                        ar:"تحت الإجراء"
                      }
                    });
  
                  }
                  setTopBanner({
                    duration: 10000,
                    message:
                      locale == "ar"
                        ? "تم التقديم بنجاح على برنامج" +
                          " " +
                          debounced_data?.company_name +
                          " " +
                          "على" +
                          " " +
                          debounced_data.expand?.program_id?.name +
                          "، قم بمتابعة حالة الطلب من خلال صفحة طلباتي"
                        : "You have successfully applied for the" +
                          " " +
                          debounced_data.expand?.program_id?.name +
                          " " +
                          "program at" +
                          " " +
                          debounced_data?.company_name +
                          ". You can follow up on your application status through the My Applications page",
                    color: "#2DC071",
                    top: "83px",
                    backgroundColor: "#F2FFF7EB",
                    icon: <IconSquareRoundedCheck />,
                  });
                  const record =  pb
                  .collection("invitations")?.update(debounced_data?.id, {
                    status:{
                      en:"accepted",
                      ar:"مقبولة"
                    }
                  })
                  setReloader(!reloader);
                  onClose({});
                });
            }
           
          }}
        >
          {tn("Apply")}
        </Button>
        <Button
          compact
          mx="xl"
          style={{
            color: "white",
            width: "100px",
            borderRadius: "5.112px",
            backgroundColor: "#F9837C",
          }}
          color="red"
          onClick={() => {
            navigate("/applicant/programs_search")
            onClose({});
          }}
        >
          {tn("close")}
        </Button>
      </div>
    </div>
  ) : (
    <div
      style={{
        height: "1270px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Loader color="color1" />
    </div>
  );
};
